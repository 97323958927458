import { useCallback, useEffect, useMemo, useState } from "react";
import { getItem, setItem } from "@lib/localstorage";
import { FilterType } from "@containers/TicketFilter";

type UseFiltersHistoryHook = (size: number) => {
  history: FilterType[][];
  add: (filter: FilterType[]) => void;
};

const STORAGE_KEY = "filters";

const isFilterUnique = (filters: FilterType[][], filter: FilterType[]) => {
  const paramsMap = filter.reduce((acc, { value, field }) => {
    return {
      ...acc,
      [field.id]: value,
    };
  }, {} as Record<FilterType["field"]["id"], unknown>);

  return !filters.some(
    (filterFromHistory) =>
      filterFromHistory.length === filter.length &&
      filterFromHistory.every(
        ({ field, value }) => paramsMap[field.id] === value,
      ),
  );
};

export const useFiltersHistory: UseFiltersHistoryHook = (size) => {
  const [filtersHistory, setFiltersHistory] = useState<FilterType[][]>([]);

  const add = useCallback(
    (filter: FilterType[]) => {
      if (isFilterUnique(filtersHistory, filter)) {
        if (filtersHistory.length === size) {
          const newHistory = [...filtersHistory];
          newHistory.pop();
          setFiltersHistory([filter, ...newHistory]);
        } else {
          setFiltersHistory([filter, ...filtersHistory]);
        }
      }
    },
    [filtersHistory],
  );

  useEffect(() => {
    setFiltersHistory(getItem<"filters", FilterType[][]>(STORAGE_KEY) || []);
  }, []);

  useEffect(() => {
    if (filtersHistory.length) {
      setItem(STORAGE_KEY, filtersHistory);
    }
  }, [filtersHistory]);

  return useMemo(
    () => ({
      history: filtersHistory,
      add,
    }),
    [filtersHistory],
  );
};
