import {
  CustomPage,
  CreateCustomPageRequest,
  CreateCustomPageResponse,
} from "@functions-types";
import { makeCloudFunction } from "../makeCloudFunction";

export type { CustomPage };

export const createCustomPage = makeCloudFunction<
  CreateCustomPageRequest,
  CreateCustomPageResponse
>("customPage", "createCustomPage");
