import { QuillDelta, QuillViewer } from "@components/QuillEditor";
import { CommentActivity } from "@functions-types";
import { makeStyles } from "@providers/Mui";
import { FC } from "react";
import { RecordLayout } from "./RecordLayout";

type HistoryCommentRecordProps = {
  record: CommentActivity;
};

export const HistoryCommentRecord: FC<HistoryCommentRecordProps> = ({
  record,
}) => {
  const classes = useStyles();

  return (
    <RecordLayout record={record} verb="commented">
      <div className={classes.comment}>
        <QuillViewer
          value={
            typeof record.text === "string"
              ? record.text // legacy
              : (record.text.json as QuillDelta)
          }
        />
      </div>
    </RecordLayout>
  );
};

const useStyles = makeStyles((theme) => ({
  comment: {
    "& .ql-editor": {
      padding: 0,
    },
  },
}));
