import { useEffect } from "react";
import { usePrevious } from "@hooks/usePrevious";
import { CustomField } from "@functions-types";
import { isNonNullish } from "@lib/utils";

export function useCTIUpdate<T extends { id: string; customFields: (CustomField | null)[] } | null>(
  value: T,
  addField: (sourceId: string, fields: CustomField[]) => void,
  deleteField: (sourceId: string) => void,
  setValue: (
    fieldPath: string,
    value: string | boolean | null | undefined | Date | [string, string],
  ) => void,
) {
  const prevValue = usePrevious(value);

  useEffect(() => {
    if (prevValue?.id && prevValue.id !== value?.id) {
      deleteField(prevValue.id);
      setValue(`customFieldsValues.${prevValue.id}`, null);
    }

    if (value?.id && value?.customFields?.length && value.id !== prevValue?.id) {
      const customFields = value.customFields.filter(isNonNullish);
      addField(value.id, customFields);
      customFields.forEach(({ id, defaultValue, type }) => {
        if (type === "datePicker" && !defaultValue) {
          setValue(`customFieldsValues.${id}`, null);
        } else {
          setValue(`customFieldsValues.${id}`, defaultValue);
        }
      });
    }
  }, [value]);
}
