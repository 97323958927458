import React from "react";
import { Modify } from "@lib/types";
import { FormHelperText, Grid, SelectProps } from "@mui/material";
import { FieldMode, FieldModeSwitch } from "@components/Form/FieldModeSwitch";
import {
  NestedSelectFieldViewerProps,
  NestedSelectFieldViewer,
} from "./NestedSelectFieldViewer";
import { OptionsTree } from "@functions-types";
import { useForm } from "@components/Form/FormProvider";
import { get } from "lodash";
import { Controller, useWatch } from "react-hook-form";
import { FieldLabel } from "@components/Form/FieldLabel";
import { SelectLevel } from "@components/Form/fields/NestedSelectField/SelectLevel";

type NestedSelectFieldProps = Modify<
  SelectProps,
  {
    name: string;
    options: OptionsTree[];
    mode?: FieldMode;
    renderOption?: (option: OptionsTree) => React.ReactNode;
    valueClassName?: string;
    viewModeClassName?: string;
    getOptionText?: (option: OptionsTree) => string;
    NestedSelectFieldViewerProps?: Partial<NestedSelectFieldViewerProps>;
    submitOnChange?: boolean;
  }
>;

export const NestedSelectField: React.FC<NestedSelectFieldProps> = ({
  name,
  options,
  mode,
  viewModeClassName,
  NestedSelectFieldViewerProps = {},
  label,
  required,
}) => {
  const { methods } = useForm();
  const fieldValue = useWatch({ name }) as string[];
  const defaultValue = get(methods.control._defaultValues, name) as string[];
  const handleChange = (level: number, value: string) => {
    const newValue = [...(fieldValue ?? [])];
    newValue[level] = value;
    methods.setValue(name, newValue, { shouldValidate: true });
  };

  return (
    <Controller
      name={name}
      render={({ field, fieldState }) => {
        const value = field.value || defaultValue || [];

        return (
          <FieldModeSwitch
            mode={mode}
            field={name}
            edit={
              <Grid container direction="column">
                <FieldLabel label={label} name={name} required={required} />
                <Grid container wrap="nowrap" spacing={2}>
                  <SelectLevel
                    level={0}
                    value={value}
                    options={options}
                    onChange={handleChange}
                    hasError={!!fieldState.error?.message}
                  />
                </Grid>
                {fieldState.error?.message && (
                  <FormHelperText error sx={{ ml: "14px" }}>
                    {fieldState.error.message}
                  </FormHelperText>
                )}
              </Grid>
            }
            view={
              <NestedSelectFieldViewer
                {...NestedSelectFieldViewerProps}
                label={label}
                value={value}
                field={name}
                className={viewModeClassName}
              />
            }
          />
        );
      }}
    />
  );
};
