import React, { useState } from "react";
import { makeStyles } from "@providers/Mui";
import { Grid, Typography } from "@mui/material";
import { useSnackbar } from "@providers/Snackbar";
import {
  createStore as createStoreCloudFunction,
  Store,
  updateStore as updateStoreCloudFunction,
  useListCountries,
  useListStores,
} from "@cloud-functions";
import { useAsync } from "@hooks/useAsync";
import { logError } from "@lib/logger";
import { Form } from "@components/Form";
import { Button } from "@components/Button";
import { Blocker } from "@components/Blocker";
import { StoreForm } from "./StoreForm";
import { StoresList } from "./StoresList";
import { storeValidator } from "./storeValidator";
import { formStateToInput, storeToFormState } from "./utils";
import { StoreFormState } from "./types";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    height: "100%",
  },
  content: {
    height: "100%",
  },
  newStore: {
    color: theme.palette.info.main,
    textTransform: "none",
    width: "100%",
    marginTop: theme.spacing(1),
  },
}));

export const Stores: React.FC = () => {
  const classes = useStyles();
  const { showError, showSuccess } = useSnackbar();
  const [storesList, storesListStatus] = useListStores();
  const [countriesList, countriesListStatus] = useListCountries();
  const [selectedStore, setSelectedStore] = useState<Store | null>(null);
  const [createStore, createStoreState] = useAsync(createStoreCloudFunction);
  const [updateStore, updateStoreState] = useAsync(updateStoreCloudFunction);

  const handleSubmit = async (values: StoreFormState) => {
    try {
      if (selectedStore?.id) {
        const updatedStore = await updateStore({
          input: {
            id: selectedStore.id,
            ...formStateToInput(values),
          },
        });
        storesListStatus.refetch();
        setSelectedStore(updatedStore);
        showSuccess(<Typography variant="body2">Store updated successfully</Typography>);
      } else {
        const store = await createStore({
          input: formStateToInput(values),
        });
        storesListStatus.refetch();
        setSelectedStore(store);
        showSuccess(<Typography variant="body2">Store created successfully</Typography>);
      }
    } catch (err) {
      logError(err);

      showError(<Typography variant="body2">Something went wrong during saving status</Typography>);
    }
  };

  const loading =
    createStoreState.isLoading ||
    updateStoreState.isLoading ||
    storesListStatus.loading ||
    countriesListStatus.loading;

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={3}>
        <StoresList
          stores={storesList ?? []}
          onSelect={setSelectedStore}
          activeStoreId={selectedStore?.id}
        />
        <Button className={classes.newStore} onClick={() => setSelectedStore(null)}>
          Create store
        </Button>
      </Grid>
      <Grid item xs={9} className={classes.content}>
        <Form<StoreFormState>
          formMode="create"
          onSubmit={handleSubmit}
          className={classes.content}
          resolver={storeValidator}
          defaultValues={storeToFormState(selectedStore)}
          key={selectedStore?.id}>
          <StoreForm selectedStore={selectedStore} countries={countriesList ?? []} />
        </Form>
      </Grid>
      <Blocker open={loading} />
    </Grid>
  );
};
