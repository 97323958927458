import React, { useEffect } from "react";
import { MainLayout } from "@components/MainLayout";
import { useUser } from "@providers/Auth";
import { Grid, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { Navigate, Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { CTI } from "./CTI";
import { makeStyles } from "@providers/Mui";
import { ConfigOverride } from "./ConfigOverride";
import { SpacesSettings } from "./Spaces";
import { CustomFields } from "./CustomFields";
import { CustomPages } from "./CustomPages";
import { Automation } from "@pages/Admin/Automation";
import { Statuses } from "@pages/Admin/Statuses";
import { Groups } from "@pages/Admin/Groups";
import { Stores } from "@pages/Admin/Stores";

type Item = {
  disabled?: boolean;
  label: string;
  to: string;
};
const items: Item[] = [
  {
    label: "Spaces",
    to: "/admin/spaces",
  },
  {
    label: "Groups",
    to: "/admin/groups",
  },
  {
    label: "Teams",
    to: "/admin/teams",
    disabled: true,
  },
  {
    label: "Users",
    to: "/admin/users",
    disabled: true,
  },
  {
    label: "CTI",
    to: "/admin/cti",
  },
  {
    label: "Custom fields",
    to: "/admin/custom-fields",
  },
  {
    label: "Custom pages",
    to: "/admin/custom-pages",
  },
  {
    label: "Statuses",
    to: "/admin/statuses",
  },
  {
    label: "Country",
    to: "/admin/country",
    disabled: true,
  },
  {
    label: "Stores",
    to: "/admin/stores",
  },
  {
    label: "Severity",
    to: "/admin/severity",
    disabled: true,
  },
  {
    label: "Feature Flags",
    to: "/admin/app-config",
  },
  {
    label: "Automation",
    to: "/admin/automation",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    backgroundColor: theme.palette.grey[350],
  },
  sidebar: {
    borderRight: "1px solid #d2d2d2",
    backgroundColor: "#f9f9f9",
    minWidth: 150,
  },
  content: {
    padding: theme.spacing(2),
    height: "100%",
    overflowY: "auto",
  },
}));

export const Admin: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();
  const { user } = useUser();

  const isAdminOrManager = user?.isAdmin || user?.isManager;

  useEffect(() => {
    if (isAdminOrManager) return;
    navigate("/");
  }, []);

  if (!isAdminOrManager) {
    return <>Loading...</>;
  }

  return (
    <MainLayout fullWidth fullHeight className={classes.root}>
      <Grid container direction="row" wrap="nowrap" sx={{ height: "100%" }}>
        <Grid item xs={2} className={classes.sidebar}>
          <List>
            {items.map((item) => (
              <ListItem disablePadding key={item.label}>
                <ListItemButton
                  selected={location.pathname === item.to}
                  disabled={item.disabled}
                  onClick={() => {
                    navigate(item.to);
                  }}>
                  <ListItemText primary={item.label} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={10} className={classes.content}>
          <Routes>
            <Route path="/cti" element={<CTI />} />
            <Route path="/app-config" element={<ConfigOverride />} />
            <Route path="/spaces" element={<SpacesSettings />} />
            <Route path="/groups" element={<Groups />} />
            <Route path="/custom-fields" element={<CustomFields />} />
            <Route path="/automation" element={<Automation />} />
            <Route path="/custom-pages" element={<CustomPages />} />
            <Route path="/statuses" element={<Statuses />} />
            <Route path="/stores" element={<Stores />} />
            <Route path="*" element={<Navigate to="/admin/cti" />} />
          </Routes>
        </Grid>
      </Grid>
    </MainLayout>
  );
};
