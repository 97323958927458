import React, { useRef } from "react";
import { SnackbarProvider as NotistackSnackbarProvider } from "notistack";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@providers/Mui";

const useStyles = makeStyles((theme) => ({
  root: {
    "& $variantSuccess": {
      backgroundColor: theme.palette.success.main,
    },
    "& $variantError": {
      backgroundColor: theme.palette.error.main,
    },
  },
  contentRoot: {
    flexWrap: "nowrap",
    alignItems: "baseline",
  },
  variantSuccess: {
    "& $icon": {
      color: "white",
    },
  },
  variantError: {
    backgroundColor: theme.palette.error.main,
    "& $icon": {
      color: "white",
    },
  },
  closeIcon: {
    alignSelf: "baseline",
    color: "white",
  },
}));

export const SnackbarProvider: React.FC = ({ children }) => {
  const notistackRef = useRef<null | NotistackSnackbarProvider>(null);
  const classes = useStyles();

  const onClickDismiss =
    (key: React.ReactText): (() => void) =>
    (): void => {
      notistackRef.current?.closeSnackbar(key);
    };

  return (
    <NotistackSnackbarProvider
      ref={notistackRef}
      maxSnack={3}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      action={(key) => (
        <IconButton onClick={onClickDismiss(key)}>
          <CloseIcon fontSize="small" className={classes.closeIcon} />
        </IconButton>
      )}
      classes={classes}>
      {children}
    </NotistackSnackbarProvider>
  );
};
