import { FeatureName } from "@functions-types";
import { useAppConfig } from "@providers/AppConfigProvider";

export const useFeatureFlag = (feature: FeatureName) => {
  const { config } = useAppConfig();
  return config.featureFlags[feature];
};

export const useTicketAttachmentsFeature = () => useFeatureFlag("ticketAttachments");
export const useCommentAttachmentsFeature = () => useFeatureFlag("commentAttachments");
export const useRiderLoginFeature = () => useFeatureFlag("riderLogin");
export const useCustomFieldsFeature = () => useFeatureFlag("customFields");
export const useCustomPagesFeature = () => useFeatureFlag("customPages");
export const usePrivateTicketsFeature = () => useFeatureFlag("privateTickets");
export const useTicketSLAFeature = () => useFeatureFlag("ticketSLA");
export const useSpaceOverviewFeature = () => useFeatureFlag("spaceOverview");
export const useTicketSearchFeature = () => useFeatureFlag("ticketSearch");
export const useWorkflowFeature = () => useFeatureFlag("workflow");
export const useAssignToMyselfFeature = () => useFeatureFlag("assignToMyself");
export const useLiveTicketsCollectionV1Feature = () => useFeatureFlag("liveTicketsCollectionV1");
export const useApprovalsFeature = () => useFeatureFlag("approvals");
export const useUserProfileFeature = () => useFeatureFlag("userProfile");
export const useLinkedTicketsFeature = () => useFeatureFlag("linkedTickets");
