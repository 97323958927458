import React from "react";
import { makeStyles } from "@providers/Mui";
import { Ticket } from "@cloud-functions";
import { Link } from "react-router-dom";
import { HighlightMatches } from "./HighlightMatches";
import { HasClassName } from "@lib/types";
import clsx from "clsx";

type SearchResultMode = "link" | "preview";

type SearchResultProps = HasClassName & {
  ticket: Ticket;
  onClick?: () => void;
  mode?: SearchResultMode;
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.grey[100],
    marginTop: theme.spacing(1),
    display: "flex",
    textDecoration: "none",
    color: theme.palette.grey[700],
    width: "100%",
  },
  id: {
    color: theme.palette.primary.dark,
    minWidth: 80,
  },
  title: {
    color: theme.palette.grey[900],
  },
  space: {
    marginLeft: "auto",
  },
}));

export const SearchResult: React.FC<SearchResultProps> = ({
  ticket,
  onClick,
  className,
  mode = "link",
}) => {
  const classes = useStyles();

  // TODO: extract this into <Wrapper /> utility
  const Wrapper: React.FC =
    mode === "link"
      ? (props) => (
          <Link
            to={`/ticket/${ticket.id}`}
            className={clsx(classes.root, className)}
            onClick={onClick}>
            {props.children}
          </Link>
        )
      : (props) => (
          <div className={clsx(classes.root, className)} onClick={onClick}>
            {props.children}
          </div>
        );

  return (
    <Wrapper>
      <span className={classes.id}>
        <HighlightMatches>{ticket.id}</HighlightMatches>
      </span>

      <span className={classes.title}>
        <HighlightMatches>{ticket.title}</HighlightMatches>
      </span>

      <span className={classes.space}>{ticket.item?.space?.name}</span>
    </Wrapper>
  );
};
