import React from "react";
import ViewListIcon from '@mui/icons-material/ViewList';
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@providers/Mui";
import { H4 } from "@components/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    minHeight: 400,
  },
  icon: {
    color: theme.palette.secondary.main,
    fontSize: 60,
    marginBottom: theme.spacing(2),
  },
  message: {
    color: theme.palette.secondary.main,
    marginBottom: theme.spacing(2),
  },
}));

export const EmptyList: React.FC = () => {
  const classes = useStyles();

  return (
    <Grid container direction="column" alignItems="center" justifyContent="center" className={classes.root}>
      <ViewListIcon className={classes.icon} />
      <H4 className={classes.message}>
        The space has no any agents
      </H4>
    </Grid>
  );
};
