import React from "react";
import { Grid, Typography } from "@mui/material";
import { Form } from "@components/Form";
import { FilterForm } from "./FilterForm";
import { FilterProps, FilterFormInput } from "./types";
import { multipleOperations } from "./constants";
import { useSnackbar } from "@providers/Snackbar";

export const Filter = <T extends Record<string, unknown>>(
  props: FilterProps<T>,
): React.ReactElement => {
  const { onAdd } = props;
  const { showError } = useSnackbar();
  const handleSubmit = ({ field, value, operation }: FilterFormInput) => {
    if (multipleOperations.includes(operation) && Array.isArray(value) && !value?.length) {
      showError(
        <Typography variant="body2">
          Please provide at least one value
        </Typography>
      )
      return;
    }
    if (!field || value === undefined || !operation) return;

    onAdd({
      field,
      operation,
      value:
        !value ? value
          : typeof value === "string"
          ? value
          : "id" in value
          ? value.id
          : value.map(({ id, label }) => ({ id, label })),
      label: !value ? "N/A"
        : Array.isArray(value)
        ? value.map(({ name, label }) => name ?? label ?? "N/A").join(", ")
        : typeof value === "string"
        ? value
        : value.name ?? value.label ?? "N/A",
    });
  };

  return (
    <Form<FilterFormInput>
      onSubmit={handleSubmit}
      formMode="create"
      key={props.options.length}
    >
      <Grid
        container
        direction="row"
        flexWrap="nowrap"
        spacing={1}
        alignItems="flex-end"
      >
        <FilterForm {...props} />
      </Grid>
    </Form>
  );
};
