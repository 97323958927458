import {
  Ticket,
  CustomPageValue,
  UpdateTicketRequest,
  UpdateTicketResponse,
} from "@functions-types";
import { makeCloudFunction } from "../makeCloudFunction";

export type { Ticket, CustomPageValue };
export const updateTicket = makeCloudFunction<
  UpdateTicketRequest,
  UpdateTicketResponse
>("ticket", "updateTicket");
