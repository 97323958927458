import React, { useEffect, useState, useMemo } from "react";
import { H6 } from "@components/Typography";
import { Column, TicketsTable } from "@containers/TicketsTable";
import { Card } from "@components/Card";
import { makeStyles } from "@providers/Mui";
import { useUser } from "@providers/Auth";
import { useListTickets } from "@cloud-functions";
import { ListTicketRequest } from "@functions-types";
import { TicketDialog } from "@components/TicketDialog";
import { Blocker } from "@components/Blocker";
import { useOnEvent } from "@providers/EventsProvider";
import { ClassNameMapOf, GlobalTicketEvents } from "@lib/types";
import { EmptyList, EmptyListProps } from "./EmptyList";
import { useTicketSLAFeature } from "@hooks/featureFlags";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    padding: theme.spacing(2, 0),
    maxHeight: 330,
    minHeight: 100,
    display: "flex",
    flexDirection: "column",
    "&:not(:first-child)": {
      marginTop: theme.spacing(3),
    },
  },
  empty: {},
  hasRows: {},
  tableHeader: {
    display: "flex",
    padding: theme.spacing(0, 2.5, 1.5),
  },
  table: {
    padding: theme.spacing(0, 2.5),
  },
}));

const DEFAULT_COLUMNS: Column[] = ["title", "status", "deadline", "severity", "category"];

type TicketsProps = {
  getFilter: (userId: string, ticketSLAFeatureEnabled: boolean) => ListTicketRequest;
  title: React.ReactNode;
  emptyIcon?: React.ReactNode;
  rowsIcon?: React.ReactNode;
  classes?: ClassNameMapOf<typeof useStyles>;
  columns?: Column[];
  EmptyListProps?: Partial<EmptyListProps>;
};

export const Tickets: React.FC<TicketsProps> = (props) => {
  const {
    getFilter,
    emptyIcon,
    rowsIcon,
    title,
    columns = DEFAULT_COLUMNS,
    EmptyListProps = {},
  } = props;
  const classes = useStyles(props);
  const { user } = useUser();
  const ticketSLAFeatureEnabled = useTicketSLAFeature();
  const filter = useMemo(
    () => getFilter(user.id, ticketSLAFeatureEnabled),
    [user.id, ticketSLAFeatureEnabled, getFilter],
  );

  const [tickets, ticketsState] = useListTickets(filter);
  const [viewTicketId, setViewTicketId] = useState<string | null>(null);
  const viewTicket = useMemo(
    () => tickets?.find((ticket) => ticket.id === viewTicketId),
    [viewTicketId, tickets],
  );

  useEffect(() => {
    ticketsState.refetch(getFilter(user.id, ticketSLAFeatureEnabled));
  }, [ticketSLAFeatureEnabled]);

  useOnEvent<GlobalTicketEvents>("update-tickets", () =>
    ticketsState.refetch(getFilter(user.id, ticketSLAFeatureEnabled)),
  );

  const tableColumns = useMemo(() => {
    if (ticketSLAFeatureEnabled) return columns;
    return columns.filter((column) => column !== "deadline");
  }, [ticketSLAFeatureEnabled]);

  const isEmpty = !ticketsState.loading && !tickets?.length;
  const hasRows = !!tickets?.length;

  return (
    <Card className={clsx(classes.root, isEmpty && classes.empty, hasRows && classes.hasRows)}>
      <H6 className={classes.tableHeader}>
        {isEmpty && emptyIcon}
        {hasRows && rowsIcon}
        {title}
      </H6>
      <TicketsTable
        tickets={tickets ?? []}
        onOpen={(ticket) => setViewTicketId(ticket.id)}
        columns={tableColumns}
        fetchNext={() => ticketsState.fetchNext(getFilter(user.id, ticketSLAFeatureEnabled))}
        isFetching={ticketsState.loading}
        interactionSettings={{
          changeAssignee: false,
          changeStatus: false,
        }}
        className={classes.table}
      />
      {isEmpty && <EmptyList {...EmptyListProps} />}
      {viewTicket && (
        <TicketDialog
          open={!!viewTicket}
          onClose={() => setViewTicketId(null)}
          ticketId={viewTicket.id}
          isPrivate={viewTicket.isPrivate}
          spaceKey={viewTicket.item?.space?.key}
        />
      )}
      <Blocker open={ticketsState.loading && !tickets?.length} />
    </Card>
  );
};
