import React from "react";
import { Typography } from "@mui/material";
import { HasClassName } from "@lib/types";
import { makeStyles } from "@providers/Mui";
import clsx from "clsx";

type H6Props = HasClassName & {};

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: 16,
    fontWeight: 700,
  },
}));

export const H6: React.FC<H6Props> = ({ children, className }) => {
  const classes = useStyles();

  return <Typography className={clsx(classes.root, className)}>{children}</Typography>;
};
