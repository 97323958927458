import React from "react";
import { CustomFieldsWithValue } from "@functions-types";
import { FieldGroup } from "@components/Form/FieldGroup";
import { FieldComponent } from "@components/TicketCard/CustomFields/FieldComponent";
import { Grid } from "@mui/material";
import { Button } from "@components/Button";
import { makeStyles } from "@providers/Mui";
import { useForm } from "@components/Form/FormProvider";
import { Blocker } from "@components/Blocker";

type CustomFieldsProps = {
  fieldsList: CustomFieldsWithValue[];
  formMode: "create" | "edit";
  isLoading?: boolean;
};

const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: theme.spacing(2),
    textTransform: "none",
  },
}));

export const CustomFields: React.FC<CustomFieldsProps> = ({
  fieldsList,
  formMode,
  isLoading,
}) => {
  const classes = useStyles();
  const { resetActiveFields, activeAll } = useForm();
  if (!fieldsList.length) return null;

  return (
    <FieldGroup
      title="Additional information"
      collapsable={formMode === "edit"}>
      <Grid container spacing={2} position="relative">
        {fieldsList.map((field) => (
          <Grid item xs={12} key={field.id}>
            <FieldComponent field={field} />
          </Grid>
        ))}
        {formMode === "edit" && activeAll && (
          <Grid item>
            <Button variant="outlined" type="submit">
              Save
            </Button>
            <Button
              variant="text"
              type="button"
              className={classes.button}
              onClick={resetActiveFields}>
              Cancel
            </Button>
          </Grid>
        )}
        <Blocker open={!!isLoading} />
      </Grid>
    </FieldGroup>
  );
};
