import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { config } from "./config";

Sentry.init({
  environment: config.env,
  dsn: config.sentry?.dsn,
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: config.sentry?.tracesSampleRate,
});

ReactDOM.render(<App />, document.getElementById("root"));
