import { DecodedCTIApprovalConfigPopulated, UpdateCategoryRequest } from "@functions-types";

type UpdateCategoryInput = UpdateCategoryRequest["input"]["updates"];
type ApprovalInput = UpdateCategoryInput["approvals"];

export function fromApprovalsConfigToApprovalsInput(
  approvals: DecodedCTIApprovalConfigPopulated | null,
): ApprovalInput {
  return approvals
    ? {
        ...approvals,
        rules: (approvals.rules || []).map((rule) => ({
          ...rule,
          count: Number.isNaN(Number(rule.count)) ? 0 : Number(rule.count),
          users: (rule.users || []).map((user) => user.id),
          group: rule.group?.id ?? null,
        })),
      }
    : null;
}
