import { makeStyles } from "@providers/Mui";
import { FC } from "react";
import { useForm } from "../FormProvider";
import { HasClassName } from "@lib/types";
import clsx from "clsx";

export type FieldMode = "create" | "edit";

export type FieldModeSwitchProps = HasClassName & {
  edit: React.ReactElement;
  view: React.ReactElement;
  field: string;
  mode?: FieldMode;
};

export const FieldModeSwitch: FC<FieldModeSwitchProps> = ({
  field,
  edit,
  view,
  className,
  ...rest
}) => {
  const { activeFields, formMode, activeAll } = useForm();
  const classes = useStyles();
  const mode = rest.mode ?? formMode;

  return (
    <div className={clsx(classes.root, className)}>
      {activeFields[field] || activeAll || mode === "create" ? <>{edit}</> : <>{view}</>}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {},
}));
