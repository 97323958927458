import React from "react";
import { Rating as MuiRating } from "@mui/material";
import { makeStyles } from "@providers/Mui";
import StarIcon from "@mui/icons-material/Star";

type RatingProps = {
  rating?: number;
};

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.yellow[300],
    cursor: "default",
    fontSize: 22,
  },
  emptyIcon: {
    color: theme.palette.grey[400],
  },
}));

export const Rating: React.FC<RatingProps> = ({ rating }) => {
  const { emptyIcon, ...classes } = useStyles();
  return (
    <MuiRating
      value={rating ?? 0}
      readOnly
      classes={classes}
      precision={0.1}
      emptyIcon={
        <StarIcon
          className={emptyIcon}
          fontSize="inherit"
          sx={{ opacity: rating ? 1 : 0.6 }}
        />
      }
    />
  );
};
