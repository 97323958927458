import { makeCloudFunction } from "../makeCloudFunction";
import {
  UpdateCommentActivityRequest,
  UpdateCommentActivityResponse,
} from "@functions-types";

export const updateComment = makeCloudFunction<
  UpdateCommentActivityRequest,
  UpdateCommentActivityResponse
>("activity", "updateComment");
