import React from "react";
import { makeStyles } from "@providers/Mui";
import { Grid, Typography } from "@mui/material";
import { H6 } from "@components/Typography";
import { TextField } from "@components/Form";
import { Button } from "@components/Button";
import { CustomField } from "@pages/Admin/CTI/CustomField";
import { useListCustomFields } from "@cloud-functions";
import { Blocker } from "@components/Blocker";
import { useFormState } from "react-hook-form";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[200],
    height: "100%",
    overflowX: "auto",
  },
  container: {
    paddingBottom: theme.spacing(2),
    "&:last-children": {
      paddingBottom: 0,
    },
  },
  field: {
    "& .MuiOutlinedInput-root": {
      backgroundColor: "white",
    },
  },
  error: {
    fontSize: 12,
    color: theme.palette.error.dark,
    paddingLeft: 14,
    paddingBottom: theme.spacing(1),
  },
}));

export const CustomPagesForm: React.FC = () => {
  const classes = useStyles();
  const [customFields, customFieldsStatus] = useListCustomFields();
  const formState = useFormState();
  const error = formState.errors?.customFields?.message;

  return (
    <Grid className={classes.root}>
      <div className={classes.container}>
        <H6>Create custom page</H6>
      </div>
      <div className={classes.container}>
        <TextField
          name="name"
          label="Name"
          variant="outlined"
          fullWidth
          className={classes.field}
        />
      </div>
      <div className={classes.container}>
        <TextField
          name="content"
          label="Content"
          variant="outlined"
          fullWidth
          multiline
          rows={4}
          className={classes.field}
        />
      </div>
      <div className={classes.container}>
        <CustomField customFields={customFields ?? []} />
        {!!error && (
          <Typography className={classes.error}>
            {error}
          </Typography>
        )}
      </div>
      <div className={classes.container}>
        <Button
          type="submit"
          variant="contained"
        >
          Save
        </Button>
      </div>
      <Blocker open={customFieldsStatus.loading} />
    </Grid>
  );
};
