import { StatusChip } from "@components/StatusChip";
import { Status } from "@functions-types";
import { FC } from "react";

type StatusValueProps = {
  value: Status;
};

export const StatusValue: FC<StatusValueProps> = ({ value }) => {
  return <StatusChip status={value} />;
};
