import React from "react";
import { makeStyles } from "@providers/Mui";
import { Status, StatusColor } from "@functions-types";
import { Chip, ChipProps } from "@mui/material";
import { HasClassName, Modify } from "@lib/types";
import clsx from "clsx";

type StatusChipProps = Modify<
  ChipProps,
  HasClassName & {
    status: Status | null;
    onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  }
>;

export type ColorProps = { backgroundColor: string; color: string };

const statusColors: Record<StatusColor, ColorProps> = {
  orange: {
    backgroundColor: "rgb(255 226 199)",
    color: "rgb(255 124 4)",
  },
  blue: {
    backgroundColor: "rgb(177 217 255)",
    color: "rgb(0 75 145)",
  },
  red: {
    backgroundColor: "rgb(255 199 199)",
    color: "rgb(255 4 4)",
  },
  grey: {
    backgroundColor: "#e2e2e2",
    color: "#525252",
  },
  green: {
    backgroundColor: "rgb(206 242 197)",
    color: "rgb(36 112 1)",
  },
};

export const getStatusStyles = (status: Status) => {
  return statusColors[status.color] ?? {};
};

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 5,
    fontWeight: 600,
  },
}));

export const StatusChip: React.FC<StatusChipProps> = ({ status, onClick, className, ...props }) => {
  const classes = useStyles();

  if (!status) {
    return (
      <Chip
        {...props}
        size="small"
        className={clsx(classes.root, className)}
        style={statusColors.red}
        label="N/A"
        onClick={onClick}
      />
    );
  }

  return (
    <Chip
      {...props}
      size="small"
      className={clsx(classes.root, className)}
      style={getStatusStyles(status)}
      label={status.label}
      onClick={onClick}
    />
  );
};
