import { Button, Typography } from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";
import ElectricBike from "@mui/icons-material/ElectricBike";
import SettingsIcon from "@mui/icons-material/Settings";
import { FC } from "react";
import clsx from "clsx";
import { useRiderLoginFeature } from "@hooks/featureFlags";

import { config } from "../../config";
import { useAuth } from "../../providers/Auth/AuthProvider";
import { makeStyles } from "../../providers/Mui";

export type LoginOptionsProps = {
  onRiderLoginClick: () => void;
};

export const LoginOptions: FC<LoginOptionsProps> = ({ onRiderLoginClick }) => {
  const { login, cypressLogin } = useAuth();
  const classes = useStyles();
  const isRiderLoginEnabled = useRiderLoginFeature();

  return (
    <>
      <Typography className={classes.message}>
        Welcome to Zapp Trouble Ticket
      </Typography>
      <Typography className={classes.loginMessage}>
        Login to your account
      </Typography>

      <Button
        className={classes.button}
        startIcon={<GoogleIcon />}
        onClick={login}
        variant="outlined">
        Google Login for Cental Ops Employee
      </Button>

      {config.cypressUserId && (
        <Button
          className={clsx(classes.button, classes.cypressLoginButton)}
          startIcon={<SettingsIcon />}
          onClick={cypressLogin}
          variant="outlined"
          data-cy="cypress_silentLogin">
          Cypress silent login
        </Button>
      )}

      {isRiderLoginEnabled && (
        <Button
          className={classes.button}
          startIcon={<ElectricBike />}
          onClick={onRiderLoginClick}
          variant="outlined">
          SMS Login for Rider Employee
        </Button>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  message: {
    marginTop: theme.spacing(1),
    fontSize: 22,
    fontWeight: "bold",
    [theme.breakpoints.up("md")]: {
      fontSize: 22,
    },
  },
  loginMessage: {
    marginTop: theme.spacing(1),
    fontSize: 16,
  },
  button: {
    display: "flex",
    textTransform: "none",
    margin: theme.spacing(3, "auto"),
    borderColor: theme.palette.primary.main,
    borderRadius: 8,
    color: theme.palette.primary.main,
    fontWeight: "bold",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 380,
      fontSize: 14,
    },
  },
  cypressLoginButton: {
    visibility: "hidden",
    height: 0,
    margin: 0,
    padding: 0,
  },
}));
