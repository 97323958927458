import { RadioGroupField } from "@components/Form";
import { CTIApprovalConfig, CTIApprovalConfigMode, Group } from "@functions-types";
import { makeStyles } from "@providers/Mui";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import clsx from "clsx";
import React, { FC, useMemo, useState } from "react";
import { Collapse, darken, Tooltip } from "@mui/material";
import { FieldLabel } from "@components/Form/FieldLabel";
import { useWatch } from "react-hook-form";
import ArrowIcon from "@mui/icons-material/KeyboardArrowDown";
import { ApprovalRulesField } from "./ApprovalRules";
import { ApprovalRulesPreview } from "./ApprovalRulesPreview";
import { useActiveCTI } from "../ActiveCTIProvider";
import { capitalize } from "lodash";
import { getNodeParentApprovalsConfig } from "./utils/getNodeParentApprovalsConfig";

const ctiApprovalModeLabels: Record<CTIApprovalConfigMode, string> = {
  none: "-None-",
  inherit: "Inherits parent",
  extend: "Extends parent",
  custom: "Custom rules",
};

const approvalConfigModeOptions: {
  value: CTIApprovalConfigMode;
  label: string;
}[] = [
  {
    value: "none",
    label: ctiApprovalModeLabels["none"],
  },
  {
    value: "inherit",
    label: ctiApprovalModeLabels["inherit"],
  },
  {
    value: "extend",
    label: ctiApprovalModeLabels["extend"],
  },
  {
    value: "custom",
    label: ctiApprovalModeLabels["custom"],
  },
];

type ApprovalsFieldProps = {
  groups: Group[];
  className?: string;
  mode: "category" | "type" | "item";
};

export const ApprovalField: FC<ApprovalsFieldProps> = ({ className, mode, groups, }) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles({ open });

  const { item, type, category } = useActiveCTI();
  const self = mode === "item" ? item : mode === "type" ? type : category;
  const selfTypeName = capitalize(mode);
  const selfName = self?.name ?? "";
  const isCategory = selfTypeName === "Category";

  const defaultValue: CTIApprovalConfig = {
    mode: "none",
    rules: [],
  };

  const selfValue = useWatch<{
    approvals: CTIApprovalConfig;
  }>({
    name: "approvals",
    defaultValue,
  }) as CTIApprovalConfig;

  const inherited = useMemo(() => {
    return getNodeParentApprovalsConfig(self, mode, type, category);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [self, mode, category, type]);

  const allRulesCount = useMemo(() => {
    const inheritedRuledCount =
      selfValue.mode === "extend" || selfValue.mode === "inherit"
        ? inherited.reduce((sum, group) => {
            return sum + group[0].length;
          }, 0)
        : 0;

    const selfRulesCount =
      selfValue.mode === "custom" || selfValue.mode === "extend" ? selfValue.rules.length : 0;

    return inheritedRuledCount + selfRulesCount;
  }, [inherited, selfValue.mode, selfValue.rules.length]);

  return (
    <div className={clsx(classes.root, className)}>
      <FieldLabel
        name="approvals"
        className={classes.label}
        onClick={() => setOpen((o) => !o)}
        label={
          <>
            <DoneAllIcon fontSize="inherit" className={classes.icon} />
            <span className={classes.labelText}>
              Approvals
              {!open && <i>{`: ${ctiApprovalModeLabels[selfValue.mode]} (${allRulesCount})`}</i>}
            </span>
            <ArrowIcon
              fontSize="small"
              className={clsx(classes.arrow, open && classes.arrowOpen)}
            />
            <Tooltip title={`Define approval rules for this (${selfTypeName})`} placement="top">
              <InfoOutlinedIcon fontSize="small" />
            </Tooltip>
          </>
        }
      />
      <Collapse in={open}>
        <div className={classes.options}>
          <RadioGroupField
            name="approvals.mode"
            label={null}
            defaultValue={defaultValue}
            options={approvalConfigModeOptions.filter((o) => {
              if (!isCategory) return true;
              if (o.value === "inherit" || o.value === "extend") return false;
              return true;
            })}
          />

          {(selfValue.mode === "extend" || selfValue.mode === "inherit") &&
            inherited.map(([rules, source, name], index) => (
              <React.Fragment key={index}>
                <hr />

                <ApprovalRulesPreview
                  title={`Inherited/Extended rules from parent ${name} (${source.name})`}
                  rules={rules}
                />
              </React.Fragment>
            ))}

          {(selfValue.mode === "custom" || selfValue.mode === "extend") && (
            <>
              <hr />

              <ApprovalRulesField
                title={`Custom Rules for ${selfTypeName} (${selfName})`}
                rules={selfValue.rules}
                groups={groups}
              />
            </>
          )}
        </div>
      </Collapse>
    </div>
  );
};

const useStyles = makeStyles<{ open: boolean }>((theme) => ({
  root: {
    backgroundColor: theme.palette.green[100],
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  label: {
    width: "100%",
    padding: theme.spacing(1),
    backgroundColor: darken(theme.palette.green[100], 0.05),
    marginBottom: ({ open }) => (open ? theme.spacing(1) : 0),
    "&:hover": {
      cursor: "pointer",
    },
  },
  labelText: {
    flexGrow: 1,
  },
  options: {
    padding: theme.spacing(1),
  },
  arrow: {
    transform: "rotate(0deg)",
    transition: "transform ease 500ms",
  },
  arrowOpen: {
    transform: "rotate(180deg)",
  },
}));
