import React from "react";
import { CustomPage } from "@functions-types";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@providers/Mui";

type PageCardProps = {
  customPage: CustomPage;
  onSelect: (customPage: CustomPage) => void;
  activePage?: string;
};

const useStyles = makeStyles<{ active: boolean }>((theme) => ({
  root: ({ active }) => ({
    padding: theme.spacing(1, 2),
    transition: "background-color 0.2s",
    cursor: "pointer",
    backgroundColor: active ? theme.palette.grey[200] : "transparent",
    "&:hover": {
      backgroundColor: theme.palette.grey[200],
    }
  }),
  label: {
    fontSize: 14,
    fontWeight: "bold",
  },
}));

export const PageCard: React.FC<PageCardProps> = ({ customPage, onSelect, activePage }) => {
  const classes = useStyles({ active: customPage.id === activePage });
  return (
    <Grid onClick={() => onSelect(customPage)} className={classes.root}>
      <Typography className={classes.label}>
        {customPage.name}
      </Typography>
    </Grid>
  );
};
