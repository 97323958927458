import React, { createContext, useContext, useEffect, useRef } from "react";

type Callback<D extends unknown = unknown> = (data: D) => void;

type On<E extends object, K extends keyof E = keyof E, D = E[K]> = {
  (name: K, callback: Callback<D>): void;
};
type Off<E extends object, K extends keyof E = keyof E, D = E[K]> = {
  (name: K, callback: Callback<D>): void;
};
type Dispatch<E extends object, K extends keyof E = keyof E, D = E[K]> = {
  (name: K, data: D): void;
};
export type EventsContextValue = {
  on: On<any>;
  off: Off<any>;
  dispatch: Dispatch<any>;
};

export const EventsContext = createContext<EventsContextValue>(
  null as unknown as EventsContextValue,
);

export const EventsProvider: React.FC = ({ children }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const listenersRef = useRef<Record<string, Callback<any>[]>>({});

  const on: On<any> = (name: any, callback) => {
    listenersRef.current[name] = listenersRef.current[name] || [];
    listenersRef.current[name].push(callback);
  };

  const off: Off<any> = (name: any, callback) => {
    const index = (listenersRef.current[name] || []).findIndex(
      (fn) => fn === callback,
    );

    if (index !== -1) {
      listenersRef.current[name]?.splice(index, 1);
    }
  };

  const dispatch: Dispatch<any> = (name: any, data: unknown) => {
    const listeners = listenersRef.current[name] ?? [];

    listeners.forEach((l) => {
      l(data);
    });
  };

  return (
    <EventsContext.Provider value={{ on, off, dispatch }}>
      {children}
    </EventsContext.Provider>
  );
};

export function useDispatch<E extends object>(): Dispatch<E> {
  return useContext(EventsContext).dispatch;
}

export function useOnEvent<
  E extends object,
  K extends keyof E = keyof E,
  D = E[K],
>(name: K, cb: Callback<D>): void {
  const { on, off } = useContext(EventsContext);

  const cbRef = useRef(cb);
  cbRef.current = cb;

  useEffect(() => {
    const eventCallback = (data: D) => {
      cbRef.current(data);
    };

    on(name, eventCallback);

    return () => {
      off(name, eventCallback);
    };
  }, []);
}
