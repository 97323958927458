import { FC } from "react";
import clsx from "clsx";
import { FormLabel } from "@mui/material";
import { makeStyles } from "@providers/Mui";
import { useForm } from "../FormProvider";

export type FieldLabelProps = {
  name?: string;
  label?: React.ReactNode;
  action?: React.ReactNode;
  hasUnsavedChanges?: boolean;
  className?: string;
  onClick?: () => void;
  required?: boolean;
};

export const FieldLabel: FC<FieldLabelProps> = ({
  name,
  label,
  action,
  hasUnsavedChanges,
  className,
  onClick,
  required,
}) => {
  const classes = useStyles();
  const { methods } = useForm();

  return (
    <div className={classes.wrapper}>
      {label && (
        <FormLabel className={clsx(classes.root, className)} htmlFor={name} onClick={onClick}>
          {label}
          {required && " *"}
          {hasUnsavedChanges && !methods.formState.isSubmitting && (
            <span className={classes.hasUnsavedChanges}>&nbsp;• Unsaved changes</span>
          )}
        </FormLabel>
      )}
      {action}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    fontWeight: "bold",
    color: theme.palette.text.secondary,
    fontSize: 12,
    [theme.breakpoints.up("sm")]: {
      fontSize: 14,
    },
    marginBottom: theme.spacing(0.75),
    display: "flex",
    alignItems: "center",
  },
  hasUnsavedChanges: {
    color: theme.palette.warning.dark,
    fontSize: 12,
  },
  wrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
  },
}));
