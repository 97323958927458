import React, { useEffect, useMemo } from "react";
import {
  Table,
  TableBody,
  TableRow as MuiTableRow,
  TableContainer,
} from "@mui/material";
import { TableRow } from "./TableRow";
import { makeStyles } from "@providers/Mui";
import { useInView } from "react-intersection-observer";
import { HasClassName } from "@lib/types";
import { Columns, ExtendedUser } from "./types";
import { Header } from "./Header";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
    width: "100%",
    overflowY: "auto",
    overflowX: "auto",
  },
}));

type UsersTableProps = HasClassName & {
  users: ExtendedUser[];
  fetchNext?: () => void;
  onOpen?: (user: ExtendedUser) => void;
  columns: Columns[];
};

export const UsersTable: React.FC<UsersTableProps> = ({
  users,
  fetchNext,
  onOpen,
  columns,
  className,
}) => {
  const classes = useStyles();
  const columnsMap = useMemo(
    () =>
      columns.reduce(
        (acc, cur) => ({ ...acc, [cur]: true }),
        {} as Record<Columns, boolean>,
      ),
    [columns],
  );

  const { ref, inView } = useInView({
    initialInView: false,
  });

  useEffect(() => {
    if (inView) {
      fetchNext?.();
    }
  }, [inView]);

  return (
    <TableContainer className={clsx(classes.container, className)}>
      <Table stickyHeader>
        <Header columnsMap={columnsMap} />
        <TableBody>
          {users.map((user) => (
            <TableRow
              user={user}
              key={user.id}
              onOpen={() => onOpen?.(user)}
              columnsMap={columnsMap}
            />
          ))}
          {!!fetchNext && <MuiTableRow ref={ref} />}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
