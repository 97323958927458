import React, { useEffect, useMemo } from "react";
import { Autocomplete, CheckboxField, SelectField, TextField } from "@components/Form";
import { makeStyles } from "@providers/Mui";
import { Collapse, FormHelperText, Tooltip } from "@mui/material";
import { useWatch } from "react-hook-form";
import { useForm } from "@components/Form/FormProvider";
import {
  automationSourceOptions,
  automationTypesOptions,
  commentAuthorOptions,
} from "../../utils";
import { Automation, CTITree, Status } from "@functions-types";
import { useOnChange } from "@hooks/useOnChange";
import { applyDefaultState } from "./utils";
import { fieldsMap } from "@lib/constants";
import { CustomFieldsSelector } from "./CustomFieldsSelector";
import { StatusField } from "@containers/TicketFilter/StatusField";
import { AutomationTypeOption, AutomationSourceOption } from "../../types";

const useStyles = makeStyles((theme) => ({
  fieldWrapper: {
    paddingBottom: theme.spacing(2),
    "&:last-children": {
      paddingBottom: 0,
    },
  },
  field: {
    "& .MuiOutlinedInput-root": {
      backgroundColor: theme.palette.common.white,
    },
  },
  select: {
    backgroundColor: theme.palette.common.white,
  },
  group: {
    margin: theme.spacing(1, 0, 2),
    borderLeft: `2px solid ${theme.palette.grey[400]}`,
    paddingBottom: 0,
    paddingLeft: theme.spacing(2)
  },
  helper: {
    paddingTop: theme.spacing(1),
  },
  link: {
    color: theme.palette.primary.dark,
  },
}));

type AutomationDetailsProps = {
  automation: Automation | null;
  categories: CTITree[];
  statuses: Status[];
};

export const AutomationDetails: React.FC<AutomationDetailsProps> = ({
  automation,
  categories,
  statuses,
}) => {
  const classes = useStyles();
  const actionType: AutomationTypeOption | null = useWatch({ name: "action.type" });
  const actionContent = useWatch({ name: "action.content" });
  const source: AutomationSourceOption | null = useWatch({ name: "source" });
  const { methods: { setValue, formState } } = useForm();

  const category: CTITree | null = useWatch({ name: "category" });
  const type: CTITree["types"][0] | null = useWatch({ name: "type" });

  useEffect(() => {
    if (categories.length && !category && automation?.category) {
      applyDefaultState(
        setValue,
        categories,
        automation.category?.id,
        automation.type?.id,
        automation.item?.id,
      );
    }
  }, [automation, formState.isSubmitting]);

  useOnChange(category, () => {
    setValue("type", null);
    setValue("item", null);
  });

  useOnChange(type, () => {
    setValue("item", null);
  });

  const onInsertUserNameClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    setValue("action.content", `${actionContent ?? ""}##username##`);
  };

  const types = useMemo(() => category?.types ?? [], [category]);
  const items = useMemo(() => type?.items ?? [], [type]);

  return (
    <>
      <div className={classes.fieldWrapper}>
        <TextField
          name="name"
          label="Name"
          variant="outlined"
          fullWidth
          className={classes.field}
        />
      </div>
      <div className={classes.fieldWrapper}>
        <Autocomplete
          name="category"
          label={fieldsMap.category}
          options={categories}
          saveOnChange={false}
          getOptionText={(option) => option.name}
          isOptionEqualToValue={(option, value) =>
            option.id === value.id
          }
          className={classes.select}
        />
      </div>
      <div className={classes.fieldWrapper}>
        <Autocomplete
          name="type"
          label={fieldsMap.type}
          saveOnChange={false}
          getOptionText={(option) => option.name}
          isOptionEqualToValue={(option, value) =>
            option.id === value.id
          }
          placeholder={
            !types.length
              ? `-- Please select ${fieldsMap.category} --`
              : undefined
          }
          options={types}
          disabled={!types.length}
          className={classes.select}
        />
      </div>
      <div className={classes.fieldWrapper}>
        <Autocomplete
          name="item"
          label={fieldsMap.item}
          placeholder={
            !items.length
              ? `-- Please select ${fieldsMap.type} --`
              : undefined
          }
          saveOnChange={false}
          getOptionText={(option) => option.name}
          isOptionEqualToValue={(option, value) =>
            option.id === value.id
          }
          options={items}
          disabled={!items.length}
          className={classes.select}
        />
      </div>
      <div className={classes.fieldWrapper}>
        <CustomFieldsSelector />
      </div>
      <div className={classes.fieldWrapper}>
        <SelectField
          name="source"
          label="Event source"
          variant="outlined"
          fullWidth
          className={classes.select}
          getOptionText={(option) => option.label}
          options={automationSourceOptions}
        />
      </div>
      <Collapse in={source?.id === "ztt_comment_created"}>
        <div className={classes.group}>
          <div className={classes.fieldWrapper}>
            <SelectField
              name="commentOptions.commentedByReporter"
              label="Comment created by"
              variant="outlined"
              fullWidth
              className={classes.select}
              getOptionText={(option) => option.label}
              options={commentAuthorOptions}
            />
          </div>
        </div>
      </Collapse>
      <div className={classes.fieldWrapper}>
        <SelectField
          name="action.type"
          label="Action type"
          variant="outlined"
          fullWidth
          className={classes.select}
          getOptionText={(option) => option.label}
          options={automationTypesOptions}
        />
      </div>
      <Collapse in={actionType?.id === "changeStatus"}>
        <div className={classes.group}>
          <div className={classes.fieldWrapper}>
            <StatusField
              name="action.content"
              label="New status"
              operation="=="
              options={statuses}
            />
          </div>
        </div>
      </Collapse>
      <Collapse in={actionType?.id === "comment"}>
        <div className={classes.group}>
          <div className={classes.fieldWrapper}>
            <TextField
              name="action.content"
              label="Comment text"
              variant="outlined"
              fullWidth
              multiline
              rows={3}
              className={classes.field}
            />
            <FormHelperText className={classes.helper}>
              Please use placeholder{" "}
              <a
                href="#"
                className={classes.link}
                onClick={onInsertUserNameClick}
              >
                ##username##
              </a>
              {" "}to insert full name of reporter into a comment
            </FormHelperText>
          </div>
        </div>
      </Collapse>
      <div className={classes.fieldWrapper}>
        <CheckboxField
          name="active"
          size="small"
          disableRipple
          label="Automation active"
        />
      </div>
      <div className={classes.fieldWrapper}>
        <Tooltip
          title="Automation can be applied to the same source multiple times"
          placement="top"
        >
          <CheckboxField
            name="repeatable"
            size="small"
            disableRipple
            label="Repeatable"
          />
        </Tooltip>
      </div>
    </>
  );
};
