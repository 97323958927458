import {
  Status,
  CreateStatusRequest,
  CreateStatusResponse,
} from "@functions-types";
import { makeCloudFunction } from "../makeCloudFunction";

export type { Status };

export const createStatus = makeCloudFunction<
  CreateStatusRequest,
  CreateStatusResponse
>("status", "createStatus");
