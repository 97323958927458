import { useReducer, useCallback, useMemo } from "react";
import { Ticket, QueryFilterOperations } from "@functions-types";
import { useListCountries, useListSeverity, useListStatuses } from "@cloud-functions";
import {
  FilterableProperties,
  FilterType,
  Operation,
  TicketSelects,
} from "./types";

const DEFAULT_OPERATION: QueryFilterOperations = "==";

type UseTicketFilterHook = (options: {
  fields: (keyof Partial<FilterableProperties>)[];
  operationsMap: Partial<Record<keyof Ticket, Operation[]>>;
  defaultFilter?: FilterType[];
}) => FilterState & {
  addFilter: (filter: FilterType) => void;
  removeFilter: (filter: FilterType) => void;
  applyFilters: (filters: FilterType[]) => void;
  loading: boolean;
  selects: Partial<TicketSelects>;
};

type FilterState = {
  filters: FilterType[];
  remainingFields: [keyof FilterableProperties, Operation[]][];
};

type AddFilterAction = {
  type: "add_filter";
  payload: FilterType;
};

type RemoveFilterAction = {
  type: "remove_filter";
  payload: FilterType;
};

type ApplyFiltersAction = {
  type: "apply_filters";
  payload: FilterType[];
};

type FilterActions = AddFilterAction | RemoveFilterAction | ApplyFiltersAction;

const getUsedFiltersMap = (filters: FilterType[]) => {
  return filters.reduce((acc, cur) => {
    return {
      ...acc,
      [cur.field.id]: true,
    };
  }, {} as Record<keyof Ticket, boolean>);
};

const getInitialState = (
  fields: (keyof Partial<FilterableProperties>)[],
  operationsMap: Partial<Record<keyof FilterableProperties, Operation[]>>,
  defaultFilter: FilterType[],
): FilterState => {
  const usedFiltersMap = getUsedFiltersMap(defaultFilter);

  return {
    remainingFields: fields
      .filter((fieldName) => !usedFiltersMap[fieldName])
      .map((fieldName) => [
        fieldName,
        operationsMap[fieldName] ?? [DEFAULT_OPERATION],
      ]),
    filters: defaultFilter,
  };
};

const reducer =
  (
    fields: (keyof Partial<FilterableProperties>)[],
    operationsMap: Partial<Record<keyof FilterableProperties, Operation[]>>,
  ) =>
  (state: FilterState, action: FilterActions): FilterState => {
    switch (action.type) {
      case "add_filter": {
        const filters = [
          ...state.filters.filter(
            ({ field }) => field !== action.payload.field,
          ),
          action.payload,
        ];
        const usedFiltersMap = getUsedFiltersMap(filters);
        return {
          ...state,
          filters,
          remainingFields: fields
            .filter((fieldName) => !usedFiltersMap[fieldName])
            .map((fieldName) => [
              fieldName,
              operationsMap[fieldName] ?? [DEFAULT_OPERATION],
            ]),
        };
      }
      case "remove_filter": {
        const filters = state.filters.filter(
          ({ field }) => field !== action.payload.field,
        );
        const usedFiltersMap = getUsedFiltersMap(filters);
        return {
          ...state,
          filters,
          remainingFields: fields
            .filter((fieldName) => !usedFiltersMap[fieldName])
            .map((fieldName) => [
              fieldName,
              operationsMap[fieldName] ?? [DEFAULT_OPERATION],
            ]),
        };
      }
      case "apply_filters": {
        const usedFiltersMap = getUsedFiltersMap(action.payload);
        return {
          ...state,
          filters: action.payload,
          remainingFields: fields
            .filter((fieldName) => !usedFiltersMap[fieldName])
            .map((fieldName) => [
              fieldName,
              operationsMap[fieldName] ?? [DEFAULT_OPERATION],
            ]),
        };
      }
    }
    return state;
  };

export const useTicketFilter: UseTicketFilterHook = ({
  fields,
  operationsMap,
  defaultFilter = [],
}) => {
  const [severity, { loading: severityLoading }] = useListSeverity();
  const [country, { loading: countriesLoading }] = useListCountries();
  const [status, { loading: statusesLoading }] = useListStatuses();

  const [filterState, dispatch] = useReducer(
    reducer(fields, operationsMap),
    getInitialState(fields, operationsMap, defaultFilter),
  );

  const addFilter = useCallback((filter: FilterType) => {
    dispatch({
      type: "add_filter",
      payload: filter,
    });
  }, []);

  const removeFilter = useCallback((filter: FilterType) => {
    dispatch({
      type: "remove_filter",
      payload: filter,
    });
  }, []);

  const applyFilters = useCallback((filters: FilterType[]) => {
    dispatch({
      type: "apply_filters",
      payload: filters,
    });
  }, []);

  return useMemo(
    () => ({
      ...filterState,
      addFilter,
      removeFilter,
      applyFilters,
      loading:
        severityLoading ||
        // categoriesLoading ||
        // typesLoading ||
        // itemsLoading ||
        // teamsLoading ||
        countriesLoading ||
        statusesLoading,
      selects: {
        severity,
        // category,
        // type,
        // item,
        // team,
        country,
        status,
      },
    }),
    [
      filterState,
      addFilter,
      removeFilter,
      applyFilters,
      severityLoading,
      countriesLoading,
      statusesLoading,
      severity,
      country,
      status,
    ],
  );
};

