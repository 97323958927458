import { HistoryActivity, Ticket } from "@functions-types";
import { makeStyles } from "@providers/Mui";
import { FC, useMemo } from "react";
import { RecordLayout } from "./RecordLayout";
import { FieldValue, fieldComponentMap } from "./Values/Field";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { UpdateTicketForm } from "@components/TicketCard/utils";
import { fieldsMap } from "@lib/constants";

type HistoryChangeRecordProps = {
  record: HistoryActivity<keyof Ticket>;
};

export const HistoryChangeRecord: FC<HistoryChangeRecordProps> = ({
  record,
}) => {
  const classes = useStyles();

  const verb = useMemo(() => {
    if (!record.from) {
      return "set";
    }

    if (!record.to) {
      return "removed";
    }

    return "updated";
  }, [record]);

  const field = record.field as keyof UpdateTicketForm;

  return (
    <RecordLayout
      record={record}
      verb={
        <span>
          {verb}{" "}
          <strong>
            <i>{fieldsMap[record.field]}</i>
          </strong>{" "}
        </span>
      }>
      {fieldComponentMap[field] && (
        <div className={classes.root}>
          <div className={classes.from}>
            <FieldValue field={field} value={record.from} />
          </div>

          <div className={classes.arrow}>
            <ArrowForwardIcon fontSize="inherit" />
          </div>
          <div className={classes.to}>
            <FieldValue field={field} value={record.to} />
          </div>
        </div>
      )}
    </RecordLayout>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  from: {
    // maxWidth: "50%",
  },
  to: {
    // maxWidth: "50%",
  },
  arrow: {
    margin: theme.spacing(0, 3),
    fontSize: 14,
    color: "grey",
    display: "flex",
    alignSelf: "center",
  },
}));
