import { useCallback, useMemo } from "react";
import {
  Category,
  Group,
  Item,
  Severity,
  Type,
  useListGroups,
  useListItems,
  useListTypes,
  useListSeverity,
  useListCategories,
  useListSpaces,
  Space,
  useListCustomFields,
  CustomField,
} from "@cloud-functions";

export type UseSettingsLookupsResult = {
  loading: boolean;
  severity: Severity[];
  spaces: Space[];
  teams: Group[];
  categories: Category[];
  customFields: CustomField[];
  typesMap: Record<string, Type[]>;
  itemsMap: Record<string, Item[]>;
  update: () => void;
};

export const useCTILookups = (): UseSettingsLookupsResult => {
  const [severity, { loading: severityLoading }] = useListSeverity();
  const [categories, categoriesStatus] = useListCategories();
  const [typesData, typesStatus] = useListTypes();
  const [itemsData, itemsStatus] = useListItems({});
  const [customFields, customFieldsStatus] = useListCustomFields();
  const [teams, { loading: teamsLoading }] = useListGroups();
  const [spaces, { loading: spacesLoading }] = useListSpaces();

  const loading =
    severityLoading ||
    itemsStatus.loading ||
    categoriesStatus.loading ||
    teamsLoading ||
    spacesLoading ||
    customFieldsStatus.loading;

  const itemsMap = useMemo(() => {
    if (!itemsData?.length) return {};

    return itemsData.reduce((acc, curr) => {
      if (!curr?.type?.id) return acc;
      return {
        ...acc,
        [curr.type.id]: [...(acc[curr.type.id] || []), curr],
      };
    }, {} as Record<string, Item[]>);
  }, [itemsData]);

  const typesMap = useMemo(() => {
    if (!typesData?.length) return {};
    return typesData.reduce((acc, curr) => {
      if (!curr.category?.id) return acc;
      return {
        ...acc,
        [curr.category.id]: [...(acc[curr.category.id] || []), curr],
      };
    }, {} as Record<string, Type[]>);
  }, [typesData]);

  const handleUpdate = useCallback(() => {
    categoriesStatus.refetch();
    typesStatus.refetch();
    itemsStatus.refetch({});
  }, []);

  return {
    loading,
    severity: severity ?? [],
    spaces: spaces ?? [],
    categories: categories ?? [],
    teams: teams ?? [],
    customFields: customFields ?? [],
    typesMap,
    itemsMap,
    update: handleUpdate,
  };
};
