import {
  Review,
  CreateReviewRequest,
  CreateReviewResponse,
} from "@functions-types";
import { makeCloudFunction } from "../makeCloudFunction";

export type { Review };
export const createReview = makeCloudFunction<
  CreateReviewRequest,
  CreateReviewResponse
>("review", "createReview");
