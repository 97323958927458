import { CustomFieldForm } from "./types";
import { CreateCustomFieldInput, CustomField } from "@functions-types";
import { omit } from "lodash";

export const fieldsTypes = [
  { id: "numberField", name: "Number field" },
  { id: "textField", name: "Text field" },
  { id: "multilineText", name: "Multiline text field" },
  { id: "checkbox", name: "Checkbox" },
  { id: "selectField", name: "Select field" },
  { id: "nestedSelectField", name: "Nested select field" },
  { id: "datePicker", name: "Date picker" },
  { id: "spaceField", name: "ZTT Space picker" },
  { id: "storeSelectField", name: "Store select field" },
];

export const formStateToInput = ({
  type,
  defaultValue,
  ...values
}: CustomFieldForm): CreateCustomFieldInput => {
  return {
    type: type.id,
    defaultValue:
      defaultValue && typeof defaultValue === "object" && "id" in defaultValue
        ? defaultValue.id
        : defaultValue,
    ...omit(values, "lessThanEnabled", "greaterThanEnabled"),
    options: type.id === "selectField" ? values.options : null,
  };
};

export const customFieldToFormState = (
  values?: CustomField | null,
): CustomFieldForm | undefined => {
  if (!values) return undefined;
  const { type, defaultValue, ...customField } = values;
  return {
    type: {
      id: type,
      name: fieldsTypes.find(({ id }) => id === type)?.name ?? "N/A",
    },
    defaultValue:
      type === "selectField" && typeof defaultValue === "string"
        ? { id: defaultValue, label: defaultValue }
        : defaultValue ?? "",
    greaterThanEnabled: values.greaterThan !== null && values.greaterThan !== undefined,
    lessThanEnabled: values.lessThan !== null && values.lessThan !== undefined,
    ...customField,
  };
};
