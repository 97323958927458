import { FC, useEffect, useRef } from "react";
import { Form, OTPField } from "@components/Form";
import { useForm } from "@components/Form/FormProvider";
import LinearProgress from "@mui/material/LinearProgress";

import { makeStyles } from "../../../providers/Mui";
import { useRiderLogin } from "@providers/RiderLoginProvider";
import { useAsync } from "@hooks/useAsync";
import { Button, Typography } from "@mui/material";
import clsx from "clsx";

export const OneTimePassForm: FC<{ disabled: boolean }> = (props) => {
  const { triggerSubmit } = useForm();

  return (
    <OTPField
      name="otp"
      variant="outlined"
      fullWidth
      size="small"
      disabled={props.disabled}
      onChange={(val) => {
        if (val?.length === 6) {
          triggerSubmit();
        }
      }}
    />
  );
};

export const OneTimePass: FC = () => {
  const classes = useStyles();
  const riderLogin = useRiderLogin();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [confirmOTP, { isLoading, error }] = useAsync(riderLogin.confirmOTP);

  const handleSubmit = async (values: any) => {
    await confirmOTP(values.otp);
  };

  const recaptchaContainerRef = useRef(null as any as HTMLDivElement);

  useEffect(() => {
    riderLogin.renderRecaptcha(recaptchaContainerRef.current);
  }, []);

  return (
    <div>
      <div
        ref={recaptchaContainerRef}
        className={clsx(
          classes.recaptcha,
          riderLogin.recaptchaResolved && classes.recaptchaResolved,
        )}
      />

      {riderLogin.step === "otp" && (
        <>
          <Form
            formMode="create"
            onSubmit={handleSubmit}
            className={classes.form}>
            {isLoading && (
              <LinearProgress sx={{ height: "1px", marginBottom: "4px" }} />
            )}
            <OneTimePassForm disabled={isLoading} />
          </Form>
          {error && (
            <Typography className={classes.error}>
              The code is either wrong or expired, we have sent a new code to
              fill in
            </Typography>
          )}

          <div className={classes.bottomBar}>
            <Typography className={classes.bottomBarText}>
              Didn’t receive code?
            </Typography>

            <Button
              type="button"
              className={classes.textButton}
              onClick={riderLogin.reset}
              variant="text">
              Request again
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(5),
    textAlign: "center",
  },
  button: {
    textTransform: "none",
    marginTop: theme.spacing(4),
    borderColor: theme.palette.primary.main,
    borderRadius: 8,
    color: theme.palette.primary.main,
    fontSize: 14,
    fontWeight: "bold",
  },
  bottomBar: {
    marginTop: theme.spacing(5),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  bottomBarText: {
    textAlign: "center",
    fontSize: 16,
  },
  textButton: {
    textTransform: "none",
    color: theme.palette.primary.main,
    fontSize: 16,
    fontWeight: "bold",
  },
  error: {
    color: "red",
  },
  recaptcha: {
    margin: theme.spacing(2, 0),
    display: "flex",
    justifyContent: "center",
    height: "100px",
    transition: "height ease 0.5s",
    overflow: "hidden",
  },
  recaptchaResolved: {
    height: 0,
  },
}));
