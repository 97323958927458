import React from "react";
import { makeStyles } from "@providers/Mui";
import { Card } from "@components/Card";
import { H6, H7 } from "@components/Typography";
import { useGetSpaceReview } from "@cloud-functions";
import { Grid, Rating, Typography } from "@mui/material";
import { ReviewCardPlaceholder } from "./ReviewCardPlaceholder";
import { EmptyReviewCard } from "@pages/SpaceOverviewPage/ReviewCard/EmptyReviewCard";
import StarIcon from "@mui/icons-material/Star";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2),
    },
  },
  agentBox: {
    padding: theme.spacing(2, 3, 2, 1),
  },
  reviewBox: {
    padding: theme.spacing(0, 3, 0, 3),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(3, 5, 3, 0),
    },
  },
  content: {
    fontSize: 12,
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      borderLeft: `1px solid ${theme.palette.grey[300]}`,
      paddingLeft: theme.spacing(5),
      paddingTop: 0,
      borderTop: 0,
    },
  },
  emptyFeedback: {
    color: theme.palette.grey[500],
  },
  agentName: {
    marginTop: theme.spacing(1),
  },
  agentTitle: {
    fontSize: 12,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  emptyIcon: {
    color: theme.palette.grey[400],
  },
  score: {
    fontSize: 12,
    color: theme.palette.text.secondary,
    paddingLeft: theme.spacing(1.5),
  },
}));

const useRatingStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.yellow[300],
    cursor: "default",
    fontSize: 22,
  },
}));

type ReviewCardProps = {
  spaceId: string;
};

export const ReviewCard: React.FC<ReviewCardProps> = ({ spaceId }) => {
  const classes = useStyles();
  const ratingClasses = useRatingStyles();
  const [review, reviewState] = useGetSpaceReview({ input: { spaceId } });

  if (reviewState.loading) {
    return (
      <ReviewCardPlaceholder />
    );
  }

  if (!review) {
    return (
      <EmptyReviewCard />
    );
  }

  return (
    <Card className={classes.root} container alignItems="center">
      <Grid item xs={12} sm={6} className={classes.agentBox}>
        <H6>Employee of last week</H6>
        <H7 className={classes.agentName}>
          {review.assignee?.displayName ?? "N/A"}
        </H7>
        {review.assignee?.title && (
          <Typography className={classes.agentTitle}>
            {review.assignee?.title}
          </Typography>
        )}
        <Grid container wrap="nowrap" alignItems="center">
          <Rating
            value={review.rating}
            readOnly
            classes={ratingClasses}
            emptyIcon={
              <StarIcon
                className={classes.emptyIcon}
                fontSize="inherit"
              />
            }
          />
          <Typography className={classes.score}>
            {review.rating}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.reviewBox}>
        {review.feedback ? (
          <Typography className={classes.content}>
            “&nbsp;{review.feedback}&nbsp;“
          </Typography>
        ) : (
          <Typography className={clsx(classes.content, classes.emptyFeedback)}>
            No feedback provided
          </Typography>
        )}
      </Grid>
    </Card>
  );
};
