import { User } from "@functions-types";
import { makeStyles } from "@providers/Mui";
import { FC } from "react";
import { UserValue } from "./UserValue";

type UsersListValueProps = {
  value: User[];
};

export const UsersListValue: FC<UsersListValueProps> = ({ value }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {value.map((user) => (
        <UserValue value={user} key={user.id} />
      ))}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {},
}));
