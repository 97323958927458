import React from "react";
import { Card } from "@components/Card";
import { makeStyles } from "@providers/Mui";
import { Grid, Skeleton } from "@mui/material";
import { repeatNodes } from "@lib/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2),
    },
  },
  agentBox: {
    padding: theme.spacing(3),
  },
  reviewBox: {
    padding: theme.spacing(0, 3, 0, 3),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(5, 5, 0, 0),
    },
  },
  content: {
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      borderLeft: `1px solid ${theme.palette.grey[300]}`,
      paddingLeft: theme.spacing(5),
      paddingTop: 0,
      borderTop: 0,
    },
  },
}));

export const ReviewCardPlaceholder = () => {
  const classes = useStyles();
  return (
    <Card className={classes.root} container>
      <Grid item xs={12} sm={6} className={classes.agentBox}>
        <Skeleton variant="rectangular" height={18} width="90%" sx={{ mb: 3 }} />
        <Skeleton variant="rectangular" height={18} width="50%" sx={{ mb: 1.5 }} />
        <Skeleton variant="rectangular" height={16} width="40%" sx={{ mb: 2 }} />
        <Skeleton variant="rectangular" height={20} width="50%" />
      </Grid>
      <Grid item xs={12} sm={6} className={classes.reviewBox}>
        <div className={classes.content}>
          {repeatNodes(5, (key) => <Skeleton variant="rectangular" height={15} width="100%" sx={{ mb: 1 }} key={key} />)}
        </div>
      </Grid>
    </Card>
  );
};
