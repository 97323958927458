import { SLAActivity, SLAType } from "@functions-types";
import { FC } from "react";
import { RecordLayout } from "./RecordLayout";

type HistorySLARecordProps = {
  record: SLAActivity;
};

const SLATypesMap: Record<SLAType, string> = {
  firstReactionSLA: "First reaction SLA",
  nextReactionSLA: "Reaction SLA",
  resolveSLA: "Resolve SLA",
}

export const HistorySLARecord: FC<HistorySLARecordProps> = ({
  record,
}) => {
  return (
    <RecordLayout
      record={record}
      verb={`${SLATypesMap[record.SLAType]} is reached`}
    />
  );
};
