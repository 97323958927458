import React, { useEffect, useMemo, useState } from "react";
import {
  Grid,
  Button,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow as MuiTableRow,
  TableContainer,
  TableCell,
} from "@mui/material";
import { useIsMobile } from "@hooks/useIsMobile";
import { useSpaces } from "@providers/Spaces";
import { makeStyles } from "@providers/Mui";
import { useSnackbar } from "@providers/Snackbar";
import { Card } from "@components/Card";
import { H6 } from "@components/Typography";
import { WorkflowStatus } from "@functions-types";
import { WorkflowStatusForm } from "./WorkflowStatusForm";
import { Visualization } from "./Visualization";
import { FilteredStatus } from "@pages/Workflows/types";
import { StatusRow } from "@pages/Workflows/StatusRow";
import {
  useListStatuses,
  updateWorkflow as updateWorkflowCloudFunction,
  useGetWorkflowById,
} from "@cloud-functions";
import { useAsync } from "@hooks/useAsync";
import { Blocker } from "@components/Blocker";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  emptyItemsListText: {
    color: theme.palette.grey[700],
  },
  table: {
    width: "100%",
  },
  th: {
    textAlign: "left",
    fontWeight: "bold",
  },
  history: {
    height: "100%",
    overflow: "hidden",
  },
  card: {
    height: "100%",
  },
  header: {
    padding: theme.spacing(0, 2.5, 1),
  },
  scrollable: {
    height: "100%",
    overflow: "auto",
    padding: theme.spacing(0, 2.5),
  },
}));

const TABLE_HEADER_COLUMNS: string[] = [
  "Status",
  "Transition",
  "Actions",
];

export const Workflows: React.FC = () => {
  const classes = useStyles();
  const isMobile = useIsMobile();
  const spacing = isMobile ? 2 : 3;
  const { selectedSpace } = useSpaces();
  const [workflow, workflowStatus] = useGetWorkflowById()
  const [statuses, statusesStatus] = useListStatuses();
  const { showError } = useSnackbar();
  const [hasFormActiveId, setHasFormActiveId] = useState<string | null>(null);
  const [updateWorkflow, { isLoading }] = useAsync(updateWorkflowCloudFunction, {
    reThrow: true,
  });

  useEffect(() => {
    if (selectedSpace?.workflow?.id) {
      workflowStatus.refetch({ input: { id: selectedSpace.workflow.id } });
    }
  }, [selectedSpace?.workflow?.id]);

  const onSave = async (status: FilteredStatus) => {
    if (!workflow || !selectedSpace?.workflow) {
      return;
    }

    try {
      let statuses = [...workflow.statuses];
      const isNewStatus = !statuses.find(
        (workflowStatus) => workflowStatus.status?.id === status.status.id
      );

      if (isNewStatus) {
        statuses.push(status);
      } else {
        statuses = statuses.map((workflowStatus) => {
          if (workflowStatus.status?.id === status.status.id) {
            return { ...status };
          }
          return workflowStatus;
        });
      }
      await updateWorkflow({
        input: {
          id: workflow.id,
          updates: {
            id: workflow.id,
            statuses,
          }
        }
      });
      workflowStatus.refetch({ input: { id: selectedSpace.workflow.id } });
    } catch (error) {
      showError(
        <Typography variant="body2">
          Failed to save workflow
        </Typography>,
      );
    }
  };

  const handleStatusEdit = (statusId: string) => {
    setHasFormActiveId(statusId);
  };

  const statusesMap: Record<string, WorkflowStatus> = useMemo(
    () =>
      (workflow?.statuses ?? []).reduce(
        (prev, curr) => {
          if (!curr.status) return prev;
          return { ...prev, [curr.status.id]: curr };
        },
        {},
      ),
    [workflow?.statuses],
  );

  const workflowStatuses = useMemo(() => {
    if (!workflow?.statuses) {
      return [];
    }

    return workflow
      .statuses
      .filter((status) => status.status)
      .sort(
        (statusA, statusB) => (statusA.status?.order || 0) - (statusB.status?.order || 0)
      ) as FilteredStatus[];
  }, [workflow]);

  return (
    <Grid container spacing={spacing} className={classes.root}>
      <Grid
        item
        container
        direction="row"
        spacing={spacing}
        xs={12}
        wrap="nowrap"
      >
        <Grid item xs={6}>
          <Card>
            <H6 className={classes.header}>Workflows</H6>
            {workflow ? (
              <TableContainer>
                <Table>
                  <TableHead>
                    <MuiTableRow>
                      {TABLE_HEADER_COLUMNS.map((title) => (
                        <TableCell key={title} className={classes.th}>
                          {title}
                        </TableCell>
                      ))}
                    </MuiTableRow>
                  </TableHead>
                  <TableBody>
                    {workflowStatuses.map((status) =>
                      status.status?.id === hasFormActiveId ? (
                        <MuiTableRow key={status.status?.id}>
                          <TableCell colSpan={6}>
                            <WorkflowStatusForm
                              onSave={onSave}
                              onClose={() => setHasFormActiveId(null)}
                              workflowStatuses={workflowStatuses}
                              statuses={statuses ?? []}
                              currentStatus={status}
                            />
                          </TableCell>
                        </MuiTableRow>
                      ) : (
                        <StatusRow
                          key={status.status.id}
                          status={status}
                          statusesMap={statusesMap}
                          onEdit={handleStatusEdit}
                        />
                      ),
                    )}
                    {!workflow?.statuses?.length && (
                      <MuiTableRow>
                        <TableCell colSpan={6}>
                          <Typography className={classes.emptyItemsListText}>
                            There are currently no statuses available. <br />
                            Add them by clicking the "Add new status" button
                            below...
                          </Typography>
                        </TableCell>
                      </MuiTableRow>
                    )}
                    {(statuses ?? []).length > workflowStatuses.length && (
                      <MuiTableRow>
                        <TableCell colSpan={4}>
                          <Button
                            sx={{
                              textTransform: "none",
                            }}
                            variant="text"
                            onClick={() => setHasFormActiveId("new")}
                            color="primary"
                          >
                            Add new status
                          </Button>
                        </TableCell>
                      </MuiTableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography className={classes.emptyItemsListText}>
                No workflow available
              </Typography>
            )}
            {workflow?.id && hasFormActiveId === "new" && (
              <WorkflowStatusForm
                onSave={onSave}
                onClose={() => setHasFormActiveId(null)}
                workflowStatuses={workflowStatuses}
                statuses={statuses ?? []}
              />
            )}
            <Blocker open={isLoading || statusesStatus.loading} />
          </Card>
        </Grid>
        {workflow?.id && (
          <Grid item xs={6}>
            <Card>
              <H6 className={classes.header}>Visualization</H6>
              {!workflow?.statuses?.length ? (
                <Typography className={classes.emptyItemsListText}>
                  Nothing to visualize just yet
                </Typography>
              ) : (
                <Visualization workflowItems={workflow?.statuses ?? []} />
              )}
            </Card>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
