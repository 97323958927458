import { BrowserRouter, Navigate, Routes, Route } from "react-router-dom";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

import "./firebase";
import { AuthProvider, LoginSwitch, UserProvider } from "@providers/Auth";
import { MuiProvider } from "@providers/Mui";
import { ViewTicket } from "@pages/ViewTicket";
import { SnackbarProvider } from "@providers/Snackbar";
import { SpacesProvider } from "@providers/Spaces";
import { EventsProvider } from "@providers/EventsProvider";
import { AppConfigProvider } from "@providers/AppConfigProvider";
import { Admin } from "@pages/Admin";
import { CreateTicket } from "@pages/CreateTicket";
import { MediaViewer } from "@pages/MediaViewer";
import { Space } from "@pages/Space";
import { Home } from "@pages/Home";
import { ReviewPage } from "@pages/FeedbackPage";
import { Profile } from "@pages/Profile";

const App = () => (
  <MuiProvider>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <AppConfigProvider>
        <EventsProvider>
          <BrowserRouter>
            <AuthProvider>
              <LoginSwitch>
                <UserProvider>
                  <SpacesProvider>
                    <SnackbarProvider>
                      <Routes>
                        <Route path="/ticket/:id" element={<ViewTicket />} />
                        <Route
                          path="/ticket/create"
                          element={<CreateTicket />}
                        />
                        <Route path="/space/:key/*" element={<Space />} />
                        <Route path="/admin/*" element={<Admin />} />
                        <Route path="/media/*" element={<MediaViewer />} />
                        <Route
                          path="/review/:ticketId"
                          element={<ReviewPage />}
                        />
                        <Route path="/profile/*" element={<Profile />} />
                        <Route path="/" element={<Home />} />
                        <Route path="*" element={<Navigate to="/" />} />
                      </Routes>
                    </SnackbarProvider>
                  </SpacesProvider>
                </UserProvider>
              </LoginSwitch>
            </AuthProvider>
          </BrowserRouter>
        </EventsProvider>
      </AppConfigProvider>
    </LocalizationProvider>
  </MuiProvider>
);

export default App;
