import { makeCloudFunction } from "../../makeCloudFunction";
import {
  History,
  ListSpaceHistoryRequest,
  ListSpaceHistoryResponse,
} from "@functions-types";
import { makeUseCloudFunction } from "../../makeUseCloudFunction";
import { last } from "lodash";

export type { History };
export const listSpaceHistory = makeCloudFunction<
  ListSpaceHistoryRequest,
  ListSpaceHistoryResponse
>("history", "listSpaceHistory");
export const useListSpaceHistory = makeUseCloudFunction<
  ListSpaceHistoryRequest,
  ListSpaceHistoryResponse
>(listSpaceHistory, {
  useCache: true,
  pagination: {
    limit: 50,
    orderBy: "createdAt",
    orderDirection: "desc",
  },
  getCursor: (historyItem) => last(historyItem)?.id,
  triggerOnMounting: false,
});
