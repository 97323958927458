import React from "react";
import { MainLayout } from "@components/MainLayout";
import { makeStyles } from "@providers/Mui";
import { useSpaces } from "@providers/Spaces";
import { UserHomePage, AgentHomePage } from "@containers/HomePage";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(4),
    },
  },
}));

export const Home: React.FC = () => {
  const classes = useStyles();
  const { spaces } = useSpaces();

  return (
    <MainLayout className={classes.root} fullHeight fullWidth>
      {spaces.length ? <AgentHomePage /> : <UserHomePage />}
    </MainLayout>
  );
};
