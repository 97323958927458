import React from "react";
import { Type } from "@functions-types";
import { Chip } from "@mui/material";
import { makeStyles } from "@providers/Mui";

type TypeCardProps = {
  type: Type | null;
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-block",
    borderRadius: 5,
    fontWeight: 500,
    backgroundColor: "rgb(226, 226, 226)",
    color: "rgb(82, 82, 82)",
    height: 24,
    lineHeight: "24px",
    padding: theme.spacing(0, 1),
    whiteSpace: "nowrap",
  },
}));

export const TypeCard: React.FC<TypeCardProps> = ({ type }) => {
  const classes = useStyles();
  return (
    <span className={classes.root}>{type?.name ?? "N/A"}</span>
  );
};
