import React from "react";
import { Status } from "@functions-types";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@providers/Mui";

type StatusCardProps = {
  status: Status;
  onSelect?: (status: Status) => void;
  activeStatusId?: string;
};

const useStyles = makeStyles<{ active: boolean }>((theme) => ({
  root: ({ active }) => ({
    padding: theme.spacing(1, 2),
    transition: "background-color 0.2s",
    cursor: "pointer",
    backgroundColor: active ? theme.palette.grey[200] : "transparent",
    "&:hover": {
      backgroundColor: theme.palette.grey[200],
    }
  }),
  label: {
    fontSize: 14,
    fontWeight: "bold",
  },
}));

export const StatusCard: React.FC<StatusCardProps> = ({ status, onSelect, activeStatusId }) => {
  const classes = useStyles({ active: status.id === activeStatusId });
  return (
    <Grid onClick={() => onSelect?.(status)} className={classes.root}>
      <Typography className={classes.label}>
        {status.label}
      </Typography>
    </Grid>
  );
};
