import { Tickets } from "@containers/HomePage/Tickets";
import { Column } from "@containers/TicketsTable/types";
import { ListTicketRequest } from "@functions-types";
import { makeStyles } from "@providers/Mui";
import React from "react";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const getTicketsPendingForMyApprovalsFilter = (userId: string): ListTicketRequest => ({
  query: {
    where: [["pendingApproversIds" as "id", "array-contains", userId]],
    orderBy: [["createdAt", "desc"]],
  },
});

const useStyles = makeStyles<{ withBorderColoring: boolean }>((theme) => ({
  root: {
    maxHeight: "100%",
  },
  hasRows: (props) => ({
    border: props.withBorderColoring ? `2px solid ${theme.palette.orange[200]}` : undefined,
  }),
  empty: (props) => ({
    border: props.withBorderColoring ? `2px solid ${theme.palette.green[500]}` : undefined,
  }),
  hasRowsIcon: {
    color: theme.palette.orange[200],
    marginRight: theme.spacing(1),
  },
  emptyIcon: {
    color: theme.palette.green[500],
    marginRight: theme.spacing(1),
  },
}));

type PendingApprovalsProps = {
  withIcon?: boolean;
  withBorderColoring?: boolean;
  columns?: Column[];
};

const DEFAULT_COLUMNS: Column[] = ["title", "status", "severity", "category", "approvalState"];

export const PendingApprovals: React.FC<PendingApprovalsProps> = (props) => {
  const { columns = DEFAULT_COLUMNS, withIcon = false, withBorderColoring = false } = props;
  const classes = useStyles({ withBorderColoring });

  return (
    <Tickets
      classes={{ root: classes.root, empty: classes.empty, hasRows: classes.hasRows }}
      getFilter={getTicketsPendingForMyApprovalsFilter}
      title="Tickets pending on my approval"
      emptyIcon={withIcon && <CheckCircleOutlineIcon className={classes.emptyIcon} />}
      rowsIcon={withIcon && <WarningAmberIcon className={classes.hasRowsIcon} />}
      columns={columns}
      EmptyListProps={{
        message: "You are good! no pending tickets for approvals 🎉",
        showIcon: false,
      }}
    />
  );
};
