import { makeStyles } from "@providers/Mui";
import { FC } from "react";

type TextValueProps = {
  value: string;
};

export const TextValue: FC<TextValueProps> = ({ value }) => {
  const classes = useStyles();

  return <div className={classes.root}>{value}</div>;
};

const useStyles = makeStyles((theme) => ({
  root: {},
}));
