import React, { useMemo } from "react";
import { makeStyles } from "@providers/Mui";
import { ApprovalOverallState, TicketApprovalRule } from "./types";
import { Theme } from "@mui/material";
import { getApprovalState, ruleIsFullfilled, ruleIsRejected } from "./utils";
import { Approver } from "./Approver";
import clsx from "clsx";
import { useTicketApproval } from "./TicketApprovalProvider";

type ApprovalRuleProps = {
  rule: TicketApprovalRule;
  active: boolean;
};

export const approvalOverallStateColors: Record<
  ApprovalOverallState,
  (theme: Theme) => {
    backgroundColor: string;
    border: string;
  }
> = {
  pending: (theme: Theme) => ({
    backgroundColor: theme.palette.orange[100],
    border: `2px solid ${theme.palette.orange[200]}`,
  }),
  approved: (theme: Theme) => ({
    backgroundColor: theme.palette.green[100],
    border: `2px solid ${theme.palette.green[500]}`,
  }),
  rejected: (theme: Theme) => ({
    backgroundColor: theme.palette.red[100],
    border: `2px solid ${theme.palette.red[400]}`,
  }),
};

export const ApprovalRule: React.FC<ApprovalRuleProps> = ({ rule, active }) => {
  const { activeRuleIndex } = useTicketApproval();
  const state: ApprovalOverallState = getApprovalState(rule);
  const classes = useStyles({ state });

  const isPending = useMemo(
    () => active && !ruleIsFullfilled(rule) && !ruleIsRejected(rule),
    [rule, active],
  );

  return (
    <div
      className={clsx(
        classes.root,
        activeRuleIndex !== -1 && rule.order > activeRuleIndex && classes.disabled,
      )}>
      {rule.type === "group" && rule.count && (
        <div className={classes.message}>
          {isPending && "waiting for"} at least <strong>{rule.count}</strong> approvals from the
          group <strong>{rule.group?.name}</strong>:
        </div>
      )}

      {rule.type === "users" && rule.count && rule.approvers.length > 1 && (
        <div className={classes.message}>
          {isPending && (
            <i>
              <strong>pending</strong> on
            </i>
          )}{" "}
          at least <strong>{rule.count}</strong> approvals from:
        </div>
      )}

      {rule.type === "users" && rule.count && rule.approvers.length === 1 && isPending && (
        <div className={classes.message}>
          <i>
            <strong>pending</strong> on approval by
          </i>
          :
        </div>
      )}

      {rule.type === "manager_1" && (
        <div className={classes.message}>
          {isPending && "waiting for"} <strong>direct manager</strong>:
        </div>
      )}

      {rule.type === "manager_2" && (
        <div className={classes.message}>
          {isPending && "waiting for"} <strong>manager of manager</strong> :
        </div>
      )}

      {rule.approvers.map((approver) =>
        approver ? (
          <Approver
            key={approver.id}
            approver={approver}
            active={active}
            feedback={rule.feedbacks[approver.id] ?? null}
            ruleId={rule.id}
          />
        ) : null,
      )}
    </div>
  );
};

const useStyles = makeStyles<{ state: ApprovalOverallState }>((theme) => ({
  root: (props) => ({
    fontSize: 14,
    borderRadius: 5,
    marginTop: theme.spacing(1),
    ...approvalOverallStateColors[props.state](theme),
  }),
  disabled: {
    opacity: 0.5,
    borderWidth: `1px !important`,
  },
  message: {
    padding: theme.spacing(1),
  },
}));
