import { Button, darken, Typography } from "@mui/material";
import { makeStyles } from "@providers/Mui";
import clsx from "clsx";
import { FC } from "react";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpward from "@mui/icons-material/ArrowUpward";

type ActivityFilterType = "all" | "comment" | "history";

type TicketActivityFilterProps = {
  filter: ActivityFilterType;
  onChange: (value: ActivityFilterType) => void;
  asc: boolean;
  onChangeSort: (asc: boolean) => void;
};

export const TicketActivityFilter: FC<TicketActivityFilterProps> = ({
  filter,
  onChange,
  asc,
  onChangeSort,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="caption" className={classes.label}>
        Show:
      </Typography>

      <div className={classes.buttons}>
        <Button
          className={clsx(
            classes.button,
            filter === "all" && classes.buttonActive,
          )}
          onClick={() => {
            onChange("all");
          }}>
          All
        </Button>
        <Button
          className={clsx(
            classes.button,
            filter === "comment" && classes.buttonActive,
          )}
          onClick={() => {
            onChange("comment");
          }}>
          Comments
        </Button>
        <Button
          className={clsx(
            classes.button,
            filter === "history" && classes.buttonActive,
          )}
          onClick={() => {
            onChange("history");
          }}>
          History
        </Button>
      </div>
      <div className={classes.sort}>
        <Button
          className={classes.button}
          onClick={() => {
            onChangeSort(!asc);
          }}
          endIcon={asc ? <ArrowDownwardIcon /> : <ArrowUpward />}>
          {asc ? "Oldest first" : "Newest first"}
        </Button>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  label: {
    marginRight: theme.spacing(2),
  },
  buttons: {},
  sort: {
    flex: 1,
    textAlign: "right",
  },
  button: {
    padding: "1px 8px",
    textTransform: "none",
    fontSize: 13,
    backgroundColor: "white",
    color: "#5a7986",
    marginRight: theme.spacing(1),
  },
  buttonActive: {
    "&, &:hover": {
      backgroundColor: "#5a7986",
      color: "white",
    },
    "&:hover": {
      backgroundColor: darken("#5a7986", 0.2),
    },
  },
}));
