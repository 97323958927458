import { CTITree } from "@functions-types";

type SetFn = (property: string, value: unknown) => void;

const promisifiedRAF = async (fn: () => void) => new Promise<void>(
  (resolve) => requestAnimationFrame(() => {
    fn();
    requestAnimationFrame(() => resolve())
}));

export const applyDefaultState = async (
  setFn: SetFn,
  categories: CTITree[],
  categoryId: string,
  typeId?: string,
  itemId?: string,
): Promise<void> => {
  const category = categories.find(({ id }) => id === categoryId);
  if (!category?.id) return;
  await promisifiedRAF(() => setFn("category", category));

  const type = category.types.find(({ id }) => id === typeId);
  if (!type?.id) return;
  await promisifiedRAF(() => setFn("type", type));

  const item = type.items.find(({ id }) => id === itemId);
  if (!item?.id) return;
  await promisifiedRAF(() => setFn("item", item));
};