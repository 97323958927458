import { FC } from "react";
import * as yup from "yup";
import "yup-phone";
import { Button } from "@mui/material";
import { Form, PhoneNumberField } from "@components/Form";
import { yupResolver } from "@hookform/resolvers/yup";

import { makeStyles } from "../../../providers/Mui";
import { useRiderLogin } from "@providers/RiderLoginProvider";

const formValidation = yup.object().shape({
  phone: yup
    .string()
    .required("Phone is a required field")
    .phone(undefined, false, "Phone number is not valid"),
});

const formValidationResolver = yupResolver(formValidation);

export const PhoneNumber: FC = () => {
  const riderLogin = useRiderLogin();
  const classes = useStyles();

  const handleSubmit = async (values: any) => {
    riderLogin.setPhoneNumber(values.phone);
  };

  return (
    <>
      <Form
        formMode="create"
        onSubmit={handleSubmit}
        className={classes.form}
        resolver={formValidationResolver}
        defaultValues={{
          phone: riderLogin.lastUsedRiderPhoneNumber ?? "",
        }}>
        <PhoneNumberField
          name="phone"
          variant="outlined"
          fullWidth
          size="small"
        />

        <Button type="submit" className={classes.button} variant="outlined">
          Send SMS
        </Button>
      </Form>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(5),
    textAlign: "center",
  },
  button: {
    textTransform: "none",
    marginTop: theme.spacing(4),
    borderColor: theme.palette.primary.main,
    borderRadius: 8,
    color: theme.palette.primary.main,
    fontSize: 14,
    fontWeight: "bold",
  },
}));
