import {
  CustomField,
  OptionsTree,
  CreateCustomFieldRequest,
  CreateCustomFieldResponse,
} from "@functions-types";
import { makeCloudFunction } from "../makeCloudFunction";

export type { CustomField, OptionsTree };

export const createCustomField = makeCloudFunction<
  CreateCustomFieldRequest,
  CreateCustomFieldResponse
>("customField", "createCustomField");
