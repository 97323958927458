import { CTIApprovalConfigRule, Group } from "@functions-types";
import { makeStyles } from "@providers/Mui";
import clsx from "clsx";
import { FC, useState } from "react";
import { Label } from "@components/Label";
import { ApprovalRuleForm } from "./ApprovalRuleForm";
import { useFieldArray, useForm as useReactHookForm } from "react-hook-form";
import { useForm } from "@components/Form/FormProvider";
import AddIcon from "@mui/icons-material/Add";
import { Button } from "@components/Button";
import { Typography } from "@mui/material";
import { joinNodes } from "@lib/utils";

type ApprovalRulesFieldProps = {
  className?: string;
  title?: string;
  rules: CTIApprovalConfigRule[];
  groups: Group[];
};

export const ApprovalRulesField: FC<ApprovalRulesFieldProps> = ({
  title,
  className,
  rules,
  groups,
}) => {
  const classes = useStyles();
  const { triggerSubmit } = useForm();

  useReactHookForm({
    defaultValues: {
      approvals: { rules },
    },
  });

  const {
    fields: fieldsValue,
    append,
    remove,
  } = useFieldArray<any>({
    name: "approvals.rules",
  });

  const fields = fieldsValue as any as (CTIApprovalConfigRule & { id: string })[];

  const onSave = () => {
    setOpenId(null);
    triggerSubmit();
  };

  const onDelete = (index: number) => {
    remove(index);
    triggerSubmit();
  };

  const [openId, setOpenId] = useState<string | null>(null);

  return (
    <div className={clsx(classes.root, className)}>
      {title && <Label label={title} className={classes.label} />}

      {joinNodes(
        fields.map((field, index) => (
          <ApprovalRuleForm
            key={field.id} // important to include key with field's id index={rules.length} />
            index={index}
            onSave={onSave}
            onDelete={onDelete}
            value={field}
            open={field.id === openId}
            onSummaryClick={() => setOpenId(field.id)}
            onCancelClick={() => setOpenId(null)}
            groups={groups}
          />
        )),
        <Typography className={classes.then}>then</Typography>,
      )}

      <Button
        type="submit"
        color="success"
        onClick={() =>
          append({
            type: "manager_1",
            users: null,
            group: null,
            count: 1,
          })
        }
        startIcon={<AddIcon />}>
        Add
      </Button>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {},
  label: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  then: {
    color: theme.palette.grey[700],
    fontSize: 10,
    textTransform: "uppercase",
  },
}));
