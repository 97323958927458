import { CTIApprovalConfigRule, Group, User } from "@functions-types";
import { makeStyles } from "@providers/Mui";
import clsx from "clsx";
import React, { FC, useCallback, useMemo, useState } from "react";
import { AsyncAutocomplete, Autocomplete, RadioGroupField, TextField } from "@components/Form";
import { useWatch } from "react-hook-form";
import { Collapse, IconButton } from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/DeleteOutline";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@components/Button";
import { UserCard } from "@components/UserCard";
import { searchUsers as searchUsersCloudFunction } from "@cloud-functions";
import { isNonNullish } from "@lib/utils";
import { ctiApprovalRuleTypeLabels } from "./utils/ctiApprovalRuleTypeLabels";
import { ApprovalRulePreview } from "./ApprovalRulePreview";
import { formatUserName } from "@lib/user";
import { FormLabel } from "@mui/material";

type ApprovalRuleFormProps = {
  groups: Group[];
  index: number;
  value: CTIApprovalConfigRule;
  className?: string;
  onSave?: () => void;
  onSummaryClick?: () => void;
  onCancelClick?: () => void;
  onDelete?: (index: number) => void;
  open: boolean;
};

type CTIApprovalConfigRuleType = CTIApprovalConfigRule["type"];

const approvalConfigRuleTypeOptions: {
  value: CTIApprovalConfigRuleType;
  label: string;
}[] = [
  {
    value: "manager_1",
    label: ctiApprovalRuleTypeLabels["manager_1"],
  },
  {
    value: "manager_2",
    label: ctiApprovalRuleTypeLabels["manager_2"],
  },
  {
    value: "users",
    label: ctiApprovalRuleTypeLabels["users"],
  },
  {
    value: "group",
    label: ctiApprovalRuleTypeLabels["group"],
  },
];

export const ApprovalRuleForm: FC<ApprovalRuleFormProps> = ({
  groups,
  index,
  value,
  className,
  open,
  onSave,
  onDelete,
  onSummaryClick,
  onCancelClick,
}) => {
  const classes = useStyles();
  const type = useWatch({
    name: `approvals.rules.${index}.type`,
  }) as CTIApprovalConfigRuleType;

  const users = (useWatch({
    name: `approvals.rules.${index}.users`,
    defaultValue: [],
  }) ?? []) as User[];

  const usersMap = useMemo(() => {
    return users.reduce(
      (result, user) => ({ ...result, [user.id]: true }),
      {} as Record<string, boolean>,
    );
  }, [users]);

  const searchUsers = useCallback(
    async (query: string) => {
      const users = await searchUsersCloudFunction({
        query,
      });

      return users.filter((v) => isNonNullish(v) && !usersMap[v.id]);
    },
    [usersMap],
  );

  const selectedGroup = useWatch({
    name: `approvals.rules.${index}.group`,
    defaultValue: null,
  }) as Group | null;

  const count = useWatch({
    name: `approvals.rules.${index}.count`,
    defaultValue: 0,
  }) as number;

  const [shouldShowError, setShouldShowError] = useState(false);
  const error = useMemo(() => {
    if (type === "group" && !selectedGroup) {
      return "Please select at least one group";
    }

    const minCount = 1;
    const maxCount = 4;
    if (type === "group" && (count < minCount || count > maxCount)) {
      return `Please set count between ${minCount} and ${maxCount}`;
    }

    if (type === "users" && users.length === 0) {
      return "Please select at least one user";
    }

    return null;
  }, [type, users, selectedGroup, count]);

  const save = () => {
    if (error) {
      setShouldShowError(true);
      return;
    }

    onSave?.();
    setShouldShowError(false);
  };

  return (
    <div className={clsx(classes.root, className)}>
      <Collapse in={!open}>
        <div className={classes.summary} onClick={onSummaryClick}>
          <ApprovalRulePreview value={value} />
        </div>
      </Collapse>
      <Collapse in={open}>
        <div className={classes.header}>
          <IconButton disableRipple onClick={onCancelClick}>
            <CloseIcon />
          </IconButton>
        </div>

        <hr />

        <RadioGroupField
          name={`approvals.rules.${index}.type`}
          label={null}
          options={approvalConfigRuleTypeOptions}
        />

        {type === "users" && (
          <div className={classes.field}>
            <AsyncAutocomplete<User>
              disablePortal
              label="Users"
              name={`approvals.rules.${index}.users`}
              multiple
              getOptionText={formatUserName}
              getOptionDisabled={(option) => !option.active}
              fullWidth
              search={searchUsers}
              disableClearable
              renderItem={(option) => {
                const { email, displayName, active } = option;

                if (!displayName) {
                  return <UserCard user={null} />;
                }

                return <UserCard user={{ displayName, email, active }} withBackground />;
              }}
            />
          </div>
        )}

        {type === "group" && (
          <div className={classes.field}>
            <Autocomplete
              options={groups ?? []}
              name={`approvals.rules.${index}.group`}
              label="Group"
              saveOnChange={false}
              getOptionText={(option) => option.name}
            />
          </div>
        )}

        {(type === "group" || (type === "users" && users.length > 1)) && (
          <div className={classes.field}>
            <TextField
              name={`approvals.rules.${index}.count`}
              label="At least"
              variant="outlined"
              fullWidth
              size="small"
              helperText="At least how many users must approve this request"
              type="number"
            />
          </div>
        )}

        <hr />

        {shouldShowError && error && <FormLabel error>{error}</FormLabel>}

        <div className={classes.actions}>
          <IconButton disableRipple onClick={() => onDelete?.(index)} type="submit">
            <RemoveCircleOutlineIcon />
          </IconButton>
          <Button onClick={save} type="button">
            Save
          </Button>
        </div>
      </Collapse>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(1),
    borderRadius: 5,
    "&:not(:last-child)": {
      marginBottom: theme.spacing(1),
    },
  },
  field: {
    marginTop: theme.spacing(2),
  },
  summary: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  label: {
    margin: theme.spacing(1, 0),
  },
  header: {
    display: "flex",
    justifyContent: "flex-end",
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
  },
}));
