import {
  Status,
  UpdateStatusRequest,
  UpdateStatusResponse,
} from "@functions-types";
import { makeCloudFunction } from "../makeCloudFunction";

export type { Status };
export const updateStatus = makeCloudFunction<
  UpdateStatusRequest,
  UpdateStatusResponse
>("status", "updateStatus");
