import React from "react";
import { Grid, IconButton } from "@mui/material";
import { Status } from "@functions-types";
import { StatusChip } from "@components/StatusChip";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { makeStyles } from "@providers/Mui";
import { SelectField } from "@components/Form";
import { TransitionActionFormState } from "../../../types";

type TransitionCardProps = {
  status: Status;
  onDelete: () => void;
  index: number;
  actions: TransitionActionFormState[];
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 0),
    "&:not(:last-child)": {
      borderBottom: `1px solid ${theme.palette.grey[400]}`,
    },
  },
  remove: {
    flexShrink: 0,
    marginLeft: theme.spacing(1),
    padding: theme.spacing(0, 1),
  },
  select: {
    backgroundColor: theme.palette.common.white,
  },
}));

export const TransitionCard: React.FC<TransitionCardProps> = ({
  status,
  onDelete,
  index,
  actions,
}) => {
  const classes = useStyles();
  return (
    <Grid container wrap="nowrap" className={classes.root} alignItems="center">
      <Grid item xs={5}>
        <StatusChip status={status} />
      </Grid>
      <Grid item xs={5}>
        <SelectField
          name={`transitions.${index}.action`}
          options={actions}
          fullWidth
          getOptionText={(option) => option.name}
          className={classes.select}
        />
      </Grid>
      <Grid item xs={2}>
        <IconButton
          className={classes.remove}
          disableRipple
          color="warning"
          onClick={onDelete}
        >
          <RemoveCircleOutlineIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};
