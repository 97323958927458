import React, { useEffect, useMemo, useState } from "react";
import { Section } from "./Section";
import { Grid } from "@mui/material";
import { useCTILookups } from "./useCTILookups";
import { Blocker } from "@components/Blocker";
import { Category, Item, Type } from "@functions-types";
import { CategoryForm } from "./CategoryForm";
import { TypeForm } from "./TypeForm";
import { ItemForm } from "./ItemForm";
import { itemToFormState } from "@pages/Admin/CTI/ItemForm/utils";
import { ActiveCTIProvider } from "./ActiveCTIProvider";
import { useListGroups } from "@cloud-functions";

export const CTI: React.FC = () => {
  const { loading, itemsMap, typesMap, teams, severity, categories, customFields, update, spaces } =
    useCTILookups();
  const [groups, groupsState] = useListGroups();
  const [activeCategory, setActiveCategory] = useState<Category | null>(null);
  const [activeType, setActiveType] = useState<Type | null>(null);
  const [activeItem, setActiveItem] = useState<Item | null>(null);
  const [types, setTypes] = useState<Type[]>([]);
  const [items, setItems] = useState<Item[]>([]);

  const handleCategoryClick = (category: Category): void => {
    setActiveItem(null);
    setActiveType(null);
    setActiveCategory(category);
  };

  const handleTypeClick = (type: Type): void => {
    setActiveItem(null);
    setActiveType(type);
  };

  const handleItemClick = (item: Item): void => {
    setActiveItem(item);
  };

  useEffect(() => {
    if (categories?.length) {
      setActiveCategory((oldCategory) => oldCategory ?? categories[0]);
    }
  }, [loading, categories]);

  useEffect(() => {
    setTypes(activeCategory ? typesMap[activeCategory.id] || [] : []);
  }, [activeCategory, typesMap]);

  useEffect(() => {
    if (types.length) {
      setActiveType((oldType) => {
        if (types.some(({ id }) => id === oldType?.id)) {
          return oldType;
        }
        return types[0];
      });
    }
  }, [types]);

  useEffect(() => {
    setItems(activeType ? itemsMap[activeType.id] || [] : []);
  }, [activeType, itemsMap]);

  useEffect(() => {
    if (items.length) {
      setActiveItem((oldItem) => {
        if (items.some(({ id }) => id === oldItem?.id)) {
          return oldItem;
        }

        return items[0];
      });
    }
  }, [items]);

  const onSaveCategory = (category: Category) => {
    setActiveCategory(category);
    update();
  };

  const onSaveType = (type: Type) => {
    setActiveType(type);
    update();
  };

  const onSaveItem = (item: Item) => {
    setActiveItem(item);
    update();
  };

  const sortedTypes = useMemo(() => {
    return Array.from(types).sort(function (a, b) {
      return a.name.localeCompare(b.name);
    });
  }, [types]);

  const groupsArray = useMemo(() => groups ?? [], [groups]);

  return (
    <ActiveCTIProvider item={activeItem} type={activeType} category={activeCategory}>
      <Grid container direction="row" wrap="nowrap">
        <Section
          header="Categories"
          creationButtonLabel="Add category"
          items={categories}
          activeItem={activeCategory}
          onItemClick={handleCategoryClick}
          creationForm={(onCancel) => (
            <CategoryForm
              key="create-category"
              customFields={customFields}
              onSave={update}
              onClose={onCancel}
              onSelect={handleCategoryClick}
              groups={groupsArray}
            />
          )}
        />
        <Section
          header="Types"
          creationButtonLabel="Add type"
          items={sortedTypes}
          activeItem={activeType}
          onItemClick={handleTypeClick}
          editForm={
            categories &&
            activeCategory && (
              <CategoryForm
                key={`category/${activeCategory.id}`}
                customFields={customFields}
                defaultValues={categories.find(({ id }) => id === activeCategory.id)}
                onSave={onSaveCategory}
                groups={groupsArray}
              />
            )
          }
          creationForm={(onCancel) =>
            activeCategory && (
              <TypeForm
                key="create-type"
                customFields={customFields}
                onSave={update}
                onClose={onCancel}
                category={activeCategory}
                onSelect={handleTypeClick}
                groups={groupsArray}
              />
            )
          }
        />
        <Section
          header="Items"
          creationButtonLabel="Add item"
          items={items}
          activeItem={activeItem}
          onItemClick={handleItemClick}
          editForm={
            types &&
            activeType &&
            activeCategory && (
              <TypeForm
                key={`type/${activeType.id}`}
                customFields={customFields}
                defaultValues={types.find(({ id }) => id === activeType.id)}
                onSave={onSaveType}
                category={activeCategory}
                groups={groupsArray}
              />
            )
          }
          creationForm={(onCancel) =>
            activeCategory &&
            activeType && (
              <ItemForm
                key="create-item"
                onSave={update}
                onClose={onCancel}
                category={activeCategory}
                type={activeType}
                teams={teams}
                spaces={spaces}
                severity={severity}
                customFields={customFields}
                onSelect={handleItemClick}
                groups={groupsArray}
              />
            )
          }
        />
        <Section
          editForm={
            activeCategory &&
            activeType &&
            activeItem && (
              <ItemForm
                key={`item/${activeItem.id}`}
                category={activeCategory}
                type={activeType}
                onSave={onSaveItem}
                defaultValues={itemToFormState(items.find(({ id }) => id === activeItem.id))}
                teams={teams}
                spaces={spaces}
                severity={severity}
                customFields={customFields}
                groups={groupsArray}
              />
            )
          }
        />
      </Grid>
      <Blocker open={loading || groupsState.loading} />
    </ActiveCTIProvider>
  );
};
