import { makeStyles } from "@providers/Mui";
import { FC } from "react";

export const NoneValue: FC = () => {
  const classes = useStyles();

  return <div className={classes.root}>None</div>;
};

const useStyles = makeStyles((theme) => ({
  root: {
    color: "#a4a4a4",
    padding: "4px 6px",
  },
}));
