import React from "react";
import { Category } from "@functions-types";
import { makeStyles } from "@providers/Mui";

export type CategoryCardProps = {
  category: Category | null;
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-block",
    borderRadius: 5,
    fontWeight: 500,
    backgroundColor: "rgb(226, 226, 226)",
    color: "rgb(82, 82, 82)",
    height: 24,
    lineHeight: "24px",
    padding: theme.spacing(0, 1),
    whiteSpace: "nowrap",
  },
}));

export const CategoryCard: React.FC<CategoryCardProps> = ({ category }) => {
  const classes = useStyles();
  return <span className={classes.root}>{category?.name ?? "N/A"}</span>;
};
