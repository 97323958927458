import { WorkflowStatusType } from "@functions-types";

export const typeLabels: Record<WorkflowStatusType, string> = {
  start: "Start",
  pending: "Pending",
  finish: "Termination",
  approval: "Approval",
  declined: "Declined",
};

export const availableTypes: { id: WorkflowStatusType; label: string }[] = [
  { id: "start", label: typeLabels.start },
  { id: "pending", label: typeLabels.pending },
  { id: "finish", label: typeLabels.finish },
  { id: "approval", label: typeLabels.approval },
  { id: "declined", label: typeLabels.declined },
];
