import React, { useEffect } from "react";
import { useParams } from "react-router";
import { useDownloading } from "@hooks/useDownloading";

export const MediaViewer: React.FC = () => {
  const params = useParams();
  const { download } = useDownloading();
  const fileRef = params["*"];

  useEffect(() => {
    if (!fileRef) {
      return;
    }

    download(fileRef).then((url) => {
      window.open(url, "_blank");
    });
  }, [fileRef]);

  return (
    <div>
      <h4>Your download will start shortly...</h4>
    </div>
  );
};
