import {
  AutomationFormState,
  AutomationSourceOption,
  AutomationTypeOption,
  CommentAuthorOptions,
} from "./types";
import {
  CreateAutomationInput,
  AutomationModel,
  AutomationActionsTypes,
  Status,
} from "@functions-types";
import { FilterType } from "@containers/TicketFilter";
import pick from "lodash/pick";

export const automationSourceOptions: AutomationSourceOption[] = [
  { id: "ztt_ticket_created", label: "Ticket created" },
  { id: "ztt_ticket_updated", label: "Ticket updated" },
  { id: "ztt_comment_created", label: "Comment added" },
];

const labelsMap: Record<AutomationActionsTypes, string> = {
  comment: "Add comment",
  assignToStoreManager: "Assign to a store manager",
  changeStatus: "Change status",
};

export const automationTypesOptions: AutomationTypeOption[] = [
  { id: "comment", label: labelsMap.comment },
  { id: "assignToStoreManager", label: labelsMap.assignToStoreManager },
  { id: "changeStatus", label: labelsMap.changeStatus },
];

export const commentAuthorOptions: CommentAuthorOptions[] = [
  { id: "any", label: "Any user" },
  { id: "reporter", label: "Reporter" },
];

export const formStateToInput = (
  values: AutomationFormState,
  filters: FilterType[],
): CreateAutomationInput => {
  return {
    ...pick(values, ["name", "repeatable", "active"]),
    action: {
      type: values.action.type.id,
      content: typeof values.action.content === "string"
        ? values.action.content : values.action.content?.id ?? "",
    },
    source: values.source.id,
    category: values.category?.id ?? "",
    type: values.type?.id ?? "",
    item: values.item?.id ?? "",
    customFields: (values.customFields ?? [])
      .filter(({ customField }) => customField?.id)
      .map(({ customField }) => ({
        customField: customField?.id ?? "",
        value: values.customFieldsValues[customField?.id ?? ""] ?? undefined,
      })),
    commentOptions: {
      commentedByReporter: values.commentOptions?.commentedByReporter?.id === "reporter",
    },
    filters,
  };
};

export const fromModelState = (model: AutomationModel, statuses: Status[]): AutomationFormState => {
  return {
    ...model,
    action: {
      type:
        automationTypesOptions.find(({ id }) => id === model.action.type) ??
        automationTypesOptions[0],
      content: model.action.type === "changeStatus"
        ? statuses.find((status) => status.id === model.action.content) : model.action.content,
    },
    source:
      automationSourceOptions.find(({ id }) => id === model.source) ?? automationSourceOptions[0],
    category: undefined,
    type: undefined,
    item: undefined,
    customFieldsValues: (model.customFields ?? []).reduce(
      (acc, cur) => ({
        ...acc,
        [cur.customField?.id ?? ""]: cur.value,
      }),
      {},
    ),
    commentOptions: model.commentOptions?.commentedByReporter !== undefined ? {
      commentedByReporter: model.commentOptions?.commentedByReporter
        ? commentAuthorOptions[1] : commentAuthorOptions[0],
    } : undefined,
  };
};
