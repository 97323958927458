import React from "react";
import clsx from "clsx";
import { Grid } from "@mui/material";
import { makeStyles } from "@providers/Mui";
import { UserAvatar } from "@components/UserAvatar";
import { HasClassName } from "@lib/types";

type UserCardProps = HasClassName & {
  user: { displayName: string; email?: string; active: boolean } | null;
  withBackground?: boolean;
  withAvatar?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
};

const useStyles = makeStyles<{ withBackground: boolean }>((theme) => ({
  root: ({ withBackground }) => ({
    display: "inline-flex",
    fontWeight: 600,
    color: "rgb(82, 82, 82)",
    lineHeight: "20px",
    whiteSpace: "nowrap",
    fontSize: 12,
    width: "auto",
    backgroundColor: withBackground ? "#f0f0f0" : "transparent",
    padding: withBackground ? "4px 6px" : 0,
    borderRadius: 4,
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    "&:last-child": {
      marginRight: 0,
      marginBottom: 0,
    },
  }),
  avatar: {
    width: 20,
    height: 20,
    fontSize: 10,
    marginRight: 6,
    lineHeight: "20px",
  },
  unassigned: {
    "&&": {
      backgroundColor: theme.palette.error.main,
      color: "white",
      padding: theme.spacing(0, 1),
    },
  },
}));

export const UserCard: React.FC<UserCardProps> = ({
  user,
  withBackground = false,
  withAvatar = true,
  className,
  onClick,
}) => {
  const classes = useStyles({ withBackground });
  return (
    <Grid
      className={clsx(classes.root, !user && classes.unassigned, className)}
      container
      flexWrap="nowrap"
      alignItems="center"
      justifyContent="flex-start"
      onClick={onClick}>
      {user && withAvatar && (
        <UserAvatar user={user} className={classes.avatar} />
      )}
      {user?.displayName
        ? `${user.displayName}${user.active ? "" : " (inactive)"}`
        : "Unassigned"}
    </Grid>
  );
};
