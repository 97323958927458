import { makeStyles } from "@providers/Mui";
import clsx from "clsx";
import React, { useEffect, useImperativeHandle, useRef } from "react";
import { FieldValues, useForm, UseFormProps, UseFormReturn, FormProvider } from "react-hook-form";
import { HasClassName, Modify } from "../../lib/types";
import {
  FormProvider as CustomFormProvider,
  FormProviderProps as CustomFormProviderProps,
  FormProviderRef,
} from "./FormProvider";

export type FormRef<
  D extends FieldValues = FieldValues,
  C extends Record<never, never> = Record<never, never>,
> = {
  setDefaultValues: (values: any) => void;
  setValue: (name: string, value: any) => void;
  triggerSubmit: () => void;
  getMethods: () => UseFormReturn<D, C>;
};

export type FormProps<
  D extends FieldValues = FieldValues,
  C extends Record<never, never> = Record<never, never>,
> = Modify<
  UseFormProps<D, C>,
  {
    onSubmit: (data: D) => Promise<any> | any;
    resolver?: any; // TODO: fix type
    children: React.ReactNode;
    ref_?: React.MutableRefObject<FormRef<D, C> | null>;
    formMode?: CustomFormProviderProps["formMode"];
    disabled?: boolean;
  }
>;

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    maxWidth: "100%",
  },
}));

export const Form = <
  D extends FieldValues = FieldValues,
  C extends Record<never, never> = Record<never, never>,
>({
  children,
  onSubmit,
  className,
  ref_,
  formMode,
  disabled = false,
  ...props
}: FormProps<D, C> & HasClassName): React.ReactElement => {
  const classes = useStyles();
  const activeFieldContextRef = useRef<FormProviderRef | null>(null);
  const methods = useForm<D, C>(props) as UseFormReturn<D, C>;
  const handleSubmit = methods.handleSubmit<D>(async (data) => {
    await onSubmit(data as D);
    activeFieldContextRef.current?.resetActiveFields();
  });

  // we don't use this anymore
  useImperativeHandle(
    ref_,
    () => ({
      setDefaultValues: (values) => {
        methods.reset(values);
      },
      setValue: (name: string, value: any) => {
        methods.setValue(name as any, value);
      },
      triggerSubmit: () => {
        activeFieldContextRef.current?.triggerSubmit?.();
      },
      getMethods: () => methods,
    }),
    [],
  );

  useEffect(() => {
    methods.reset(props.defaultValues);
  }, [methods, props.defaultValues]);

  return (
    <FormProvider {...methods}>
      <form className={clsx(classes.root, className)} onSubmit={handleSubmit}>
        <CustomFormProvider<D, C>
          formMode={formMode}
          methods={methods}
          disabled={disabled}
          ref_={activeFieldContextRef}>
          {children}
        </CustomFormProvider>
      </form>
    </FormProvider>
  );
};
