import * as yup from "yup";
import { yupResolver, Resolver } from "@hookform/resolvers/yup";
import { CustomFieldForm } from "./types";

type ResolverArgs = Parameters<ReturnType<Resolver>>;
export const customFieldValidator = async (
  values: CustomFieldForm,
  context: ResolverArgs[1],
  config: ResolverArgs[2],
) => {
  const additionalValidators: Record<string, yup.SchemaOf<unknown>> = {};

  if (values?.type?.id === "selectField") {
    const options = (values.options || []).filter((option) => !!option);
    values.options = options;
    additionalValidators["options"] = yup.array().test({
      message: "Please provide at least one option",
      test: () => !!options?.length,
    });
  }

  if (values?.type?.id === "checkbox") {
    additionalValidators["checkboxLabel"] = yup.string().required("Checkbox label is required");
  }

  if (values?.type?.id === "nestedSelectField") {
    const optionsTree = (values.optionsTree || []).filter((option) => !!option);
    additionalValidators["optionsTree"] = yup.array().test({
      message: "Please provide at least one option",
      test: () => !!optionsTree?.length,
    });
  }

  if (values.lessThanEnabled) {
    additionalValidators["lessThan"] = yup.string().required("Please enter minimum value");
  }

  if (values.greaterThanEnabled) {
    additionalValidators["greaterThan"] = yup.number().required("Please enter maximum value");
  }

  return yupResolver(
    yup.object().shape({
      name: yup.string().required("Name is a required field"),
      label: yup.string().required("Label is a required field"),
      type: yup.object().required("Please select a type"),
      ...additionalValidators,
    }),
  )(values, context, config);
};
