import React from "react";
import clsx from "clsx";
import { Status } from "@functions-types";
import { TableCell, TableRow as MuiTableRow, Typography } from "@mui/material";
import { UserCard } from "@components/UserCard";
import { format } from "@lib/date";
import { CategoryCard } from "@components/CategoryCard";
import { TypeCard } from "@components/TypeCard";
import { ItemCard } from "@components/ItemCard";
import { makeStyles } from "@providers/Mui";
import { TicketStatus } from "./TicketStatus";
import { Assignee, SearchUserResponse } from "./Assignee";
import { Column, InteractionSettings } from "./types";
import { Severity } from "./Severity";
import { StatusChip } from "@components/StatusChip";
import { Deadline } from "@containers/TicketsTable/Deadline";
import { Ticket } from "@cloud-functions";
import { Selectable } from "@lib/ticket";
import { approvalOverallStateColors } from "@components/TicketCard/Sidebar/Approvals/ApprovalRule";
import { ApprovalOverallState } from "@components/TicketCard/Sidebar/Approvals/types";

type TableRowProps = {
  ticket: Ticket;
  onOpen: (ticketId: string) => void;
  statuses: Selectable<Status>;
  onStatusUpdate: (ticketId: string, status: string) => Promise<void>;
  onAssigneeUpdate: (ticketId: string, assignee: string) => Promise<void>;
  userSearch: (query: string) => Promise<SearchUserResponse>;
  columns: Column[];
  interactionSettings?: InteractionSettings;
};

const useStyles = makeStyles<{ approvalState: ApprovalOverallState | null }>((theme) => ({
  root: {
    backgroundColor: `${theme.palette.common.white} !important`,
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
      cursor: "pointer",
    },
  },
  private: {
    backgroundColor: theme.palette.warning.light,
  },
  ceil: {
    borderBottom: "none",
    padding: theme.spacing(1),
    position: "relative",
  },
  noBreak: {
    whiteSpace: "nowrap",
    fontSize: 14,
    lineHeight: 1.43,
    color: "rgba(0, 0, 0, 0.87)",
  },
  header: {
    minWidth: 200,
    textOverflow: "ellipsis",
    maxWidth: 210,
    overflow: "hidden",
  },
  deprecated: {
    color: theme.palette.secondary.main,
    textDecoration: "line-through",
    fontSize: 14,
  },
  approvalState: (props) => ({
    borderRadius: 4,
    padding: theme.spacing(0.25, 1),
    textTransform: "capitalize",
    ...approvalOverallStateColors[props.approvalState ?? "pending"](theme),
  }),
}));

export const TableRow: React.FC<TableRowProps> = ({
  ticket,
  onOpen,
  statuses,
  onStatusUpdate,
  onAssigneeUpdate,
  userSearch,
  columns,
  interactionSettings = {},
}) => {
  const classes = useStyles({ approvalState: ticket.approvalState });
  const { changeStatus = true, changeAssignee = true } = interactionSettings;

  const handleClick = (event: React.MouseEvent) => {
    if (event.metaKey || event.ctrlKey) {
      window.open(`/ticket/${ticket.id}`, "_blank");
    } else {
      onOpen(ticket.id);
    }
  };

  const columnRenderer: Record<Column, () => React.ReactNode> = {
    id: () => (
      <TableCell className={classes.ceil}>
        {ticket.id.length > 10 ? (
          <Typography className={classes.deprecated}>Deprecated</Typography>
        ) : (
          <Typography className={classes.noBreak}>{ticket.id}</Typography>
        )}
      </TableCell>
    ),
    title: () => (
      <TableCell className={classes.ceil}>
        <div className={classes.header}>{ticket.title}</div>
      </TableCell>
    ),
    createdBy: () => (
      <TableCell className={classes.ceil}>
        <UserCard user={ticket.createdBy} />
      </TableCell>
    ),
    assignee: () => (
      <TableCell className={classes.ceil}>
        {changeAssignee ? (
          <Assignee
            assignee={ticket.assignee}
            ticketId={ticket.id}
            onAssigneeUpdate={onAssigneeUpdate}
            userSearch={userSearch}
          />
        ) : (
          <UserCard user={ticket.assignee} />
        )}
      </TableCell>
    ),
    status: () => (
      <TableCell className={classes.ceil}>
        {changeStatus ? (
          <TicketStatus
            ticketId={ticket.id}
            status={ticket.status}
            statuses={statuses}
            onStatusUpdate={onStatusUpdate}
          />
        ) : (
          <StatusChip status={ticket.status} />
        )}
      </TableCell>
    ),
    deadline: () => (
      <TableCell className={classes.ceil}>
        <Deadline date={ticket.deadline} resolved={ticket.resolved} />
      </TableCell>
    ),
    createdAt: () => (
      <TableCell className={clsx(classes.ceil, classes.noBreak)}>
        {format(ticket.createdAt, "dd MMMM hh:mm aaa")}
      </TableCell>
    ),
    severity: () => (
      <TableCell className={classes.ceil}>
        <Severity>{ticket.severity?.value}</Severity>
      </TableCell>
    ),
    category: () => (
      <TableCell className={classes.ceil}>
        <CategoryCard category={ticket.category} />
      </TableCell>
    ),
    type: () => (
      <TableCell className={classes.ceil}>
        <TypeCard type={ticket.type} />
      </TableCell>
    ),
    item: () => (
      <TableCell className={classes.ceil}>
        <ItemCard item={ticket.item} />
      </TableCell>
    ),
    approvalState: () => (
      <TableCell className={classes.ceil}>
        <span className={classes.approvalState}>{ticket.approvalState}</span>
      </TableCell>
    ),
  };

  return (
    <MuiTableRow
      className={clsx(classes.root, ticket.isPrivate && classes.private)}
      onClick={handleClick}>
      {columns.map((column) => columnRenderer[column]())}
    </MuiTableRow>
  );
};
