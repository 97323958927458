import React from "react";
import { makeStyles } from "@providers/Mui";
import { H6 } from "@components/Typography";
import { Grid } from "@mui/material";
import { SelectField, TextField } from "@components/Form";
import { Button } from "@components/Button";
import { colorOptions } from "../utils";
import { Status } from "@functions-types";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[200],
    height: "100%",
    overflowX: "auto",
  },
  container: {
    paddingBottom: theme.spacing(2),
    "&:last-children": {
      paddingBottom: 0,
    },
  },
  field: {
    "& .MuiOutlinedInput-root": {
      backgroundColor: theme.palette.common.white,
    },
  },
  select: {
    backgroundColor: theme.palette.common.white,
  },
}));

export type StatusesFormProps = {
  selectedStatus: Status | null;
};

export const StatusesForm: React.FC<StatusesFormProps> = ({ selectedStatus }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.root}>
      <div className={classes.container}>
        {selectedStatus?.id ? (
          <H6>Edit status: {selectedStatus?.label}</H6>
        ) : (
          <H6>Create status</H6>
        )}
      </div>
      <div className={classes.container}>
        <TextField
          name="label"
          label="Label"
          variant="outlined"
          fullWidth
          className={classes.field}
        />
      </div>
      <div className={classes.container}>
        <TextField
          name="order"
          label="Order"
          variant="outlined"
          type="number"
          fullWidth
          className={classes.field}
        />
      </div>
      <div className={classes.container}>
        <SelectField
          label="Color"
          name="color"
          options={colorOptions}
          fullWidth
          className={classes.select}
          getOptionText={(option) => option.label}
        />
      </div>
      <div className={classes.container}>
        <Button
          type="submit"
          variant="contained"
        >
          Save
        </Button>
      </div>
    </Grid>
  );
};
