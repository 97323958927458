import React, { useEffect, useState } from "react";
import { merge } from "lodash";
import { Grid, Typography } from "@mui/material";
import { FeatureFlags, FeatureName } from "@functions-types";
import { CheckboxField, Form } from "@components/Form";
import { makeStyles } from "@providers/Mui";
import { useAppConfig } from "@providers/AppConfigProvider";
import { Button } from "@components/Button";
import { getItem, setItem } from "@lib/localstorage";
import { useSnackbar } from "@providers/Snackbar";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    position: "relative",
    minWidth: 450,
    backgroundColor: theme.palette.grey[200],
  },
  header: {
    fontSize: 16,
    fontWeight: "bold",
    paddingBottom: theme.spacing(1),
  },
  override: {
    paddingBottom: theme.spacing(3),
  },
  originalValue: {
    color: theme.palette.grey[700],
    fontSize: 14,
    marginBottom: theme.spacing(-2.5),
  },
}));

export const ConfigOverride: React.FC = () => {
  const classes = useStyles();
  const {
    config: externalConfig,
    updateFeatureFlags,
    originalConfig,
  } = useAppConfig();
  const [featureFlags, setFeatureFlags] = useState(externalConfig.featureFlags);
  const { showSuccess } = useSnackbar();

  useEffect(() => {
    const overrides = getItem("featureFlagsOverrides");
    setFeatureFlags(merge(externalConfig.featureFlags, overrides));
  }, [externalConfig]);

  const handleSubmit = (featureFlags: FeatureFlags) => {
    setItem("featureFlagsOverrides", featureFlags);
    updateFeatureFlags?.(featureFlags);
    showSuccess(
      <Typography variant="body2">
        Changes has been successfully saved
      </Typography>,
    );
  };

  if (!featureFlags) return <>Loading...</>;

  return (
    <Grid direction="column" container alignContent="center">
      <Form<FeatureFlags>
        formMode="create"
        onSubmit={handleSubmit}
        defaultValues={featureFlags}
        className={classes.root}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography className={classes.header}>
              Overide feature flags
            </Typography>
          </Grid>
          {Object.entries(featureFlags).map(([name, value]) => (
            <Grid item key={name} className={classes.override}>
              <CheckboxField
                name={name}
                label={
                  <>
                    {name}
                    {originalConfig?.featureFlags?.[name as FeatureName] !==
                      undefined && (
                      <Typography className={classes.originalValue}>
                        Default:
                        <em>
                          {" "}
                          {String(
                            originalConfig.featureFlags[name as FeatureName],
                          )}
                        </em>
                      </Typography>
                    )}
                  </>
                }
              />
            </Grid>
          ))}
          <Grid item>
            <Button type="submit" variant="contained">
              Save
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Grid>
  );
};
