import React, { useEffect, useMemo } from "react";
import { Grid } from "@mui/material";
import { H2 } from "@components/Typography";
import { useUser } from "@providers/Auth";
import { makeStyles } from "@providers/Mui";
import { History } from "@containers/History";
import { ListTicketRequest } from "@functions-types";
import { StatisticsCard } from "./StatisticsCard";
import { Tickets } from "./Tickets";
import { useIsMobile } from "@hooks/useIsMobile";
import { useListUserHistory } from "@cloud-functions";
import { collection, doc, limit, orderBy, query, where } from "firebase/firestore";
import { firestore } from "@@firebase";
import { useOnSnapshot } from "@hooks/useOnSnapshot";

const useStyles = makeStyles((theme) => ({
  mainContent: {
    height: "auto",
    [theme.breakpoints.up("sm")]: {
      // height: "calc(100vh - 260px)", // until statistic is not calculated
      height: "calc(100vh - 135px)",
    },
  },
  card: {
    height: "100%",
  },
  tableHeader: {
    paddingBottom: theme.spacing(1),
  },
  scrollTrack: {
    display: "flex",
    maxWidth: "100%",
    overflowX: "auto",
    scrollSnapType: "x mandatory",

    /* Hide scrollbar for Chrome, Safari and Opera */
    "&::-webkit-scrollbar": {
      display: "none",
    },
    /* Hide scrollbar for IE, Edge and Firefox */
    "-ms-overflow-style": "none" /* IE and Edge */,
    "scrollbar-width": "none" /* Firefox */,
  },
  statCard: {
    scrollSnapAlign: "start",
    minWidth: 210,
    flex: 1,
    "&:not(:last-child)": {
      marginRight: theme.spacing(2),
    },
  },
  history: {
    height: "100%",
    overflow: "hidden",
  },
  sidebar: {
    height: "auto",
    [theme.breakpoints.up("sm")]: {
      height: `calc(100% + ${theme.spacing(3)})`, // fix Grid spacing issue
    },
  },
}));

const getTicketsCreatedByMeFilter = (
  userId: string,
  ticketSLAFeatureEnabled?: boolean,
): ListTicketRequest => ({
  query: {
    where: [["createdBy.id", "==", userId]],
    orderBy: ticketSLAFeatureEnabled ? [["deadline", "desc"]] : [["createdAt", "desc"]],
  },
});

const getTicketsICreatedForSomeoneElseFilter = (
  userId: string,
  ticketSLAFeatureEnabled?: boolean,
): ListTicketRequest => ({
  query: {
    where: [["trulyCreatedBy.id", "==", userId]],
    orderBy: ticketSLAFeatureEnabled ? [["deadline", "desc"]] : [["createdAt", "desc"]],
  },
});

export const UserHomePage: React.FC = () => {
  const classes = useStyles();
  const { user } = useUser();

  const isMobile = useIsMobile();
  const spacing = isMobile ? 2 : 3;

  const [history, historyStatus] = useListUserHistory();

  const historyQuery = useMemo(() => {
    return query(
      collection(firestore, "history"),
      where("ticketOwner", "==", doc(firestore, "users", user.id)),
      orderBy("createdAt", "desc"),
      limit(1),
    );
  }, [user.id]);
  useOnSnapshot(historyQuery, () => historyStatus.refetch({}), []);

  useEffect(() => {
    historyStatus.refetch({});
  }, []);

  return (
    <Grid container spacing={spacing}>
      <Grid item xs={12}>
        <H2>Hi {user.firstName}!</H2>
      </Grid>
      <Grid item xs={12} sx={{ display: "none" }}>
        <div className={classes.scrollTrack}>
          <div className={classes.statCard}>
            <StatisticsCard title="Waiting for approval" count={0} />
          </div>
          <div className={classes.statCard}>
            <StatisticsCard title="Waiting for my response" count={0} />
          </div>
          <div className={classes.statCard}>
            <StatisticsCard title="Created by me" count={0} />
          </div>
        </div>
      </Grid>
      <Grid
        item
        xs={12}
        container
        spacing={spacing}
        className={classes.mainContent}
        direction={isMobile ? "column" : "row"}
        flexWrap="nowrap">
        <Grid item xs={12} sm={8} maxHeight="100%">
          <Tickets getFilter={getTicketsCreatedByMeFilter} title="Tickets created by me" />
          <Tickets
            getFilter={getTicketsICreatedForSomeoneElseFilter}
            title="Tickets I created on behalf of someone else"
          />
        </Grid>
        <Grid item xs={12} sm={4} height="100%">
          <Grid
            container
            spacing={spacing}
            direction="column"
            flexWrap="nowrap"
            className={classes.sidebar}>
            <Grid item className={classes.history}>
              <History
                className={classes.card}
                history={history}
                loading={historyStatus.loading}
                fetchNext={() => historyStatus.fetchNext({})}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
