import React, { useMemo } from "react";
import {
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  Typography,
} from "@mui/material";
import { useWatch } from "react-hook-form";
import { makeStyles } from "@providers/Mui";
import { CustomField as CustomFieldType } from "@functions-types";
import { useForm } from "@components/Form/FormProvider";
import { CustomFieldItem } from "./CustomFieldItem";
import { SelectField } from "@components/Form";
import AddIcon from "@mui/icons-material/Add";
import { isNonNullish, moveItem } from "@lib/utils";
import { Container, Draggable, DropResult } from "react-smooth-dnd";
import DragHandleIcon from "@mui/icons-material/DragHandle";

const useStyles = makeStyles((theme) => ({
  root: {},
  select: {
    backgroundColor: "white",
  },
  addButton: {
    flexShrink: 0,
    marginLeft: theme.spacing(1),
  },
  selectContainer: {
    width: "100%",
    flexShrink: 2,
  },
  header: {
    color: "#565656",
    fontWeight: "bold",
    fontSize: 14,
    marginBottom: theme.spacing(0.75),
  },
}));

type CustomFieldProps = {
  customFields: CustomFieldType[];
};

export const CustomField: React.FC<CustomFieldProps> = ({ customFields }) => {
  const classes = useStyles();
  const customFieldsList: CustomFieldType[] = useWatch({
    name: "customFields",
  });
  const selectedField: CustomFieldType[] = useWatch({ name: "customField" });

  const onDrop = ({ removedIndex, addedIndex }: DropResult) => {
    if (removedIndex !== null && addedIndex !== null) {
      const newCustomFieldsList = moveItem(
        customFieldsList,
        removedIndex,
        addedIndex,
      );
      setValue("customFields", newCustomFieldsList);
    }
  };

  const {
    methods: { setValue },
  } = useForm();

  const handleAddCustomField = () => {
    setValue("customFields", [...(customFieldsList || []), selectedField], {
      shouldValidate: true,
    });
    setValue("customField", null);
  };

  const handleRemoveCustomField = (fieldId: string) => {
    setValue(
      "customFields",
      customFieldsList.filter(({ id }) => id !== fieldId),
      { shouldValidate: true },
    );
  };

  const availableFields = useMemo(() => {
    const fieldsMap = (customFieldsList || [])
      .filter(isNonNullish)
      .reduce((acc, cur) => {
        return {
          ...acc,
          [cur.id]: true,
        };
      }, {} as Record<string, boolean>);
    return customFields.filter(({ id }) => !fieldsMap[id]);
  }, [customFieldsList, customFields]);

  return (
    <Grid>
      <Typography className={classes.header}>Custom fields</Typography>
      <List>
        <Container
          dragHandleSelector=".drag-handle"
          lockAxis="y"
          onDrop={onDrop}>
          {(customFieldsList || []).filter(isNonNullish).map((item) => (
            <Draggable key={item.id}>
              <ListItem>
                <ListItemIcon className="drag-handle">
                  <DragHandleIcon />
                </ListItemIcon>
                <CustomFieldItem
                  key={item.id}
                  name={item.label}
                  fieldId={item.id}
                  onDelete={handleRemoveCustomField}
                />
              </ListItem>
            </Draggable>
          ))}
        </Container>
      </List>
      <Grid container alignItems="center" flexWrap="nowrap">
        <Grid item className={classes.selectContainer}>
          <SelectField
            name="customField"
            variant="outlined"
            fullWidth
            className={classes.select}
            getOptionText={(option) => option.label}
            options={availableFields}
            placeholder="Select field"
            submitOnChange
          />
        </Grid>
        <Grid item>
          <IconButton
            className={classes.addButton}
            disableRipple
            onClick={handleAddCustomField}>
            <AddIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
};
