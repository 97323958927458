import { Label } from "@components/Label";
import { CTIApprovalConfigRule } from "@functions-types";
import { joinNodes } from "@lib/utils";
import { lighten, Typography } from "@mui/material";
import { makeStyles } from "@providers/Mui";
import clsx from "clsx";
import { FC } from "react";
import { ApprovalRulePreview } from "./ApprovalRulePreview";

type ApprovalRulesPreviewProps = {
  title?: string;
  className?: string;
  rules: CTIApprovalConfigRule[];
};

export const ApprovalRulesPreview: FC<ApprovalRulesPreviewProps> = ({
  title,
  className,
  rules,
}) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, className)}>
      {title && <Label label={title} className={classes.label} />}

      {joinNodes(
        rules.map((rule) => <ApprovalRulePreview value={rule} />),
        <Typography className={classes.then}>then</Typography>,
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 5,
    backgroundColor: lighten(theme.palette.green[100], 0.5),
    padding: theme.spacing(1),
  },
  label: {
    marginBottom: theme.spacing(1),
  },
  then: {
    color: theme.palette.grey[700],
    fontSize: 10,
    textTransform: "uppercase",
  },
}));
