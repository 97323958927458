import React, { useRef, useState } from "react";
import { TreeItem } from "@mui/lab";
import { OptionsTree } from "@functions-types";
import { makeStyles } from "@providers/Mui";
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { Grid, IconButton, Typography } from "@mui/material";
import { AddNode } from "../AddNode";
import { NodeForm } from "../NodeForm";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import clsx from "clsx";

type TreeNodeProps = {
  node: OptionsTree;
  id: string;
  path: string;
  onSave: (value: string, path: string) => void;
  onDelete: (path: string) => void;
  level: number;
};

const useStyles = makeStyles((theme) => ({
  root: {
    "&:hover $icon": {
      opacity: 1,
    },
  },
  value: {
    paddingRight: theme.spacing(2),
  },
  icon: {
    fontSize: 18,
    opacity: 0,
    transition: "opacity 0.3s",
  },
  delete: {
    color: theme.palette.error.main,
  },
}));

const MAX_LEVEL = 4;

export const TreeNode: React.FC<TreeNodeProps> = ({
  node,
  id,
  path,
  onSave,
  onDelete,
  level,
}) => {
  const classes = useStyles();
  const [addFormOpen, setAddFormOpen] = useState(false);
  const [editFormOpen, setEditFormOpen] = useState(false);
  const addNodeRef = useRef<HTMLButtonElement>(null);
  const editNodeRef = useRef<HTMLButtonElement>(null);

  return (
    <TreeItem
      label={
        <Grid
          container
          wrap="nowrap"
          alignItems="center"
          className={classes.root}
        >
          <Typography className={classes.value}>{node.value}</Typography>
          <IconButton
            disableRipple
            onClick={() => setEditFormOpen(true)}
            ref={editNodeRef}
          >
            <EditIcon fontSize="small" className={classes.icon} />
          </IconButton>
          <IconButton disableRipple onClick={() => onDelete(path)}>
            <CloseIcon
              fontSize="small"
              className={clsx(classes.icon, classes.delete)}
            />
          </IconButton>
          {editNodeRef.current && (
            <NodeForm
              value={node.value}
              path={path}
              onSave={onSave}
              open={editFormOpen}
              onClose={() => setEditFormOpen(false)}
              anchorRef={editNodeRef}
            />
          )}
        </Grid>
      }
      nodeId={id}
      expandIcon={level < MAX_LEVEL ? <ChevronRightIcon /> : <></>}
      collapseIcon={level < MAX_LEVEL ? <ExpandMoreIcon /> : <></>}
    >
      {(node.children || []).map((node, idx) => (
        <TreeNode
          node={node}
          id={`${id}-${node.value}`}
          key={`${id}-${node.value}-${idx}`}
          path={`${path}.children.${idx}`}
          onSave={onSave}
          level={level + 1}
          onDelete={onDelete}
        />
      ))}
      {level < MAX_LEVEL && (
        <AddNode
          id={`${id}-add`}
          _ref={addNodeRef}
          onClick={() => setAddFormOpen(true)}
        />
      )}
      <NodeForm
        path={`${path}.children`}
        onSave={onSave}
        open={addFormOpen}
        onClose={() => setAddFormOpen(false)}
        anchorRef={addNodeRef}
      />
    </TreeItem>
  );
};
