import { makeCloudFunction } from "../makeCloudFunction";
import {
  DeleteCommentActivityRequest,
  DeleteCommentActivityResponse,
} from "@functions-types";

export const deleteComment = makeCloudFunction<
  DeleteCommentActivityRequest,
  DeleteCommentActivityResponse
>("activity", "deleteComment");
