import React from "react";
import { useParams } from "react-router";
import { MainLayout } from "@components/MainLayout";
import { TicketCard } from "@components/TicketCard";
import { Card } from "@components/Card";
import { useQuery } from "@hooks/useQuery";
import { ActivityFilterType } from "@containers";

const sanitizeQuery = (
  query: URLSearchParams,
): {
  autoFocusOnComment: boolean;
  defaultActivityTab: ActivityFilterType | undefined;
} => {
  const autoFocusOnComment = Boolean(query.get("comment"));
  const defaultActivityTabString = String(query.get("activity"));

  let defaultActivityTab: ActivityFilterType | undefined = undefined;
  if (["all", "comment", "history"].includes(defaultActivityTabString)) {
    defaultActivityTab = defaultActivityTabString as ActivityFilterType;
  }

  return {
    autoFocusOnComment,
    defaultActivityTab,
  };
};

export const ViewTicket: React.FC = () => {
  const { id } = useParams();
  const query = useQuery();

  const { autoFocusOnComment, defaultActivityTab } = sanitizeQuery(query);

  if (!id) {
    return null;
  }

  return (
    <MainLayout fullHeight fullWidth>
      <Card>
        <TicketCard
          key={id}
          ticketId={id}
          withTicketLink
          autoFocusOnComment={autoFocusOnComment}
          defaultActivityTab={defaultActivityTab}
        />
      </Card>
    </MainLayout>
  );
};
