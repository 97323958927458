import React, { useEffect, useMemo, useState } from "react";
import { MainLayout } from "@components/MainLayout";
import { makeStyles } from "@providers/Mui";
import { useParams } from "react-router";
import { Form } from "@components/Form";
import { CreateReviewInput } from "@functions-types";
import {
  createReview as createReviewCloudFunction,
  useGetReview,
  useGetTicket,
} from "@cloud-functions";
import { reviewValidator } from "./validationSchema";
import { ReviewForm } from "./ReviewForm";
import { ReviewPlaceholder } from "./ReviewPlaceholder";
import { ReviewResult } from "./ReviewResult";
import { Blocker } from "@components/Blocker";
import { useUser } from "@providers/Auth";
import { useAsync } from "@hooks/useAsync";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(2),
    height: "100%",
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(4),
    },
  },
}));

const reviewDefault: Omit<CreateReviewInput, "ticket"> = {
  rating: 1,
};

export const ReviewPage: React.FC = () => {
  const classes = useStyles();
  const { ticketId } = useParams();
  const { user } = useUser();
  const [reviewAdded, setReviewAdded] = useState(false);
  const [error, setError] = useState("");

  const [ticket, ticketState] = useGetTicket({
    input: { id: ticketId ?? "" },
  });
  const [review, reviewState] = useGetReview({
    input: { id: ticketId ?? "" },
  });

  const [createReview, createReviewState] = useAsync(
    createReviewCloudFunction,
  );

  const handleSubmit = async (values: CreateReviewInput) => {
    try {
      const result = await createReview({
        input: {
          ...values,
          ticket: ticketId ?? "",
        }
      });
      if (result) {
        setReviewAdded(true);
      } else {
        setError("Problem during review creation");
      }
    } catch (e) {
      console.error(e);
      setError("Problem during review creation");
    }
  };

  useEffect(() => {
    if (ticket?.createdBy?.id && user.id !== ticket.createdBy.id) {
      setError("Only ticket owner can add review");
    }

    if (ticket && !ticket.assignee?.id) {
      setError("Ticket is not assigned to agent");
    }
  }, [ticket, user]);

  const loading = ticketState.loading || reviewState.loading;

  const renderContent = useMemo(() => {
    if (error) {
      return <ReviewResult title={error} hasError />;
    }

    if (loading) {
      return <ReviewPlaceholder />;
    }

    if (!ticket) {
      return <ReviewResult title="Ticket is not exist" hasError />;
    }

    if (!review) {
      return <ReviewForm saved={reviewAdded} />;
    }

    return <ReviewResult title="You already rated your experience!" />;
  }, [loading, error, ticket, review, reviewAdded]);

  return (
    <MainLayout fullHeight>
      <Form<CreateReviewInput>
        onSubmit={handleSubmit}
        resolver={reviewValidator}
        formMode="create"
        className={classes.root}
        defaultValues={reviewDefault}
      >
        {renderContent}
        <Blocker open={createReviewState.isLoading} />
      </Form>
    </MainLayout>
  );
};
