import { makeStyles } from "@providers/Mui";
import { FC } from "react";
import { Avatar } from "@mui/material";
import { HasClassName } from "@lib/types";
import clsx from "clsx";
import {
  getUserColour,
  getUsernameInitials,
  getInactiveUserColour,
} from "@ztt/common";

type UserAvatarProps = HasClassName & {
  user: { email?: string; displayName: string; active: boolean } | null;
};

export const UserAvatar: FC<UserAvatarProps> = ({ user, className }) => {
  const classes = useStyles();

  return (
    <Avatar
      className={clsx(classes.root, className)}
      alt={user?.displayName ?? ""}
      // src={user?.photoUrl ?? ""}
      sx={{
        ...getUserColour(user?.email ?? user?.displayName),
        ...(user?.active ? {} : getInactiveUserColour()),
      }}>
      {getUsernameInitials(user?.displayName)}
    </Avatar>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: 32,
    height: 32,
    fontSize: 14,
  },
}));
