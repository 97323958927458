import { Country, Store, useListStores } from "@cloud-functions";
import { useMemo } from "react";

type UseStoresLookupsResult = {
  countries: Country[];
  storesMap: Record<string, Store[]>;
  loading: boolean;
};

export const useStoresLookups = (): UseStoresLookupsResult => {
  const [storesList, { loading }] = useListStores();

  return useMemo(() => (storesList ?? []).reduce((acc, cur) => {
    if (!cur.country?.id) return acc;

    if (!acc.storesMap[cur.country.id]) {
      acc.countries.push(cur.country);
    }

    return {
      ...acc,
      storesMap: {
        ...acc.storesMap,
        [cur.country.id]: [
          ...(acc.storesMap[cur.country.id] ?? []),
          cur,
        ],
      },
    };
  }, { countries: [], storesMap: {}, loading } as UseStoresLookupsResult),
    [loading, storesList]);
};
