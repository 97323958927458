import { FC, useEffect, useRef, useState } from "react";
import {
  Tooltip,
  IconButton,
  CircularProgress,
  Typography,
  Button,
} from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { makeStyles } from "@providers/Mui";
import { useUploading } from "@hooks/useUploading";
import { isNonNullish } from "@lib/utils";
import { MAX_FILE_SIZE } from "@components/TicketCard/AttachmentSection";
import { useForm } from "@components/Form/FormProvider";
import { useSnackbar } from "@providers/Snackbar";
import { useWatch } from "react-hook-form";
import { Attachment } from "@functions-types";

type CommentAttachmentUploaderProps = {
  commentId?: string;
};

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2, 0),
  },
  input: {
    display: "none",
  },
  button: {
    minWidth: 0,
    marginTop: 0,
    marginRight: theme.spacing(1),
    textTransform: "none",
    color: theme.palette.grey[700],
  },
  loader: {
    color: theme.palette.grey[700],
  },
}));

export const CommentAttachmentUploader: FC<CommentAttachmentUploaderProps> = ({
  commentId,
}) => {
  const classes = useStyles();
  const { methods } = useForm();
  const [files, setFiles] = useState<FileList | null>(null);
  const attachments: Attachment[] = useWatch({ name: "attachments" }) || [];
  const inputRef = useRef<HTMLInputElement>(null);
  const { upload, loading } = useUploading("comment");
  const { showError, showSuccess } = useSnackbar();
  const { setValue } = methods;

  const htmlId = `comment-attachment-${commentId ?? "new"}`;
  const htmlName = `commentAttachment${commentId ?? "new"}`;

  useEffect(() => {
    if (files) {
      (async () => {
        const uploadedRefs = await Promise.all(
          Array.from(files).map(async (file) => {
            if (file.size > MAX_FILE_SIZE) {
              showError(
                <Typography variant="body2">
                  File {file.name} is too large. Max size is 8Mb.
                </Typography>,
              );
              return null;
            }
            const fileRef = await upload(file);
            if (fileRef) {
              return {
                fileRef,
                fileName: file.name,
              };
            }

            return null;
          }),
        );
        const uploadedRefsFiltered = uploadedRefs.filter(isNonNullish);

        const hasMoreThanOneRef = uploadedRefsFiltered.length > 1;

        setFiles(null);
        setValue("attachments", [...attachments, ...uploadedRefsFiltered]);
        if (uploadedRefsFiltered.length > 0) {
          showSuccess(
            <Typography variant="body2">
              {`${uploadedRefsFiltered.length} file${
                hasMoreThanOneRef ? "s" : ""
              } ha${hasMoreThanOneRef ? "ve" : "s"} been successfully
              attached`}
            </Typography>,
          );
        }

        if (inputRef.current?.value) {
          inputRef.current.value = "";
        }
      })();
    }
  }, [files]);

  return (
    <label htmlFor={htmlId}>
      <input
        name={htmlName}
        id={htmlId}
        type="file"
        className={classes.input}
        onChange={(event) => setFiles(event.target.files)}
        ref={inputRef}
      />
      <Tooltip title="Add comment attachment">
        <Button
          className={classes.button}
          disabled={loading}
          component="span"
          startIcon={
            loading ? (
              <CircularProgress
                size={16}
                color="secondary"
                className={classes.loader}
              />
            ) : (
              <AttachFileIcon />
            )
          }>
          Add attachment
        </Button>
      </Tooltip>
    </label>
  );
};
