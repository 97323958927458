import React from "react";
import { Grid, Typography } from "@mui/material";
import { H6 } from "@components/Typography";
import { makeStyles } from "@providers/Mui";
import { Status } from "@functions-types";
import { StatusCard } from "./StatusCard";

const useStyles = makeStyles((theme) => ({
  header: {
    padding: theme.spacing(2),
  },
}));

type StatusesListProps = {
  statuses: Status[];
  onSelect: (status: Status) => void;
  activeStatusId?: string;
};

export const StatusesList: React.FC<StatusesListProps> = ({ statuses, onSelect, activeStatusId }) => {
  const classes = useStyles();
  return (
    <Grid container direction="column">
      <H6 className={classes.header}>Statuses</H6>
      {!statuses.length && (
        <Typography variant="caption" align="center">
          No statuses available
        </Typography>
      )}
      {statuses.map((status) => (
        <StatusCard
          status={status}
          onSelect={onSelect}
          activeStatusId={activeStatusId}
          key={status.id}
        />
      ))}
    </Grid>
  );
};
