import React from "react";
import { Card } from "@components/Card";
import { Typography } from "@mui/material";
import { makeStyles } from "@providers/Mui";

type StatisticsCardProps = {
  title: string;
  count: number | string;
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
    padding: theme.spacing(1),
    scrollSnapAlign: "start",
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2),
    },
  },
  count: {
    fontSize: 26,
    fontWeight: 500,
  },
}));

export const StatisticsCard: React.FC<StatisticsCardProps> = ({
  title,
  count,
}) => {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <Typography className={classes.count}>{count}</Typography>
      <Typography>{title}</Typography>
    </Card>
  );
};
