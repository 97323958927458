import React, { useEffect } from "react";
import { H6 } from "@components/Typography";
import { Card } from "@components/Card";
import { HasClassName } from "@lib/types";
import { History as HistoryRecord } from "@cloud-functions";
import { HistoryPlaceholder } from "./HistoryPlaceholder";
import { useInView } from "react-intersection-observer";
import { Grid } from "@mui/material";
import { useUser } from "@providers/Auth";
import { HistoryItem } from "@containers/History/HistoryItem";
import { makeStyles } from "@providers/Mui";
import clsx from "clsx";
import { EmptyList } from "./EmptyList";

type HistoryProps = HasClassName & {
  loading: boolean;
  fetchNext: () => void;
  history?: HistoryRecord[];
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 0, 5),
  },
  scrollable: {
    height: "100%",
    overflow: "auto",
    padding: theme.spacing(0, 2.5),
  },
  header: {
    padding: theme.spacing(0, 2.5, 1),
  },
}));

export const History: React.FC<HistoryProps> = ({ className, loading, fetchNext, history }) => {
  const classes = useStyles();
  const { user } = useUser();
  const { ref, inView } = useInView({
    initialInView: false,
  });

  useEffect(() => {
    if (inView && user?.id) {
      fetchNext();
    }
  }, [inView, user?.id]);

  return (
    <Card className={clsx(classes.root, className)} alignItems="flex-start">
      {loading && !history?.length ? (
        <HistoryPlaceholder />
      ) : (
        <>
          <H6 className={classes.header}>Recent activity</H6>
          <Grid
            container
            direction="column"
            className={classes.scrollable}
            flexWrap="nowrap">
            {history?.map((item) => (
              <HistoryItem {...item} key={item.id} />
            ))}
            {!history?.length && !loading && <EmptyList />}
            <Grid ref={ref} />
          </Grid>
        </>
      )}
    </Card>
  );
};
