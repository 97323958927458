import { FirebaseDateTime, SLA, SLAUnit } from "@functions-types";

export const firestoreTimeToDate = (time: FirebaseDateTime) => {
  return new Date(time._seconds * 1000);
};

export const firestoreTimeToMs = (time: FirebaseDateTime) => {
  return time._seconds * 1000;
};

const unitsMap: Record<SLAUnit, number> = {
  minute: 60 * 1000,
  hour: 60 * 60 * 1000,
  day: 24 * 60 * 60 * 1000,
};

export const SLAtoNumber = (sla: SLA) => {
  return sla.value * unitsMap[sla.unit];
};
