import {
  Category,
  GetMeResponse,
  Item,
  Ticket,
  Type,
  Space,
} from "@functions-types";
import { isNonNullish } from "./utils";

export function isSpaceManager(user: GetMeResponse, space: Space): boolean {
  const isSpaceManager = space.managers?.some((u) => u?.id === user.id);
  return isSpaceManager;
}

export function isSpaceMember(user: GetMeResponse, space: Space): boolean {
  const members = space.group?.members ?? [];
  const isSpaceMember = members.some((member) => member?.id === user.id);
  return isSpaceMember;
}

export function isSpaceManagerOrMember(
  user: GetMeResponse,
  space: Space,
): boolean {
  return isSpaceManager(user, space) || isSpaceMember(user, space);
}

export function canActOnTicket(user: GetMeResponse, ticket?: Ticket): boolean {
  const space = ticket?.item?.space;
  if (!space) return false;

  if (user.isAdmin || user.isManager) return true;

  const isAssignee = ticket.assignee?.id === user.id;
  if (isAssignee) return true;

  return isSpaceManagerOrMember(user, space);
}

export function canViewCategory(
  user: GetMeResponse,
  category: Category,
): boolean {
  if (user.isAdmin || user.isManager) return true;

  if (user.isRider) {
    return (
      !category.userTypeRestriction ||
      category.userTypeRestriction === "any" ||
      category.userTypeRestriction === "rider"
    );
  }

  // employee
  return (
    !category.userTypeRestriction ||
    category.userTypeRestriction === "any" ||
    category.userTypeRestriction === "employee"
  );
}

export function canViewType(user: GetMeResponse, type: Type): boolean {
  if (!type.category || !canViewCategory(user, type.category)) return false;

  if (user.isAdmin || user.isManager) return true;

  if (user.isRider) {
    return (
      !type.userTypeRestriction ||
      type.userTypeRestriction === "any" ||
      type.userTypeRestriction === "rider"
    );
  }

  // employee
  return (
    !type.userTypeRestriction ||
    type.userTypeRestriction === "any" ||
    type.userTypeRestriction === "employee"
  );
}

export function canViewItem(user: GetMeResponse, item: Item): boolean {
  if (!item.space) return false; // otherwise, users will get an exception while creating a ticket because we can't compute tikcet id based on space
  if (!item.type || !canViewType(user, item.type)) return false;

  if (user.isAdmin || user.isManager) return true;

  if (user.isRider) {
    return (
      !item.userTypeRestriction ||
      item.userTypeRestriction === "any" ||
      item.userTypeRestriction === "rider"
    );
  }

  // employee
  return (
    !item.userTypeRestriction ||
    item.userTypeRestriction === "any" ||
    item.userTypeRestriction === "employee"
  );
}

export function canViewWorkflow(
  user: GetMeResponse,
  space?: Space | null,
): boolean {
  if (!space) {
    return false;
  }

  if (user?.isAdmin || user?.isManager) {
    return true;
  }

  const isSpaceManager =
    space.managers?.filter(isNonNullish).some(({ id }) => id === user.id) ||
    false;

  return isSpaceManager;
}
