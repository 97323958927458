import React, { useState } from "react";
import { makeStyles } from "@providers/Mui";
import { Grid, Typography } from "@mui/material";
import { Button } from "@components/Button";
import { Status } from "@functions-types";
import { useSnackbar } from "@providers/Snackbar";
import {
  createStatus as createStatusCloudFunction,
  updateStatus as updateStatusCloudFunction,
  useListStatuses,
} from "@cloud-functions";
import { logError } from "@lib/logger";
import { Form } from "@components/Form";
import { useAsync } from "@hooks/useAsync";
import { Blocker } from "@components/Blocker";
import { StatusesList } from "./StatusesList";
import { StatusesForm } from "./StatusesForm";
import { statusValidator } from "./statusValidator";
import { StatusFormState } from "./types";
import { statusToFormState, formStateToInput } from "./utils";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    height: "100%",
  },
  content: {
    height: "100%",
  },
  newStatus: {
    color: theme.palette.info.main,
    textTransform: "none",
    width: "100%",
    marginTop: theme.spacing(1),
  },
}));

export const Statuses: React.FC = () => {
  const classes = useStyles();
  const { showError } = useSnackbar();
  const [statusesList, statusesListStatus] = useListStatuses();
  const [selectedStatus, setSelectedStatus] = useState<Status | null>(null);
  const [createStatus, createStatusState] = useAsync(createStatusCloudFunction);
  const [updateStatus, updateStatsState] = useAsync(updateStatusCloudFunction);

  const handleSubmit = async (values: StatusFormState) => {
    try {
      if (selectedStatus?.id) {
        const updatedStatus = await updateStatus({
          input: {
            id: selectedStatus.id,
            updates: formStateToInput(values),
          },
        });
        statusesListStatus.refetch();
        setSelectedStatus(updatedStatus);
      } else {
        const status = await createStatus({
          input: formStateToInput(values),
        });
        statusesListStatus.refetch();
        setSelectedStatus(status);
      }
    } catch (err) {
      logError(err);

      showError(
        <Typography variant="body2">
          Something went wrong during saving status
        </Typography>,
      );
    }
  };

  const loading =
    createStatusState.isLoading ||
    updateStatsState.isLoading ||
    statusesListStatus.loading;

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={3}>
        <StatusesList
          statuses={statusesList ?? []}
          onSelect={setSelectedStatus}
          activeStatusId={selectedStatus?.id}
        />
        <Button
          className={classes.newStatus}
          onClick={() => setSelectedStatus(null)}>
          Create status
        </Button>
      </Grid>
      <Grid item xs={9} className={classes.content}>
        <Form<StatusFormState>
          formMode="create"
          onSubmit={handleSubmit}
          className={classes.content}
          resolver={statusValidator}
          defaultValues={statusToFormState(selectedStatus)}
          key={selectedStatus?.id}>
          <StatusesForm selectedStatus={selectedStatus} />
        </Form>
      </Grid>
      <Blocker open={loading} />
    </Grid>
  );
};
