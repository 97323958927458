import { ListTicketResponse, ListTicketRequest } from "@functions-types";
import { makeUseCloudFunctionV2 } from "../makeUseCloudFunctionV2";
import { makeCloudFunction } from "../makeCloudFunction";
import { last } from "lodash";

export const listTickets = makeCloudFunction<ListTicketRequest, ListTicketResponse>(
  "ticket",
  "listTickets",
);

export const useListTickets = makeUseCloudFunctionV2<ListTicketRequest, ListTicketResponse>(
  listTickets,
  {
    useCache: false,
    query: {
      orderBy: [["createdAt", "desc"]],
    },
    getCursor: (tickets) => last(tickets)?.id,
    triggerOnMounting: false,
  },
);
