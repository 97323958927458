import { Tickets } from "@containers/HomePage/Tickets/Tickets";
import { ListTicketRequest } from "@functions-types";
import { makeStyles } from "@providers/Mui";
import React from "react";

type ApprovalsWatchingProps = {};

const getTicketsIMWatchingFilter = (userId: string): ListTicketRequest => ({
  query: {
    where: [
      ["watchersIds", "array-contains", userId],
      ["approvalRules", "!=", null],
    ],
    orderBy: [
      ["approvalRules", "asc"],
      ["createdAt", "desc"],
    ],
  },
});

const useStyles = makeStyles((theme) => ({
  tickets: {
    maxHeight: "100%",
  },
}));

export const ApprovalsWatching: React.FC<ApprovalsWatchingProps> = () => {
  const classes = useStyles();

  return (
    <Tickets
      classes={{ root: classes.tickets }}
      getFilter={getTicketsIMWatchingFilter}
      title="Tickets I am watching (that requires approvals)"
      columns={["title", "status", "severity", "category", "approvalState"]}
    />
  );
};
