import React, { useCallback, useEffect, useRef, useState } from "react";
import { UserCard } from "@components/UserCard";
import { Ref, User } from "@functions-types";
import { makeStyles } from "@providers/Mui";
import {
  Autocomplete,
  CircularProgress,
  TextField,
  Popper,
  ClickAwayListener,
  Grow,
  Paper,
} from "@mui/material";
import { mapOptionToValue } from "@components/Form/fields/Autocomplete/utils";
import { useDebounce } from "@hooks/useDebounce";
import { Blocker } from "@components/Blocker";

export type SearchUserResponse = {
  id: string;
  label: string;
  email: string;
}[];

type AssigneeProps = {
  assignee: Ref<User>;
  onAssigneeUpdate: (ticketId: string, assignee: string) => Promise<void>;
  ticketId: string;
  userSearch: (query: string) => Promise<SearchUserResponse>;
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-block",
    width: "auto",
  },
  loaderContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(255,255,255,0.4)",
  },
  field: {
    minWidth: 300,
  },
  input: {
    fontSize: 15,
    "& input": {
      paddingRight: theme.spacing(2),
    },
  },
  paper: {
    marginTop: theme.spacing(1),
    zIndex: 2
  }
}));

const getOptionText = (option: SearchUserResponse[0]) =>
  `${option.label} (${option.email})`;

export const Assignee: React.FC<AssigneeProps> = ({ assignee, onAssigneeUpdate, ticketId, userSearch }) => {
  const classes = useStyles();
  const fieldRef = useRef<HTMLDivElement>(null);
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = React.useState<SearchUserResponse | null>(null);
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = useState(assignee ? {
    id: assignee?.id,
    label: assignee?.displayName,
    email: assignee.email,
  } : null);
  const [searchQuery, setSearchQuery] = React.useState("");

  const labelsMapRef = useRef<Record<string, string>>({});

  const handleChipClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setEdit(true);
  };

  const search = useCallback(
    async (query: string) => {
      const results = await userSearch(query);
      setOptions(results);

      results.forEach((record) => {
        labelsMapRef.current[record.id] =
          getOptionText(record) ?? record.label;
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userSearch],
  );
  const debouncedSearch = useDebounce(search, 500);

  const getOptionLabel = (option: SearchUserResponse[0]) => {
    if (!option) return "-N/A-";

    return (
      labelsMapRef.current[typeof option === "string" ? option : option.id] ??
      "-"
    );
  };

  useEffect(() => {
    if (searchQuery) {
      setOptions(null);
      debouncedSearch(searchQuery);
    }
  }, [debouncedSearch, searchQuery]);

  const optionsLoading = !!(open && options === null && searchQuery.length);

  return (
    <div className={classes.root} ref={fieldRef}>
      <Popper
        open={edit}
        anchorEl={fieldRef.current}
        placement="bottom"
        transition
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper className={classes.paper}>
              <ClickAwayListener onClickAway={() => setEdit(false)}>
                <Autocomplete
                  onChange={async (_, data) => {
                    setValue(data);
                    if (data?.id) {
                      setOpen(false);
                      setEdit(false);
                      setLoading(true);
                      await onAssigneeUpdate(ticketId, data.id);
                      setLoading(false);
                    }
                  }}
                  onClick={(event) => event.stopPropagation()}
                  isOptionEqualToValue={mapOptionToValue}
                  getOptionLabel={getOptionLabel}
                  value={value}
                  defaultValue={value}
                  options={options ?? []}
                  loading={optionsLoading}
                  open={Boolean(open && searchQuery.length)}
                  renderOption={(props, option) => (
                    <li
                      {...props}
                      onClick={(event) => {
                        event.stopPropagation();
                        props?.onClick?.(event);
                      }}
                      key={option.id}
                    >
                      {getOptionText(option) ?? option.label}
                    </li>
                  )}
                  onOpen={() => {
                    setOpen(true);
                  }}
                  onClose={() => {
                    setOpen(false);
                    setOptions([]);
                    setSearchQuery("");
                  }}
                  filterOptions={(o) => o}
                  filterSelectedOptions
                  className={classes.field}
                  renderInput={(props) => <TextField
                    {...props}
                    size="small"
                    autoFocus
                    onChange={(e) => {
                      setSearchQuery(e.target.value);
                    }}
                    InputProps={{
                      ...props.InputProps,
                      className: classes.input,
                      placeholder: "Start typing to search...",
                      endAdornment: optionsLoading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : (
                        props.InputProps.endAdornment
                      ),
                    }}
                    inputProps={{
                      ...props.inputProps,
                      value: searchQuery,
                    }}
                    onClick={(event) => event.stopPropagation()}
                  />}
                />
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <UserCard user={assignee} onClick={handleChipClick} />
      {loading && (
        <div className={classes.loaderContainer}>
          <Blocker open progressSx={{ height: 2 }} />
        </div>
      )}
    </div>
  );
};
