import React from "react";
import { TableCell, TableHead, TableRow } from "@mui/material";
import { makeStyles } from "@providers/Mui";
import { userFieldsMap } from "@lib/constants";
import { Columns } from "../types";

type HeaderProps = {
  columnsMap: Record<Columns, boolean>;
};

const columns: Columns[] = [
  "id",
  "displayName",
  "title",
  "department",
  "currentWeekRating",
  "score",
];

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    zIndex: 1,
  },
  header: {
    position: "static",
    fontWeight: 600,
    padding: theme.spacing(1, 1),
    top: 0,
    borderTop: 0,
    borderBottom: 0,
    backgroundColor: theme.palette.grey[50],
    fontSize: 11,
  },
  headerRow: {
    position: "sticky",
    top: 0,
    textTransform: "uppercase",
    fontSize: 11,
  },
}));

export const Header: React.FC<HeaderProps> = ({ columnsMap }) => {
  const classes = useStyles();
  return (
    <TableHead className={classes.root}>
      <TableRow className={classes.headerRow}>
        {columns
          .filter((column) => columnsMap[column])
          .map((column) => (
            <TableCell
              className={classes.header}
              key={column}
              align={column === "score" ? "center" : "left"}>
              {userFieldsMap[column]}
            </TableCell>
          ))}
      </TableRow>
    </TableHead>
  );
};
