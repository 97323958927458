import React, { useEffect, useMemo } from "react";
import { Grid, Collapse } from "@mui/material";
import { makeStyles } from "@providers/Mui";
import { H6 } from "@components/Typography";
import { TextField, SelectField, CheckboxField } from "@components/Form";
import { Button } from "@components/Button";
import { useWatch } from "react-hook-form";
import { useForm } from "@components/Form/FormProvider";
import { usePrevious } from "@hooks/usePrevious";
import { CustomFieldForm as CustomFieldFormType } from "../../../Admin/CustomFields/types";
import { fieldsTypes } from "../utils";
import { OptionsList } from "./OptionsList";
import { NestedSelect } from "./NestedSelect";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[200],
    height: "100%",
    overflowX: "auto",
  },
  container: {
    paddingBottom: theme.spacing(2),
    "&:last-children": {
      paddingBottom: 0,
    },
  },
  field: {
    "& .MuiOutlinedInput-root": {
      backgroundColor: "white",
    },
  },
  select: {
    backgroundColor: "white",
  },
  group: {
    margin: theme.spacing(1, 0, 2),
    borderLeft: "2px solid rgb(224, 224, 224)",
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
  },
}));

const fieldsWithoutDefaultValue = [
  "checkbox",
  "selectField",
  "datePicker",
  "nestedSelectField",
  "storeSelectField",
];

export const CustomFieldForm: React.FC = () => {
  const classes = useStyles();
  const required = useWatch({ name: "required" });
  const fieldType: CustomFieldFormType["type"] = useWatch({ name: "type" });
  const lessThan = useWatch({ name: "lessThanEnabled" });
  const greaterThan = useWatch({ name: "greaterThanEnabled" });
  const options = useWatch({ name: "options" });
  const {
    methods: { setValue },
  } = useForm();
  const prevType = usePrevious(fieldType);

  useEffect(() => {
    if (prevType && fieldType?.id !== prevType?.id) {
      if (fieldType?.id !== "selectField") {
        setValue("options", null);
      }

      if (fieldType?.id !== "nestedSelectField") {
        setValue("optionsTree", null);
      }

      if (fieldType?.id !== "numberField") {
        setValue("greaterThanEnabled", false);
        setValue("lessThanEnabled", false);
        setValue("lessThan", null);
        setValue("lessThanValidationText", null);
        setValue("greaterThan", null);
        setValue("greaterThanValidationText", null);
      }

      setValue("defaultValue", "");
    }
  }, [fieldType]);

  const optionsList: { id: string; label: string }[] = useMemo(() => {
    const filteredOptions = (options || []).filter(
      (option: string) => !!option,
    );
    if (!filteredOptions?.length) return [];
    return [
      { id: "", label: "-- None --" },
      ...filteredOptions.map((option: string) => ({
        id: option,
        label: option,
      })),
    ];
  }, [options]);

  return (
    <Grid className={classes.root}>
      <div className={classes.container}>
        <H6>Create custom field</H6>
      </div>
      <div className={classes.container}>
        <TextField
          name="name"
          label="Name"
          variant="outlined"
          fullWidth
          className={classes.field}
        />
      </div>
      <div className={classes.container}>
        <TextField
          name="label"
          label="Label"
          variant="outlined"
          fullWidth
          className={classes.field}
        />
      </div>
      <div className={classes.container}>
        <SelectField
          name="type"
          label="Field type"
          variant="outlined"
          fullWidth
          className={classes.select}
          getOptionText={(option) => option.name}
          options={fieldsTypes}
          submitOnChange
        />
      </div>
      <Collapse in={fieldType?.id === "numberField"}>
        <div className={classes.container}>
          <CheckboxField
            name="lessThanEnabled"
            size="small"
            disableRipple
            label="Max value validation"
          />
        </div>
      </Collapse>
      <Collapse
        in={fieldType?.id === "spaceField" || fieldType?.id === "selectField"}>
        <div className={classes.container}>
          <CheckboxField
            name="multiple"
            size="small"
            disableRipple
            label="Allow multiple values"
          />
        </div>
      </Collapse>
      <Collapse in={lessThan}>
        <div className={classes.group}>
          <div className={classes.container}>
            <TextField
              type="number"
              name="lessThan"
              label="Value less than"
              variant="outlined"
              fullWidth
              className={classes.field}
            />
          </div>
          <div className={classes.container}>
            <TextField
              name="lessThanValidationText"
              label="Validation error"
              variant="outlined"
              fullWidth
              className={classes.field}
            />
          </div>
        </div>
      </Collapse>
      <Collapse in={fieldType?.id === "numberField"}>
        <div className={classes.container}>
          <CheckboxField
            name="greaterThanEnabled"
            size="small"
            disableRipple
            label="Min value validation"
          />
        </div>
      </Collapse>
      <Collapse in={greaterThan}>
        <div className={classes.group}>
          <div className={classes.container}>
            <TextField
              name="greaterThan"
              type="number"
              label="Value greater than"
              variant="outlined"
              fullWidth
              className={classes.field}
            />
          </div>
          <div className={classes.container}>
            <TextField
              name="greaterThanValidationText"
              label="Validation error"
              variant="outlined"
              fullWidth
              className={classes.field}
            />
          </div>
        </div>
      </Collapse>
      <Collapse in={fieldType?.id === "checkbox"}>
        <div className={classes.container}>
          <TextField
            name="checkboxLabel"
            label="Checkbox label"
            variant="outlined"
            fullWidth
            className={classes.field}
          />
        </div>
      </Collapse>
      <Collapse in={fieldType?.id === "selectField"}>
        <div className={classes.container}>
          <OptionsList />
        </div>
      </Collapse>
      <Collapse in={fieldType?.id === "nestedSelectField"}>
        <div className={classes.container}>
          <NestedSelect />
        </div>
      </Collapse>
      <div className={classes.container}>
        <CheckboxField
          name="required"
          size="small"
          disableRipple
          label="Required"
        />
      </div>
      <Collapse in={required}>
        <div className={classes.group}>
          <TextField
            name="requiredValidationText"
            label="Validation error"
            variant="outlined"
            fullWidth
            className={classes.field}
          />
        </div>
      </Collapse>
      <div className={classes.container}>
        {fieldType?.id === "checkbox" && (
          <CheckboxField
            name="defaultValue"
            size="small"
            disableRipple
            label="Checked by default"
          />
        )}
        {!!optionsList?.length && (
          <SelectField
            name="defaultValue"
            label="Default value"
            variant="outlined"
            fullWidth
            className={classes.select}
            getOptionText={(option) => option.label}
            defaultValue={optionsList[0]}
            options={optionsList}
            submitOnChange
          />
        )}
        {!fieldsWithoutDefaultValue.includes(fieldType?.id) && (
          <TextField
            name="defaultValue"
            label="Default value"
            variant="outlined"
            fullWidth
            className={classes.field}
          />
        )}
      </div>
      <div className={classes.container}>
        <TextField
          name="description"
          label="Description"
          variant="outlined"
          fullWidth
          className={classes.field}
        />
      </div>
      <div className={classes.container}>
        <Button type="submit" variant="contained">
          Save
        </Button>
      </div>
    </Grid>
  );
};
