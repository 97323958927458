import React, { useState } from "react";
import { StatusChip } from "@components/StatusChip";
import { Ref, Status } from "@functions-types";
import { MenuItem, Select } from "@mui/material";
import { makeStyles } from "@providers/Mui";
import { Blocker } from "@components/Blocker";
import { Selectable } from "@lib/ticket";

type TicketStatusProps = {
  status: Ref<Status>;
  statuses: Selectable<Status>;
  onStatusUpdate: (ticketId: string, status: string) => Promise<void>;
  ticketId: string;
};


const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    "& > fieldset": {
      top: 0,
      border: 0,
    },
    "& legend": {
      display: "none",
    },
  },
  select: {
    padding: 0,
    paddingRight: "0 !important",
    border: 0,
  },
  container: {
    position: "relative",
    display: "inline-block",
    width: "auto",
  },
  loaderContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(255,255,255,0.4)",
  },
}));

export const TicketStatus: React.FC<TicketStatusProps> = ({ status, statuses, onStatusUpdate, ticketId }) => {
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(status ? {
    id: status?.id,
    label: status?.label,
    item: status
  } : null);
  const classes = useStyles();

  const handleChipClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setEdit(true);
  };

  return (
    <div className={classes.container}>
      {!edit ? (
        <StatusChip status={status} onClick={handleChipClick} />
      ) : (
        <Select
          onChange={async (event) => {
            const status = statuses.find((status) => status.id === event.target.value);
            if (status) {
              setLoading(true);
              setValue(status);
              await onStatusUpdate(ticketId, status.id);
              setLoading(false);
            }
          }}
          fullWidth
          size="small"
          value={value}
          onClose={(event) => {
            event.stopPropagation();
            setEdit(false);
          }}
          onOpen={() => {
            setEdit(true);
          }}
          open={edit}
          renderValue={(option) => <StatusChip status={option?.item || null} />}
          className={classes.root}
          classes={{
            select: classes.select,
          }}
          IconComponent={() => null}
        >
          {statuses.filter((option) => option.id !== status?.id).map((option) => {
            return (
              <MenuItem value={option.item.id} key={option.item.id} onClick={(event) => event.stopPropagation()}>
                <StatusChip status={option.item} />
              </MenuItem>
            );
          })}
        </Select>
      )}
      {loading && (
        <div className={classes.loaderContainer}>
          <Blocker open progressSx={{ height: 2 }} />
        </div>
      )}
    </div>
  );
};
