import { DependencyList, useEffect } from "react";
import { onSnapshot, Query } from "firebase/firestore";
import { useSyncRef } from "./useSyncRef";

export const useOnSnapshot = (
  query: Query,
  cb: (firstFetch: boolean) => void,
  deps: DependencyList,
) => {
  const cbRef = useSyncRef(cb);

  useEffect(() => {
    let counter = 0;
    const unsubscribe = onSnapshot(
      query,
      () => {
        const firstFetch = counter === 0;
        cbRef.current(firstFetch);
        counter++;
      },
      (error) => {
        console.error(error);
      },
    );

    return unsubscribe;
  }, deps);
};
