import { useCallback, useRef, useEffect } from "react";
import debounce from "lodash/debounce";
import type { DebouncedFunc } from "lodash";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type VoidFn = (...args: any[]) => void;

export function useDebounce<F extends VoidFn>(
  fn: F,
  timeout: number,
): DebouncedFunc<F> {
  const fnRef = useRef(fn);

  // keep the fn ref in sync all the time
  fnRef.current = fn;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFn = useCallback(
    debounce((...args: Parameters<F>) => {
      fnRef.current?.(...args);
    }, timeout),
    [],
  );

  useEffect(
    () => () => {
      debouncedFn.cancel();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return debouncedFn;
}
