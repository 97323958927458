import { useEffect, useRef, useState } from "react";
import { CloudFunction } from "./makeCloudFunction";
import LRUCache from "lru-cache";
import { BaseQueryOptions } from "@functions-types";
import { FunctionsErrorCode } from "@firebase/functions";
import { merge, pick, uniqBy } from "lodash";
import { logError } from "@lib/logger";

const makeCacheKey = (params: object): string => {
  return `${JSON.stringify(params)}`;
};

type ErrorCodeMapper<T extends string> = `functions/${T}`;
type CloudFunctionErrorCode = ErrorCodeMapper<FunctionsErrorCode>;

export interface CloudFunctionError extends Error {
  code?: CloudFunctionErrorCode;
}

export type OnResponse<T> = (data: T | undefined) => void;
export type OnError = (error: CloudFunctionError) => void;

type MakeCloudFunctionOptions<
  RequestData extends BaseQueryOptions<any, any>,
  ResponseData extends Resource[],
  Query extends RequestData["query"] = RequestData["query"],
> = {
  useCache: boolean;
  getCursor?: (response: ResponseData) => string | undefined;
  triggerOnMounting: boolean;
  query?: Query;
};

type Resource = { id: string };

type Setter<T> = (current: T) => T;

function unify<T extends Resource[]>(data: T): T {
  return uniqBy(data, (item) => item.id) as T;
}

const DEFAULT_PAGE_SIZE = 50;

export function makeUseCloudFunctionV2<
  RequestData extends BaseQueryOptions<any, any>,
  ResponseData extends Resource[],
  Query extends RequestData["query"] = RequestData["query"],
>(
  fn: CloudFunction<RequestData, ResponseData>,
  options?: Partial<MakeCloudFunctionOptions<RequestData, ResponseData, Query>>,
) {
  const {
    useCache = true,
    query = {} as Query,
    getCursor,
    triggerOnMounting = true,
  } = options ?? {};
  const cache = new LRUCache<string, ResponseData>({
    max: 30,
    dispose: (key, value) => {
      console.log(`dropping ${key}/${value} from cache`);
    },
    length() {
      return 1;
    },
    noDisposeOnSet: true,
    updateAgeOnGet: true,
  });

  return function useCloudFunction(defaultParams?: RequestData) {
    const [data, _setData] = useState<ResponseData>();
    const [isFetching, setIsFetching] = useState<boolean>();
    const [error, setError] = useState<CloudFunctionError | null>(null);
    const [queryProps, setQueryProps] = useState<Query>(query as Query);
    const isEndReachedRef = useRef(false);
    const isFetchingRef = useRef(false);
    const cursorRef = useRef<string>();

    const debug = (message: string, ...args: any[]) => {
      if (!(window as any).DEBUG_useCloudFunction) {
        return;
      }

      console.log(message, ...args, {
        length: data?.length ?? 0,
        data,
        isFetching,
        isEndReached: isEndReachedRef.current,
        cursor: cursorRef.current,
      });
    };

    const reset = (limit: number) => {
      isEndReachedRef.current = false;
      isFetchingRef.current = false;
      cursorRef.current = undefined;
      fetch(lastFetchParams.current!, { limit, resetBeforeFetch: false, setIsFetchingFlag: false });
    };

    const setData = (result: ResponseData | undefined | Setter<typeof data>) => {
      const setValue = (value: ResponseData | undefined) => {
        if (value === undefined) {
          _setData(undefined);
          cursorRef.current = undefined;
          return;
        }

        const unifiedData = unify<ResponseData>(value);

        if (unifiedData.length === value.length) {
          _setData(unifiedData);
          cursorRef.current = getCursor?.(unifiedData);
        } else {
          debug(
            "Detected duplicate item, assuming the query is now broken, we will refetch all data",
          );
          reset(unifiedData.length);
        }
      };

      if (typeof result === "function") {
        const newData = result(data);
        setValue(newData);
        return;
      }

      setValue(result);
    };

    const lastFetchParams = useRef<RequestData>();
    const fetch = async (
      params: RequestData,
      options: { resetBeforeFetch?: boolean; limit?: number; setIsFetchingFlag?: boolean } = {
        resetBeforeFetch: false,
        setIsFetchingFlag: true,
      },
    ) => {
      debug("fetch", params, options);

      try {
        lastFetchParams.current = params;

        if (isFetchingRef.current) {
          debug("fetch()", "Aborting because there is already an ongoing request");
          return;
        }

        isFetchingRef.current = true;

        const refetchWindow =
          Array.isArray(data) && !options.resetBeforeFetch ? data.length : undefined;
        const limit = options.limit ?? refetchWindow ?? queryProps?.limit ?? DEFAULT_PAGE_SIZE;

        const queryParams: RequestData = {
          ...params,
          query: {
            ...merge({}, queryProps, params?.query || {}),
            cursor: undefined,
            limit,
          },
        };
        const cacheKey = makeCacheKey(queryParams);

        // instantly return a data from cache if possible
        // pretty much like "cache-and-network" policy from apollo
        // https://www.apollographql.com/docs/react/data/queries/#cache-and-network
        if (useCache) {
          const resultFromCache = cache.get(cacheKey);
          resultFromCache && setData(resultFromCache);
        }

        if (options.resetBeforeFetch) {
          cursorRef.current = undefined;
          setData(undefined);
        }

        isEndReachedRef.current = false;

        if (options.setIsFetchingFlag) {
          setIsFetching(true);
        }

        debug("fetch().queryParams", queryParams);
        const response = await fn(queryParams);
        const isEndReached = response.length === 0 || response.length < limit;
        debug("fetch.isEndReached", { isEndReached, responseLen: response.length, limit });
        isEndReachedRef.current = isEndReached;

        setData(response);

        if (useCache) {
          cache.set(cacheKey, response);
        }
      } catch (err) {
        logError(err);
        setError(err instanceof Error ? err : Error(`${err}`));
      } finally {
        isFetchingRef.current = false;
        setIsFetching(false);
      }
    };

    const fetchNext = async (params?: RequestData) => {
      debug("fetchNext", params);

      try {
        if (isEndReachedRef.current) {
          debug("fetchNext", "Aborting because end is reached");
          return;
        }

        if (!data) {
          debug("fetchNext", "Aborting because data is missing", data);
          return;
        }

        if (!cursorRef.current) {
          debug("fetchNext", "Aborting because NO cursor found", cursorRef.current);
          return;
        }

        if (isFetchingRef.current) {
          debug("fetchNext", "Aborting because there is already an ongoing request");
          return;
        }

        isFetchingRef.current = true;

        const limit = query?.limit ?? params?.query?.limit ?? DEFAULT_PAGE_SIZE;
        const input = {
          ...merge({}, query, params?.query ?? {}),
          cursor: cursorRef.current,
          limit,
        };

        setQueryProps({
          ...query,
          ...pick(input ?? {}, "limit", "order", "where", "cursor"),
          limit,
          cursor: cursorRef.current,
        } as Query);

        debug("fetchNext.query", { ...params, query: input });

        setIsFetching(true);
        const result = await fn({ ...params, query: input } as RequestData);

        debug("fetchNext.result", result);

        const isEndReached = result.length === 0 || result.length < limit;
        debug("fetchNext.isEndReached", { isEndReached, responseLen: result.length, limit });
        isEndReachedRef.current = isEndReached;

        setData((current) => {
          if (Array.isArray(current) && Array.isArray(result)) {
            return [...current, ...result] as ResponseData;
          }

          return result;
        });

        return result;
      } catch (err) {
        logError(err);
        setError(err instanceof Error ? err : Error(`${err}`));
      } finally {
        isFetchingRef.current = false;
        setIsFetching(false);
      }
    };

    // TODO: fix `any` type
    const updateItem = (identity: (item: any) => boolean, newValue: unknown) => {
      debug("updateItem");

      if (!Array.isArray(data)) {
        return console.warn("trying to run `updateItem` on non array data");
      }

      if (!data) {
        return console.warn("trying to run `updateItem` on empty data");
      }

      const index = data.findIndex(identity);

      if (index === -1) {
        return console.warn("couldn't find item to update");
      }

      setData([...data.slice(0, index), newValue, ...data.slice(index + 1)] as any);
    };

    // TODO: fix `any` type
    const removeItem = (identity: (item: any) => boolean) => {
      debug("removeItem");

      if (!Array.isArray(data)) {
        return console.warn("trying to run `removeItem` on non array data");
      }

      if (!data) {
        return console.warn("trying to run `removeItem` on empty data");
      }

      const index = data.findIndex(identity);

      if (index === -1) {
        return console.warn("couldn't find item to remove");
      }

      setData(data.filter((item) => !identity(item)) as any);
    };

    // on mounting fetch for default params
    useEffect(() => {
      if (triggerOnMounting) {
        fetch(defaultParams!);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loading = Boolean((!error && !data) || isFetching);
    const isEndReached = isEndReachedRef.current;

    return [
      data,
      {
        error,
        loading,
        isEndReached,
        refetch: fetch,
        fetchNext,
        updateItem,
        removeItem,
      },
    ] as const;
  };
}
