import { createContext, FC, useContext, useMemo } from "react";
import { useUser } from "@providers/Auth";
import { Ticket } from "@functions-types";

type TicketActivityContextValue = {
  ticketId: string;
  teamMember: boolean;
};

const TicketActivityContext = createContext<TicketActivityContextValue>(
  null as any as TicketActivityContextValue,
);

type TicketActivityProviderProps = {
  ticket: Ticket;
};

export const TicketActivityProvider: FC<TicketActivityProviderProps> = ({
  ticket,
  children,
}) => {
  const { user } = useUser();

  const values = useMemo(() => {
    if (!user) return { ticketId: ticket.id, teamMember: false };
    return {
      ticketId: ticket.id,
      teamMember:
        ticket.item?.space?.group?.members?.some(
          (member) => member?.id === user.id,
        ) || false,
    };
  }, [user, ticket]);

  return (
    <TicketActivityContext.Provider value={values}>
      {children}
    </TicketActivityContext.Provider>
  );
};

export const useTicketActivity = () => useContext(TicketActivityContext);
