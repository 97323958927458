import { makeCloudFunction } from "../makeCloudFunction";
import { Space } from "@functions-types";
import { makeUseCloudFunction } from "../makeUseCloudFunction";

export type { Space };
export const listSpaces = makeCloudFunction<void, Space[]>(
  "space",
  "listSpaces",
);
export const useListSpaces = makeUseCloudFunction(listSpaces);
