import React from "react";
import { TableCell, TableHead, TableRow } from "@mui/material";
import { makeStyles } from "@providers/Mui";
import { fieldsMap } from "@lib/constants";
import { Column } from "../types";

type HeaderProps = {
  columns: Column[];
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    zIndex: 1,
  },
  header: {
    position: "static",
    fontWeight: 600,
    padding: theme.spacing(1, 1),
    top: 0,
    borderTop: 0,
    borderBottom: 0,
    backgroundColor: "#FAFAFA",
    fontSize: 11,
  },
  headerRow: {
    position: "sticky",
    top: 0,
    textTransform: "uppercase",
    fontSize: 11,
  },
}));

export const Header: React.FC<HeaderProps> = ({ columns }) => {
  const classes = useStyles();
  return (
    <TableHead className={classes.root}>
      <TableRow className={classes.headerRow}>
        {columns.map((column) => (
          <TableCell
            className={classes.header}
            key={column}
            align={column === "severity" ? "center" : "left"}>
            {fieldsMap[column]}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
