import React, { useState, useMemo } from "react";
import { Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Space } from "@functions-types";
import { useSpaces } from "@providers/Spaces";
import { SpaceForm } from "./SpaceForm";
import { useOnEvent } from "@providers/EventsProvider";
import { AdminSettingsEvents } from "../types";
import { makeStyles } from "@providers/Mui";
import { isNonNullish } from "@lib/utils";

const useStyles = makeStyles((theme) => ({
  categoriesList: {
    overflowY: "scroll",
    height: "100%",
  },
  container: {
    height: "100%",
  },
}));

export const SpacesSettings: React.FC = () => {
  const classes = useStyles();
  const [createMode, setCreateMode] = useState(false);
  const { spaces, selectedSpace: globalSelectedSpace, refetch } = useSpaces();
  const [selectedSpace, setSelectedSpace] = useState<Space | null>(
    globalSelectedSpace ?? spaces[0] ?? null,
  );

  useOnEvent<AdminSettingsEvents>("update-space", () => {
    refetch();
  });

  useOnEvent<AdminSettingsEvents>("create-space", async (newSpace) => {
    await refetch();
    setSelectedSpace(newSpace as Space);
    setCreateMode(false);
  });

  const orderedSpaces = useMemo(
    () =>
      (spaces ?? [])
        .filter(isNonNullish)
        .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())),
    [spaces],
  );

  return (
    <Grid container spacing={2} className={classes.container}>
      <Grid item xs={2} className={classes.categoriesList}>
        <List>
          {orderedSpaces?.map((space) => (
            <ListItem disablePadding key={space.id}>
              <ListItemButton
                selected={selectedSpace?.id === space.id && !createMode}
                onClick={() => {
                  setSelectedSpace(space);
                  setCreateMode(false);
                }}>
                <ListItemText>{space.name}</ListItemText>
              </ListItemButton>
            </ListItem>
          ))}

          <ListItem disablePadding>
            <ListItemButton
              selected={createMode}
              sx={(theme) => ({
                color: theme.palette.primary.main,
                fontWeight: "bold",
              })}
              onClick={() => {
                setCreateMode(true);
              }}>
              <ListItemIcon>
                <AddIcon />
              </ListItemIcon>
              <ListItemText>Create</ListItemText>
            </ListItemButton>
          </ListItem>
        </List>
      </Grid>
      <Grid item xs={10}>
        {createMode ? (
          <SpaceForm key="create" />
        ) : (
          <SpaceForm space={selectedSpace} key={selectedSpace?.id} />
        )}
      </Grid>
    </Grid>
  );
};
