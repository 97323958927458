import React, { useEffect, useMemo, useState } from "react";
import { CustomField, CustomFieldsWithValue, Ticket } from "@functions-types";
import { CustomFields } from "./CustomFields";
import { UpdateTicketFormState } from "@components/TicketCard/types";
import { Form, FormRef } from "@components/Form";
import { useAsync } from "@hooks/useAsync";
import { updateTicket as updateTicketCloudFunction } from "@cloud-functions";
import { useSnackbar } from "@providers/Snackbar";
import { useDispatch } from "@providers/EventsProvider";
import { GlobalTicketEvents } from "@lib/types";
import { getCustomFieldValuesFromState } from "@components/TicketCard/utils";
import { Typography } from "@mui/material";
import { customFieldsTicketValidator } from "@lib/ticket";
import { logError } from "@lib/logger";

type CustomFieldsUpdateFormProps = {
  ticket: Ticket;
  fieldsList: CustomFieldsWithValue[];
  addCustomFields: (sourceId: string, fields: CustomField[]) => void;
  ref_?: React.MutableRefObject<FormRef<UpdateTicketFormState> | null>;
};

export const CustomFieldsUpdateForm: React.FC<CustomFieldsUpdateFormProps> = ({
  ticket,
  fieldsList,
  addCustomFields,
  ref_,
}) => {
  const [updateTicket, { isLoading }] = useAsync(updateTicketCloudFunction, {
    reThrow: true,
  });
  const { showError } = useSnackbar();

  const [currentTicketState, setCurrentTicketState] = useState<Ticket>(
    () => ticket,
  );
  const dispatch = useDispatch<GlobalTicketEvents>();

  const defaultValues = useMemo(() => {
    return {
      customFieldsValues: (currentTicketState.customFields ?? []).reduce(
        (acc, cur) => ({
          ...acc,
          [cur.id]: cur.value ?? cur.defaultValue,
        }),
        {} as UpdateTicketFormState["customFieldsValues"],
      ),
    };
  }, [currentTicketState]);

  useEffect(() => {
    setCurrentTicketState(ticket);
    if (ticket?.customFields) {
      const fieldsMap: Record<string, CustomFieldsWithValue[]> = {};
      ticket.customFields.forEach((field) => {
        field.source.forEach((sourceId) => {
          ///addCustomFields(sourceId, [field]);
          fieldsMap[sourceId] = fieldsMap[sourceId] || [];
          fieldsMap[sourceId].push(field);
        });
      });
      Object.entries(fieldsMap).forEach(([sourceId, fields]) =>
        addCustomFields(sourceId, fields),
      );
    }
  }, [ticket]);

  const handleSubmit = async (values: UpdateTicketFormState) => {
    try {
      const updates = getCustomFieldValuesFromState(values, fieldsList);

      if (Object.keys(updates).length === 0) {
        // everything is up to date!
        return;
      }

      const savedTicket = await updateTicket({
        input: {
          id: ticket.id,
          updates,
        },
      });

      setCurrentTicketState(savedTicket);
      dispatch("update-tickets");
    } catch (err) {
      logError(err);

      showError(
        <Typography variant="body2">
          Something went wrong during saving your updates
        </Typography>,
      );
    }
  };

  const formValidator = useMemo(
    () => customFieldsTicketValidator(fieldsList),
    [fieldsList],
  );

  return (
    <Form<UpdateTicketFormState>
      ref_={ref_}
      formMode="edit"
      onSubmit={handleSubmit}
      defaultValues={defaultValues}
      resolver={formValidator}>
      <CustomFields
        fieldsList={fieldsList}
        formMode="edit"
        isLoading={isLoading}
      />
    </Form>
  );
};
