import {
  formatDistance as dateFnsFormatDistance,
  formatRelative as dateFnsFormatRelative,
  format as dateFnsFormat,
} from "date-fns";
import { FirebaseDateTime } from "@functions-types";

export function formatRelative(date: FirebaseDateTime | number): string {
  const dateObj = dateFromFirebaseDateTimeOrNumber(date);
  return dateFnsFormatRelative(dateObj, new Date());
}

export function formatDistance(date: FirebaseDateTime | number): string {
  const dateObj = dateFromFirebaseDateTimeOrNumber(date);

  return dateFnsFormatDistance(dateObj, new Date(), {
    includeSeconds: true,
    addSuffix: true,
  });
}

export function dateFromFirebaseDateTimeOrNumber(date: FirebaseDateTime | number): Date {
  return typeof date === "number" ? new Date(date) : new Date(date._seconds * 1000);
}

export function format(date: FirebaseDateTime | number, format: string): string {
  return dateFnsFormat(dateFromFirebaseDateTimeOrNumber(date), format);
}

export const formatTimeFrame = (from: FirebaseDateTime | number, to: FirebaseDateTime | number) => {
  const fromDate = dateFromFirebaseDateTimeOrNumber(from);
  const toDate = dateFromFirebaseDateTimeOrNumber(to);

  return dateFnsFormatDistance(toDate, fromDate, { addSuffix: true });
};
