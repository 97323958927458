import React from "react";
import { H6 } from "@components/Typography";
import { Grid } from "@mui/material";
import { makeStyles } from "@providers/Mui";
import { Automation } from "@functions-types";
import { AutomationCard } from "@pages/Admin/Automation/AutomationsList/AutomationCard";

const useStyles = makeStyles((theme) => ({
  header: {
    padding: theme.spacing(2),
  },
}));

type AutomationsListProps = {
  automations?: Automation[];
  activeAutomation?: string;
  onSelect: (automation: Automation) => void;
};

export const AutomationsList: React.FC<AutomationsListProps> = ({
  automations,
  activeAutomation,
  onSelect
}) => {
  const classes = useStyles();
  return (
    <Grid container direction="column">
      <H6 className={classes.header}>Automations</H6>
      {(automations || []).map((automation) => (
        <AutomationCard
          key={automation.id}
          automation={automation}
          onSelect={onSelect}
          activeAutomation={activeAutomation}
        />
      ))}
    </Grid>
  );
};
