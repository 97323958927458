import { IconButton, LinearProgress, Typography } from "@mui/material";
import { makeStyles } from "@providers/Mui";
import ArrowIcon from "@mui/icons-material/KeyboardArrowDown";
import { FC, useState } from "react";
import clsx from "clsx";

type FieldGroupProps = {
  title?: string;
  collapsable?: boolean;
  summary?: string;
  isLoading?: boolean;
};

export const FieldGroup: FC<FieldGroupProps> = (props) => {
  const { title, children, collapsable, summary, isLoading } = props;
  const [open, setOpen] = useState(true);
  const classes = useStyles({ ...props, open });

  const toggle = () => {
    setOpen(!open);
  };

  return (
    <div className={classes.root}>
      {title && (
        <div className={classes.title} onClick={collapsable ? toggle : undefined}>
          <Typography fontWeight="bold" fontSize="inherit">
            {title}

            {summary && !open && <span className={classes.summary}>{summary}</span>}
          </Typography>

          {collapsable && (
            <IconButton size="small">
              <ArrowIcon
                fontSize="inherit"
                className={clsx(classes.arrow, open && classes.arrowOpen)}
              />
            </IconButton>
          )}
        </div>
      )}

      {isLoading && <LinearProgress className={classes.loading} />}
      <div className={clsx(classes.content, open && classes.contentOpen)}>{children}</div>
    </div>
  );
};

const useStyles = makeStyles<FieldGroupProps & { open: boolean }>((theme) => ({
  root: {
    border: "1px solid #d6d6d6",
    borderRadius: 5,
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    marginTop: theme.spacing(2),
    "&:first-child": {
      marginTop: 0,
    },
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: ({ open }) => (open ? "1px solid #d6d6d6" : "none"),
    backgroundColor: "#f6f6f6",
    padding: theme.spacing(1),
    fontSize: 12,
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2),
      fontSize: 14,
    },
    "&:hover": {
      cursor: (props) => (props.collapsable ? "pointer" : "default"),
      backgroundColor: (props) => (props.collapsable ? theme.palette.grey[350] : "none"),
    },
  },
  summary: {
    color: "#9c9c9c",
    marginLeft: theme.spacing(2),
    fontSize: 12,
  },
  content: {
    maxHeight: 0,
    transition: "all ease 500ms",
    padding: theme.spacing(0, 1),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(0, 2),
    },
  },
  contentOpen: {
    maxHeight: "initial",
    padding: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2),
    },
  },
  arrow: {
    transform: "rotate(0deg)",
    transition: "transform ease 500ms",
  },
  arrowOpen: {
    transform: "rotate(180deg)",
  },

  loading: {
    height: 3,
    width: "100%",
  },
}));
