import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@providers/Mui";
import { TicketApprovalRule } from "./types";
import { UserCard } from "@components/UserCard";
import { format } from "@lib/date";
import { useUser } from "@providers/Auth";
import { Button } from "@components/Button";
import { useTicketApproval } from "./TicketApprovalProvider";
import { Collapse, TextField } from "@mui/material";
import { useQuery } from "@hooks/useQuery";

type ApproverProps = {
  approver: NonNullable<TicketApprovalRule["approvers"][0]>;
  feedback: TicketApprovalRule["feedbacks"][0] | null;
  active: boolean;
  ruleId: string;
};

export const Approver: React.FC<ApproverProps> = ({
  approver,
  feedback: approverFeedback,
  active,
  ruleId,
}) => {
  const { approve, decline, isLoading } = useTicketApproval();
  const { user } = useUser();

  const classes = useStyles();
  const isMe = user.id === approver.id;

  const [declining, setDeclining] = useState(false);
  const [declinationReason, setDeclinationReason] = useState("");

  const declinationReasonRef = useRef<HTMLTextAreaElement>();
  // auto approve/decline based on query params
  const query = useQuery();

  useEffect(() => {
    const action = query.get("action");
    const approvalRuleId = query.get("rid");

    if (!approvalRuleId || !action || !isMe || approverFeedback || ruleId !== approvalRuleId) {
      return;
    }

    if (action === "approve") {
      approve(approvalRuleId);
      return;
    }

    if (action === "decline") {
      setDeclining(true);
    }
  }, []);

  const focusOnDeclineReason = () => {
    declinationReasonRef.current?.focus();
  };

  return (
    <div className={classes.root}>
      {approverFeedback && (
        <div className={classes.feedback}>
          <UserCard user={approverFeedback.createdBy} className={classes.userCard} />
          {approverFeedback.result === "approved" && (
            <strong className={classes.approved}>approved</strong>
          )}
          {approverFeedback.result === "rejected" && (
            <strong className={classes.declined}>declined</strong>
          )}{" "}
          the ticket at <i>{format(approverFeedback.createdAt, "dd MMMM hh:mm aaa")}</i>
          {approverFeedback.result === "rejected" && approverFeedback.comment && (
            <>
              <br />
              <blockquote className={classes.declinationComment}>
                <i>
                  <q>{approverFeedback.comment}</q>
                </i>
              </blockquote>
            </>
          )}
        </div>
      )}

      {!approverFeedback && (
        <>
          <UserCard user={approver} className={classes.userCard} />
          {isMe && (
            <>
              <Collapse in={declining} unmountOnExit onAnimationEnd={focusOnDeclineReason}>
                <TextField
                  size="small"
                  placeholder="Please give a declination reason"
                  multiline
                  minRows={2}
                  maxRows={3}
                  fullWidth
                  className={classes.declinationReason}
                  value={declinationReason}
                  onChange={(e) => setDeclinationReason(e.target.value)}
                  inputRef={declinationReasonRef}
                />
              </Collapse>
              <div className={classes.feedbackButtons}>
                <Button
                  size="small"
                  color="error"
                  disabled={!active || isLoading}
                  variant="contained"
                  className={classes.feedbackButton}
                  onClick={() => {
                    if (declining) {
                      decline(ruleId, declinationReason);
                    } else {
                      setDeclining(true);
                    }
                  }}>
                  Decline
                </Button>
                {declining && (
                  <Button
                    size="small"
                    variant="text"
                    className={classes.feedbackButton}
                    onClick={() => {
                      setDeclining(false);
                      setDeclinationReason("");
                    }}>
                    Cancel
                  </Button>
                )}

                {!declining && (
                  <Button
                    size="small"
                    color="success"
                    disabled={!active || isLoading}
                    variant="contained"
                    className={classes.feedbackButton}
                    onClick={() => approve(ruleId)}>
                    Approve
                  </Button>
                )}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: 14,
    borderRadius: 5,
    padding: theme.spacing(1),
    "&:not(:last-child)": {
      marginBottom: theme.spacing(1),
    },
  },
  feedback: {},
  approved: {
    color: theme.palette.success.main,
  },
  declined: {
    color: theme.palette.error.main,
  },
  feedbackButtons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  feedbackButton: {
    "&:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },
  userCard: {
    backgroundColor: theme.palette.grey[400],
    padding: theme.spacing(0.5),
  },
  declinationReason: {
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
  },
  declinationComment: {
    borderLeft: `3px solid ${theme.palette.grey[500]}`,
    marginLeft: theme.spacing(1),
    marginRight: 0,
    padding: theme.spacing(0.5, 1.5),
  },
}));
