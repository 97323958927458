import React from "react";
import { Typography } from "@mui/material";
import { HasClassName } from "@lib/types";
import { makeStyles } from "@providers/Mui";
import clsx from "clsx";

type CaptionProps = HasClassName & {};

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.grey[700],
    textTransform: "uppercase",
    fontSize: 10,
    fontWeight: "bold",
  },
}));

export const Caption: React.FC<CaptionProps> = ({ children, className }) => {
  const classes = useStyles();
  return (
    <Typography variant="caption" className={clsx(classes.root, className)}>
      {children}
    </Typography>
  );
};
