import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { CustomFieldsWithValue, Ticket } from "@functions-types";
import { pick } from "lodash";
import { ObjectSchema } from "yup";
import { richTextValidation } from "@lib/validations/rich-text";

const ticketValidationObject = {
  title: yup.string().required("Title is a required field"),
  description: richTextValidation("Description is required"),
  severity: yup.object().required("Please select severity"),
  team: yup.object().nullable(),
  assignee: yup.object().nullable(),
  item: yup.object().nullable().required("Please select item"),
  country: yup.object().required("Please select country"),
};

export const ticketValidation = yup.object().shape(ticketValidationObject);

export const ticketValidationResolver = yupResolver(ticketValidation);

const getCustomValidators = (customFields: CustomFieldsWithValue[]) => {
  const additionalValidators: Record<string, yup.SchemaOf<unknown>> = {};

  customFields.forEach((field) => {
    switch (field.type) {
      case "checkbox": {
        if (field.required) {
          additionalValidators[field.id] = yup
            .boolean()
            .nullable()
            .oneOf([true], field.requiredValidationText ?? "Field is required");
        }
        break;
      }
      case "textField":
      case "selectField":
      case "datePicker":
      case "multilineText": {
        if (field.required) {
          additionalValidators[field.id] = yup
            .string()
            .nullable()
            .required(field.requiredValidationText ?? "Field is required");
        }
        break;
      }

      case "numberField": {
        let numberValidator = yup.number().nullable();
        if (field.required) {
          numberValidator = numberValidator.required(
            field.requiredValidationText ?? "Field is required",
          );
        }

        if (!!field.lessThan) {
          numberValidator = numberValidator.max(
            field.lessThan,
            field.lessThanValidationText ??
              `Value should be less than ${field.lessThan}`,
          );
        }

        if (!!field.greaterThan) {
          numberValidator = numberValidator.min(
            field.greaterThan,
            field.greaterThanValidationText ??
              `Value should be greater than ${field.greaterThan}`,
          );
        }

        additionalValidators[field.id] = numberValidator;
        break;
      }

      case "storeSelectField": {
        additionalValidators[field.id] = yup
          .array()
          .nullable()
          .test({
            test: (value) => (value?.length ?? 0) === 2,
            message: field.requiredValidationText ?? "Please choose a store",
          });
        break;
      }

      case "nestedSelectField": {
        additionalValidators[field.id] = yup
          .array()
          .nullable()
          .test({
            test: (value) => !!value?.length,
            message: field.requiredValidationText ?? "Field is required",
          });
      }
    }
  });

  return additionalValidators;
};

type ExtendedValidator = typeof ticketValidationObject & {
  customFieldsValues?: ObjectSchema<{}>;
};

export const ticketFieldValidator = (
  customFields: CustomFieldsWithValue[],
  customFieldsEnabled: boolean,
) => {
  const validators: ExtendedValidator = {
    ...ticketValidationObject,
  };

  if (customFieldsEnabled) {
    validators.customFieldsValues = yup.object().shape({
      ...getCustomValidators(customFields),
    });
  }

  return yupResolver(yup.object().shape(validators));
};

export const partialTicketValidationResolverFor = (
  fields: (keyof Ticket)[],
) => {
  const validationObj = pick(ticketValidationObject, fields);
  const validationSchema = yup.object().shape(validationObj);
  return yupResolver(validationSchema);
};

export const customFieldsTicketValidator = (
  customFields: CustomFieldsWithValue[],
) =>
  yupResolver(
    yup.object().shape({
      customFieldsValues: yup.object().shape(getCustomValidators(customFields)),
    }),
  );
