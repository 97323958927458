import { useEffect, useRef, useState } from "react";
import { CloudFunction } from "./makeCloudFunction";
import LRUCache from "lru-cache";
import { Pagination } from "@functions-types";
import { FunctionsErrorCode } from "@firebase/functions";
import { merge, pick } from "lodash";
import { logError } from "@lib/logger";

const makeCacheKey = (params: object): string => {
  return `${JSON.stringify(params)}`;
};

type ErrorCodeMapper<T extends string> = `functions/${T}`;
type CloudFunctionErrorCode = ErrorCodeMapper<FunctionsErrorCode>;

export interface CloudFunctionError extends Error {
  code?: CloudFunctionErrorCode;
}

export type OnResponse<T> = (data: T | undefined) => void;
export type OnError = (error: CloudFunctionError) => void;

type MakeCloudFunctionOptions<ResponseData> = {
  useCache: boolean;
  pagination?: Pagination;
  getCursor?: (response: ResponseData) => unknown;
  triggerOnMounting: boolean;
};

/**
 * @deprecated use makeUseCloudFunctionV2 instead
 */
export function makeUseCloudFunction<
  RequestData extends { input?: object & Partial<Pagination> } | void,
  ResponseData extends unknown,
>(
  fn: CloudFunction<RequestData, ResponseData>,
  options?: Partial<MakeCloudFunctionOptions<ResponseData>>,
) {
  const {
    useCache = true,
    pagination,
    getCursor,
    triggerOnMounting = true,
  } = options ?? {};
  const cache = new LRUCache<string, ResponseData>({
    max: 30,
    dispose: (key, value) => {
      console.log(`dropping ${key}/${value} from cache`);
    },
    length() {
      return 1;
    },
    noDisposeOnSet: true,
    updateAgeOnGet: true,
  });

  return function useCloudFunction(defaultParams?: RequestData) {
    const [data, setData] = useState<ResponseData>();
    const [isFetching, setIsFetching] = useState<boolean>();
    const [error, setError] = useState<CloudFunctionError | null>(null);
    const [paginationProps, setPaginationProps] = useState<Pagination>({
      ...(pagination || {}),
    });
    const [isEndReached, setIsEndReached] = useState(false);
    const activeRequestIDRef = useRef(1);

    const fetch = async (
      params: RequestData,
      options: { resetBeforeFetch?: boolean } = {},
    ) => {
      try {
        const currentRequestID = activeRequestIDRef.current + 1;
        activeRequestIDRef.current = currentRequestID;
        const refetchWindow = Array.isArray(data) ? data.length : 0;
        const { resetBeforeFetch = false } = options;
        const queryParams = {
          ...params,
          input: {
            ...merge({}, paginationProps, params?.input || {}),
            startAfter: undefined,
            limit:
              Math.max(refetchWindow, paginationProps.limit ?? 0) || undefined,
          },
        };
        const cacheKey = makeCacheKey(queryParams);

        // instantly return a data from cache if possible
        // pretty much like "cache-and-network" policy from apollo
        // https://www.apollographql.com/docs/react/data/queries/#cache-and-network
        if (useCache) {
          const resultFromCache = cache.get(cacheKey);
          resultFromCache && setData(resultFromCache);
        }

        if (resetBeforeFetch) {
          setData(undefined);
        }

        setIsFetching(true);
        const response = await fn(queryParams);
        if (currentRequestID !== activeRequestIDRef.current) {
          return;
        }
        setIsEndReached(false);
        setData(response);

        if (useCache) {
          cache.set(cacheKey, response);
        }
      } catch (err) {
        logError(err);
        setError(err instanceof Error ? err : Error(`${err}`));
      } finally {
        setIsFetching(false);
      }
    };

    const fetchNext = async (params?: RequestData) => {
      if (isEndReached) return;

      try {
        if (!pagination || !data || !getCursor) {
          return;
        }

        const currentRequestID = activeRequestIDRef.current + 1;
        activeRequestIDRef.current = currentRequestID;
        const cursor = getCursor(data);
        const input = {
          ...merge({}, pagination, params?.input ?? {}),
          startAfter: cursor,
        };
        setPaginationProps({
          ...pagination,
          ...pick(
            params?.input ?? {},
            "limit",
            "orderBy",
            "orderDirection",
            "startAfter",
          ),
          startAfter: cursor,
        });

        setIsFetching(true);
        const result = await fn({ ...params, input } as RequestData);
        if (currentRequestID !== activeRequestIDRef.current) {
          return;
        }
        if (Array.isArray(result) && result.length < (pagination?.limit || 1)) {
          setIsEndReached(true);
        }
        setData((current) => {
          if (Array.isArray(current) && Array.isArray(result)) {
            return [...current, ...result] as ResponseData;
          }

          return result;
        });

        return result;
      } catch (err) {
        logError(err);
        setError(err instanceof Error ? err : Error(`${err}`));
      } finally {
        setIsFetching(false);
      }
    };

    // TODO: fix `any` type
    const updateItem = (
      identity: (item: any) => boolean,
      newValue: unknown,
    ) => {
      if (!Array.isArray(data)) {
        return console.warn("trying to run `updateItem` on non array data");
      }

      if (!data) {
        return console.warn("trying to run `updateItem` on empty data");
      }

      const index = data.findIndex(identity);

      if (index === -1) {
        return console.warn("couldn't find item to update");
      }

      setData([
        ...data.slice(0, index),
        newValue,
        ...data.slice(index + 1),
      ] as any);
    };

    // TODO: fix `any` type
    const removeItem = (identity: (item: any) => boolean) => {
      if (!Array.isArray(data)) {
        return console.warn("trying to run `removeItem` on non array data");
      }

      if (!data) {
        return console.warn("trying to run `removeItem` on empty data");
      }

      const index = data.findIndex(identity);

      if (index === -1) {
        return console.warn("couldn't find item to remove");
      }

      setData(data.filter((item) => !identity(item)) as any);
    };

    const clear = () => {
      setData(undefined);
    };

    // on mounting fetch for default params
    useEffect(() => {
      if (triggerOnMounting) {
        fetch(defaultParams!);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loading = Boolean((!error && !data) || isFetching);

    return [
      data,
      {
        refetch: fetch,
        error,
        loading,
        fetchNext,
        updateItem,
        removeItem,
        clear,
      },
    ] as const;
  };
}
