import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";

import { makeStyles } from "../../providers/Mui";
import logoSrc from "./logo.png";
import { LoginOptions } from "./LoginOptions";
import { RidersLoginMain } from "./RidersLogin/RidersLoginMain";

export const LoginPage: FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [riderMode, setRiderMode] = useState(false);

  return (
    <div className={classes.root}>
      <button
        type="button"
        onClick={() => navigate("/")}
        className={classes.logoButton}>
        <img className={classes.logo} src={logoSrc} alt="Zapp" />
      </button>

      <div className={classes.content}>
        {riderMode ? (
          <RidersLoginMain />
        ) : (
          <LoginOptions onRiderLoginClick={() => setRiderMode(true)} />
        )}
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100vh",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    backgroundColor: "white",
    padding: theme.spacing(18, 4, 0, 4),
    boxSizing: "border-box",
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      padding: 0,
      alignItems: "center",
    },
    position: "relative",
  },
  logoButton: {
    width: 150,
    position: "absolute",
    top: theme.spacing(6),
    left: "50%",
    transform: "translate(-50%)",
    background: "transparent",
    border: 0,
    cursor: "pointer",
    transition: "0.4s",
    "&:hover": {
      opacity: 0.75,
    },
  },
  logo: {
    width: "100%",
    height: "100%",
  },
  content: {
    textAlign: "center",
  },
}));
