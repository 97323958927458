import React from "react";
import { Grid, Typography, IconButton } from "@mui/material";
import { fieldsMap } from "@lib/constants";
import { FieldValue, Operation } from "@containers/TicketFilter";
import { makeStyles } from "@providers/Mui";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

type ConditionCardProps = {
  field: FieldValue;
  operation: Operation;
  value: unknown;
  label: string;
  onRemove: () => void;
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    "&:not(:last-child)": {
      borderBottom: `1px solid ${theme.palette.secondary.light}`,
    },
  },
  remove: {
    flexShrink: 0,
    marginLeft: theme.spacing(1),
    padding: theme.spacing(0, 1),
  },
}));

export const ConditionCard: React.FC<ConditionCardProps> = ({ field, operation, label, onRemove }) => {
  const classes = useStyles();
  return (
    <Grid container direction="row" className={classes.root} justifyContent="space-between">
      <Typography>
        {fieldsMap[field.id]}
        <Typography
          sx={{ color: (theme) => theme.palette.primary.main }}
          component="span"
        >
          {` ${operation} `}
        </Typography>
        {label}
      </Typography>
      <IconButton
        className={classes.remove}
        disableRipple
        color="warning"
        onClick={onRemove}
      >
        <RemoveCircleOutlineIcon />
      </IconButton>
    </Grid>
  )
};
