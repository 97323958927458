import React, { useMemo } from "react";
import { CustomPageValue } from "@functions-types";
import { Grid, Typography } from "@mui/material";
import { FieldComponent } from "@components/TicketCard/CustomFields/FieldComponent";
import { FieldGroup } from "@components/Form/FieldGroup";
import { Form } from "@components/Form";
import { noop } from "lodash";
import { UpdateTicketFormState } from "@components/TicketCard/types";

type CustomPageCardProps = {
  customPage: CustomPageValue;
};

export const CustomPageCard: React.FC<CustomPageCardProps> = ({ customPage }) => {
  const defaultValues = useMemo(
    () => ({
      customFieldsValues: (customPage.customFields ?? []).reduce(
        (acc, cur) => ({
          ...acc,
          [cur.id]: cur.value ?? cur.defaultValue,
        }),
        {} as UpdateTicketFormState["customFieldsValues"],
      ),
    }),
    [customPage],
  );

  return (
    <FieldGroup title={customPage.name}>
      <Form formMode="edit" onSubmit={noop} disabled defaultValues={defaultValues}>
        <Grid container spacing={2} position="relative">
          {customPage.content && (
            <Grid item xs={12}>
              <Typography>{customPage.content}</Typography>
            </Grid>
          )}
          {customPage.customFields.map((field) => (
            <Grid item xs={12} key={field.id}>
              <FieldComponent field={field} />
            </Grid>
          ))}
        </Grid>
      </Form>
    </FieldGroup>
  );
};
