import { UpdateTicketForm } from "@components/TicketCard/utils";
import React, { FC } from "react";
import { LookupValue } from "./LookupValue";
import { NoneValue } from "./None";
import { RichTextValue } from "./RichTextValue";
import { StatusValue } from "./StatusValue";
import { TextValue } from "./TextValue";
import { UsersListValue } from "./UsersListValue";
import { UserValue } from "./UserValue";

type FieldValueProps = {
  field: keyof UpdateTicketForm;
  value: any;
};

export const fieldComponentMap: Record<
  keyof UpdateTicketForm,
  React.ComponentType<{ value: any }>
> = {
  title: TextValue,
  description: RichTextValue,
  assignee: UserValue,
  country: LookupValue,
  item: LookupValue,
  severity: LookupValue,
  status: StatusValue,
  watchers: UsersListValue,
  category: LookupValue,
  type: LookupValue,
};

export const FieldValue: FC<FieldValueProps> = ({ field, value }) => {
  const Component = fieldComponentMap[field];

  if (!Component) return null;

  if (
    value === null ||
    value === undefined ||
    (Array.isArray(value) && value.length === 0)
  ) {
    return <NoneValue />;
  }

  return <Component value={value} />;
};
