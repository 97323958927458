import React, { useCallback, useMemo, useRef } from "react";
import { Ticket, useGetTicket } from "@cloud-functions";
import { useTicketLookups } from "@lib/ticket";
import { Grid } from "@mui/material";
import { Blocker } from "@components/Blocker";
import { Sidebar } from "./Sidebar";
import { TicketPartialUpdatesForm } from "./TicketPartialUpdatesForm";
import { ActivityFilterType, TicketActivity } from "@containers/TicketActivity";
import { TicketContent } from "@components/TicketCard/TicketContent";
import { TicketLink } from "@components/TicketLink";
import { useCustomFields } from "@components/TicketCard/CustomFields";
import { CustomFieldsUpdateForm } from "@components/TicketCard/CustomFieldsUpdateForm";
import {
  useCustomFieldsFeature,
  useCustomPagesFeature,
  useTicketSLAFeature,
} from "@hooks/featureFlags";
import { FormRef } from "@components/Form";
import { UpdateTicketFormState } from "@components/TicketCard/types";
import { NoneEmptyArray } from "@lib/types";
import { useUser } from "@providers/Auth";
import { canActOnTicket } from "@lib/can";
import { SLADetails } from "./SLADetails";
import { CustomPageValues } from "@components/TicketCard/CustomPageValues";

type TicketCardProps = {
  ticketId: string;
  withTicketLink?: boolean;
  autoFocusOnComment?: boolean;
  defaultActivityTab?: ActivityFilterType;
};

const sidebarFields: NoneEmptyArray<keyof Ticket> = [
  "severity",
  "assignee",
  "country",
  "item",
  "status",
  "watchers",
  "category",
  "type",
  "updatedAt",
  "isPrivate",
];

export const TicketCard: React.FC<TicketCardProps> = ({
  ticketId,
  withTicketLink = false,
  autoFocusOnComment = false,
  defaultActivityTab,
}) => {
  const { user } = useUser();
  const ticketSLAFeatureEnabled = useTicketSLAFeature();
  const [ticket, ticketState] = useGetTicket({
    input: { id: ticketId },
  });
  const canAct = useMemo(() => canActOnTicket(user, ticket), [user, ticket]);
  const {
    loading: loadingLookups,
    categories,
    severity,
    countries,
    statusesMap,
  } = useTicketLookups();
  const { addCustomFields, deleteCustomFields, fieldsList } = useCustomFields();
  const customFieldsFeatureEnabled = useCustomFieldsFeature();
  const customPagesFeatureEnabled = useCustomPagesFeature();
  const customFieldsRef = useRef<FormRef<UpdateTicketFormState> | null>(null);

  const handleTicketUpdate = useCallback(() => {
    ticketState.refetch({
      input: { id: ticketId },
    });
  }, [ticketState]);

  const setCustomFieldValue = useCallback(
    (fieldName: string, value: string | boolean | null | undefined | Date | [string, string]) => {
      if (!value) return;
      const [, customFieldId] = fieldName.split(".");
      customFieldsRef?.current?.setValue?.(
        fieldName as keyof UpdateTicketFormState,
        (ticket?.customFields.find(({ id }) => id === customFieldId)?.value ?? value) as string,
      );
    },
    [customFieldsRef, ticket],
  );

  if (
    !ticket &&
    !ticketState.loading &&
    ticketState?.error?.code === "functions/permission-denied"
  ) {
    return <>Permission denied</>;
  }

  if (!ticket || loadingLookups || (ticketState.loading && !ticket)) {
    return <>Loading</>;
  }

  return (
    <>
      <Grid container spacing={2} sx={(theme) => ({ padding: theme.spacing(2) })}>
        {withTicketLink && (
          <Grid xs={12} item>
            <TicketLink
              ticketId={ticket.id}
              isPrivate={ticket.isPrivate}
              spaceKey={ticket.item?.space?.key}
            />
          </Grid>
        )}
        <Grid sm={12} md={7} lg={8} xl={9} item>
          <TicketPartialUpdatesForm
            id={ticketId}
            ticket={ticket}
            fields={["title", "description", "attachments"]}
            onTicketUpdate={handleTicketUpdate}>
            {(ticketState) => <TicketContent ticket={ticketState} />}
          </TicketPartialUpdatesForm>
          {customFieldsFeatureEnabled && (
            <Grid item sx={{ pt: 2 }}>
              <CustomFieldsUpdateForm
                ticket={ticket}
                fieldsList={fieldsList}
                addCustomFields={addCustomFields}
                ref_={customFieldsRef}
              />
            </Grid>
          )}
          {customPagesFeatureEnabled && ticket.customPages && (
            <CustomPageValues customPages={ticket.customPages} />
          )}
          <Grid item>
            <TicketActivity
              ticket={ticket}
              autoFocusOnComment={autoFocusOnComment}
              defaultActivityTab={defaultActivityTab}
            />
          </Grid>
        </Grid>

        <Grid sm={12} md={5} lg={4} xl={3} item>
          <TicketPartialUpdatesForm
            disabled={!canAct}
            id={ticketId}
            ticket={ticket}
            fields={sidebarFields}
            onTicketUpdate={handleTicketUpdate}>
            {(ticketState) => (
              <Sidebar
                severity={severity}
                countries={countries}
                statusesMap={statusesMap}
                ticket={ticketState}
                addCustomFields={addCustomFields}
                deleteCustomFields={deleteCustomFields}
                setCustomValue={setCustomFieldValue}
                onTicketUpdate={handleTicketUpdate}
                categories={categories}
              />
            )}
          </TicketPartialUpdatesForm>
          {ticketSLAFeatureEnabled && <SLADetails ticket={ticket} />}
        </Grid>
      </Grid>
      <Blocker open={(ticketState.loading && !ticket) || loadingLookups} />
    </>
  );
};
