import React from "react";
import { makeStyles } from "@providers/Mui";
import { Typography } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import { Link } from "react-router-dom";
import { HasClassName } from "@lib/types";

type TicketLinkProps = HasClassName & {
  spaceKey?: string;
  ticketId: string;
  isPrivate: boolean;
};

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: "none",
    color: theme.palette.primary.dark,
  },
  privateLabel: {
    fontSize: 14,
    color: theme.palette.grey[700],
    paddingTop: 2,
  },
  privateIcon: {
    fontSize: 14,
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(1),
    marginBottom: -2,
  },
}));

export const TicketLink: React.FC<TicketLinkProps> = ({
  className,
  spaceKey,
  ticketId,
  isPrivate,
}) => {
  const classes = useStyles();

  return (
    <div className={className}>
      {spaceKey && (
        <Link to={`/space/${spaceKey}`} className={classes.link}>
          {`${spaceKey} / `}
        </Link>
      )}
      <Link to={`/ticket/${ticketId}`} className={classes.link}>
        {ticketId.length > 10 ? "Open ticket" : ticketId}
      </Link>

      {isPrivate && (
        <Typography className={classes.privateLabel} component="span">
          <LockIcon className={classes.privateIcon} />
          Private
        </Typography>
      )}
    </div>
  );
};
