import { default as Delta } from "quill-delta";

import { FieldView } from "@components/Form/FieldView";
import { makeStyles } from "@providers/Mui";
import { FieldLabel } from "@components/Form/FieldLabel";
import "react-quill/dist/quill.bubble.css";
import { QuillDelta, QuillViewer } from "@components/QuillEditor";

export type RichTextViewerProps = {
  field: string;
  delta?: QuillDelta;
  label?: React.ReactNode;
  hasUnsavedChanges?: boolean;
  emptyValue?: string;
  className?: string;
  required?: boolean;
};

export const RichTextViewer = ({
  field,
  delta,
  label,
  hasUnsavedChanges,
  emptyValue = "N/A",
  className,
  required,
}: RichTextViewerProps) => {
  const classes = useStyles();

  return (
    <>
      <FieldLabel
        label={label}
        hasUnsavedChanges={hasUnsavedChanges}
        required={required}
      />
      <FieldView field={field} className={className}>
        <QuillViewer className={classes.root} value={delta ?? emptyValue} />
      </FieldView>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    "& p": {
      minHeight: 24,
      margin: "3px 0",
    },
  },
}));
