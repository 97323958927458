import { makeCloudFunction } from "../makeCloudFunction";
import { CustomField, OptionsTree } from "@functions-types";
import { makeUseCloudFunction } from "../makeUseCloudFunction";

export type { CustomField, OptionsTree };
export const listCustomFields = makeCloudFunction<void, CustomField[]>(
  "customField",
  "listCustomFields",
);
export const useListCustomFields = makeUseCloudFunction(listCustomFields);
