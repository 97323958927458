import React, { useCallback, useMemo, useRef } from "react";
import { Popup } from "@components/Popup";
import { makeStyles } from "@providers/Mui";
import { ticketFieldValidator, useTicketLookups } from "@lib/ticket";
import { Button, DialogActions, DialogContent, Grid, Typography } from "@mui/material";
import { TicketContent } from "@components/TicketCard/TicketContent";
import { Sidebar } from "@components/TicketCard/Sidebar";
import { Blocker } from "@components/Blocker";
import { Form, FormRef } from "@components/Form";
import { useNavigate } from "react-router";
import { useAsync } from "@hooks/useAsync";
import { createTicket as createTicketCloudFunction, TicketCreateLink } from "@cloud-functions";
import { useSnackbar } from "@providers/Snackbar";
import { DialogHeader } from "@components/DialogHeader";
import { useSpaces } from "@providers/Spaces";
import { CreateTicketFormState } from "./types";
import { convertFormStateToValues } from "./utils";
import { useCustomFields, CustomFields } from "../TicketCard/CustomFields";
import { CategoryTypeItemPicker } from "./CategoryTypeItemPicker";
import { useCustomFieldsFeature } from "@hooks/featureFlags";
import { logError } from "@lib/logger";

const usePopupStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(0.5),
    maxHeight: "calc(100% - 8px)",

    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(4),
      maxHeight: "calc(100% - 64px)",
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  header: {
    padding: theme.spacing(1, 0),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2),
    },
  },
  content: {
    padding: theme.spacing(1, 0),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2),
    },
  },
  actions: {
    padding: theme.spacing(1, 0),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2),
    },
  },
}));

type TicketFormProps = {
  open: boolean;
  onClose: () => void;
  onCreate?: () => void;
  link: TicketCreateLink;
};

export const TicketForm: React.FC<TicketFormProps> = ({ open, onClose, link, onCreate }) => {
  const { selectedSpace } = useSpaces();
  const popupClasses = usePopupStyles();
  const classes = useStyles();
  const navigate = useNavigate();
  const { addCustomFields, deleteCustomFields, fieldsList } = useCustomFields();
  const customFieldsFeatureEnabled = useCustomFieldsFeature();
  const formRef = useRef<FormRef<CreateTicketFormState> | null>(null);

  const setCustomFieldValue = useCallback(
    (key: string, value: string | boolean | null | undefined | Date | [string, string]) => {
      formRef?.current?.setValue?.(key as keyof CreateTicketFormState, value as string);
    },
    [formRef],
  );

  const [createTicket, createTicketState] = useAsync(createTicketCloudFunction, {
    reThrow: true,
  });

  const { loading, categories, severity, countries, statusesMap } = useTicketLookups();

  const { showSuccess, showError } = useSnackbar();

  const handleSubmit = async (ticket: CreateTicketFormState) => {
    try {
      const result = await createTicket({
        input: convertFormStateToValues(ticket, fieldsList, link),
      });
      showSuccess(
        <div>
          <Typography variant="body2">Ticket has been created successfully</Typography>
          <Button
            sx={{
              textTransform: "none",
              padding: 0,
              color: "white",
              textDecoration: "underline",
            }}
            variant="text"
            type="button"
            onClick={() => navigate(`/ticket/${result.id}`)}
            data-cy="cypress_openTicketSuccessButton">
            Open ticket
          </Button>
        </div>,
      );

      if (selectedSpace && !link) {
        navigate(`/space/${selectedSpace.key}/tickets`);
      }

      onCreate?.();
      onClose();
    } catch (err) {
      logError(err);
      showError(<Typography variant="body2">Something went wrong</Typography>);
    }
  };

  const formValidator = useMemo(
    () => ticketFieldValidator(fieldsList, customFieldsFeatureEnabled),
    [fieldsList, customFieldsFeatureEnabled],
  );

  return (
    <Popup
      open={open}
      onClose={onClose}
      classes={popupClasses}
      maxWidth="md"
      keepMounted={false}
      disablePortal={false}>
      <DialogHeader
        header={
          <Typography variant="h5">
            {link ? `${link.to} / Create linked ticket` : "Create ticket"}
          </Typography>
        }
        onClose={onClose}
        className={classes.header}
      />
      <DialogContent className={classes.content}>
        <Form<CreateTicketFormState>
          onSubmit={handleSubmit}
          formMode="create"
          resolver={formValidator}
          ref_={formRef}
          reValidateMode="onChange">
          <Grid
            container
            spacing={2}
            sx={(theme) => ({ padding: theme.spacing(1) })}
            direction="column">
            <Grid item xs={12} sm={8}>
              <CategoryTypeItemPicker categories={categories} />
            </Grid>
            <Grid item xs={12} sm={8}>
              <TicketContent />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Sidebar
                severity={severity}
                countries={countries}
                statusesMap={statusesMap}
                addCustomFields={addCustomFields}
                deleteCustomFields={deleteCustomFields}
                setCustomValue={setCustomFieldValue}
                categories={categories}
              />
            </Grid>
            {customFieldsFeatureEnabled && (
              <Grid item xs={12} sm={4}>
                <CustomFields fieldsList={fieldsList} formMode="create" />
              </Grid>
            )}
          </Grid>
          <Blocker open={loading || createTicketState.isLoading} />
        </Form>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Grid item container justifyContent="flex-end" sx={{ pr: 3, padding: 1 }}>
          <Button
            sx={{
              textTransform: "none",
            }}
            variant="text"
            type="button"
            onClick={onClose}>
            Cancel
          </Button>
          <Button
            sx={(theme) => ({
              backgroundColor: theme.palette.primary.main,
              marginLeft: theme.spacing(2),
              textTransform: "none",
            })}
            variant="contained"
            type="button"
            data-cy="cypress_createTicketButton"
            onClick={() => {
              formRef.current?.triggerSubmit?.();
            }}
            disabled={loading || createTicketState.isLoading}>
            {link ? "Create and link ticket" : "Create ticket"}
          </Button>
        </Grid>
      </DialogActions>
    </Popup>
  );
};
