import { FieldView } from "@components/Form/FieldView";
import { makeStyles } from "@providers/Mui";
import { FieldLabel } from "@components/Form/FieldLabel";

type AutocompleteViewerProps = {
  field: string;
  value?: string;
  label?: React.ReactNode;
  hasUnsavedChanges?: boolean;
  onClick?: () => void;
};

export const AutocompleteViewer = ({
  field,
  value,
  label,
  hasUnsavedChanges,
  onClick,
}: AutocompleteViewerProps) => {
  const classes = useStyles();

  return (
    <>
      <FieldLabel label={label} hasUnsavedChanges={hasUnsavedChanges} />
      <FieldView field={field} onClick={onClick}>
        <div className={classes.root}>{value ?? "N/A"}</div>
      </FieldView>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
  },
}));
