import React from "react";
import { TableCell, TableRow as MuiTableRow, Typography } from "@mui/material";
import { makeStyles } from "@providers/Mui";
import { Rating } from "@components/Rating";
import { Columns, ExtendedUser } from "./types";

type TableRowProps = {
  user: ExtendedUser;
  onOpen: () => void;
  columnsMap: Record<Columns, boolean>;
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: `${theme.palette.common.white} !important`,
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
      cursor: "pointer",
    },
    "&:not(:last-child) $ceil": {
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
    }
  },
  private: {
    backgroundColor: theme.palette.warning.light,
  },
  ceil: {
    borderBottom: "none",
    padding: theme.spacing(1),
    position: "relative",
  },
  noBreak: {
    whiteSpace: "nowrap",
    fontSize: 14,
    lineHeight: 1.43,
    color: "rgba(0, 0, 0, 0.87)",
  },
  header: {
    minWidth: 200,
  },
  deprecated: {
    color: theme.palette.secondary.main,
    textDecoration: "line-through",
    fontSize: 14,
  },
}));

export const TableRow: React.FC<TableRowProps> = ({
  user,
  onOpen,
  columnsMap,
}) => {
  const classes = useStyles();

  return (
    <MuiTableRow
      className={classes.root}
      onClick={onOpen}>
      {columnsMap.id && (
        <TableCell className={classes.ceil}>
          <Typography className={classes.noBreak}>{user.id}</Typography>
        </TableCell>
      )}
      {columnsMap.displayName && (
        <TableCell className={classes.ceil}>
          <div className={classes.header}>{user?.displayName ?? "N/A"}</div>
        </TableCell>
      )}
      {columnsMap.title && (
        <TableCell className={classes.ceil}>
          <Typography className={classes.noBreak}>{user?.title ?? "N/A"}</Typography>
        </TableCell>
      )}
      {columnsMap.currentWeekRating && (
        <TableCell className={classes.ceil}>
          <Rating rating={user.currentWeekRating} />
        </TableCell>
      )}
      {columnsMap.score && (
        <TableCell align="center" className={classes.ceil}>
          {user.score || "N/A"}
        </TableCell>
      )}
    </MuiTableRow>
  );
};
