import React, { useEffect, useMemo, useState } from "react";
import { Controller, useWatch } from "react-hook-form";
import { Country, OptionsTree, Store } from "@functions-types";
import {
  CircularProgress,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  SelectProps,
} from "@mui/material";
import { makeStyles } from "@providers/Mui";
import { useForm } from "@components/Form/FormProvider";
import { FieldMode, FieldModeSwitch } from "@components/Form/FieldModeSwitch";
import { FieldLabel } from "@components/Form/FieldLabel";
import { Modify } from "@lib/types";
import { StoreFieldViewerProps, StoreFieldViewer } from "./StoreFieldViewer";
import { useStoresLookups } from "./utils";
import clsx from "clsx";

type StoreFieldProps = Modify<
  SelectProps,
  {
    name: string;
    mode?: FieldMode;
    renderOption?: (option: OptionsTree) => React.ReactNode;
    valueClassName?: string;
    viewModeClassName?: string;
    StoreFieldViewerProps?: Partial<StoreFieldViewerProps>;
  }
>;

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  field: {
    "& > fieldset": {
      top: 0,
    },
    "& legend": {
      display: "none",
    },
  },
  error: {
    color: theme.palette.error.dark,
    fontSize: 12,
    paddingLeft: 14,
    paddingTop: theme.spacing(0.5),
  },
  loader: {
    marginRight: theme.spacing(0.25),
  },
  label: {
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

export const StoreField: React.FC<StoreFieldProps> = ({
  name,
  mode,
  viewModeClassName,
  StoreFieldViewerProps = {},
  label,
  className,
  required,
}) => {
  const classes = useStyles();
  const { methods } = useForm();
  const { loading, storesMap, countries } = useStoresLookups();
  const [country, setCountry] = useState<Country | null>(null);
  const [store, setStore] = useState<Store | null>(null);
  const fieldValue = useWatch({ name }) as [string, string] | null;

  useEffect(() => {
    if (store && country) {
      methods.setValue(name, [country.id, store.id], { shouldValidate: true });
    }
  }, [store, country, name]);

  useEffect(() => {
    if (fieldValue && !country && countries.length) {
      const [countryId] = fieldValue;
      const newCountry = countries.find(({ id }) => id === countryId);
      if (newCountry) {
        setCountry(newCountry);
      }
    }
  }, [fieldValue, country, countries]);

  const stores = useMemo(() => {
    if (!country) {
      return [];
    }

    return (storesMap[country.id] ?? [])
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [country, storesMap]);

  useEffect(() => {
    if (fieldValue && !store && stores.length) {
      const [, storeId] = fieldValue;
      const newStore = stores.find(({ id }) => id === storeId);
      if (newStore) {
        setStore(newStore);
      }
    }
  }, [fieldValue, country, stores, store]);

  return (
    <Controller
      name={name}
      key={store?.id}
      render={({ fieldState }) => (
        <FieldModeSwitch
          mode={mode}
          field={name}
          edit={
            <Grid container direction="column" className={classes.root}>
              <FieldLabel
                label={
                  <>
                    {label}
                    {loading && (
                      <CircularProgress
                        size={12}
                        className={classes.loader}
                      />
                    )}
                  </>
                }
                name={name}
                className={classes.label}
                required={required}
              />
              <Grid container wrap="nowrap" spacing={2}>
                <Grid item sm={6} xs={12}>
                  <Select<Country | null>
                    fullWidth
                    displayEmpty
                    onChange={(event) => {
                      setCountry(event.target.value as Country);
                      setStore(null);
                    }}
                    size="small"
                    error={!!fieldState.error?.message}
                    value={country}
                    className={clsx(classes.field, className)}
                    renderValue={() => <>{country?.name ?? "Select country"}</>}
                  >
                    {countries.map((option) => (
                      <MenuItem
                        value={option as unknown as string}
                        key={option.id}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Select<Store | null>
                    fullWidth
                    displayEmpty
                    onChange={(event) => {
                      setStore(event.target.value as Store);
                    }}
                    size="small"
                    error={!!fieldState.error?.message}
                    value={store}
                    className={clsx(classes.field, className)}
                    disabled={!stores.length}
                    renderValue={() => <>{store?.name ?? "Select store"}</>}
                  >
                    {stores.map((option) => (
                      <MenuItem
                        value={option as unknown as string}
                        key={option.id}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
              {fieldState.error?.message && (
                <FormHelperText error sx={{ ml: "14px" }}>
                  {fieldState.error.message}
                </FormHelperText>
              )}
            </Grid>
          }
          view={
            <StoreFieldViewer
              {...StoreFieldViewerProps}
              label={label}
              country={country}
              store={store}
              field={name}
              className={viewModeClassName}
              loading={loading}
            />
          }
        />
      )}
    />
  );
};
