import React, { useState } from "react";
import { Grid, Popover, TextField } from "@mui/material";
import { makeStyles } from "@providers/Mui";
import { Button } from "@components/Button";

type NodeFormProps = {
  path: string;
  value?: string;
  onSave: (value: string, path: string) => void;
  open: boolean;
  onClose: () => void;
  anchorRef: React.RefObject<HTMLButtonElement>;
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  button: {
    height: 40,
  },
}));

export const NodeForm: React.FC<NodeFormProps> = ({
  path,
  value,
  onSave,
  open,
  onClose,
  anchorRef,
}) => {
  const classes = useStyles();
  const [fieldValue, setFieldValue] = useState(value ?? "");

  const handleSubmit = () => {
    if (fieldValue) {
      onSave(fieldValue, path);
      onClose();
      setFieldValue("");
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(event.target.value);
  };

  return (
    <Popover
      open={open}
      onClose={onClose}
      anchorEl={anchorRef.current}
      keepMounted={false}
    >
      <Grid container wrap="nowrap" spacing={2} className={classes.root}>
        <Grid item>
          <TextField
            name="value"
            variant="outlined"
            size="small"
            fullWidth
            onChange={handleChange}
            value={fieldValue}
            placeholder="Please enter value"
            autoFocus
          />
        </Grid>
        <Grid item>
          <Button
            type="button"
            variant="contained"
            onClick={handleSubmit}
            className={classes.button}
            disabled={!fieldValue.length}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </Popover>
  );
};
