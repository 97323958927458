import { useCallback, useState } from "react";
import { ref, uploadBytes } from "firebase/storage";
import { storage } from "@@firebase";
import { uid } from "uid";
import { logError } from "@lib/logger";

type Space = "ticket" | "comment";

const getUniqueRefId = (space: Space, fileName: string) => {
  return `${space}/${uid(16)}-${fileName}`;
};

export const useUploading = (space: Space) => {
  const [loading, setLoading] = useState(false);
  const upload = useCallback(
    async (file: File) => {
      setLoading(true);
      try {
        const refId = getUniqueRefId(space, file.name);
        const fileRef = ref(storage, refId);
        await uploadBytes(fileRef, file);
        return refId;
      } catch (err) {
        logError(err, "something went wrong during the file uploading");
      } finally {
        setLoading(false);
      }
    },
    [space],
  );

  return { upload, loading };
};
