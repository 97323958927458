import React, { useState } from "react";
import { Controller } from "react-hook-form";
import {
  Autocomplete as MuiAutocomplete,
  AutocompleteProps as MuiAutocompleteProps,
  FormHelperText,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { Modify } from "@lib/types";
import { useForm, useOnFieldAcivated } from "@components/Form/FormProvider";
import { FieldModeSwitch } from "@components/Form/FieldModeSwitch";
import { FieldLabel } from "@components/Form/FieldLabel";
import { AutocompleteViewer } from "./AutocompleteViewer";

type AutocompleteProps<
  T extends { id: unknown },
  Multiple extends boolean | undefined = boolean,
  DisableClearable extends boolean | undefined = boolean,
  FreeSolo extends boolean | undefined = boolean,
> = Modify<
  MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
  {
    renderInput?:
      | MuiAutocompleteProps<
          T,
          Multiple,
          DisableClearable,
          FreeSolo
        >["renderInput"]
      | undefined;
    name: string;
    label: TextFieldProps["label"];
    placeholder?: string;
    saveOnChange?: boolean;
    getValue?: (data: null | T) => unknown;
    getOptionIcon?: (option: T | null) => React.ReactNode;
    getOptionText?: (option: T) => string;
    onActivation?: () => void;
    inputClassName?: string;
    actions?: React.ReactNode;
    required?: boolean;
  }
>;
type SelectOption = { id: string };
export const Autocomplete = <
  T extends SelectOption,
  Multiple extends boolean | undefined = boolean,
  DisableClearable extends boolean | undefined = boolean,
  FreeSolo extends boolean | undefined = boolean,
>({
  name,
  label,
  placeholder,
  saveOnChange = true,
  getOptionIcon,
  onActivation,
  inputClassName,
  actions,
  getOptionText,
  disablePortal = true,
  required,
  ...props
}: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) => {
  const { methods, triggerSubmit, formMode } = useForm();

  useOnFieldAcivated(name, () => {
    onActivation?.();
  });

  const defaultValue = methods.control._defaultValues[name] ?? props.defaultValue;
  const [open, setOpen] = useState(false);

  const getOptionLabel = (option: T) => {
    if (!option) return "-N/A-";

    return getOptionText?.(option) ?? option.id ?? "-";
  };

  const defaultValueLabel = getOptionLabel(defaultValue);

  return (
    <Controller
      name={name}
      render={({ field: { onChange, ...field }, fieldState }) => {
        const hasUnsavedChanges =
          field.value && typeof field.value === "object"
            ? field.value.id !== defaultValue?.id
            : field.value !== defaultValue;

        return (
          <FieldModeSwitch
            field={name}
            edit={
              <>
                <FieldLabel label={label} name={name} required={required} />
                <MuiAutocomplete<T, Multiple, DisableClearable, FreeSolo>
                  {...field}
                  {...props}
                  id={name}
                  disablePortal={disablePortal}
                  onChange={(...args) => {
                    const [, data] = args;
                    props.onChange?.(...args);
                    onChange(data);
                    if (saveOnChange && formMode !== "create") {
                      triggerSubmit();
                    }
                  }}
                  getOptionLabel={getOptionLabel}
                  value={field.value ?? props.defaultValue ?? null}
                  defaultValue={defaultValue}
                  open={open}
                  onOpen={() => setOpen(true)}
                  onClose={() => setOpen(false)}
                  renderOption={(props, option) => (
                    <li {...props} data-cy={`cypress_autocompleteItem_${name}`}>
                      {getOptionIcon?.(option)}
                      {getOptionLabel?.(option)}
                    </li>
                  )}
                  renderInput={(props) => (
                    <TextField
                      {...props}
                      autoFocus={open}
                      error={Boolean(fieldState.error?.message)}
                      placeholder={placeholder}
                      disabled={methods.formState.isSubmitting}
                      size="small"
                      className={inputClassName}
                      data-cy={`cypress_autocomplete_${name}`}
                    />
                  )}
                />
                {fieldState.error?.message && (
                  <FormHelperText error sx={{ pl: 1.75 }}>
                    {fieldState.error.message}
                  </FormHelperText>
                )}
                {actions}
              </>
            }
            view={
              <AutocompleteViewer
                hasUnsavedChanges={hasUnsavedChanges}
                label={label}
                value={
                  methods.formState.isSubmitting
                    ? getOptionLabel(field.value)
                    : defaultValueLabel
                }
                field={name}
                onClick={() => setOpen(true)}
              />
            }
          />
        );
      }}
    />
  );
};
