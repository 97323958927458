import { useCallback } from "react";
import {
  OptionsObject,
  SnackbarMessage,
  useSnackbar as useNotistackSnackbar,
  SnackbarKey,
} from "notistack";

type ShowSnackbarFn = (
  message: SnackbarMessage,
  options?: OptionsObject,
) => SnackbarKey;
type ShowSnackbarVariantFn = (
  message: SnackbarMessage,
  options?: Omit<OptionsObject, "variant">,
) => SnackbarKey;

type UseSnackbarHook = {
  showError: ShowSnackbarVariantFn;
  showSuccess: ShowSnackbarVariantFn;
  closeSnackbar: (key?: SnackbarKey | undefined) => void;
};

export const useSnackbar = (): UseSnackbarHook => {
  const { enqueueSnackbar, closeSnackbar } = useNotistackSnackbar();

  const showMessage = useCallback<ShowSnackbarFn>((message, options) => {
    return enqueueSnackbar(message, {
      autoHideDuration: 3000,
      persist: false,
      variant: "default",
      ...options,
    });
  }, []);

  const showError = useCallback<UseSnackbarHook["showError"]>(
    (message, options) => {
      return showMessage(message, {
        autoHideDuration: 6000,
        ...options,
        variant: "error",
      });
    },
    [],
  );

  const showSuccess = useCallback<UseSnackbarHook["showSuccess"]>(
    (message, options) => {
      return showMessage(message, {
        ...options,
        variant: "success",
      });
    },
    [],
  );

  return { showError, showSuccess, closeSnackbar };
};
