import React from "react";
import { Backdrop, LinearProgress, Theme } from "@mui/material";
import { SxProps } from "@mui/system";

export type BlockerProps = {
  open: boolean;
  progressSx?: SxProps<Theme>;
};

export const Blocker: React.FC<BlockerProps> = ({ open, progressSx }) => {
  return (
    <Backdrop
      sx={{
        position: "absolute",
        backgroundColor: "rgba(255, 255, 255, 0.5)",
        zIndex: (theme) => theme.zIndex.drawer + 1,
        alignItems: "flex-start",
      }}
      open={open}>
      <LinearProgress
        variant="indeterminate"
        color="primary"
        sx={{ width: "100%", ...progressSx }}
      />
    </Backdrop>
  );
};
