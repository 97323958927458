import { makeCloudFunction } from "../../makeCloudFunction";
import { History, ListUserHistoryRequest } from "@functions-types";
import { makeUseCloudFunction } from "../../makeUseCloudFunction";
import { last } from "lodash";

export type { History };
export const listUserHistory = makeCloudFunction<
  ListUserHistoryRequest,
  History[]
>("history", "listUserHistory");
export const useListUserHistory = makeUseCloudFunction<
  ListUserHistoryRequest,
  History[]
>(listUserHistory, {
  useCache: true,
  pagination: {
    limit: 50,
    orderBy: "createdAt",
    orderDirection: "desc",
  },
  getCursor: (historyItem) => last(historyItem)?.id,
  triggerOnMounting: false,
});
