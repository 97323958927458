import React from "react";
import { Store } from "@functions-types";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@providers/Mui";

type StoreCardProps = {
  store: Store;
  onSelect?: (store: Store) => void;
  activeStoreId?: string;
};

const useStyles = makeStyles<{ active: boolean }>((theme) => ({
  root: ({ active }) => ({
    padding: theme.spacing(1, 2),
    transition: "background-color 0.2s",
    cursor: "pointer",
    backgroundColor: active ? theme.palette.grey[200] : "transparent",
    "&:hover": {
      backgroundColor: theme.palette.grey[200],
    }
  }),
  label: {
    fontSize: 14,
    fontWeight: "bold",
  },
}));

export const StoreCard: React.FC<StoreCardProps> = ({ store, onSelect, activeStoreId }) => {
  const classes = useStyles({ active: store.id === activeStoreId });
  return (
    <Grid onClick={() => onSelect?.(store)} className={classes.root}>
      <Typography className={classes.label}>
        {store.name}
      </Typography>
    </Grid>
  );
};
