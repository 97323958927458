import { makeCloudFunction } from "../makeCloudFunction";
import { Status } from "@functions-types";
import { makeUseCloudFunction } from "../makeUseCloudFunction";

export type { Status };
export const listStatuses = makeCloudFunction<void, Status[]>(
  "status",
  "listStatuses",
);
export const useListStatuses = makeUseCloudFunction(listStatuses);
export const useListStatusesRequest = makeUseCloudFunction(listStatuses, {
  triggerOnMounting: false,
});
