import React from "react";
import { FilteredStatus } from "../types";
import { Grid, IconButton, TableCell, TableRow as MuiTableRow, Typography } from "@mui/material";
import { StatusChip } from "@components/StatusChip";
import EditIcon from "@mui/icons-material/Edit";
import { WorkflowStatus } from "@functions-types";
import { makeStyles } from "@providers/Mui";
import { typeLabels } from "../WorkflowStatusForm/values";

type StatusRowProps = {
  status: FilteredStatus;
  statusesMap: Record<string, WorkflowStatus>;
  onEdit: (statusId: string) => void;
};

const useStyles = makeStyles((theme) => ({
  statusType: {
    textAlign: "center",
    paddingTop: theme.spacing(1),
    fontSize: 12,
    textTransform: "uppercase",
    color: theme.palette.grey[700],
  },
  statusChip: {
    margin: theme.spacing(0, 0.5, 0.5, 0),
  },
}));

export const StatusRow: React.FC<StatusRowProps> = ({ status, statusesMap, onEdit }) => {
  const classes = useStyles();
  return (
    <MuiTableRow>
      <TableCell>
        <Grid container direction="column" spacing={1}>
          <StatusChip status={status.status} />
          <Typography variant="caption" className={classes.statusType}>
            {status?.type && typeLabels[status.type]}
          </Typography>
        </Grid>
      </TableCell>
      <TableCell>
        {status?.transitions?.map(({ destinationId }) => (
          <StatusChip
            status={statusesMap[destinationId]?.status}
            className={classes.statusChip}
          />
        ))}
      </TableCell>
      <TableCell>
        <Grid container wrap="nowrap">
          <IconButton onClick={() => onEdit(status.status.id)}>
            <EditIcon />
          </IconButton>
        </Grid>
      </TableCell>
    </MuiTableRow>
  );
};
