import React from "react";
import { makeStyles } from "@providers/Mui";
import { Grid } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { H3 } from "@components/Typography";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    width: "100%",
    maxWidth: 380,
  },
  icon: {
    color: theme.palette.green[500],
    fontSize: 55,
    marginTop: theme.spacing(7.5),
  },
  error: {
    color: theme.palette.error.main,
  },
}));

type ReviewResultProps = {
  title: string;
  hasError?: boolean;
};

export const ReviewResult: React.FC<ReviewResultProps> = ({ title, hasError, children }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.root} container direction="column" alignItems="center">
      <H3>{title}</H3>
      {children}
      {hasError ? (
        <CancelIcon className={clsx(classes.icon, classes.error)} />
      ) : (
        <CheckCircleIcon className={classes.icon} />
      )}
    </Grid>
  );
};
