import React, { useEffect, useMemo } from "react";
import { FieldLabel } from "@components/Form/FieldLabel";
import { useWatch } from "react-hook-form";
import { Status, WorkflowTransition } from "@functions-types";
import { makeStyles } from "@providers/Mui";
import { useForm } from "@components/Form/FormProvider";
import { SelectField } from "@components/Form";
import { TransitionCard } from "./TransitionCard";
import { useListCustomPages } from "@cloud-functions";
import { emptyAction } from "../../utils";
import { Blocker } from "@components/Blocker";

type TransitionsSelectorProps = {
  statuses: Status[];
};

const useStyles = makeStyles((theme) => ({
  statusChip: {
    margin: theme.spacing(0, 0.5, 0.5, 0),
  },
  select: {
    backgroundColor: theme.palette.common.white,
    marginTop: theme.spacing(1),
  },
}));

export const TransitionsSelector: React.FC<TransitionsSelectorProps> = ({
  statuses,
}) => {
  const classes = useStyles();
  const [customPages, customPagesState] = useListCustomPages();
  const status = useWatch({ name: "status" }) as Status | null;
  const transitions = useWatch({ name: "transitions" }) as WorkflowTransition[] | null;
  const newTransition = useWatch({ name: "newTransition" }) as Status | null;
  const { methods: { setValue } } = useForm();
  const availableTransitions = useMemo(() => {
    const transitionsMap: Record<string, boolean> = (transitions ?? []).reduce((acc, cur) => ({
      ...acc,
      [cur.destinationId]: true,
    }), {});

    return statuses.filter(({ id }) => !transitionsMap[id] && id !== status?.id);
  }, [transitions, statuses, status]);

  const statusesMap: Record<string, Status> = useMemo(
    () => statuses.reduce((acc, cur) => ({
      ...acc,
      [cur.id]: cur,
    }), {}),
    [statuses]
  );

  useEffect(() => {
    if (newTransition) {
      const newTransitions = [
        ...(transitions || []),
        { destinationId: newTransition.id, action: emptyAction },
      ];
      setValue("transitions", newTransitions);
      setValue("newTransition", null);
    }
  }, [newTransition]);

  const handleDeleteTransition = (statusId: string) => {
    const newTransitions = (transitions ?? [])
      .filter(({ destinationId }) => destinationId !== statusId);
    setValue("transitions", newTransitions);
  };

  const actionsList = useMemo(() => {
    return [
      emptyAction,
      ...(customPages ?? []),
    ]
  }, [customPages]);

  return (
    <>
      <FieldLabel label="Transitions" />
      {(transitions ?? []).map(({ destinationId }, index) => (
        <TransitionCard
          status={statusesMap[destinationId]}
          key={destinationId}
          onDelete={() => handleDeleteTransition(destinationId)}
          index={index}
          actions={actionsList}
        />
      ))}
      {!!availableTransitions.length && (
        <SelectField
          name="newTransition"
          options={availableTransitions}
          fullWidth
          getOptionText={(option) => option.label}
          className={classes.select}
        />
      )}
      <Blocker open={customPagesState.loading} />
    </>
  );
};
