import { GetTicketRequest, GetTicketResponse } from "@functions-types";
import { makeUseCloudFunction } from "../makeUseCloudFunction";
import { makeCloudFunction } from "../makeCloudFunction";

export const getTicket = makeCloudFunction<GetTicketRequest, GetTicketResponse>(
  "ticket",
  "getTicket",
);
export const useGetTicket = makeUseCloudFunction(getTicket, {
  useCache: false,
});
export const useGetTicketLazy = makeUseCloudFunction(getTicket, {
  triggerOnMounting: false,
});
