import React from "react";
import { makeStyles } from "@providers/Mui";
import { Store } from "@functions-types";
import { H6 } from "@components/Typography";
import { Grid, Typography } from "@mui/material";
import { StoreCard } from "@pages/Admin/Stores/StoresList/StoreCard";

const useStyles = makeStyles((theme) => ({
  header: {
    padding: theme.spacing(2),
  },
}));

type StoresListProps = {
  stores: Store[];
  onSelect: (store: Store) => void;
  activeStoreId?: string;
};

export const StoresList: React.FC<StoresListProps> = ({ stores, onSelect, activeStoreId }) => {
  const classes = useStyles();
  return (
    <Grid container direction="column">
      <H6 className={classes.header}>Stores</H6>
      {!stores.length && (
        <Typography variant="caption" align="center">
          No stores available
        </Typography>
      )}
      {stores.map((store) => (
        <StoreCard
          store={store}
          onSelect={onSelect}
          activeStoreId={activeStoreId}
          key={store.id}
        />
      ))}
    </Grid>
  );
};
