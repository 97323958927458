import { FieldView } from "@components/Form/FieldView";
import { makeStyles } from "@providers/Mui";
import { FieldLabel } from "@components/Form/FieldLabel";

export type OTPFieldViewerProps = {
  field: string;
  value?: string;
  label?: React.ReactNode;
  hasUnsavedChanges?: boolean;
  emptyValue?: React.ReactNode;
  className?: string;
};

export const OTPFieldViewer = ({
  field,
  value,
  label,
  hasUnsavedChanges,
  emptyValue = "N/A",
  className,
}: OTPFieldViewerProps) => {
  const classes = useStyles();

  return (
    <>
      <FieldLabel label={label} hasUnsavedChanges={hasUnsavedChanges} />
      <FieldView field={field} className={className}>
        <div className={classes.root}>{value ?? emptyValue}</div>
      </FieldView>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 0.5),
  },
}));
