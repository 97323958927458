import React from "react";
import { Automation, AutomationActionsTypes } from "@functions-types";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@providers/Mui";

type AutomationCardProps = {
  automation: Automation;
  onSelect: (automation: Automation) => void;
  activeAutomation?: string;
};

const useStyles = makeStyles<{ active: boolean }>((theme) => ({
  root: ({ active }) => ({
    padding: theme.spacing(1, 2),
    transition: "background-color 0.2s",
    cursor: "pointer",
    backgroundColor: active ? theme.palette.grey[200] : "transparent",
    "&:hover": {
      backgroundColor: theme.palette.grey[200],
    },
  }),
  label: {
    fontSize: 14,
    fontWeight: "bold",
  },
  description: {
    fontSize: 12,
    color: theme.palette.grey[700],
  },
}));

const sourceOptionsMap: Record<Automation["source"], string> = {
  ztt_ticket_created: "ticket created",
  ztt_ticket_updated: "ticket updated",
  ztt_comment_created: "comment created",
  ztt_comment_updated: "comment updated",
};

const automationTypeLabelsMap: Record<AutomationActionsTypes, string> = {
  comment: "Add comment",
  assignToStoreManager: "Assign to a store manager",
  changeStatus: "Change status",
};

export const AutomationCard: React.FC<AutomationCardProps> = ({
  automation,
  onSelect,
  activeAutomation,
}) => {
  const classes = useStyles({ active: automation.id === activeAutomation });
  return (
    <Grid onClick={() => onSelect(automation)} className={classes.root}>
      <Typography className={classes.label}>{automation.name}</Typography>
      <Typography className={classes.description}>
        {automationTypeLabelsMap[automation.action.type]} on {sourceOptionsMap[automation.source]}
      </Typography>
    </Grid>
  );
};
