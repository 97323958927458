import { FC, useMemo } from "react";
import { Button, Divider, lighten, Typography } from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";

import { makeStyles } from "../../../providers/Mui";
import { PhoneNumber } from "./PhoneNumber";
import { OneTimePass } from "./OneTimePass";
import { RiderLoginStep, useRiderLogin } from "@providers/RiderLoginProvider";
import { useAuth } from "@providers/Auth/AuthProvider";

const steps: {
  message: string;
  description: string;
  key: RiderLoginStep;
}[] = [
  {
    message: "Phone number",
    description: "A 6 digit code will be sent via SMS",
    key: "phone-number",
  },
  {
    message: "Verify",
    description: "Verify you are not a robot",
    key: "recaptcha",
  },
  {
    message: "Code",
    description: "Enter the 6 digit code you received",
    key: "otp",
  },
];

export const RidersLoginMain: FC = () => {
  const { login } = useAuth();
  const classes = useStyles();
  const riderLogin = useRiderLogin();

  const activeStepIndex = useMemo(
    () => steps.findIndex(({ key }) => riderLogin.step === key),
    [riderLogin.step],
  );
  const activeStep = steps[activeStepIndex];

  return (
    <div className={classes.root}>
      <Typography className={classes.message}>{activeStep.message}</Typography>
      <Typography className={classes.description}>
        {activeStep.description}
      </Typography>
      <div className={classes.content}>
        {activeStep.key === "phone-number" && <PhoneNumber />}
        {(activeStep.key === "recaptcha" || activeStep.key === "otp") && (
          <OneTimePass />
        )}
      </div>
      {riderLogin.phoneNumber && (
        <Typography className={classes.header}>
          Signing in as <strong>({riderLogin.phoneNumber}) </strong>
          <Button
            variant="text"
            onClick={riderLogin.reset}
            className={classes.changeNumberButton}>
            Change
          </Button>
        </Typography>
      )}
      <Divider className={classes.divider} />
      <div className={classes.loginAsOps}>
        Or{" "}
        <Button
          className={classes.button}
          startIcon={<GoogleIcon />}
          onClick={login}
          variant="text">
          Login as Central Ops Employee
        </Button>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("md")]: {
      height: 400,
      paddingTop: theme.spacing(4),
    },
  },
  stepActive: {
    color: `${theme.palette.primary.main} !important`,
  },
  stepCompleted: {
    color: `${theme.palette.primary.main} !important`,
  },
  stepper: {},
  content: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  header: {
    marginBottom: theme.spacing(2),
    textAlign: "center",
  },
  message: {
    marginTop: theme.spacing(6),
    fontSize: 22,
    fontWeight: "bold",
  },
  description: {
    marginTop: theme.spacing(1),
    fontSize: 16,
  },
  changeNumberButton: {
    textTransform: "none",
    color: theme.palette.primary.main,
    fontSize: 16,
    fontWeight: "bold",
  },
  divider: {
    marginTop: theme.spacing(10),
  },
  loginAsOps: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  button: {
    textTransform: "none",
    margin: theme.spacing(3, "auto"),
    marginLeft: theme.spacing(1),
    color: lighten(theme.palette.primary.main, 0.3),
    fontWeight: "bold",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 380,
      fontSize: 14,
    },
  },
}));
