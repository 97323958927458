import React, { useEffect } from "react";
import { useParams } from "react-router";
import { MainLayout } from "@components/MainLayout";
import { Navigate, Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { Divider, Grid, Hidden, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { TicketsListPage } from "@pages/TicketsList";
import { TicketsArchivePage } from "@pages/TicketsArchive";
import { makeStyles } from "@providers/Mui";
import { useUser } from "@providers/Auth";
import { SpacesMenu } from "@components/MainLayout/SpacesMenu";
import { useSpaces } from "@providers/Spaces";
import { useIsMobile } from "@hooks/useIsMobile";
import { SpaceOverviewPage } from "@pages/SpaceOverviewPage";
import { Workflows } from "@pages/Workflows";
import { useSpaceOverviewFeature, useWorkflowFeature } from "@hooks/featureFlags";
import { canViewWorkflow } from "@lib/can";
import { H6 } from "@components/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
  },
  spacesMenu: {
    color: theme.palette.primary.main,
    width: "100%",
    justifyContent: "flex-start",
  },
  sidebar: {
    backgroundColor: theme.palette.common.white,
  },
  content: {
    padding: theme.spacing(2),
    height: "100%",
  },
  divider: {
    borderColor: theme.palette.secondary.light,
  },
  boldMenu: {
    fontWeight: 700,
    fontSize: 14,
    color: theme.palette.grey[800],
    lineHeight: 1.5,
    margin: theme.spacing(0.5, 0),
  },
  menuIconContainer: {
    minWidth: 30,
  },
  menuIcon: {
    fontSize: 20,
  },
  header: {
    fontSize: 15,
    margin: theme.spacing(3, 2, 0),
  },
}));

const useListItemStyles = makeStyles((theme) => ({
  root: {
    "&$selected": {
      backgroundColor: theme.palette.blue[100],
    },
    "& .MuiTypography-root": {
      color: theme.palette.grey[800],
      fontSize: 14,
    },
  },
  selected: {},
}));


export const Space: React.FC = () => {
  const classes = useStyles();
  const listItemClasses = useListItemStyles();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useUser();
  const { selectedSpace, setSelectedSpaceByKey } = useSpaces();
  const key = params.key!;
  const spaceOverviewEnabled = useSpaceOverviewFeature();
  const isWorkflowFeatureEnabled = useWorkflowFeature();

  const canViewWorkflowFeature = canViewWorkflow(user, selectedSpace) && isWorkflowFeatureEnabled;

  const isMobile = useIsMobile();
  const commonItems = [
    {
      to: `/space/${key}/tickets`,
      label: isMobile ? "All" : "Unresolved tickets",
      disabled: false,
    },
    {
      to: `/space/${key}/archive`,
      label: isMobile ? "Archived" : "Archived tickets",
      disabled: false,
    },
  ];

  const personalItems = [
    {
      to: `/space/${key}/tickets?filter=my`,
      label: isMobile ? "Mine" : "Your unresolved tickets",
      disabled: false,
    },
    {
      to: `/space/${key}/archive?filter=my`,
      label: isMobile ? "Archived" : "Your archived tickets",
      disabled: false,
    },
  ];

  // set app selected space if you redirect directly to a space route
  useEffect(() => {
    if (selectedSpace?.key !== key) {
      setSelectedSpaceByKey(key);
    }
  }, [key]);

  return (
    <MainLayout fullWidth fullHeight className={classes.root}>
      <Grid container height="100%">
        <Grid item xs={2} className={classes.sidebar}>
          <Hidden smDown>
            <SpacesMenu className={classes.spacesMenu} />
            <Divider className={classes.divider} />
          </Hidden>
          {spaceOverviewEnabled && (
            <List>
              <ListItem disablePadding>
                <ListItemButton
                  classes={listItemClasses}
                  selected={`${location.pathname}${location.search}` === `/space/${key}/overview`}
                  onClick={() => {
                    navigate(`/space/${key}/overview`);
                  }}
                >
                  <Hidden smDown>
                    <ListItemIcon className={classes.menuIconContainer}>
                      <SupportAgentIcon className={classes.menuIcon} />
                    </ListItemIcon>
                  </Hidden>
                  <div className={classes.boldMenu}>
                    {isMobile ? "All" : "Overview"}
                  </div>
                </ListItemButton>
              </ListItem>
            </List>
          )}

          <H6 className={classes.header}>All</H6>
          <List>
            {commonItems.map((item) => (
              <ListItem disablePadding key={item.label}>
                <ListItemButton
                  classes={listItemClasses}
                  selected={`${location.pathname}${location.search}` === item.to}
                  onClick={() => {
                    navigate(item.to);
                  }}
                  disabled={item.disabled}
                >
                  <ListItemText>{item.label}</ListItemText>
                </ListItemButton>
              </ListItem>
            ))}
          </List>

          <H6 className={classes.header}>For You</H6>
          <List>
            {personalItems.map((item) => (
              <ListItem disablePadding key={item.label}>
                <ListItemButton
                  classes={listItemClasses}
                  selected={`${location.pathname}${location.search}` === item.to}
                  onClick={() => {
                    navigate(item.to);
                  }}
                  disabled={item.disabled}
                >
                  <ListItemText>{item.label}</ListItemText>
                </ListItemButton>
              </ListItem>
            ))}
          </List>

          {canViewWorkflowFeature && (
            <>
              <Divider className={classes.divider} />
              <List>
                <ListItem disablePadding>
                  <ListItemButton
                    classes={listItemClasses}
                    selected={`${location.pathname}${location.search}` === `/space/${key}/workflows`}
                    onClick={() => {
                      navigate(`/space/${key}/workflows`);
                    }}
                  >
                    <Hidden smDown>
                      <ListItemIcon className={classes.menuIconContainer}>
                        <SettingsOutlinedIcon className={classes.menuIcon} />
                      </ListItemIcon>
                    </Hidden>
                    <div className={classes.boldMenu}>
                      Settings
                    </div>
                  </ListItemButton>
                </ListItem>
              </List>
            </>
          )}
        </Grid>
        <Grid item xs={10} className={classes.content}>
          <Hidden smUp>
            <SpacesMenu className={classes.spacesMenu} />
          </Hidden>
          <Routes>
            {canViewWorkflowFeature && <Route path="/workflows" element={<Workflows />} />}
            <Route path="/overview" element={<SpaceOverviewPage />} />
            <Route path="/tickets" element={<TicketsListPage />} />
            <Route path="/archive" element={<TicketsArchivePage />} />
            <Route path="*" element={<Navigate to={`/space/${key}/tickets`} />} />
          </Routes>
        </Grid>
      </Grid>
    </MainLayout>
  );
};
