import React from "react";
import { Typography } from "@mui/material";
import { makeStyles } from "@providers/Mui";
import { fieldsMap, unitMap } from "@lib/constants";
import { SLA, SLAType } from "@functions-types";

const useStyles = makeStyles((theme) => ({
  root: {
    "&:not(:last-child)": {
      paddingBottom: theme.spacing(2.5),
    },
  },
  header: {
    fontSize: 12,
    color: theme.palette.text.secondary,
    paddingBottom: theme.spacing(0.5),
    fontWeight: 700,
  },
  value: {
    fontSize: 12,
    color: theme.palette.text.primary,
    fontWeight: 700,
  },
}));

type SLADetailsProps = {
  sla: SLA;
  type: SLAType;
};

export const SLADetails: React.FC<SLADetailsProps> = ({ sla, type }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography className={classes.header}>
        {fieldsMap[type]}
      </Typography>
      <Typography className={classes.value}>
        within {sla.value}{unitMap[sla.unit]}
      </Typography>
    </div>
  );
};
