import clsx from "clsx";
import { Container } from "@mui/material";
import { makeStyles } from "@providers/Mui";
import { HasClassName } from "@lib/types";
import { FC } from "react";
import { AppBar } from "./AppBar";

type MainLayoutProps = HasClassName & {
  fullWidth?: boolean;
  fullHeight?: boolean;
};

export const MainLayout: FC<MainLayoutProps> = ({
  children,
  fullWidth = false,
  fullHeight = false,
  className,
}) => {
  const classes = useStyles({ fullWidth, fullHeight });

  return (
    <div className={classes.root}>
      <AppBar />
      <div className={classes.filler} />
      <Container className={clsx(classes.content, className)}>
        {children}
      </Container>
    </div>
  );
};

const useStyles = makeStyles<{ fullWidth: boolean; fullHeight: boolean }>(
  (theme) => ({
    root: ({ fullHeight }) => ({
      width: "100%",
      height: fullHeight ? "100%" : "auto",
      overflowY: "hidden",
    }),
    filler: {
      minHeight: 60,
    },
    content: ({ fullWidth, fullHeight }) => ({
      flexGrow: 1,
      position: "relative",
      padding: theme.spacing(2),
      maxWidth: fullWidth ? "100%" : 1400,
      height: fullHeight ? "calc(100% - 60px)" : "auto",
      overflowY: "auto",
    }),
  }),
);
