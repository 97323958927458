import { useCallback, useEffect, useMemo } from "react";
import { Grid, Button } from "@mui/material";
import {
  AsyncAutocomplete,
  SelectField,
  TextField,
  Autocomplete,
} from "@components/Form";
import AddIcon from "@mui/icons-material/Add";
import { makeStyles } from "@providers/Mui";
import { useFormContext, useWatch } from "react-hook-form";
import { searchUsers, Status, Ticket, User } from "@cloud-functions";
import { fieldsMap } from "@lib/constants";
import { FieldValue, TicketSelects, FilterProps, Operation } from "./types";
import { multipleOperations } from "./constants";
import { StatusField } from "@containers/TicketFilter/StatusField";

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    paddingRight: theme.spacing(4),
    minWidth: 200,
  },
  field: {
    "& .MuiOutlinedInput-root": {
      backgroundColor: theme.palette.common.white,
    },
  },
  select: {
    backgroundColor: theme.palette.common.white,
  },
}));

type FilterFormProps<T extends Record<string, unknown>> = FilterProps<T>;

const searchableFields = ["assignee", "watchers", "createdBy"];

const fieldToOptions = (field: keyof Ticket) => ({
  id: field,
  label: fieldsMap[field],
});

type OptionShape = {
  id: string;
  name?: string;
  label?: string;
};

type UserOption = {
  id: string | null;
  email?: string | null;
  label?: string;
};

const usersDefaultValues = [{ id: null, email: null, label: "Unassigned" }];

export const FilterForm = <T extends Record<string, unknown>>({
  options,
  selectsMap,
}: FilterFormProps<T>) => {
  const classes = useStyles();
  const { setValue } = useFormContext();
  const field = useWatch({ name: "field" }) as FieldValue;
  const operation = useWatch({ name: "operation" }) as Operation;
  const searchValue = useWatch({ name: "searchValue" });
  const value = useWatch({ name: "value" });

  const search = useCallback(async (query: string): Promise<UserOption[]> => {
    const users = await searchUsers({
      query,
    });

    return (users.filter(Boolean) as NonNullable<User>[]).map((user) => ({
      id: user.id,
      label: user.displayName,
      email: user.email,
    }));
  }, []);

  const operationsMap = useMemo(() => Object.fromEntries(options), [options]);
  const operations = field ? operationsMap[field?.id] ?? [] : [];

  useEffect(() => {
    if (options.length) {
      requestAnimationFrame(() =>
        setValue("field", fieldToOptions(options[0][0] as keyof Ticket)),
      );
    }
  }, [options]);

  useEffect(() => {
    if (operations.length) {
      setValue("operation", operations[0]);
    }
  }, [operations]);

  const valuesList = useMemo(() => {
    if (field && selectsMap[field?.id as keyof TicketSelects]?.length) {
      return selectsMap[field?.id as keyof TicketSelects] as OptionShape[];
    }

    return null;
  }, [selectsMap, field]);

  useEffect(() => {
    if (!value && operation) {
      setValue(
        "value",
        operation === "in" ? [] : valuesList?.[0] ?? null
      );
    }
  }, [valuesList, operation, value]);

  useEffect(() => {
    if (searchableFields.includes(field?.id)) {
      requestAnimationFrame(() => setValue("searchValue", []));
    }
  }, [field]);

  useEffect(() => {
    if (searchValue) {
      requestAnimationFrame(() => setValue("value", searchValue));
    }
  }, [searchValue]);

  const fieldsOptions = useMemo(
    () => options.map(([field]) => fieldToOptions(field as keyof Ticket)),
    [options],
  );

  const valueField = useMemo(() => {
    if (field?.id === "status" && valuesList?.length) {
      return (
        <StatusField
          operation={operation}
          options={valuesList as Status[]}
          name="value"
          label="Value"
        />
      );
    }

    if (valuesList && valuesList.length !== 0) {
      return (
        <Autocomplete<OptionShape>
          classes={classes}
          name="value"
          label="Value"
          getOptionText={(op) => op.name ?? op.label ?? "N/A"}
          renderOption={(props, option) => <li {...props}>{option.name}</li>}
          options={valuesList}
          clearIcon={null}
          className={classes.select}
          disablePortal={false}
        />
      );
    }

    if (!valuesList?.length && !searchableFields.includes(field?.id)) {
      return (
        <TextField
          label="Value"
          size="small"
          name="value"
          defaultValue=""
          sx={{ width: 200 }}
          className={classes.field}
        />
      );
    }

    if (!valuesList?.length && searchableFields.includes(field?.id)) {
      return (
        <AsyncAutocomplete<UserOption>
          disablePortal
          name="searchValue"
          label="Value"
          search={search}
          multiple={multipleOperations.includes(operation)}
          defaultOptions={
            multipleOperations.includes(operation) ? [] : usersDefaultValues
          }
          sx={{ minWidth: 200 }}
          size="small"
          getOptionText={(option) => option.label ?? ""}
        />
      )
    }
  }, [field, operation, valuesList]);

  return (
    <>
      <Grid item>
        <SelectField
          id="filter-field"
          name="field"
          getOptionText={(option) => option.label}
          options={fieldsOptions}
          controlSx={{ minWidth: 100 }}
          label="Field"
          submitOnChange
          className={classes.select}
        />
      </Grid>
      <Grid item>
        <SelectField
          id="filter-operation"
          name="operation"
          options={operations}
          getOptionText={(option) => option}
          controlSx={{ minWidth: 100 }}
          label="Operation"
          disabled={!operations.length}
          key={field?.label}
          submitOnChange
          className={classes.select}
        />
      </Grid>
      <Grid item>
        {valueField}
      </Grid>
      <Grid item>
        <Button
          color="primary"
          type="submit"
          sx={{ textTransform: "none" }}
          endIcon={<AddIcon />}
        >
          Add filter
        </Button>
      </Grid>
    </>
  );
};
