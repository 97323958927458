import { CreateStatusInput, Status } from "@functions-types";
import { ColorOption, StatusFormState } from "./types";

export const colorOptions: ColorOption[] = [
  { id: "green", label: "Green" },
  { id: "grey", label: "Grey" },
  { id: "blue", label: "Blue" },
  { id: "orange", label: "Orange" },
  { id: "red", label: "Red" },
];

export const statusToFormState = (status?: Status | null): StatusFormState | undefined => {
  if (!status) {
    return;
  }

  return {
    ...status,
    color: colorOptions.find(({ id }) => id === status.color) ?? colorOptions[0],
  }
};

export const formStateToInput = (state: StatusFormState): CreateStatusInput => ({
  ...state,
  color: state.color.id,
});
