import { makeCloudFunction } from "../../makeCloudFunction";
import {
  ListSpaceAgentsRequest,
  ListSpaceAgentsResponse,
} from "@functions-types";
import { makeUseCloudFunction } from "../../makeUseCloudFunction";

export const listSpaceAgents = makeCloudFunction<
  ListSpaceAgentsRequest,
  ListSpaceAgentsResponse
>("space", "listSpaceAgents");
export const useListSpaceAgents = makeUseCloudFunction<
  ListSpaceAgentsRequest,
  ListSpaceAgentsResponse
>(listSpaceAgents, {
  useCache: true,
  triggerOnMounting: false,
});
