import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { merge } from "lodash";
import { onSnapshot, doc } from "firebase/firestore";
import { AppConfig, FeatureFlags } from "@functions-types";
import { firestore } from "@@firebase";
import { getItem } from "@lib/localstorage";
import { logError } from "@lib/logger";

type AppConfigContextValue = {
  config: AppConfig;
  originalConfig: AppConfig | null;
  updateConfig?: (config: AppConfig) => void;
  updateFeatureFlags?: (featureFlags: FeatureFlags) => void;
};

const initialValue: AppConfigContextValue = {
  config: {
    featureFlags: {
      ticketAttachments: false,
      riderLogin: false,
      customFields: true,
      customPages: false,
      commentAttachments: false,
      privateTickets: false,
      ticketSLA: true, // to avoid jumping to a real config
      spaceOverview: false,
      ticketSearch: false,
      workflow: false,
      assignToMyself: true,
      liveTicketsCollectionV1: true,
      approvals: false,
      userProfile: true,
      linkedTickets: false,
    },
  },
  originalConfig: null,
};

export const AppConfigContext = createContext(initialValue);

export const useAppConfig = () => useContext(AppConfigContext);

const APP_CONFIG_COLLECTION = "appConfig";
const APP_CONFIG_ID = "appConfig";

export const AppConfigProvider: React.FC = ({ children }) => {
  const [config, setConfig] = useState(initialValue.config);
  const [originalConfig, setOriginalConfig] = useState<AppConfig | null>(null);

  useEffect(() => {
    const featureFlags = getItem("featureFlagsOverrides") ?? {};

    setConfig((currConfig) => merge({}, currConfig, { featureFlags }));
  }, []);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      doc(firestore, APP_CONFIG_COLLECTION, APP_CONFIG_ID),
      async (configDoc) => {
        if (configDoc.exists()) {
          try {
            const configData = configDoc.data() as AppConfig;
            setOriginalConfig(configData);
            const featureFlags = getItem("featureFlagsOverrides") ?? {};

            setConfig(
              merge({}, { featureFlags: initialValue.config.featureFlags }, configData, {
                featureFlags,
              }),
            );
          } catch (err) {
            logError(err, "can't load application config");
          }
        }
      },
      (error) => {
        console.error("can't load application config", error);
      },
    );

    return () => unsubscribe();
  }, []);

  const updateConfig = useCallback((config: AppConfig) => {
    setConfig(config);
  }, []);

  const updateFeatureFlags = useCallback((featureFlags: FeatureFlags) => {
    setConfig((value) =>
      merge({}, { featureFlags: initialValue.config.featureFlags }, value, {
        featureFlags,
      }),
    );
  }, []);

  const value = useMemo(
    () => ({
      config,
      originalConfig,
      updateConfig,
      updateFeatureFlags,
    }),
    [config],
  );

  return <AppConfigContext.Provider value={value}>{children}</AppConfigContext.Provider>;
};
