import React from "react";
import { makeStyles } from "@providers/Mui";
import { Grid, GridProps } from "@mui/material";
import clsx from "clsx";

export type CardProps = GridProps;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 2.5),
    backgroundColor: "white",
    borderRadius: 6,
  },
}));

export const Card: React.FC<CardProps> = ({ children, className, ...props }) => {
  const classes = useStyles(props);

  return (
    <Grid {...props} className={clsx(classes.root, className)}>
      {children}
    </Grid>
  );
};
