import React, { useMemo, useState } from "react";
import { Popup } from "@components/Popup";
import { makeStyles } from "@providers/Mui";
import {
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  CustomPage as CustomPageType,
  Ticket,
  updateTicket as updateTicketCloudFunction,
} from "@cloud-functions";
import { Form } from "@components/Form";
import { isNonNullish } from "@lib/utils";
import { CustomFieldsWithValue } from "@functions-types";
import { Button } from "@components/Button";
import { FieldComponent } from "@components/TicketCard/CustomFields/FieldComponent";
import { customFieldsTicketValidator } from "@lib/ticket";
import { CustomPageFormState } from "./types";
import { mergeFieldsWithValue } from "@components/TicketForm/utils";
import { useAsync } from "@hooks/useAsync";
import { Blocker } from "@components/Blocker";
import { useSnackbar } from "@providers/Snackbar";

const useStyles = makeStyles((theme) => ({
  header: {
    padding: theme.spacing(1, 0),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2),
    },
  },
  content: {
    padding: theme.spacing(0, 2, 2),
  },
  field: {
    padding: theme.spacing(0, 2, 2),
    minWidth: "auto",
    [theme.breakpoints.up("sm")]: {
      minWidth: 600,
    },
  },
}));

const usePopupStyles = makeStyles((theme) => ({
  icon: {
    marginLeft: theme.spacing(0.5),
    fontSize: 16,
  },
  paper: {
    padding: theme.spacing(1, 0),
    margin: theme.spacing(0.5),
    maxHeight: "calc(100% - 8px)",

    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(4),
      maxHeight: "calc(100% - 64px)",
    },
  },
}));

type CustomPageProps = {
  ticket: Ticket;
  customPage: CustomPageType;
  onSave: () => void;
  onClose?: () => void;
};

export const CustomPage: React.FC<CustomPageProps> = ({
  ticket,
  customPage,
  onSave,
  onClose,
}) => {
  const [open, setOpen] = useState(true);
  const popupClasses = usePopupStyles();
  const classes = useStyles();
  const [updateTicket, { isLoading }] = useAsync(updateTicketCloudFunction, {
    reThrow: true,
  });
  const { showError } = useSnackbar();

  const handleClose = () => {
    setOpen(false);
    onClose?.();
  };

  const fieldsList = useMemo<CustomFieldsWithValue[]>(() => {
    if (!customPage) return [];
    return customPage.customFields.filter(isNonNullish).map((customField) => ({
      ...customField,
      value: customField.defaultValue,
      source: [],
    }));
  }, [customPage]);

  const formValidator = useMemo(
    () => customFieldsTicketValidator(fieldsList),
    [fieldsList],
  );

  const handleSubmit = async (values: CustomPageFormState) => {
    if (!customPage?.id) return;
    try {
      const customFieldsValues = mergeFieldsWithValue(
        fieldsList,
        values.customFieldsValues,
      );
      await updateTicket({
        input: {
          id: ticket.id,
          updates: {
            customPages: {
              [customPage.id]: {
                ...customPage,
                customFields: customFieldsValues,
              },
            },
          },
        },
      });
      onSave();
      setOpen(false);
    } catch (e) {
      showError(
        <Typography variant="body2">
          Something went wrong during saving custom page values
        </Typography>,
      );
    }
  };

  if (!customPage) {
    return null;
  }

  return (
    <Popup
      open={open}
      onClose={handleClose}
      maxWidth="md"
      classes={popupClasses}>
      <DialogTitle className={classes.header}>
        <Grid
          direction="row"
          container
          flexWrap="nowrap"
          justifyContent="space-between"
          alignItems="center"
          className={classes.header}>
          <Grid item>{customPage.name}</Grid>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Typography className={classes.content}>
          {customPage.content}
        </Typography>
        <Form<CustomPageFormState>
          onSubmit={handleSubmit}
          formMode="create"
          resolver={formValidator}>
          {fieldsList.map((field) => (
            <Grid item xs={12} key={field.id} className={classes.field}>
              <FieldComponent field={field} />
            </Grid>
          ))}
          <Grid item xs={12} className={classes.field}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isLoading}>
              Save
            </Button>
          </Grid>
        </Form>
        <Blocker open={isLoading} />
      </DialogContent>
    </Popup>
  );
};
