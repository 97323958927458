import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

export const storeValidator = yupResolver(
  yup.object().shape({
    name: yup.string().required("Name is a required field"),
    managers: yup.array().required("Please provide at least one manager"),
    country: yup.object().nullable().required("Please select country"),
  }),
);
