import * as yup from "yup";
import { yupResolver, Resolver } from "@hookform/resolvers/yup";
import { AutomationFormState } from "./types";

type ResolverArgs = Parameters<ReturnType<Resolver>>;
export const automationFormValidator = async (
  values: AutomationFormState,
  context: ResolverArgs[1],
  config: ResolverArgs[2],
) => {
  const additionalValidators: Record<string, yup.SchemaOf<unknown>> = {};

  if (values?.action?.type?.id === "assignToStoreManager") {
    additionalValidators["action"] = yup.object().shape({
      type: yup.object().required("Please select an action type"),
    });
  } else if (values?.action?.type?.id === "changeStatus") {
    additionalValidators["action"] = yup.object().shape({
      type: yup.object().required("Please select an action type"),
      content: yup.object().nullable().required("Please select new status"),
    });
  } else {
    additionalValidators["action"] = yup.object().shape({
      type: yup.object().required("Please select an action type"),
      content: yup.string().nullable().required("Content is a required field"),
    });
  }

  return yupResolver(
    yup.object().shape({
      name: yup.string().required("Name is a required field"),
      source: yup.object().required("Please select an event source"),
      ...additionalValidators,
    }),
  )(values, context, config);
};
