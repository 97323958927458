import React from "react";
import ViewListIcon from "@mui/icons-material/ViewList";
import { Typography } from "@mui/material";
import { makeStyles } from "@providers/Mui";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.secondary.main,
    fontSize: 19,
    padding: theme.spacing(1, 2),
  },
  icon: {
    color: theme.palette.secondary.main,
    marginRight: theme.spacing(1),
  },
}));

export type EmptyListProps = {
  message?: string;
  showIcon?: boolean;
};

export const EmptyList: React.FC<EmptyListProps> = ({
  message = "No tickets found",
  showIcon = true,
}) => {
  const classes = useStyles();

  return (
    <Typography variant="h4" className={classes.root}>
      {showIcon && <ViewListIcon className={classes.icon} />}
      {message}
    </Typography>
  );
};
