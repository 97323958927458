import React, { useCallback, useEffect, useMemo } from "react";
import { Grid } from "@mui/material";
import { useIsMobile } from "@hooks/useIsMobile";
import { StatisticsCard } from "./StatisticsCard";
import { ReviewCard } from "./ReviewCard";
import { useListSpaceHistory } from "@cloud-functions";
import { collection, doc, limit, orderBy, query, where } from "firebase/firestore";
import { firestore } from "@@firebase";
import { useOnSnapshot } from "@hooks/useOnSnapshot";
import { useUser } from "@providers/Auth";
import { useSpaces } from "@providers/Spaces";
import { History } from "@containers/History";
import { makeStyles } from "@providers/Mui";
import { isNonNullish } from "@lib/utils";
import { UsersTable, Columns } from "@containers/UsersTable";
import { useListSpaceAgents } from "@cloud-functions/space/agents/list";
import { EmptyList } from "./EmptyList";
import { Card } from "@components/Card";
import { H6 } from "@components/Typography";
import { Blocker } from "@components/Blocker";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  history: {
    height: "100%",
    overflow: "hidden",
  },
  card: {
    height: "100%",
  },
  table: {
    position: "relative",
    height: "100%",
    padding: theme.spacing(2, 0, 5),
  },
  header: {
    padding: theme.spacing(0, 2.5, 1),
  },
  scrollable: {
    height: "100%",
    overflow: "auto",
    padding: theme.spacing(0, 2.5),
  },
}));

const AGENTS_COLUMNS: Columns[] = [
  "displayName",
  "title",
  "currentWeekRating",
  "score",
];

export const SpaceOverviewPage: React.FC = () => {
  const classes = useStyles();
  const isMobile = useIsMobile();
  const spacing = isMobile ? 2 : 3;
  const { user } = useUser();
  const { selectedSpace } = useSpaces();

  const [history, historyStatus] = useListSpaceHistory({ spaceId: selectedSpace?.id ?? "" });
  const [agents, agentsStatus] = useListSpaceAgents({ input: { spaceId: selectedSpace?.id ?? "" } });

  const historyQuery = useMemo(() => {
    return query(
      collection(firestore, "history"),
      where("space", "==", doc(firestore, "space", selectedSpace?.id ?? "")),
      orderBy("createdAt", "desc"),
      limit(1),
    );
  }, [user.id]);
  useOnSnapshot(historyQuery, () => historyStatus.refetch({ spaceId: selectedSpace?.id ?? "" }), []);

  useEffect(() => {
    if (selectedSpace?.id) {
      historyStatus.refetch({ spaceId: selectedSpace.id });
      agentsStatus.refetch({ input: { spaceId: selectedSpace.id } });
    }
  }, [selectedSpace]);

  const filteredTickets = useMemo(() => (history || []).filter(isNonNullish), [history]);

  const fetchHistoryNext = useCallback(() => {
    if (selectedSpace?.id) {
      historyStatus.fetchNext({ spaceId: selectedSpace.id });
    }
  }, [selectedSpace]);

  const agentsList = useMemo(() => (agents ?? [])
      .filter(isNonNullish)
      .map((agent) => ({
        ...agent,
        score: agent.currentWeekRating ?? 0,
      }))
      .sort((agentA, agentB) =>
        (agentB.currentWeekRating ?? 0) - (agentA.currentWeekRating ?? 0)),
    [agents]
  );

  return (
    <Grid container spacing={spacing} className={classes.root}>
      <Grid item container direction="column" spacing={spacing} xs={12} sm={5} wrap="nowrap">
        {selectedSpace?.id && (
          <Grid item>
            <ReviewCard spaceId={selectedSpace?.id} />
          </Grid>
        )}
        <Grid item container spacing={spacing}>
          <Grid item xs={12} sm={6}>
            <StatisticsCard title="Average Resolution Time" count="N/A" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <StatisticsCard title="Average Completion Rate" count="N/A" />
          </Grid>
        </Grid>
        <Grid item className={classes.history}>
          <History
            className={classes.card}
            history={filteredTickets}
            loading={historyStatus.loading}
            fetchNext={fetchHistoryNext}
          />
        </Grid>
      </Grid>
      <Grid item container spacing={spacing} xs={12} sm={7}>
        <Grid item xs={12}>
          <Card className={classes.table} alignItems="flex-start">
            <H6 className={classes.header}>All Agents</H6>
            {!!agentsList.length && (
              <Grid
                container
                direction="column"
                className={classes.scrollable}
                flexWrap="nowrap"
              >
                <UsersTable
                  users={agentsList}
                  columns={AGENTS_COLUMNS}
                />
              </Grid>
            )}
            {!agentsList.length && !agentsStatus.loading && (
              <EmptyList />
            )}
            <Blocker open={agentsStatus.loading} />
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};
