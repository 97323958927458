import { makeStyles } from "@providers/Mui";
import { FC } from "react";
import { DecodedRichText } from "@functions-types";
import { QuillDelta, QuillViewer } from "@components/QuillEditor";

type RichTextValueProps = {
  value: DecodedRichText;
};

export const RichTextValue: FC<RichTextValueProps> = ({ value }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <QuillViewer value={value.json as QuillDelta} />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {},
  line: {
    minHeight: 20,
  },
}));
