import { CreationActivity } from "@functions-types";
import { FC } from "react";
import { RecordLayout } from "./RecordLayout";

type HistoryCreationRecordProps = {
  record: CreationActivity;
};

export const HistoryCreationRecord: FC<HistoryCreationRecordProps> = ({
  record,
}) => {
  return <RecordLayout record={record} verb="created the ticket" />;
};
