import { Controller } from "react-hook-form";
import { useRef } from "react";
import MuiPhoneNumber from "material-ui-phone-number";
import {
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { Modify } from "@lib/types";
import { makeStyles } from "@providers/Mui";
import { useForm, useOnFieldAcivated } from "@components/Form/FormProvider";

import { FieldModeSwitch, FieldModeSwitchProps } from "../../FieldModeSwitch";
import { FieldLabel } from "../../FieldLabel";
import {
  FieldSaveButtons,
  FieldSaveButtonsProps,
} from "../../FieldSaveButtons";
import {
  PhoneNumberFieldViewer,
  PhoneNumberFieldViewerProps,
} from "./PhoneNumberFieldViewer";

type PhoneNumberFieldProps = Modify<
  MuiTextFieldProps,
  {
    name: string;
    FieldSaveButtonsProps?: Partial<FieldSaveButtonsProps>;
    OTPFieldViewerProps?: Partial<PhoneNumberFieldViewerProps>;
    FieldModeSwitchProps?: Partial<FieldModeSwitchProps>;
  }
>;

export const PhoneNumberField = ({
  name,
  label,
  FieldSaveButtonsProps,
  OTPFieldViewerProps,
  FieldModeSwitchProps,
  size = "small",
  className,
  ...props
}: PhoneNumberFieldProps) => {
  const classes = useStyles(props);
  const { methods } = useForm();
  const inputRef = useRef<HTMLInputElement | null>(null);

  useOnFieldAcivated(name, () => {
    setTimeout(() => {
      inputRef.current?.focus();
    }, 200);
  });

  const defaultValue = methods.control._defaultValues[name];

  return (
    <Controller
      name={name}
      render={({ field, fieldState, formState }) => {
        const hasUnsavedChanges = Boolean(field.value !== defaultValue);

        return (
          <FieldModeSwitch
            field={name}
            edit={
              <>
                <FieldLabel label={label} name={name} />

                <MuiPhoneNumber
                  placeholder="Enter number"
                  name={field.name}
                  defaultCountry="gb"
                  preferredCountries={["gb", "fr", "nl", "de"]}
                  value={field.value}
                  onChange={field.onChange}
                  error={Boolean(fieldState.error?.message)}
                  helperText={fieldState.error?.message ?? props.helperText}
                />

                <FieldSaveButtons field={name} {...FieldSaveButtonsProps} />
              </>
            }
            view={
              <PhoneNumberFieldViewer
                hasUnsavedChanges={hasUnsavedChanges}
                label={label}
                value={defaultValue}
                field={name}
                {...OTPFieldViewerProps}
              />
            }
            {...FieldModeSwitchProps}
          />
        );
      }}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    ".MuiOutlinedInput": {
      border: "none",
    },
  },
}));
