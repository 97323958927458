import { usePrevious } from "./usePrevious";
import { useEffect } from "react";
import { isEqual } from "lodash";

export function useOnChange<T extends any>(
  value: T,
  callback: ({ prev, current }: { prev: T | undefined; current: T }) => void,
) {
  const prevValue = usePrevious(value);

  useEffect(() => {
    if (!isEqual(value, prevValue)) {
      callback({ prev: prevValue, current: value });
    }
  }, [value]);
}
