import React from "react";
import { FieldView } from "@components/Form/FieldView";
import { makeStyles } from "@providers/Mui";
import { FieldLabel } from "@components/Form/FieldLabel";
import { Chip } from "@mui/material";

export type AsyncAutocompleteViewerProps<T extends { id: unknown } = { id: unknown }> = {
  field: string;
  value?: string;
  label?: React.ReactNode;
  action?: React.ReactNode;
  hasUnsavedChanges?: boolean;
  multiple?: boolean;
  renderItem?: (item: T) => React.ReactNode;
  getOptionText?: (option: T) => string;
  onClick?: () => void;
};

export const AsyncAutocompleteViewer = <T extends { id: unknown }>({
  field,
  value,
  label,
  action,
  multiple,
  hasUnsavedChanges,
  renderItem,
  getOptionText,
  onClick,
}: AsyncAutocompleteViewerProps<T>) => {
  const classes = useStyles();

  const isEmpty = (multiple && (value ?? []).length === 0) || !value;
  const arrayValue = Array.from(multiple ? ((value ?? []) as any[]) : [value]);

  return (
    <>
      <FieldLabel label={label} action={action} hasUnsavedChanges={hasUnsavedChanges} />
      <FieldView field={field} onClick={onClick}>
        {isEmpty
          ? "N/A"
          : arrayValue.map((option, index) =>
              renderItem ? (
                <React.Fragment key={`${option?.id}_${index}`}>{renderItem(option)}</React.Fragment>
              ) : (
                <Chip
                  classes={classes}
                  key={`${option?.id}_${index}`}
                  label={getOptionText?.(option) ?? option.id}
                />
              ),
            )}
      </FieldView>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    "&:last-child": {
      marginBottom: 0,
    },
    maxWidth: "100%",
  },
  label: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    width: "100%",
  },
}));
