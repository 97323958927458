import React from "react";
import { Grid } from "@mui/material";
import { H6 } from "@components/Typography";
import { makeStyles } from "@providers/Mui";
import { CustomPage } from "@functions-types";
import { PageCard } from "./PageCard";

const useStyles = makeStyles((theme) => ({
  header: {
    padding: theme.spacing(2),
  },
}));

type FieldsListProps = {
  customPages: CustomPage[];
  onSelect: (customPage: CustomPage) => void;
  activeField?: string;
};

export const PagesList: React.FC<FieldsListProps> = ({ customPages, onSelect, activeField }) => {
  const classes = useStyles();
  return (
    <Grid container direction="column">
      <H6 className={classes.header}>Custom Pages</H6>
      {customPages.map((page) => (
        <PageCard
          customPage={page}
          onSelect={onSelect}
          activePage={activeField}
          key={page.id}
        />
      ))}
    </Grid>
  );
};
