import { makeStyles } from "@providers/Mui";
import { FC } from "react";

type LookupValueProps = {
  value: { name: string };
};

export const LookupValue: FC<LookupValueProps> = ({ value }) => {
  const classes = useStyles();

  return <div className={classes.root}>{value.name}</div>;
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0 6px",
    borderRadius: 4,
    backgroundColor: "#f0f0f0",
  },
}));
