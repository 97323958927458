import React from "react";
import { Typography } from "@mui/material";
import { HasClassName } from "@lib/types";
import { makeStyles } from "@providers/Mui";
import clsx from "clsx";

type H3Props = HasClassName & {};

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: 22,
    fontWeight: 700,
  },
}));

export const H3: React.FC<H3Props> = ({ children, className }) => {
  const classes = useStyles();
  return (
    <Typography className={clsx(classes.root, className)}>
      {children}
    </Typography>
  );
};
