import { FieldView } from "@components/Form/FieldView";
import { makeStyles } from "@providers/Mui";
import { FieldLabel } from "@components/Form/FieldLabel";
import { format } from "date-fns";

export type DatePickerViewerProps = {
  field: string;
  value?: string;
  label?: React.ReactNode;
  hasUnsavedChanges?: boolean;
  emptyValue?: React.ReactNode;
  className?: string;
  onClick?: () => void;
};

export const DatePickerViewer = ({
  field,
  value,
  label,
  hasUnsavedChanges,
  emptyValue = "N/A",
  className,
  onClick,
}: DatePickerViewerProps) => {
  const classes = useStyles();

  return (
    <>
      <FieldLabel label={label} hasUnsavedChanges={hasUnsavedChanges} />
      <FieldView field={field} className={className} onClick={onClick}>
        <div className={classes.root}>
          {value ? format(new Date(value), "dd/MM/yyyy") : emptyValue}
        </div>
      </FieldView>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 0.5),
  },
}));
