import { makeCloudFunction } from "../makeCloudFunction";
import {
  CreateCommentActivityRequest,
  CreateCommentActivityResponse,
} from "@functions-types";

export const createComment = makeCloudFunction<
  CreateCommentActivityRequest,
  CreateCommentActivityResponse
>("activity", "createComment");
