import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useWatch } from "react-hook-form";
import { Grid, MenuItem, Typography, darken } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import LockIcon from "@mui/icons-material/Lock";
import FlagIcon from "@mui/icons-material/Flag";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { AsyncAutocomplete, Autocomplete } from "@components/Form";
import { getTicketTransitions, UseTicketLookupsResult } from "@lib/ticket";
import { makeStyles } from "@providers/Mui";
import { applyDefaultState } from "./utils";
import {
  CTITree,
  CustomField,
  CustomPage as CustomPageType,
  searchUsers,
  Severity,
  Ticket,
} from "@cloud-functions";
import { Status } from "@functions-types";
import { SelectField } from "@components/Form/fields/SelectField";
import { CheckboxField } from "@components/Form/fields/CheckboxField";
import { FieldGroup } from "@components/Form/FieldGroup";
import { useForm } from "@components/Form/FormProvider";
import { getStatusStyles, StatusChip } from "@components/StatusChip";
import { usePrevious } from "@hooks/usePrevious";
import { fieldsMap } from "@lib/constants";
import { UserCard } from "@components/UserCard";
import { Label } from "@components/Label";
import { format } from "@lib/date";
import { Faq } from "@components/Faq";
import { isNonNullish } from "@lib/utils";
import { useUser } from "@providers/Auth";
import { useCTIUpdate } from "@components/TicketCard/CustomFields";
import { useApprovalsFeature, usePrivateTicketsFeature } from "@hooks/featureFlags";
import { canActOnTicket } from "@lib/can";
import { AssigneeField } from "./AssigneeField";
import { CustomPage } from "@containers/CustomPage";
import { useSnackbar } from "@providers/Snackbar";
import { TicketApprovals } from "./Approvals";
import { formatUserName } from "@lib/user";

type SidebarProps = Pick<
  UseTicketLookupsResult,
  "categories" | "countries" | "severity" | "statusesMap"
> & {
  ticket?: Ticket;
  addCustomFields: (key: string, fields: CustomField[]) => void;
  deleteCustomFields: (key: string) => void;
  setCustomValue: (
    key: string,
    value: string | boolean | null | undefined | Date | [string, string],
  ) => void;
  onTicketUpdate?: () => void;
};

const useStyles = makeStyles<{ status?: Status }>((theme) => ({
  root: {
    paddingBottom: theme.spacing(2),
  },
  selectedStatus: ({ status }) => ({
    ...(status ? getStatusStyles(status) : {}),
    fontWeight: "bold",
    paddingRight: 40,
  }),
  status: ({ status }) => ({
    "& > fieldset": {
      borderWidth: "1px !important",
      borderColor: status ? `${getStatusStyles(status).color} !important` : "none",
    },
    "&.Mui-disabled, &.Mui-disabled > fieldset": {
      opacity: 0.7,
    },
  }),
  statusChip: {
    padding: "0 8px",
    borderRadius: 5,
  },
  box: {
    backgroundColor: "#f8f8f8",
    padding: theme.spacing(1, 1.5),
    borderRadius: 4,
    overflowX: "auto",
    marginTop: 6,
  },
  note: {
    fontSize: 12,
    color: theme.palette.grey[700],
    padding: theme.spacing(0, 2),
  },
  user: {
    marginRight: theme.spacing(0.5),
    display: "inline",
    "&:last-child": {
      paddingLeft: theme.spacing(0.5),
    },
  },
  assignee: {
    width: "100%",
    backgroundColor: "rgb(231 231 231)",
  },
  declined: {
    color: theme.palette.error.main,
  },
  pending: {
    color: darken(theme.palette.yellow[300], 0.2),
  },
}));

export const Sidebar: React.FC<SidebarProps> = ({
  categories,
  severity,
  countries,
  ticket,
  statusesMap,
  addCustomFields,
  deleteCustomFields,
  setCustomValue,
  onTicketUpdate: onTicketUpdateFromProps,
}) => {
  const privateTicketsFeatureEnabled = usePrivateTicketsFeature();
  const approvalsFeatureEnabled = useApprovalsFeature();

  const { user } = useUser();
  const { showError } = useSnackbar();
  const { activateField, formMode, triggerSubmit, methods } = useForm();
  const { setValue } = methods;
  const category: CTITree | null = useWatch({ name: "category" });
  const type: CTITree["types"][0] | null = useWatch({ name: "type" });
  const item: CTITree["types"][0]["items"][0] | null = useWatch({
    name: "item",
  });
  const severityValue: Severity = useWatch({ name: "severity" });
  const currentStatus: Status | null = useWatch({ name: "status" });
  const prevStatus = usePrevious(currentStatus);
  const updatedAt: number | null = useWatch({ name: "updatedAt" });
  const [customPage, setCustomPage] = useState<CustomPageType | null>(null);

  const classes = useStyles({
    status: currentStatus ?? undefined,
  });
  const prevItem = usePrevious(item);

  const canAct = useMemo(() => canActOnTicket(user, ticket), [user, ticket]);

  const onTicketUpdate = useCallback(() => {
    onTicketUpdateFromProps?.();
  }, [onTicketUpdateFromProps]);

  useEffect(() => {
    const workflowStatuses = ticket?.item?.space?.workflow?.statuses ?? [];
    if (prevStatus && currentStatus?.id !== prevStatus.id && workflowStatuses) {
      const prevWorkflowStatus = workflowStatuses.find(
        ({ status }) => status?.id === prevStatus.id,
      );
      const transition = (prevWorkflowStatus?.transitions ?? []).find(
        ({ destinationId }) => destinationId === currentStatus?.id,
      );

      if (transition?.action && transition.action?.customPage) {
        setCustomPage(transition.action?.customPage);
      }
    }
  }, [currentStatus, ticket]);

  useEffect(() => {
    applyDefaultState(
      categories,
      setValue,
      ticket?.category?.id,
      ticket?.type?.id,
      ticket?.item?.id,
    );
  }, [ticket?.id]);

  useCTIUpdate(category, addCustomFields, deleteCustomFields, setCustomValue);
  useCTIUpdate(type, addCustomFields, deleteCustomFields, setCustomValue);
  useCTIUpdate(item, addCustomFields, deleteCustomFields, setCustomValue);

  const types = useMemo(() => category?.types ?? [], [category]);
  const items = useMemo(() => type?.items ?? [], [type]);

  const search = useCallback(async (query: string) => {
    const users = await searchUsers({
      query,
    });

    return users.filter(isNonNullish);
  }, []);

  const searchExcludingMe = useCallback(async (query: string) => {
    const users = await searchUsers({
      query,
    });

    return users.filter(isNonNullish).filter(({ id }) => id !== user?.id);
  }, []);

  const defaultWatchersOptions = useMemo(() => {
    return (ticket?.watchers ?? []).filter(isNonNullish);
  }, [ticket?.watchers]);

  useEffect(() => {
    if (item?.default_severity && !severityValue) {
      setValue("severity", item.default_severity);
    }
  }, [item, severityValue]);

  useEffect(() => {
    if (prevItem !== undefined && item && prevItem?.id !== item?.id && formMode === "edit") {
      requestAnimationFrame(() => triggerSubmit());
    }
  }, [item, formMode]);

  const availableStatuses: Status[] = useMemo(() => {
    if (!ticket) return [];
    const transitions = getTicketTransitions(ticket, currentStatus?.id);

    if (!transitions?.length) {
      showError(<Typography variant="body2">Ticket has incorrect status.</Typography>);
      return [];
    }

    return transitions.map(({ destinationId }) => statusesMap[destinationId]).filter(isNonNullish);
  }, [ticket, statusesMap, currentStatus]);

  const handleCustomPageUpdate = useCallback(() => {
    setCustomPage(null);
    onTicketUpdate?.();
  }, []);

  const handleCategorySelect = useCallback(() => {
    setValue("type", null);
    setValue("item", null);
    activateField("type", true);
    activateField("item", true);
  }, []);

  const handleTypeSelect = useCallback(() => {
    setValue("item", null);
    activateField("item", true);
  }, []);

  return (
    <Grid container spacing={2} className={classes.root}>
      {formMode === "edit" && (
        <Grid item xs={12}>
          <SelectField
            options={availableStatuses}
            name="status"
            mode="create"
            disabled={ticket?.approvalState !== "approved"}
            helperText={
              ticket?.approvalState === "rejected" ? (
                <span className={classes.declined}>Ticket has been declined</span>
              ) : ticket?.approvalState === "pending" ? (
                <span className={classes.pending}>Ticket is pending approval</span>
              ) : null
            }
            getOptionText={(option) => option.label}
            renderOption={(option) => {
              // don't show selected item
              if (option.id === currentStatus?.id) return null;

              return (
                <MenuItem value={option as unknown as string} key={option.id}>
                  <StatusChip status={option} />
                </MenuItem>
              );
            }}
            valueClassName={classes.selectedStatus}
            className={classes.status}
            submitOnChange
          />
        </Grid>
      )}

      {formMode === "edit" && ticket && approvalsFeatureEnabled && (
        <Grid item xs={12}>
          <TicketApprovals ticket={ticket} onTicketUpdate={onTicketUpdate} />
        </Grid>
      )}

      {formMode === "edit" && (
        <Grid item xs={12}>
          <FieldGroup
            title="Assignment"
            collapsable={formMode === "edit"}
            summary={`${fieldsMap.category}, ${fieldsMap.type}, ${fieldsMap.item} and ${fieldsMap.assignee}`}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Autocomplete
                  name="category"
                  label={fieldsMap.category}
                  options={categories}
                  saveOnChange={false}
                  getOptionText={(option) => option.name}
                  onChange={handleCategorySelect}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  actions={
                    category?.faq_link && <Faq url={category.faq_link} label={category.faq_label} />
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  name="type"
                  label={fieldsMap.type}
                  saveOnChange={false}
                  getOptionText={(option) => option.name}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  placeholder={
                    !types.length ? `-- Please select ${fieldsMap.category} --` : undefined
                  }
                  options={types}
                  onChange={handleTypeSelect}
                  disabled={!types.length}
                  actions={type?.faq_link && <Faq url={type.faq_link} label={type.faq_label} />}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  name="item"
                  label={fieldsMap.item}
                  placeholder={!items.length ? `-- Please select ${fieldsMap.type} --` : undefined}
                  saveOnChange={false}
                  getOptionText={(option) => option.name}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  options={items}
                  disabled={!items.length}
                  actions={item?.faq_link && <Faq url={item.faq_link} label={item.faq_label} />}
                />
              </Grid>
              <Grid item xs={12}>
                <AssigneeField canAct={canAct} searchUser={search} ticket={ticket} />
              </Grid>
            </Grid>
          </FieldGroup>
        </Grid>
      )}
      <Grid item xs={12}>
        <FieldGroup
          title="Details"
          collapsable={formMode === "edit"}
          summary={`${fieldsMap.severity} and ${fieldsMap.country}`}>
          <Grid container spacing={2}>
            {privateTicketsFeatureEnabled && (
              <Grid item xs={12}>
                <CheckboxField
                  submitOnChange={formMode === "edit"}
                  uncheckConfirmationText="Are you sure you want to make this ticket public? This ticket will be visible for everyone."
                  name="isPrivate"
                  label={<Label label={fieldsMap.isPrivate} Icon={LockIcon} />}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <SelectField
                getOptionText={(option) => option.name}
                fullWidth
                options={severity}
                name="severity"
                label={<Label label={fieldsMap.severity} Icon={ErrorOutlineIcon} required />}
                submitOnChange
              />
            </Grid>
            <Grid item xs={12}>
              <SelectField
                getOptionText={(option) => option.name}
                fullWidth
                label={<Label label={fieldsMap.country} Icon={FlagIcon} required />}
                name="country"
                options={countries}
                submitOnChange
              />
            </Grid>
          </Grid>
        </FieldGroup>
      </Grid>
      <Grid item xs={12}>
        <AsyncAutocomplete
          disablePortal
          label={<Label label={fieldsMap.watchers} Icon={VisibilityIcon} />}
          name="watchers"
          multiple
          getOptionText={formatUserName}
          getOptionDisabled={(option) => !option.active}
          search={search}
          defaultOptions={defaultWatchersOptions}
          disableClearable
          renderItem={(option) => {
            const { email, displayName, active } = option ?? {};

            if (!displayName) {
              return <UserCard user={null} />;
            }
            return (
              <UserCard
                user={{ displayName, email, active }}
                className={classes.assignee}
                withBackground
              />
            );
          }}
        />
      </Grid>
      {formMode === "create" && (
        <Grid item xs={12}>
          <AsyncAutocomplete
            disablePortal
            label={<Label label={fieldsMap.trulyCreatedBy} Icon={AccountCircleIcon} />}
            name="createdFor"
            getOptionText={formatUserName}
            search={searchExcludingMe}
            renderItem={(option) => {
              const { email, displayName, active } = option;

              if (!email || !displayName) {
                return <UserCard user={null} />;
              }
              return <UserCard user={{ displayName, email, active }} />;
            }}
          />
        </Grid>
      )}
      {ticket?.createdBy && (
        <Grid item xs={12}>
          <Label label={fieldsMap.createdBy} Icon={AccountCircleIcon} />
          <div className={classes.box}>
            <UserCard user={ticket.createdBy} />
          </div>
        </Grid>
      )}
      {ticket?.createdAt && (
        <Grid item xs={12}>
          <Typography className={classes.note}>
            {fieldsMap.createdAt} {format(ticket.createdAt, "dd MMMM hh:mm aaa")}
          </Typography>
          {updatedAt && (
            <Typography className={classes.note}>
              {fieldsMap.updatedAt} {format(updatedAt, "dd MMMM hh:mm aaa")}
            </Typography>
          )}
        </Grid>
      )}
      {ticket?.trulyCreatedBy && (
        <Grid item xs={12}>
          <Typography className={classes.note} component="div">
            <UserCard user={ticket.trulyCreatedBy} withAvatar={false} className={classes.user} />
            has created this ticket on behalf of
            <UserCard user={ticket.createdBy} withAvatar={false} className={classes.user} />
          </Typography>
        </Grid>
      )}
      {ticket && customPage && (
        <CustomPage ticket={ticket} customPage={customPage} onSave={handleCustomPageUpdate} />
      )}
    </Grid>
  );
};
