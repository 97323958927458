import {
  GetMeResponse,
  GetMySpacesResponse,
  FeatureFlags,
} from "@functions-types";

type LocalStorageItem = {
  filters: object[];
  userCache: Record<string, GetMeResponse>;
  userSpacesCache: Record<string, GetMySpacesResponse>;
  userSelectedSpaceCache: Record<string, string>;
  featureFlagsOverrides: FeatureFlags;
  lastUsedRiderPhoneNumber: string | null;
};

export function getItem<
  N extends keyof LocalStorageItem,
  T extends LocalStorageItem[N] = LocalStorageItem[N],
>(name: N): T | null {
  const value = localStorage.getItem(name);
  return value ? (JSON.parse(value) as T) : null;
}

export function setItem<
  N extends keyof LocalStorageItem,
  T extends LocalStorageItem[N] = LocalStorageItem[N],
>(name: N, value: T): void {
  localStorage.setItem(name, JSON.stringify(value));
}

export function removeItem<N extends keyof LocalStorageItem>(name: N): void {
  localStorage.removeItem(name);
}
