import { head, words } from "lodash";

export const avatarColors = [
  { backgroundColor: "#c62828", color: "white" },
  { backgroundColor: "#ad1457", color: "white" },
  { backgroundColor: "#6a1b9a", color: "white" },
  { backgroundColor: "#4527a0", color: "white" },
  { backgroundColor: "#283593", color: "white" },
  { backgroundColor: "#1565c0", color: "white" },
  { backgroundColor: "#0277bd", color: "white" },
  { backgroundColor: "#00838f", color: "white" },
  { backgroundColor: "#00695c", color: "white" },
  { backgroundColor: "#2e7d32", color: "white" },
  { backgroundColor: "#558b2f", color: "white" },
  { backgroundColor: "#9e9d24", color: "white" },
  { backgroundColor: "#f9a825", color: "white" },
  { backgroundColor: "#ff8f00", color: "white" },
  { backgroundColor: "#ef6c00", color: "white" },
  { backgroundColor: "#d84315", color: "white" },
];

export function getUserColour(email = ""): {
  backgroundColor: string;
  color: string;
} {
  let chatCodesSum = 0;

  for (let index = 0; index < (email || "").length; index++) {
    chatCodesSum += email.charCodeAt(index);
  }

  return avatarColors[chatCodesSum % avatarColors.length];
}

export function getInactiveUserColour() {
  return {
    backgroundColor: "#bdbdbd",
    color: "black",
  };
}

export function getUsernameInitials(displayName = "") {
  const names = words(displayName);
  const firstNameLetter = head(names[0]) ?? "";
  const secondNameLetter = head(names[1]) ?? "";

  return `${firstNameLetter.toUpperCase()}${secondNameLetter.toUpperCase()}`;
}
