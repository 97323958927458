import { Activity } from "@functions-types";
import { makeStyles } from "@providers/Mui";
import { FC } from "react";
import { EmptyMessage } from "./EmptyMessage";
import { HistoryRecord } from "./HistoryRecord";

type HistoryListProps = {
  records: Activity[];
};

export const HistoryList: FC<HistoryListProps> = ({ records }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {records.length === 0 && (
        <EmptyMessage>- Found no activity log for this ticket -</EmptyMessage>
      )}

      {records.map((record) => (
        <HistoryRecord key={record.id} record={record} />
      ))}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: 13,
    marginTop: theme.spacing(2),
  },
}));
