import { CTITree } from "@functions-types";

type SetFn = (property: "category" | "type" | "item", value: unknown) => void;

export const applyDefaultState = (
  categories: CTITree[],
  setFn: SetFn,
  categoryId?: string,
  typeId?: string,
  itemId?: string,
): void => {
  if (!categoryId || !typeId || !itemId) return;

  requestAnimationFrame(() => {
    const category = categories.find(({ id }) => id === categoryId);
    if (!category) return;

    setFn("category", category);

    requestAnimationFrame(() => {
      const type = category.types.find(({ id }) => id === typeId);
      if (!type) return;

      setFn("type", type);

      requestAnimationFrame(() => {
        const item = type.items.find(({ id }) => id === itemId);
        if (!item) return;

        setFn("item", item);
      });
    });
  });
};
