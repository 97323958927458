import clsx from "clsx";
import { FieldView } from "@components/Form/FieldView";
import { makeStyles } from "@providers/Mui";
import { FieldLabel } from "@components/Form/FieldLabel";
import type { SelectOption } from "./SelectField";
import { HasClassName } from "@lib/types";

export type SelectFieldViewerProps<T extends SelectOption> = HasClassName & {
  field: string;
  value?: T;
  label?: React.ReactNode;
  hasUnsavedChanges?: boolean;
  onClick?: () => void;
};

export const SelectFieldViewer = <T extends SelectOption>({
  field,
  value,
  label,
  hasUnsavedChanges,
  className,
  onClick,
}: SelectFieldViewerProps<T>) => {
  const classes = useStyles();

  return (
    <>
      <FieldLabel label={label} hasUnsavedChanges={hasUnsavedChanges} />
      <FieldView field={field} onClick={onClick}>
        <div className={clsx(classes.root, className)}>{value ?? "N/A"}</div>
      </FieldView>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
  },
}));
