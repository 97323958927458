import { default as ReactQuill } from "react-quill";
import { makeStyles } from "@providers/Mui";
import { modules, formats } from "./quill.modules";
import "react-quill/dist/quill.bubble.css";
import clsx from "clsx";
import { QuillDelta } from "./types";

type QuillViewerProps = {
  value?: QuillDelta | string;
  className?: string;
};

export const QuillViewer = ({ value, className }: QuillViewerProps) => {
  const classes = useStyles();

  return (
    <ReactQuill
      theme="bubble"
      className={clsx(classes.root, className)}
      modules={modules}
      formats={formats}
      readOnly
      value={value ?? "N/A"}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    "& p": {
      minHeight: 24,
      margin: "3px 0",
    },
  },
}));
