import { FirebaseApp, initializeApp } from "firebase/app";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { config } from "./config";

const app: FirebaseApp = initializeApp(config.firebase.app);
const functions = getFunctions(app, config.firebase.region);
const auth = getAuth(app);
const firestore = getFirestore(app);
const storage = getStorage(app);

auth.useDeviceLanguage();

if (process.env.REACT_APP_USE_FIREBASE_EMULATORS === "true") {
  connectFunctionsEmulator(functions, "localhost", 5001);
  connectAuthEmulator(auth, "http://localhost:5002");
  connectFirestoreEmulator(firestore, "localhost", 9090);
  connectStorageEmulator(storage, "localhost", 9199);
}

export { auth, app, functions, firestore, storage };
