import React from "react";
import { MainLayout } from "@components/MainLayout";
import { useNavigate, useLocation, Route, Routes, Navigate } from "react-router-dom";
import {
  Grid,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { makeStyles } from "@providers/Mui";
import {
  ApprovalsCreatedByMe,
  ApprovalsWatching,
  PendingApprovals,
} from "@pages/Profile/pages/Approvals";
import { UserInformation } from "@pages/Profile/pages/UserInformation";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
  },
  sidebar: {
    borderRight: `1px solid ${theme.palette.grey[350]}`,
    backgroundColor: theme.palette.common.white,
    paddingTop: theme.spacing(2),
  },
  content: {
    padding: theme.spacing(4, 2),
    height: "100%",
  },
  childItemLabel: {
    marginLeft: theme.spacing(7),
  },
}));

type MenuItem = {
  to: string;
  label: string;
  disabled: boolean;
  icon: React.ReactNode;
  children?: Pick<MenuItem, "label" | "to">[];
};

const items: MenuItem[] = [
  {
    to: "/profile/me",
    label: "Profile",
    disabled: false,
    icon: <PersonOutlineOutlinedIcon />,
  },
  {
    to: "/profile/approvals",
    label: "Approvals",
    disabled: false,
    icon: <CheckCircleOutlineOutlinedIcon />,
    children: [
      {
        to: "/profile/approvals/pending-my-approval",
        label: "Pending my approval",
      },
      {
        to: "/profile/approvals/created-by-me",
        label: "Created by me",
      },
      {
        to: "/profile/approvals/watching",
        label: "Watching",
      },
    ],
  },
];

export const Profile: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <MainLayout fullWidth fullHeight className={classes.root}>
      <Grid container height="100%">
        <Grid item xs={2} className={classes.sidebar}>
          <List>
            {items.map((item: MenuItem) => (
              <>
                <ListItem disablePadding key={item.label}>
                  <ListItemButton
                    selected={`${location.pathname}${location.search}` === item.to}
                    onClick={() => {
                      navigate(item.to);
                    }}
                    disabled={item.disabled}>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <Hidden mdDown>
                      <ListItemText>{item.label}</ListItemText>
                    </Hidden>
                  </ListItemButton>
                </ListItem>
                {item.children?.map((child) => (
                  <ListItem disablePadding key={child.label}>
                    <ListItemButton
                      selected={`${location.pathname}${location.search}` === child.to}
                      onClick={() => {
                        navigate(child.to);
                      }}
                      disabled={item.disabled}>
                      <ListItemText className={classes.childItemLabel}>{child.label}</ListItemText>
                    </ListItemButton>
                  </ListItem>
                ))}
              </>
            ))}
          </List>
        </Grid>
        <Grid item xs={10} className={classes.content}>
          <Routes>
            <Route path="/me" element={<UserInformation />} />
            <Route path="/approvals/pending-my-approval" element={<PendingApprovals />} />
            <Route path="/approvals/created-by-me" element={<ApprovalsCreatedByMe />} />
            <Route path="/approvals/watching" element={<ApprovalsWatching />} />
            <Route
              path="/approvals/*"
              element={<Navigate to="/profile/approvals/pending-my-approval" />}
            />

            <Route path="*" element={<Navigate to="/profile/me" />} />
          </Routes>
        </Grid>
      </Grid>
    </MainLayout>
  );
};
