import React, { useCallback, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@providers/Mui";
import { Form } from "@components/Form";
import { FieldsList } from "./FieldsList";
import { CustomFieldForm } from "./CustomFieldForm";
import { CustomFieldForm as CustomFieldFormType } from "./types";
import { customFieldValidator } from "@pages/Admin/CustomFields/customFieldValidator";
import { useAsync } from "@hooks/useAsync";
import {
  createCustomField as createCustomFieldCloudFunction,
  updateCustomField as updateCustomFieldCloudFunction,
  CustomField,
  useListCustomFields,
} from "@cloud-functions";
import { useSnackbar } from "@providers/Snackbar";
import { customFieldToFormState, formStateToInput } from "./utils";
import { Button } from "@components/Button";
import { Blocker } from "@components/Blocker";
import { logError } from "@lib/logger";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    height: "100%",
  },
  content: {
    height: "100%",
  },
  newField: {
    color: theme.palette.info.main,
    textTransform: "none",
    width: "100%",
    marginTop: theme.spacing(1),
  },
}));

export const CustomFields: React.FC = () => {
  const classes = useStyles();
  const [customFields, customFieldsStatus] = useListCustomFields();
  const [selectedField, setSelectedField] = useState<null | CustomField>(null);
  const { showError } = useSnackbar();
  const [createCustomField, createCustomFieldState] = useAsync(
    createCustomFieldCloudFunction,
  );
  const [updateCustomField, updateCustomFieldState] = useAsync(
    updateCustomFieldCloudFunction,
  );

  const handleSubmit = async (values: CustomFieldFormType) => {
    try {
      if (selectedField?.id) {
        const updatedField = await updateCustomField({
          input: {
            id: selectedField.id,
            updates: {
              id: selectedField.id,
              ...formStateToInput(values),
            },
          },
        });
        customFieldsStatus.refetch();
        setSelectedField(updatedField);
      } else {
        const customField = await createCustomField({
          input: formStateToInput(values),
        });
        customFieldsStatus.refetch();
        setSelectedField(customField);
      }
    } catch (err) {
      logError(err);

      showError(
        <Typography variant="body2">
          Something went wrong during saving custom field
        </Typography>,
      );
    }
  };

  const handleSelectField = useCallback((customField: CustomField) => {
    setSelectedField(customField);
  }, []);

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={3}>
        <FieldsList
          customFields={customFields ?? []}
          onSelect={handleSelectField}
          activeField={selectedField?.id}
        />
        <Button
          className={classes.newField}
          onClick={() => setSelectedField(null)}>
          Create custom field
        </Button>
      </Grid>
      <Grid item xs={9} className={classes.content}>
        <Form<CustomFieldFormType>
          formMode="create"
          onSubmit={handleSubmit}
          className={classes.content}
          resolver={customFieldValidator}
          defaultValues={customFieldToFormState(selectedField)}
          key={selectedField?.id}>
          <CustomFieldForm />
        </Form>
      </Grid>
      <Blocker
        open={
          createCustomFieldState.isLoading || updateCustomFieldState.isLoading
        }
      />
    </Grid>
  );
};
