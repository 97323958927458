import { Typography } from "@mui/material";
import React from "react";
import { makeStyles } from "@providers/Mui";
import { ApprovalRule } from "./ApprovalRule";
import clsx from "clsx";
import { useTicketApproval } from "./TicketApprovalProvider";

type ApprovalRulesProps = {};

export const ApprovalRules: React.FC<ApprovalRulesProps> = () => {
  const { hasNoApprovalRules, activeRuleIndex, rules } = useTicketApproval();
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, hasNoApprovalRules && classes.none)}>
      {hasNoApprovalRules && <Typography>Ticket doesn't require any approval</Typography>}

      {rules.map((rule, index) => (
        <ApprovalRule key={rule.id} rule={rule} active={activeRuleIndex === index} />
      ))}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {},
  none: {
    padding: theme.spacing(2),
    borderRadius: 5,
    backgroundColor: theme.palette.green[100],
  },
}));
