import {
  CreateApprovalFeedbackRequest,
  CreateApprovalFeedbackResponse,
} from "@functions-types";
import { makeCloudFunction } from "../makeCloudFunction";

export const createApprovalFeedback = makeCloudFunction<
  CreateApprovalFeedbackRequest,
  CreateApprovalFeedbackResponse
>("ticket", "createApprovalFeedback");
