import React from "react";
import { makeStyles } from "@providers/Mui";
import DeleteIcon from "@mui/icons-material/Delete";
import { Attachment } from "@functions-types";
import { CircularProgress, Grid, IconButton, Link } from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { useDownloading } from "@hooks/useDownloading";

type AttachmentCardProps = Attachment & {
  onDelete?: (fileRef: string, fileName: string) => void;
};

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1, 0),
    "&:hover $delete": {
      opacity: 1,
    },
  },
  iconArea: {
    width: 18,
    height: 18,
    marginRight: theme.spacing(1),
  },
  icon: {
    fontSize: 18,
    color: theme.palette.grey[700],
  },
  link: {
    cursor: "pointer",
    fontSize: 15,
  },
  delete: {
    opacity: 0,
    transition: "opacity 0.2s",
    fontSize: 18,
    marginLeft: theme.spacing(1),
    color: theme.palette.grey[700],
  },
}));

export const AttachmentCard: React.FC<AttachmentCardProps> = ({
  fileName,
  fileRef,
  onDelete,
}) => {
  const classes = useStyles();
  const { download, loading } = useDownloading();

  const handleClick = async () => {
    const url = await download(fileRef);
    window.open(url, "_blank");
  };

  return (
    <Grid container className={classes.root} alignItems="center">
      <div className={classes.iconArea}>
        {loading ? (
          <CircularProgress size={18} color="primary" />
        ) : (
          <AttachFileIcon className={classes.icon} />
        )}
      </div>
      <Link className={classes.link} onClick={handleClick}>
        {fileName}
      </Link>
      {onDelete && (
        <IconButton
          className={classes.delete}
          onClick={() => onDelete(fileRef, fileName)}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      )}
    </Grid>
  );
};
