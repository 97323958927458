import { useMemo } from "react";
import {
  Country,
  Severity,
  Status,
  useListCountries,
  useListStatuses,
  useListSeverity,
  useListCTITree,
  CTITree,
} from "@cloud-functions";

export type UseTicketLookupsResult = {
  loading: boolean;
  severity: Severity[];
  countries: Country[];
  status: Status[];
  statusesMap: Record<string, Status>;
  categories: CTITree[];
};

export const useTicketLookups = (): UseTicketLookupsResult => {
  const [severityData, { loading: severityLoading }] = useListSeverity();
  const [ctiTree, { loading: ctiTreeLoading }] = useListCTITree();
  const [countries, { loading: countriesLoading }] = useListCountries();
  const [status, { loading: statusesLoading }] = useListStatuses();

  const loading = severityLoading || ctiTreeLoading || statusesLoading || countriesLoading;

  const statusesMap = useMemo(() => {
    return (status || []).reduce(
      (acc, cur) => ({
        ...acc,
        [cur.id]: cur,
      }),
      {},
    );
  }, [status]);

  return {
    loading,
    statusesMap,
    severity: severityData ?? [],
    countries: countries ?? [],
    status: status ?? [],
    categories: ctiTree ?? [],
  };
};
