import { getMySpaces } from "@cloud-functions/me/spaces";
import { GetMeResponse, GetMySpacesResponse, Space } from "@functions-types";
import { getItem, setItem } from "@lib/localstorage";
import { useUser } from "@providers/Auth";
import { createContext, FC, useCallback, useContext, useEffect, useMemo, useState } from "react";

type SpacesContextValue = {
  spaces: Space[];
  selectedSpace: Space | null;
  setSelectedSpace: (space: Space) => void;
  refetch: () => Promise<Space[]>;
  setSelectedSpaceByKey: (key: string) => void;
  loading: boolean;
};

const SpacesContext = createContext<SpacesContextValue>({
  spaces: [],
  selectedSpace: null,
  setSelectedSpace: () => {},
  refetch: () => Promise.resolve([]),
  setSelectedSpaceByKey: () => {},
  loading: false,
});

function getUserSpacesFromCache(user: GetMeResponse | null): GetMySpacesResponse | null {
  if (!user) return null;

  const userSpaces = getItem("userSpacesCache");

  if (user && userSpaces?.[user.id]) {
    return userSpaces?.[user.id];
  }

  return null;
}

function setUserSpacesToCache(user: GetMeResponse | null, spaces: GetMySpacesResponse) {
  if (!user) return;

  setItem("userSpacesCache", {
    ...getItem("userSpacesCache"),
    [user.id]: spaces,
  });
}

export const SpacesProvider: FC = ({ children }) => {
  const { user } = useUser();
  const [spaces, setSpaces] = useState<Space[] | null>(() => {
    return getUserSpacesFromCache(user);
  });

  const [selectedSpace, _setSelectedSpace] = useState<Space | null>(() => {
    if (!user || !spaces) return null;
    const userSelectedSpaces = getItem("userSelectedSpaceCache");
    if (!userSelectedSpaces) return null;
    const selectedSpaceId = userSelectedSpaces[user.id];
    if (!selectedSpaceId) return null;
    return spaces.find((space) => space.id === selectedSpaceId) ?? null;
  });

  const fetch = useCallback(async () => {
    const spaces = await getMySpaces();
    setSpaces(spaces);
    setUserSpacesToCache(user, spaces);
    return spaces;
  }, []);

  useEffect(() => {
    if (spaces?.length && !selectedSpace) {
      setSelectedSpace(spaces[0]);
    }
  }, [spaces, selectedSpace]);

  useEffect(() => {
    fetch();
  }, []);

  const setSelectedSpace = useCallback((space: Space) => {
    _setSelectedSpace(space);

    if (user) {
      setItem("userSelectedSpaceCache", {
        ...getItem("userSelectedSpaceCache"),
        [user.id]: space.id,
      });
    }
  }, []);

  const setSelectedSpaceByKey = useCallback((key: string) => {
    const space = spaces?.find((s) => s.key === key);
    if (!space) return;
    setSelectedSpace(space);
  }, []);

  const value = useMemo(
    () => ({
      spaces: spaces ?? [],
      loading: spaces === null,
      refetch: fetch,
      selectedSpace,
      setSelectedSpace,
      setSelectedSpaceByKey,
    }),
    [spaces, selectedSpace],
  );

  return <SpacesContext.Provider value={value}>{children}</SpacesContext.Provider>;
};

export const useSpaces = () => useContext(SpacesContext);
