import {
  ApprovalOverallState,
  TicketApprovalRule,
  TicketApprovalRules,
} from "../types";
import { approvalRulesToArray } from "./approvalRulesToArray";

export function ruleIsRejected(rule: TicketApprovalRule): boolean {
  return Object.values(rule.feedbacks).some(
    (feedback) => feedback.result === "rejected",
  );
}

export function ruleIsFullfilled(rule: TicketApprovalRule): boolean {
  if (rule.type === "manager_1" || rule.type === "manager_2") {
    const approvals = Object.values(rule.feedbacks).filter(
      (feedback) => feedback.result === "approved",
    );
    return approvals.length >= 1;
  }

  const requiredApprovalsCount = rule.count;
  const approvalsCount = Object.values(rule.feedbacks).filter(
    (feedback) => feedback.result === "approved",
  ).length;

  return approvalsCount >= requiredApprovalsCount;
}

export function getApprovalOverallState(
  approvalRules: TicketApprovalRules,
): ApprovalOverallState {
  const rules = approvalRulesToArray(approvalRules);

  const rulesStates = rules.map((rule) => {
    if (ruleIsRejected(rule)) return "rejected";
    if (ruleIsFullfilled(rule)) return "approved";
    return "pending";
  });

  if (rulesStates.some((r) => r === "rejected")) return "rejected";
  if (rulesStates.every((r) => r === "approved")) return "approved";
  return "pending";
}

export function getApprovalState(
  rule: TicketApprovalRule,
): ApprovalOverallState {
  if (ruleIsRejected(rule)) return "rejected";
  if (ruleIsFullfilled(rule)) return "approved";
  return "pending";
}
