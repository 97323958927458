import React from "react";
import { Grid, Typography } from "@mui/material";
import { H3 } from "@components/Typography";
import { makeStyles } from "@providers/Mui";
import { RatingField, TextField } from "@components/Form";
import { Button } from "@components/Button";
import { ReviewResult } from "../ReviewResult";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    width: "100%",
    maxWidth: 380,
  },
  description: {
    padding: theme.spacing(2, 0, 5),
  },
  feedbackContainer: {
    width: "100%",
    padding: theme.spacing(5, 0, 7),
  },
  feedback: {
    backgroundColor: "white",
    width: "100%",
  },
  button: {
    fontSize: 14,
    fontWeight: "bold",
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(0.5, 2),
    borderRadius: 6,
    lineHeight: "initial",
  },
  successMessage: {
    padding: theme.spacing(2, 0, 5.5),
  },
}));

type ReviewFormProps = {
  saved: boolean;
};

export const ReviewForm: React.FC<ReviewFormProps> = ({ saved }) => {
  const classes = useStyles();

  if (saved) {
    return (
      <ReviewResult title="Thanks!">
        <Typography variant="body1" className={classes.successMessage}>
          Your opinion is very important for us.
        </Typography>
        <RatingField
          name="rating"
          size="large"
          precision={1}
          defaultValue={1}
          disabled
        />
      </ReviewResult>
    );
  }

  return (
    <Grid className={classes.root} container direction="column" alignItems="center">
      <H3>Rate your experience</H3>
      <Typography variant="body1" className={classes.description}>
        How did we do?
      </Typography>
      <RatingField
        name="rating"
        size="large"
        precision={1}
        defaultValue={1}
      />
      <TextField
        name="feedback"
        placeholder="Comment"
        multiline
        rows={2}
        className={classes.feedback}
        FieldModeSwitchProps={{
          className: classes.feedbackContainer,
        }}
      />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        className={classes.button}
      >
        Publish Feedback
      </Button>
    </Grid>
  );
};
