import React, { useCallback, useEffect, useRef, useState } from "react";
import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";
import { OptionsTree } from "@functions-types";
import { TreeView } from "@mui/lab";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { TreeNode } from "./TreeNode";
import { AddNode } from "./AddNode";
import { NodeForm } from "./NodeForm";
import { Typography } from "@mui/material";
import { makeStyles } from "@providers/Mui";
import { useForm } from "@components/Form/FormProvider";
import { useWatch } from "react-hook-form";

const useStyles = makeStyles((theme) => ({
  emptyMessage: {
    color: theme.palette.grey[700],
    paddingLeft: theme.spacing(4),
    paddingBottom: theme.spacing(1),
    fontSize: 14,
  },
  error: {
    fontSize: 12,
    color: theme.palette.error.dark,
    paddingLeft: 14,
    paddingBottom: theme.spacing(1),
  },
}));

export const NestedSelect: React.FC = () => {
  const classes = useStyles();
  const optionsTree: OptionsTree[] | null = useWatch({ name: "optionsTree" });
  const { methods: { setValue, formState, trigger } } = useForm();
  const [tree, setTree] = useState<OptionsTree[]>(optionsTree ?? []);
  const [open, setOpen] = useState(false);
  const addNodeRef = useRef<HTMLButtonElement>(null);
  const handleAddNode = useCallback((value: string, path: string) => {
    if (!path) {
      setTree((tree) => ([
        ...tree,
        { value, children: [] },
      ]));
    } else {
      setTree((currentTree) => {
        const newTree = cloneDeep(currentTree);
        const branch = get(newTree, path) as OptionsTree | OptionsTree[];
        if (Array.isArray(branch)) {
          branch.push({
            value,
            children: [],
          });
        } else {
          branch.value = value;
        }

        return newTree;
      });
    }
  }, []);

  const handleDelete = useCallback((path: string) => {
    const pathParts = path.split(".");
    setTree((currentTree) => {
      if (pathParts.length < 2) {
        return currentTree.filter((_, idx) => idx !== Number(path));
      } else {
        const newTree = cloneDeep(currentTree);
        const index = Number(pathParts.pop());
        pathParts.pop();
        const path = pathParts.join(".");
        const branch = get(newTree, path) as OptionsTree;
        branch.children = branch.children.filter((_, idx) => idx !== index);
        return newTree;
      }
    })
  }, []);

  useEffect(() => {
    setValue("optionsTree", tree);
    trigger("optionsTree");
  }, [tree]);

  const error = formState.errors?.optionsTree?.message;

  return (
    <>
      {!tree.length && (
        <Typography className={classes.emptyMessage}>
          Values list is empty, please add at least one
        </Typography>
      )}
      <TreeView
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
      >
        {tree.map((node, idx) => (
          <TreeNode
            node={node}
            id={node.value}
            key={`${node.value}-${idx}`}
            path={`${idx}`}
            onSave={handleAddNode}
            level={1}
            onDelete={handleDelete}
          />
        ))}
        <AddNode id="add" _ref={addNodeRef} onClick={() => setOpen(true)} />
      </TreeView>
      <NodeForm
        path=""
        onSave={handleAddNode}
        open={open}
        onClose={() => setOpen(false)}
        anchorRef={addNodeRef}
      />
      {!!error && (
        <Typography className={classes.error}>
          {error}
        </Typography>
      )}
    </>
  );
};
