import {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { GetMeResponse } from "@functions-types";
import { useAsync } from "@hooks/useAsync";
import { getItem, setItem } from "@lib/localstorage";
import { getMe } from "@cloud-functions/me";
import * as Sentry from "@sentry/react";
import { useAuth } from "./AuthProvider";

function getUserFromCache(userId?: string): GetMeResponse | null {
  const usersCache = getItem("userCache");
  if (!usersCache || !userId) return null;
  return usersCache[userId];
}

function setUserToCache(userId?: string, user?: GetMeResponse) {
  if (!userId || !user) return;

  setItem("userCache", {
    ...getItem("userCache"),
    [userId]: user,
  });
}

type UserContextValue = {
  user: GetMeResponse;
};

const UserContext = createContext<UserContextValue>({
  user: null as any as GetMeResponse,
});

export const UserProvider: FC = ({ children }) => {
  const { user: firebaseUser } = useAuth();
  const [user, setUser] = useState<GetMeResponse>(() => {
    return getUserFromCache(firebaseUser?.id) as any as GetMeResponse;
  });
  const [fetchMe, fetchMeState] = useAsync(getMe);

  useEffect(() => {
    if (user) {
      Sentry.setUser({
        id: user.id,
        email: user.email,
        username: user.displayName,
      });
    }
  }, [user]);

  useEffect(() => {
    if (firebaseUser && !fetchMeState.isLoading) {
      (async () => {
        const user = await fetchMe();
        setUser(user);
        setUserToCache(firebaseUser?.id, user);
      })();
    }
  }, [firebaseUser]);

  const value: UserContextValue = useMemo(
    () => ({
      user,
    }),
    [user],
  );

  const isLoading = fetchMeState.isLoading || !user;

  return (
    <UserContext.Provider value={value}>
      {isLoading ? "Loading..." : children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
