import { DependencyList, useCallback, useEffect, useRef } from "react";
import { logMessage } from "@lib/logger";

type UseStatLoggerOptions = {
  timeframe: number;
};

type Fn = (...args: any) => any;

export const useStatLogger = <F extends Fn>(
  cb: F,
  message: string,
  deps: DependencyList,
  options?: UseStatLoggerOptions,
) => {
  const statRef = useRef<number>(0);

  const wrappedFn = useCallback((...args: Parameters<F>) => {
    statRef.current++;
    cb(...(args as any));
  }, deps);

  useEffect(() => {
    let timer: null | number = null;
    const handleStat = () => {
      if (timer) {
        clearTimeout(timer);
      }

      logMessage(`${message}: ${statRef.current}`);
      statRef.current = 0;
      timer = setTimeout(handleStat, options?.timeframe ?? 1000 * 60) as unknown as number;
    };
    handleStat();

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [options]);

  return wrappedFn;
};
