import React from "react";
import { makeStyles } from "@providers/Mui";
import { Link } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    color: theme.palette.primary.dark,
    wordBreak: "break-all",
  },
}));

type FaqProps = {
  url: string;
  label?: string | null;
};

export const Faq: React.FC<FaqProps> = ({ url, label }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Link href={url} target="_blank" variant="body2" color="inherit">
        {label ?? url}
      </Link>
    </div>
  );
};
