import { makeCloudFunction } from "../makeCloudFunction";
import { Country } from "@functions-types";
import { makeUseCloudFunction } from "../makeUseCloudFunction";

export type { Country };
export const listCountries = makeCloudFunction<void, Country[]>(
  "country",
  "listCountries",
);
export const useListCountries = makeUseCloudFunction(listCountries);
