import { Theme as MuiTheme } from "@mui/material";
import { ClassNameMap, makeStyles as muiMakeStyles, Styles } from "@mui/styles";

export function makeStyles<
  Props extends object = {},
  Theme = MuiTheme,
  ClassKey extends string = string,
>(
  styles: Styles<Theme, Props, ClassKey>,
): keyof Props extends never
  ? // `makeStyles` where the passed `styles` do not depend on props
    (props?: any) => ClassNameMap<ClassKey>
  : // `makeStyles` where the passed `styles` do depend on props
    (props: Props) => ClassNameMap<ClassKey> {
  return muiMakeStyles(styles);
}
