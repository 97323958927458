import React from "react";
import { Card } from "@components/Card";
import { makeStyles } from "@providers/Mui";
import { Grid, Skeleton, Typography } from "@mui/material";
import { H6 } from "@components/Typography";
import RateReviewIcon from '@mui/icons-material/RateReview';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2),
    },
  },
  icon: {
    color: theme.palette.secondary.main,
    fontSize: 36,
    marginBottom: theme.spacing(2),
  },
  message: {
    color: theme.palette.secondary.main,
    fontSize: 18,
    marginBottom: theme.spacing(2),
  },
  container: {
    width: "100%",
    paddingTop: theme.spacing(4),
  },
}));

export const EmptyReviewCard = () => {
  const classes = useStyles();
  return (
    <Card className={classes.root} container>
      <H6>Employee of last week</H6>
      <Grid className={classes.container} container alignItems="center" direction="column">
        <RateReviewIcon className={classes.icon} />
        <Typography variant="h4" className={classes.message}>
          Agents have no review yet
        </Typography>
      </Grid>
    </Card>
  );
};
