import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CustomPage as CustomPageType, Status, Ticket, User } from "@functions-types";
import {
  Table,
  TableBody,
  TableRow as MuiTableRow,
  TableContainer,
  TableCell,
} from "@mui/material";
import { TableRow } from "./TableRow";
import { makeStyles } from "@providers/Mui";
import { useInView } from "react-intersection-observer";
import {
  searchUsers,
  updateTicket as updateTicketCloudFunction,
  useListStatusesRequest,
} from "@cloud-functions";
import { useAsync } from "@hooks/useAsync";
import { useDispatch } from "@providers/EventsProvider";
import { GlobalTicketEvents, HasClassName } from "@lib/types";
import { Column, InteractionSettings } from "./types";
import { Header } from "./Header";
import { getTicketTransitions, Selectable } from "@lib/ticket";
import { isNonNullish } from "@lib/utils";
import { CustomPage } from "@containers/CustomPage";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
    width: "100%",
    overflowX: "auto",
  },
  ceil: {
    borderBottom: "none",
    padding: theme.spacing(1),
  },
}));

type TicketsTableProps = HasClassName & {
  tickets: Ticket[];
  fetchNext: () => void;
  onOpen: (ticket: Ticket) => void;
  columns: Column[];
  interactionSettings?: InteractionSettings;
  isFetching?: boolean;
};

export const TicketsTable: React.FC<TicketsTableProps> = ({
  tickets,
  fetchNext,
  onOpen,
  columns,
  interactionSettings,
  className,
  isFetching,
}) => {
  const classes = useStyles();
  const [statusesData, statusesState] = useListStatusesRequest();
  const [updateTicket] = useAsync(updateTicketCloudFunction, { reThrow: true });
  const dispatch = useDispatch<GlobalTicketEvents>();
  const [customPage, setCustomPage] = useState<CustomPageType | null>(null);
  const [ticket, setTicket] = useState<Ticket | null>(null);

  const { ref, inView } = useInView({
    initialInView: false,
  });

  useEffect(() => {
    if (interactionSettings?.changeStatus) {
      statusesState.refetch();
    }
  }, [interactionSettings]);

  const handleUpdateStatus = useCallback(
    async (ticketId: string, status: string) => {
      const ticket = tickets.find(({ id }) => id === ticketId);

      if (!ticket) {
        return;
      }

      const transitions = getTicketTransitions(ticket);
      const transition = transitions?.find(({ destinationId }) => destinationId === status);

      if (transition?.action?.customPage) {
        setCustomPage(transition.action.customPage);
        setTicket(ticket);
      }

      await updateTicket({
        input: {
          updates: {
            status,
          },
          id: ticketId,
        },
      });
      dispatch("update-tickets");
    },
    [tickets],
  );

  const handleUpdateAssignee = useCallback(async (ticketId: string, assignee: string) => {
    await updateTicket({
      input: {
        updates: {
          assignee,
        },
        id: ticketId,
      },
    });
    dispatch("update-tickets");
  }, []);

  useEffect(() => {
    if (inView && !isFetching) {
      fetchNext();
    }
  }, [inView, isFetching]);

  const userSearch = useCallback(async (query: string) => {
    const users = await searchUsers({
      query,
    });

    return (users.filter(Boolean) as NonNullable<User>[]).map((user) => ({
      id: user.id,
      label: user.displayName,
      email: user.email,
    }));
  }, []);

  const statusesMap: Record<string, Selectable<Status>[0]> = useMemo(() => {
    if (!statusesData?.length) return {};
    return statusesData.reduce(
      (acc, cur) => ({
        ...acc,
        [cur.id]: {
          id: cur.id,
          label: cur.label,
          item: cur,
        },
      }),
      {},
    );
  }, [statusesData]);

  // list of ticket with precalculated transitions
  const ticketsRows = useMemo(() => {
    if (!tickets?.length) return null;
    return tickets.map((ticket) => {
      const transitions = getTicketTransitions(ticket);
      const availableStatuses = transitions
        ? transitions
            .map(({ destinationId, action }) => statusesMap[destinationId])
            .filter(isNonNullish)
        : [];
      return (
        <TableRow
          ticket={ticket}
          key={ticket.id}
          onOpen={() => onOpen(ticket)}
          statuses={availableStatuses}
          onStatusUpdate={handleUpdateStatus}
          onAssigneeUpdate={handleUpdateAssignee}
          userSearch={userSearch}
          columns={columns}
          interactionSettings={interactionSettings}
        />
      );
    });
  }, [tickets, statusesMap]);

  const handleClosePopup = useCallback(() => {
    setTicket(null);
    setCustomPage(null);
  }, []);

  const handleCustomPageUpdate = useCallback(() => {
    dispatch("update-tickets");
    handleClosePopup();
  }, []);

  if (!ticketsRows) return null;

  return (
    <>
      <TableContainer className={clsx(classes.container, className)}>
        <Table stickyHeader>
          <Header columns={columns} />
          <TableBody>
            {ticketsRows}
            <MuiTableRow ref={ref}>
              <TableCell className={classes.ceil} colSpan={columns.length}>
                {isFetching ? (
                  <>
                    Loading... (Loaded <strong>{ticketsRows.length}</strong> tickets)
                  </>
                ) : (
                  `Loaded all (${ticketsRows.length}) tickets matching current filters.`
                )}
              </TableCell>
            </MuiTableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {ticket && customPage && (
        <CustomPage
          ticket={ticket}
          customPage={customPage}
          onSave={handleCustomPageUpdate}
          onClose={handleClosePopup}
        />
      )}
    </>
  );
};
