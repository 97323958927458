import { httpsCallable } from "firebase/functions";
import { functions } from "@@firebase";
import { CloudFunctionName } from "@functions-types";
import { eventBus } from "@lib/eventBus";
import { logError } from "@lib/logger";

export type CloudFunction<RequestData, ResponseData> = (
  params: RequestData,
) => Promise<ResponseData>;

type FunctionGroup =
  | "auth"
  | "admin"
  | "severity"
  | "space"
  | "ticket"
  | "ticketLink"
  | "category"
  | "type"
  | "item"
  | "ctiTree"
  | "group"
  | "country"
  | "user"
  | "me"
  | "status"
  | "activity"
  | "history"
  | "customField"
  | "review"
  | "automation"
  | "customPage"
  | "store"
  | "workflow";

export function makeCloudFunction<
  RequestData extends object | void = void,
  ResponseData extends object | void = void,
>(group: FunctionGroup, callableName: CloudFunctionName): CloudFunction<RequestData, ResponseData> {
  const callable = httpsCallable<RequestData, ResponseData>(functions, `${group}-${callableName}`);

  return async (params: RequestData) => {
    try {
      const { data: result } = await callable(params);

      return result;
    } catch (err) {
      logError(err);
      const code = (err as any).code;

      if (code === "functions/unauthenticated") {
        eventBus.emit("unAuthenticatedCloudFunctionError");
      }

      throw err;
    }
  };
}
