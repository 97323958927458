import { User } from "firebase/auth";
import { createContext, FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { noop } from "lodash";
import { auth } from "@@firebase";
import { signInWithCustomToken } from "firebase/auth";
import * as google from "@lib/google";
import { eventBus } from "@lib/eventBus";
import { useAsync } from "@hooks/useAsync";
import { RiderLoginProvider } from "@providers/RiderLoginProvider";
import * as Sentry from "@sentry/react";
import { createCypressCustomToken } from "@cloud-functions/auth";
import { config } from "../../config";

type FirebaseUser = User & {
  id?: string;
};

type AuthContextValue = {
  user: FirebaseUser | null;
  isLoggedIn: boolean;
  login: () => void;
  cypressLogin: () => void;
  logout: () => void;
};

const AuthContext = createContext<AuthContextValue>({
  user: null,
  isLoggedIn: false,
  login: noop,
  cypressLogin: noop,
  logout: noop,
});

// this is used only to show loading while we are waiting for first onAuthStateChanged invocation
const INITIAL_USER_VALUE = {};

export const AuthProvider: FC = ({ children }) => {
  const [user, setUser] = useState<FirebaseUser | null | typeof INITIAL_USER_VALUE>(
    INITIAL_USER_VALUE,
  );
  const [fetchCustomToken, fetchCustomTokenState] = useAsync(createCypressCustomToken);

  useEffect(() => {
    if (!user) {
      // we set actual user from UserProvider
      // here, we only clear it out
      Sentry.setUser(null);
    }
  }, [user]);

  const logout = useCallback(() => {
    setUser(null);
    google.logout();
  }, []);

  const cypressLogin = useCallback(async () => {
    if (fetchCustomTokenState.isLoading) {
      return;
    }

    const { token } = await fetchCustomToken({
      uid: config.cypressUserId ?? "",
    });

    await signInWithCustomToken(auth, token);
  }, []);

  useEffect(() => {
    auth.onAuthStateChanged((u) => {
      if (!u) {
        return setUser(null);
      }

      const id: string | undefined = (
        u.providerData?.find((p) => p.providerId === "google.com") ??
        u.providerData?.find((p) => p.providerId === "phone")
      )?.uid?.replace(/\s/g, "");

      const firebaseUser = {
        id,
        ...u,
      };
      setUser(firebaseUser);
    });

    eventBus.on("unAuthenticatedCloudFunctionError", () => {
      logout();
    });
  }, []);

  const value: AuthContextValue = useMemo(
    () => ({
      user: user === INITIAL_USER_VALUE ? null : (user as User | null),
      isLoggedIn: Boolean(user),
      login: google.login,
      cypressLogin,
      logout,
    }),
    [user, logout],
  );

  const isLoading = user === INITIAL_USER_VALUE;

  return (
    <AuthContext.Provider value={value}>
      <RiderLoginProvider>{isLoading ? "Loading..." : children}</RiderLoginProvider>
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
