import { makeCloudFunction } from "../makeCloudFunction";
import {
  CreateCypressCustomTokenRequest,
  CreateCypressCustomTokenResponse,
} from "@functions-types";

export const createCypressCustomToken = makeCloudFunction<
  CreateCypressCustomTokenRequest,
  CreateCypressCustomTokenResponse
>("auth", "createCypressCustomToken");
