import React, { useEffect, useMemo, useState } from "react";
import { makeStyles } from "@providers/Mui";
import { Label } from "@components/Label";
import { Automation, useListCustomFields } from "@cloud-functions";
import { Typography } from "@mui/material";
import { useWatch } from "react-hook-form";
import { Blocker } from "@components/Blocker";
import { CustomFieldForm } from "./CustomFieldForm";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  list: {
    padding: theme.spacing(1, 0, 2),
  },
  empty: {
    padding: theme.spacing(2),
    color: theme.palette.secondary.main,
    textAlign: "center",
  },
  label: {
    padding: theme.spacing(0, 0, 1),
  },
}));

export const CustomFieldsSelector: React.FC = () => {
  const classes = useStyles();
  const [customFields, { loading }] = useListCustomFields();
  const currentCustomFields: Automation["customFields"] | null = useWatch({
    name: "customFields"
  });

  const filteredCustomFields = useMemo(
    () => (currentCustomFields ?? []).filter(({ customField }) => !!customField?.id),
    [currentCustomFields]
  );

  return (
    <div className={classes.root}>
      <Label label="Custom fields" />
      <div className={classes.list}>
        {filteredCustomFields.map(({ customField, value }) => (
          <CustomFieldForm
            key={customField?.id}
            customFields={customFields ?? []}
            currentField={customField ?? undefined}
            customFieldsValues={currentCustomFields}
          />
        ))}
        {!filteredCustomFields.length && (
          <Typography className={classes.empty}>
            No custom fields added
          </Typography>
        )}
      </div>

      <Label label="New custom field condition" className={classes.label} />
      <CustomFieldForm
        customFields={customFields ?? []}
        customFieldsValues={currentCustomFields}
      />
      <Blocker open={loading} />
    </div>
  );
};
