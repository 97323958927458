import * as Sentry from "@sentry/react";
import { config } from "../config";

export function logError(err: any, ...rest: any[]) {
  if (config.env !== "local") {
    Sentry.captureException(err);
  }

  console.error(err, ...rest);
}

export function logMessage(message: string) {
  if (config.env !== "local") {
    Sentry.captureMessage(message);
  }

  console.log(message);
}
