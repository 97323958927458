import React from "react";
import { TicketApprovalProvider, TicketApprovalProviderProps } from "./TicketApprovalProvider";
import { ApprovalRules } from "./ApprovalRules";

type ApprovalRulesProps = TicketApprovalProviderProps & {};

export const TicketApprovals: React.FC<ApprovalRulesProps> = (providerProps) => {
  return (
    <TicketApprovalProvider {...providerProps}>
      <ApprovalRules />
    </TicketApprovalProvider>
  );
};
