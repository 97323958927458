import { makeCloudFunction } from "../makeCloudFunction";
import { Automation, ListAutomationsResponse } from "@functions-types";
import { makeUseCloudFunction } from "../makeUseCloudFunction";

export type { Automation };
export const listAutomations = makeCloudFunction<void, ListAutomationsResponse>(
  "automation",
  "listAutomations",
);
export const useListAutomations = makeUseCloudFunction(listAutomations);
