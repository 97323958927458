import React, { useState } from "react";
import { makeStyles } from "@providers/Mui";
import { TextField } from "@components/Form";
import { Grid, IconButton, Typography } from "@mui/material";
import { last } from "lodash";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Button } from "@components/Button";
import { useForm } from "@components/Form/FormProvider";
import { useWatch } from "react-hook-form";

const useStyles = makeStyles((theme) => ({
  root: {},
  field: {
    marginBottom: theme.spacing(1),
    minWidth: 300,
    "& .MuiOutlinedInput-root": {
      backgroundColor: "white",
    },
  },
  label: {
    fontSize: 14,
    fontWeight: "bold",
    paddingBottom: theme.spacing(1),
    color: "#565656",
  },
  remove: {
    flexShrink: 0,
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  error: {
    fontSize: 12,
    color: theme.palette.error.dark,
    paddingLeft: 14,
    paddingBottom: theme.spacing(1),
  },
}));

export const OptionsList: React.FC = () => {
  const classes = useStyles();
  const optionsList = useWatch({ name: "options" });
  const [options, setOptions] = useState(
    Array(optionsList?.length ?? 1).fill(0).map((_, i) => i)
  );
  const { methods: { setValue, formState, trigger } } = useForm();

  const handleAddOption = () => {
    setOptions((options) => [...options, (last(options) || 0) + 1]);
  };

  const handleRemoveOption = (optionId: number) => {
    setValue(`options[${optionId}]`, "");
    setOptions((options) => options.filter((option) => option !== optionId));
  };

  const error = formState.errors?.options?.message;

  return (
    <div className={classes.root}>
      <Typography className={classes.label}>
        Options
      </Typography>
      {options.map((option) => (
        <Grid container key={option}>
          <TextField
            name={`options[${option}]`}
            variant="outlined"
            fullWidth
            className={classes.field}
            onChange={() => trigger("options")}
          />
          <IconButton
            className={classes.remove}
            disableRipple
            color="warning"
            onClick={() => handleRemoveOption(option)}
          >
            <RemoveCircleOutlineIcon />
          </IconButton>
        </Grid>
      ))}
      {!!error && (
        <Typography className={classes.error}>
          {error}
        </Typography>
      )}
      <Button onClick={handleAddOption} variant="contained">
        Add option
      </Button>
    </div>
  );
};
