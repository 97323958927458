import { useMemo } from "react";
import ReactFlow, { Controls } from "react-flow-renderer";
import { makeStyles } from "@providers/Mui";
import { WorkflowStatus } from "@functions-types";
import { buildNodes, buildEdges, getLayoutedElements } from "./utils";

export type VisualizationProps = {
  workflowItems: WorkflowStatus[];
};

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    height: 600,
  },
}));

export const Visualization: React.FC<VisualizationProps> = ({
  workflowItems,
}) => {
  const classes = useStyles();
  const itemNodes = useMemo(() => buildNodes(workflowItems), [workflowItems]);
  const itemEdges = useMemo(() => buildEdges(workflowItems), [workflowItems]);
  const { nodes, edges } = useMemo(
    () => getLayoutedElements(itemNodes, itemEdges),
    [itemNodes, itemEdges],
  );

  return (
    <div className={classes.container}>
      <ReactFlow nodes={nodes} edges={edges} fitView>
        <Controls />
      </ReactFlow>
    </div>
  );
};
