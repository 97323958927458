import { makeCloudFunction } from "./makeCloudFunction";

export * from "./severity";
export * from "./ticket";
export * from "./ticketLink";
export * from "./category";
export * from "./country";
export * from "./group";
export * from "./item";
export * from "./type";
export * from "./user";
export * from "./auth";
export * from "./status";
export * from "./activity";
export * from "./space";
export * from "./history";
export * from "./customField";
export * from "./review";
export * from "./auth";
export * from "./automation";
export * from "./customPage";
export * from "./workflow";
export * from "./store";
export * from "./ctiTree";

const populate10TicketsCloudFunction = makeCloudFunction<void, any>(
  "ticket",
  "populate10Tickets" as any,
);

const customFieldsMigrationCloudFunction = makeCloudFunction<void, any>(
  "ticket",
  "customFieldsMigration" as any,
);

(window as any).populateAllTickets = async () => {
  let count = 0;
  let total = 0;

  do {
    count = await populate10TicketsCloudFunction();
    total += count;

    if (count) {
      console.log("Finished patch, populating next batch...");
    }
  } while (count > 0);

  console.log(`Done: Populated ${total} tickets`);
};

(window as any).migrateAllCustomFieldsInTickets = async () => {
  let count = 0;
  let total = 0;

  do {
    count = await customFieldsMigrationCloudFunction();
    total += count;

    if (count) {
      console.log("Finished patch, migrating next batch...");
    }
  } while (count > 0);

  console.log(`Done: Migrated ${total} tickets with custom fields`);
};

const assignStoreFor10TicketsCloudFunction = makeCloudFunction<void, any>(
  "ticket",
  "assignStoreFor10Tickets" as any,
);
(window as any).assignStoreFor10Tickets = async () => {
  let count = 0;
  let total = 0;

  do {
    count = await assignStoreFor10TicketsCloudFunction();
    total += count;

    if (count) {
      console.log("Finished patch, next batch...");
    }
  } while (count > 0);

  console.log(`Done: handled ${total} tickets`);
};

const populate10ItemsCloudFunction = makeCloudFunction<void, any>("item", "populate10Items" as any);
const migrateWatchersFor10TicketsCloudFunction = makeCloudFunction<void, any>(
  "ticket",
  "migrateWatchersFor10Tickets" as any,
);

(window as any).migrateWatchersFor10Tickets = async () => {
  let count = 0;
  let total = 0;

  do {
    count = await migrateWatchersFor10TicketsCloudFunction();
    total += count;

    if (count) {
      console.log("Finished patch, next batch...");
    }
  } while (count > 0);

  console.log(`Done: handled ${total} tickets`);
};

(window as any).populateAllItems = async () => {
  let count = 0;
  let total = 0;

  do {
    count = await populate10ItemsCloudFunction();
    total += count;

    if (count) {
      console.log("Finished patch, populating next batch...");
    }
  } while (count > 0);

  console.log(`Done: Populated ${total} items`);
};

(window as any).populateAllSpaces = makeCloudFunction("space", "populateAllSpaces" as any);
(window as any).populateAllStores = makeCloudFunction("store", "populateAllStores" as any);

(window as any)._migrateTickets = makeCloudFunction("ticket", "ticketsMigration" as any);
(window as any)._resolveTicketsMigration = makeCloudFunction(
  "ticket",
  "resolveTicketsMigration" as any,
);
(window as any)._migrateReviews = makeCloudFunction("review", "reviewMigration" as any);
(window as any)._migrateGroups = makeCloudFunction("group", "groupMigration" as any);
(window as any)._indexAllUsers = makeCloudFunction("user", "indexAllUsers" as any);
(window as any)._indexAllSpaces = makeCloudFunction("space", "indexAllSpaces" as any);
(window as any)._indexAllTickets = makeCloudFunction("ticket", "indexAllTickets" as any);
(window as any)._markTicketsAsUnindexed = makeCloudFunction("ticket", "markAsNotIndexed" as any);
(window as any)._indexTicketsPartially = makeCloudFunction(
  "ticket",
  "indexTicketsPartially" as any,
);
(window as any)._createWorkflowsMigration = makeCloudFunction(
  "workflow",
  "createDefaultWorkflowsMigration" as any,
);
(window as any)._fixRidersActiveStatus = makeCloudFunction("user", "fixRidersActiveStatus" as any);
(window as any)._spacesMigration = makeCloudFunction("space", "spacesMigration" as any);
