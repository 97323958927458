import {
  Category,
  UpdateCategoryRequest,
  UpdateCategoryResponse,
} from "@functions-types";
import { makeCloudFunction } from "../makeCloudFunction";

export type { Category };
export const updateCategory = makeCloudFunction<
  UpdateCategoryRequest,
  UpdateCategoryResponse
>("category", "updateCategory");
