import { makeCloudFunction } from "../../makeCloudFunction";
import {
  GetSpaceReviewRequest,
  GetSpaceReviewResponse,
} from "@functions-types";
import { makeUseCloudFunction } from "@cloud-functions/makeUseCloudFunction";

export const getSpaceReview = makeCloudFunction<
  GetSpaceReviewRequest,
  GetSpaceReviewResponse
>("space", "getSpaceReview");

export const useGetSpaceReview = makeUseCloudFunction(getSpaceReview);
