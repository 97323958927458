import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

export const statusValidator = yupResolver(
  yup.object().shape({
    label: yup.string().required("Label is a required field"),
    order: yup
      .number()
      .nullable()
      .required("Order is required field")
      .min(0, "Order can't be less than 0"),
    color: yup.object().nullable().required("Please select color"),
  }),
);