import React from "react";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import WarningIcon from "@mui/icons-material/Warning";
import { Grid, SvgIconProps, Typography } from "@mui/material";
import { Autocomplete, Form, TextField } from "@components/Form";
import { useAsync } from "@hooks/useAsync";
import {
  createItem as createItemCloudFunction,
  updateItem as updateItemCloudFunction,
  Group,
  Space,
  Severity,
  Item,
  CustomField as CustomFieldType,
} from "@cloud-functions";
import { Category, Type } from "@functions-types";
import { makeStyles } from "@providers/Mui";
import { Blocker } from "@components/Blocker";
import { Button } from "@components/Button";
import { itemValidation } from "./validationSchema";
import { useSnackbar } from "@providers/Snackbar";
import { fieldsMap } from "@lib/constants";
import { CustomField } from "@pages/Admin/CTI/CustomField";
import { Caption, H6 } from "@components/Typography";
import { SlaField } from "./SlaField";
import { ItemFormState } from "./types";
import { itemFormStateToValue } from "./utils";
import { UserTypeRestrictionField } from "../UserTypeRestrictionField";
import { logError } from "@lib/logger";
import { ApprovalField } from "../ApprovalField";
import { useApprovalsFeature } from "@hooks/featureFlags";

type ItemFormProps = {
  groups: Group[];
  defaultValues?: ItemFormState & { id?: string };
  category: Category;
  type: Type;
  onSave: (item: Item) => void;
  onClose?: () => void;
  teams: Group[];
  spaces: Space[];
  severity: Severity[];
  customFields: CustomFieldType[];
  onSelect?: (item: Item) => void;
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[200],
    position: "relative",
  },
  header: {
    fontSize: 16,
    fontWeight: "bold",
    paddingBottom: theme.spacing(1),
  },
  field: {
    "& .MuiOutlinedInput-root": {
      backgroundColor: "white",
    },
  },
  close: {
    marginLeft: theme.spacing(1),
  },
}));

export const ItemForm: React.FC<ItemFormProps> = ({
  defaultValues,
  onSave,
  onClose,
  category,
  type,
  spaces,
  severity,
  customFields,
  onSelect,
  groups,
}) => {
  const classes = useStyles();
  const approvalsFeatureEnabled = useApprovalsFeature();
  const { showError } = useSnackbar();
  const [createItem, createItemState] = useAsync(createItemCloudFunction);
  const [updateItem, updateItemState] = useAsync(updateItemCloudFunction, {
    reThrow: true,
  });
  const isEdit = !!defaultValues?.id;

  const handleSubmit = async (values: ItemFormState) => {
    try {
      if (defaultValues?.id) {
        const item = await updateItem({
          input: {
            id: defaultValues.id,
            updates: {
              ...itemFormStateToValue(values),
              type: type.id,
            },
          },
        });
        onSave(item);
      } else {
        const item = await createItem({
          input: {
            ...itemFormStateToValue(values),
            type: type.id,
          },
        });
        onSelect?.(item);
        onSave(item);
      }
      onClose?.();
    } catch (err) {
      logError(err);

      showError(<Typography variant="body2">Something went wrong during saving item</Typography>);
    }
  };

  return (
    <Form<ItemFormState>
      formMode="create"
      onSubmit={handleSubmit}
      defaultValues={defaultValues}
      className={classes.root}
      resolver={itemValidation}>
      {defaultValues?.name && (
        <>
          <Caption>Item</Caption>
          <Typography className={classes.header}>{defaultValues.name}</Typography>
        </>
      )}
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <TextField
            name="name"
            label="Name"
            variant="outlined"
            fullWidth
            size="small"
            className={classes.field}
          />
        </Grid>
        {approvalsFeatureEnabled && (
          <Grid item>
            <ApprovalField className={classes.field} mode="item" groups={groups} />
          </Grid>
        )}
        <Grid item>
          <UserTypeRestrictionField className={classes.field} category={category} type={type} />
        </Grid>
        <Grid item>
          <Autocomplete
            name="space"
            label={<Label label={fieldsMap.space} Icon={BookmarksIcon} />}
            options={spaces}
            saveOnChange={false}
            inputClassName={classes.field}
            getOptionText={(option) => option.name}
          />
        </Grid>
        <Grid item>
          <H6>SLA</H6>
          <SlaField name="firstResponseSLA" label="First response" />
          <SlaField name="nextResponseSLA" label="Next response" />
          <SlaField name="resolveSLA" label="Resolve" />
        </Grid>
        <Grid item>
          <Autocomplete
            name="default_severity"
            label={<Label label="Default severity" Icon={WarningIcon} />}
            options={severity}
            saveOnChange={false}
            inputClassName={classes.field}
            getOptionText={(option) => option.name}
          />
        </Grid>
        <Grid item>
          <CustomField customFields={customFields} />
        </Grid>
        <Grid item>
          <TextField
            name="faq_link"
            label="FAQ"
            placeholder="FAQ url"
            variant="outlined"
            fullWidth
            size="small"
            className={classes.field}
          />
        </Grid>
        <Grid item>
          <Button type="submit" variant="contained" size="small">
            {isEdit ? "Save item" : "Add item"}
          </Button>
          {onClose && (
            <Button
              type="button"
              onClick={onClose}
              variant="text"
              size="small"
              className={classes.close}>
              Cancel
            </Button>
          )}
        </Grid>
      </Grid>
      <Blocker open={updateItemState.isLoading || createItemState.isLoading} />
    </Form>
  );
};

const Label: React.FC<{
  label: string;
  Icon: React.ComponentType<SvgIconProps>;
}> = (props) => {
  const classes = useLabelStyles();

  return (
    <label className={classes.root}>
      <props.Icon fontSize="inherit" className={classes.icon} />
      {props.label}
    </label>
  );
};

const useLabelStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    fontSize: 14,
    fontWeight: "bold",
    color: "#565656",
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}));
