import React, { createContext, useContext, useMemo } from "react";

type Tokens = Record<string, true>;

type SearchContextValue = {
  tokens: Tokens;
  query: string;
};

const SearchContext = createContext<SearchContextValue>({
  query: "",
  tokens: {},
});

type SearchProviderProps = {
  query: string;
};

export const SearchProvider: React.FC<SearchProviderProps> = ({
  query,
  children,
}) => {
  const tokens = useMemo(() => {
    const queryTokens = query
      .toLowerCase()
      .replace(/([a-z]+)([\d]+)/i, (_, p1, p2) => {
        return `${p1} ${p2}`;
      })
      .split(/[\s-_,]+/g);

    return queryTokens.reduce((tokens, token) => {
      tokens[token] = true;
      return tokens;
    }, {} as Tokens);
  }, [query]);

  const value = useMemo(
    () => ({
      tokens,
      query,
    }),
    [tokens, query],
  );
  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  );
};

export const useSearch = () => useContext(SearchContext);
