import React from "react";
import { CustomField } from "@functions-types";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@providers/Mui";

type FieldCardProps = {
  customField: CustomField;
  onSelect: (customField: CustomField) => void;
  activeField?: string;
};

const useStyles = makeStyles<{ active: boolean }>((theme) => ({
  root: ({ active }) => ({
    padding: theme.spacing(1, 2),
    transition: "background-color 0.2s",
    cursor: "pointer",
    backgroundColor: active ? theme.palette.grey[200] : "transparent",
    "&:hover": {
      backgroundColor: theme.palette.grey[200],
    }
  }),
  label: {
    fontSize: 14,
    fontWeight: "bold",
  },
  description: {
    fontSize: 12,
    color: theme.palette.grey[700],
  },
}));

export const FieldCard: React.FC<FieldCardProps> = ({ customField, onSelect, activeField }) => {
  const classes = useStyles({ active: customField.id === activeField });
  return (
    <Grid onClick={() => onSelect(customField)} className={classes.root}>
      <Typography className={classes.label}>
        {customField.label}
      </Typography>
      <Typography className={classes.description}>
        {customField.description}
      </Typography>
    </Grid>
  );
};
