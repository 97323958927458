import { Activity, CommentActivity } from "@functions-types";
import { makeStyles } from "@providers/Mui";
import { FC, useMemo } from "react";
import { CommentBox } from "./CommentBox";
import { CommentView } from "./CommentView";

type CommentListProps = {
  records: Activity[];
  autoFocusOnComment?: boolean;
};

function isComment(record: Activity): record is CommentActivity {
  return record.type === "comment";
}

export const CommentList: FC<CommentListProps> = ({ records, autoFocusOnComment = false }) => {
  const classes = useStyles();

  const comments: CommentActivity[] = useMemo(() => {
    return records.filter(isComment);
  }, [records]);

  return (
    <div className={classes.root}>
      {comments.map((comment) => (
        <CommentView key={`${comment.id}-${comment.updatedAt}`} comment={comment} />
      ))}

      <CommentBox key={`add-comment-${comments.length}`} autoFocusOnComment={autoFocusOnComment} />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
}));
