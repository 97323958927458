import { Group } from "@functions-types";
import { makeCloudFunction } from "../makeCloudFunction";
import { makeUseCloudFunction } from "../makeUseCloudFunction";

export type { Group };
export const listGroups = makeCloudFunction<void, Group[]>(
  "group",
  "listGroups",
);
export const useListGroups = makeUseCloudFunction(listGroups);
