import React, { useCallback, useMemo, useRef } from "react";
import { Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { MainLayout } from "@components/MainLayout";
import { Button } from "@components/Button";
import { Form, FormRef } from "@components/Form";
import { Blocker } from "@components/Blocker";
import { createTicket as createTicketCloudFunction } from "@cloud-functions";
import { useSnackbar } from "@providers/Snackbar";
import { useTicketLookups, ticketFieldValidator } from "@lib/ticket";
import { useAsync } from "@hooks/useAsync";
import { Sidebar } from "@components/TicketCard/Sidebar";
import { useSpaces } from "@providers/Spaces";
import { makeStyles } from "@providers/Mui";
import { TicketContent } from "@components/TicketCard/TicketContent";
import { convertFormStateToValues } from "@components/TicketForm/utils";
import { CreateTicketFormState } from "@components/TicketForm/types";
import { CategoryTypeItemPicker } from "@components/TicketForm/CategoryTypeItemPicker";
import { CustomFields, useCustomFields } from "@components/TicketCard/CustomFields";
import { useCustomFieldsFeature } from "@hooks/featureFlags";
import { logError } from "@lib/logger";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 2.5),
    backgroundColor: "white",
    borderRadius: 12,
    margin: `${theme.spacing(2)} auto`,
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%",
    },
  },
}));

export const CreateTicket: React.FC = () => {
  const { selectedSpace } = useSpaces();
  const navigate = useNavigate();
  const { showSuccess, showError } = useSnackbar();
  const { loading, categories, severity, countries, statusesMap } = useTicketLookups();
  const { addCustomFields, deleteCustomFields, fieldsList } = useCustomFields();
  const customFieldsFeatureEnabled = useCustomFieldsFeature();
  const customFieldsRef = useRef<FormRef<CreateTicketFormState> | null>(null);
  const classes = useStyles();

  const setCustomFieldValue = useCallback(
    (key: string, value: string | boolean | null | undefined | Date | [string, string]) => {
      customFieldsRef?.current?.setValue?.(
        // key as keyof CreateTicketFormState,
        key as any,
        value as string,
      );
    },
    [customFieldsRef],
  );

  const [createTicket, createTicketState] = useAsync(createTicketCloudFunction, {
    reThrow: true,
  });
  const handleSubmit = async (ticket: CreateTicketFormState) => {
    try {
      const result = await createTicket({
        input: convertFormStateToValues(ticket, fieldsList, null),
      });

      showSuccess(
        <div>
          <Typography variant="body2">Ticket has been created successfully</Typography>
          <Button
            sx={{
              textTransform: "none",
              padding: 0,
              color: "white",
              textDecoration: "underline",
            }}
            variant="text"
            type="button"
            onClick={() => navigate(`/ticket/${result.id}`)}
            data-cy="cypress_openTicketSuccessButton">
            Open ticket
          </Button>
        </div>,
      );

      if (selectedSpace) {
        navigate(`/space/${selectedSpace.key}/tickets`);
      }

      return result;
    } catch (err) {
      logError(err);

      showError(<Typography variant="body2">Something went wrong</Typography>);
    }
  };

  const formValidator = useMemo(
    () => ticketFieldValidator(fieldsList, customFieldsFeatureEnabled),
    [fieldsList, customFieldsFeatureEnabled],
  );

  return (
    <MainLayout fullHeight fullWidth>
      <Form<CreateTicketFormState>
        formMode="create"
        onSubmit={handleSubmit}
        resolver={formValidator}
        reValidateMode="onChange"
        ref_={customFieldsRef}>
        <Grid
          container
          spacing={2}
          maxWidth="md"
          className={classes.root}
          sx={(theme) => ({ padding: theme.spacing(2) })}>
          <Grid item xs={12}>
            <CategoryTypeItemPicker categories={categories} />
          </Grid>
          <Grid item sm={12}>
            <TicketContent />
          </Grid>
          <Grid item sm={12}>
            <Sidebar
              severity={severity}
              countries={countries}
              statusesMap={statusesMap}
              addCustomFields={addCustomFields}
              deleteCustomFields={deleteCustomFields}
              setCustomValue={setCustomFieldValue}
              categories={categories}
            />
          </Grid>
          {customFieldsFeatureEnabled && (
            <Grid item xs={12}>
              <CustomFields fieldsList={fieldsList} formMode="create" />
            </Grid>
          )}
          <Grid item container xs={12} justifyContent="right" sx={{ pb: 4 }}>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={loading || createTicketState.isLoading}>
              Create ticket
            </Button>
          </Grid>
        </Grid>
      </Form>
      <Blocker open={loading || createTicketState.isLoading} />
    </MainLayout>
  );
};
