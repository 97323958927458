import { Severity } from "@functions-types";
import { makeUseCloudFunction } from "../makeUseCloudFunction";
import { makeCloudFunction } from "../makeCloudFunction";

export type { Severity };
export const listSeverity = makeCloudFunction<void, Severity[]>(
  "severity",
  "listSeverity",
);
export const useListSeverity = makeUseCloudFunction(listSeverity);
