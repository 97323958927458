import { MarkerType, Node, Edge, Position } from "react-flow-renderer";
import dagre from "dagre";
import { WorkflowStatus } from "@functions-types";

const NODE_OFFSET_Y = 100;
const NODE_OFFSET_X = 200;

const nodeWidth = 172;
const nodeHeight = 36;
const isHorizontal = false;

const dagreGraph = new dagre.graphlib.Graph({ directed: true });
dagreGraph.setDefaultEdgeLabel(() => ({}));

const getBackgroundColor = (workflowItem: WorkflowStatus): string => {
  return workflowItem.status?.color ?? "grey";
};

export const workflowItemSortComparator = (
  a: WorkflowStatus,
  b: WorkflowStatus,
): number => {
  if (a.status?.id === b.status?.id) {
    return 0;
  }
  if (a.type === "start" || b.type === "finish") {
    return -1;
  }
  if (a.type === "finish" || b.type === "start") {
    return 1;
  }
  return 0;
};

export const buildNodes = (workflowItems: WorkflowStatus[]): Node[] => {
  let positionY = 0;
  let positionX = 100;

  return [...workflowItems].sort(workflowItemSortComparator).map((item) => {
    const newItem = {
      id: item.status?.id ?? "",
      data: {
        label: <strong>{item.status?.label}</strong>,
      },
      position: { x: positionX, y: positionY },
      style: {
        background: getBackgroundColor(item),
      },
    };
    if (item.type !== "finish") {
      positionY += NODE_OFFSET_Y;
    } else {
      positionX += NODE_OFFSET_X;
    }

    return newItem;
  });
};

export const buildEdges = (workflowItems: WorkflowStatus[]): Edge[] =>
  workflowItems.flatMap((item) => {
    if (item.transitions?.length > 0) {
      return item.transitions.map(({ destinationId }) => ({
        id: `${item.status?.id}-${destinationId}`,
        source: item.status?.id ?? "",
        target: destinationId,
        type: "smoothstep",
        markerEnd: {
          type: MarkerType.ArrowClosed,
        },
      }));
    }

    return [];
  });

// Taken from https://reactflow.dev/docs/examples/layouting/
export const getLayoutedElements = (
  nodes: Node[],
  edges: Edge[],
  direction: string = "TB",
): { nodes: Node[]; edges: Edge[] } => {
  dagreGraph.setGraph({ rankdir: direction });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.targetPosition = isHorizontal ? Position.Left : Position.Top;
    node.sourcePosition = isHorizontal ? Position.Right : Position.Bottom;

    // We are shifting the dagre node position (anchor=center center) to the top left
    // so it matches the React Flow node anchor point (top left).
    node.position = {
      x: nodeWithPosition.x - nodeWidth / 2,
      y: nodeWithPosition.y - nodeHeight / 2,
    };

    return node;
  });

  return { nodes, edges };
};
