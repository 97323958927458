import React, { useMemo } from "react";
import { Grid } from "@mui/material";
import { makeStyles } from "@providers/Mui";
import { H7 } from "@components/Typography";
import { SLACard } from "./SLACard";
import { Ticket, useGetTicketLazy } from "@cloud-functions";
import { formatTimeFrame } from "@lib/date";
import { collection, query, where } from "firebase/firestore";
import { firestore } from "@@firebase";
import { useOnSnapshot } from "@hooks/useOnSnapshot";

type SLADetailsProps = {
  ticket: Ticket;
};

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
  },
  header: {
    paddingBottom: theme.spacing(1),
  },
}));

export const SLADetails: React.FC<SLADetailsProps> = ({ ticket: initialTicket }) => {
  const classes = useStyles();
  const [ticketData, ticketState] = useGetTicketLazy({
    input: { id: initialTicket.id },
  });

  const historyQuery = useMemo(() => {
    return query(
      collection(firestore, "tickets_populated"),
      where("id", "==", initialTicket.id),
    );
  }, [initialTicket.id]);

  useOnSnapshot(
    historyQuery,
    () =>
    ticketState.refetch({
      input: { id: initialTicket.id },
    }),
    []
  );

  const ticket = ticketData || initialTicket;
  const {
    firstReactionSLA,
    nextReactionSLA,
    resolveSLA,
    item,
    resolvedAt,
  } = ticket;

  if (!firstReactionSLA && !nextReactionSLA && !resolveSLA) return null;

  return (
    <Grid className={classes.root}>
      <H7 className={classes.header}>SLAs</H7>
      {firstReactionSLA && !nextReactionSLA && item?.firstResponseSLA && !resolvedAt && (
        <SLACard
          title="Time to first response"
          sla={item.firstResponseSLA}
          deadline={firstReactionSLA}
        />
      )}
      {nextReactionSLA && !resolvedAt && item?.nextResponseSLA && (
        <SLACard
          title="Time to next response"
          sla={item.nextResponseSLA}
          deadline={nextReactionSLA}
        />
      )}
      {resolveSLA && item?.resolveSLA && (
        <SLACard
          title={resolvedAt ?
            `Resolved ${formatTimeFrame(ticket.createdAt, resolvedAt)}`
            : "Time to resolution"
          }
          sla={item.resolveSLA}
          deadline={resolveSLA}
          resolvedAt={resolvedAt ?? undefined}
        />
      )}
    </Grid>
  );
};
