import React from "react";
import { StatusChip } from "@components/StatusChip";
import { Autocomplete } from "@components/Form";
import { Status } from "@functions-types";
import { Operation } from "@containers/TicketFilter";
import { makeStyles } from "@providers/Mui";

type StatusFieldProps = {
  operation: Operation;
  options?: Status[];
  name: string;
  label: string;
};

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    paddingRight: theme.spacing(4),
    minWidth: 200,
  },
  field: {
    "& .MuiOutlinedInput-root": {
      backgroundColor: theme.palette.common.white,
    },
  },
  select: {
    backgroundColor: theme.palette.common.white,
  },
}));

const multipleOperations = ["in", "not-in"];

export const StatusField: React.FC<StatusFieldProps> = ({
  operation,
  options,
  name,
  label,
}) => {
  const classes = useStyles();

  return (
    <Autocomplete<Status>
      multiple={operation === "in"}
      classes={classes}
      name={name}
      label={label}
      getOptionText={(op) => op.label ?? "N/A"}
      renderOption={(props, option) => <li {...props}>{option.label}</li>}
      options={options ?? []}
      clearIcon={null}
      className={classes.select}
      disablePortal={false}
      defaultValue={multipleOperations.includes(operation) ? [] : null}
      renderTags={(statuses, getTagProps) => statuses.map((status, index) => (
        <StatusChip status={status} {...getTagProps({ index })} />
      ))}
    />
  );
};
