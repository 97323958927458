import { FirebaseOptions } from "@firebase/app";

import json from "./config.json";

type Env = "local" | "dev" | "stg" | "prd";

type Config = {
  env: Env;
  firebase: {
    region: string;
    app: FirebaseOptions;
  };
  sentry: {
    dsn: string;
    tracesSampleRate: number;
  };
  cypressUserId?: string;
};

export const config = {
  ...json,
  env: process.env.NODE_ENV === "development" ? "local" : json.env,
} as Config;
