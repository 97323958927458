import clsx from "clsx";
import { FieldView } from "@components/Form/FieldView";
import { makeStyles } from "@providers/Mui";
import { FieldLabel } from "@components/Form/FieldLabel";
import { HasClassName } from "@lib/types";
import { Country, Store } from "@functions-types";
import { CircularProgress, Grid } from "@mui/material";
import React from "react";

export type StoreFieldViewerProps = HasClassName & {
  field: string;
  country?: Country | null;
  store?: Store | null;
  label?: React.ReactNode;
  hasUnsavedChanges?: boolean;
  onClick?: () => void;
  loading?: boolean;
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
  },
  field: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  loader: {
    marginRight: theme.spacing(0.5),
  },
}));

export const StoreFieldViewer = ({
  field,
  store,
  country,
  label,
  hasUnsavedChanges,
  className,
  onClick,
  loading,
}: StoreFieldViewerProps) => {
  const classes = useStyles();

  return (
    <>
      <FieldLabel label={label} hasUnsavedChanges={hasUnsavedChanges} />
      <Grid container wrap="nowrap" spacing={2}>
        <Grid item sm={6} xs={12}>
          <FieldView field={field} onClick={onClick} className={classes.field}>
            <div className={clsx(classes.root, className)}>{country?.name ?? "N/A"}</div>
            {loading && (
              <CircularProgress
                size={12}
                className={classes.loader}
              />
            )}
          </FieldView>
        </Grid>
        <Grid item sm={6} xs={12}>
          <FieldView field={field} onClick={onClick} className={classes.field}>
            <div className={clsx(classes.root, className)}>{store?.name ?? "N/A"}</div>
            {loading && (
              <CircularProgress
                size={12}
                className={classes.loader}
              />
            )}
          </FieldView>
        </Grid>
      </Grid>
    </>
  );
};
