import { makeCloudFunction } from "../makeCloudFunction";
import { makeUseCloudFunction } from "../makeUseCloudFunction";
import {
  GetWorkflowByIdRequest,
  GetWorkflowByIdResponse,
} from "@functions-types";

export const getWorkflowById = makeCloudFunction<
  GetWorkflowByIdRequest,
  GetWorkflowByIdResponse
>("workflow", "getWorkflowById");

export const useGetWorkflowById = makeUseCloudFunction(
  getWorkflowById,
  { useCache: false, triggerOnMounting: false },
);
