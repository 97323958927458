import { makeStyles } from "@providers/Mui";
import clsx from "clsx";
import { FC } from "react";
import { useForm } from "../FormProvider";

type FieldViewProps = {
  field: string;
  onClick?: () => void;
  className?: string;
};

export const FieldView: FC<FieldViewProps> = ({
  field,
  onClick,
  className,
  children,
}) => {
  const { activateField, disabled: formDisabled } = useForm();
  const classes = useStyles({ disabled: formDisabled });

  return (
    <div
      className={clsx(classes.root, className)}
      data-cy={`cypress_fieldView_${field}`}
      onClick={() => {
        if (formDisabled) return;
        activateField(field);
        onClick?.();
      }}>
      {children}
    </div>
  );
};

const useStyles = makeStyles<{ disabled: boolean }>((theme) => ({
  root: {
    backgroundColor: "#f8f8f8",
    padding: theme.spacing(1, 1.5),
    borderRadius: 4,
    overflowX: "auto",
    "&:hover": ({ disabled }) =>
      disabled
        ? {}
        : {
            backgroundColor: "#f4f4f4",
            cursor: "text",
          },
  },
}));
