import React, { useMemo, useState } from "react";
import { Grid } from "@mui/material";
import { makeStyles } from "@providers/Mui";
import { AutomationsList } from "./AutomationsList";
import { Button } from "@components/Button";
import { AutomationForm } from "./AutomationForm";
import { Automation as AutomationType, useListAutomations, useListCTITree, useListStatuses } from "@cloud-functions";
import { Blocker } from "@components/Blocker";
import { isNonNullish } from "@lib/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    height: "100%",
  },
  content: {
    height: "100%",
  },
  newItem: {
    color: theme.palette.info.main,
    textTransform: "none",
    width: "100%",
    marginTop: theme.spacing(1),
  },
  sidebar: {
    position: "relative",
  },
}));

export const Automation: React.FC = () => {
  const classes = useStyles();
  const [selectedAutomation, setSelectedAutomation] = useState<AutomationType | null>(null);
  const [automations, automationsState] = useListAutomations();
  const [categories, { loading: categoriesLoading }] = useListCTITree();
  const [statuses, { loading: statusesLoading }] = useListStatuses();

  const automationsList = useMemo(() => {
    return automations?.filter(isNonNullish);
  }, [automations]);

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={3} className={classes.sidebar}>
        <AutomationsList
          automations={automationsList}
          activeAutomation={selectedAutomation?.id}
          onSelect={(automation) => setSelectedAutomation(automation)}
        />
        <Button
          className={classes.newItem}
          onClick={() => setSelectedAutomation(null)}
        >
          Create automation
        </Button>
        <Blocker open={automationsState.loading} />
      </Grid>
      <Grid item xs={9} className={classes.content}>
        <AutomationForm
          key={selectedAutomation?.id}
          selectedAutomation={selectedAutomation}
          setSelectedAutomation={setSelectedAutomation}
          refetch={automationsState.refetch}
          loading={categoriesLoading || statusesLoading}
          categories={categories ?? []}
          statuses={statuses ?? []}
        />
      </Grid>
    </Grid>
  );
};
