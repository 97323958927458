import React, { useEffect, useMemo, useRef, useState } from "react";
import { updateTicket as updateTicketCloudFunction } from "@cloud-functions";
import { Form, FormRef } from "@components/Form";
import { Ticket } from "@functions-types";
import { Typography } from "@mui/material";
import { useAsync } from "@hooks/useAsync";
import { partialTicketValidationResolverFor } from "@lib/ticket";
import { useSnackbar } from "@providers/Snackbar";
import { getUpdateTicketInitialState, getUpdatesFromState } from "@components/TicketCard/utils";
import { intersection, isEqual, pick, union } from "lodash";
import { useDispatch } from "@providers/EventsProvider";
import { GlobalTicketEvents, NoneEmptyArray } from "@lib/types";
import { UpdateTicketFormState } from "./types";
import { logError } from "@lib/logger";

type TicketPartialUpdatesFormProps = {
  id: string;
  ticket: Ticket;
  fields: NoneEmptyArray<keyof Ticket>;
  disabled?: boolean;
  children: (ticket: Ticket) => React.ReactNode;
  onTicketUpdate: (ticket: Ticket) => void;
};

export const TicketPartialUpdatesForm: React.FC<TicketPartialUpdatesFormProps> = ({
  id,
  ticket,
  fields,
  children,
  disabled = false,
  onTicketUpdate,
}) => {
  const [updateTicket] = useAsync(updateTicketCloudFunction, { reThrow: true });
  const formRef = useRef<FormRef<UpdateTicketFormState> | null>(null);
  const { showError } = useSnackbar();
  const [currentTicketState, setCurrentTicketState] = useState<Ticket>(() => ticket);
  const dispatch = useDispatch<GlobalTicketEvents>();

  const defaultValues = useMemo(() => {
    return pick(getUpdateTicketInitialState(currentTicketState), fields) as UpdateTicketFormState;
  }, [currentTicketState, fields]);

  useEffect(() => {
    setCurrentTicketState(ticket);
  }, [ticket]);

  const handleSubmit = async (values: UpdateTicketFormState) => {
    try {
      const updates = getUpdatesFromState(fields, defaultValues, values);

      if (values.description !== undefined) {
        if (!isEqual(values.description.json, currentTicketState.description?.json)) {
          updates.description = values.description;
        }
      }

      if (Object.keys(updates).length === 0) {
        // everything is up to date!
        return;
      }

      const fieldsThatAffectApproval: Array<keyof Ticket> = [
        "title",
        "description",
        "attachments",
        "item",
        "customFields",
      ];
      const formFields = Object.keys(updates);
      const approvalsWillReset = intersection(fieldsThatAffectApproval, formFields).length > 0;

      const isApprovedByApprovers =
        ticket.approvalState === "approved" && Object.keys(ticket.approvalRules ?? {}).length;
      const hasFeedback = Object.values(ticket.approvalRules ?? {}).some(
        (a) => Object.keys(a.feedbacks).length,
      );
      const isPendingAndHasFeedbacks = ticket.approvalState === "pending" && hasFeedback;

      if (approvalsWillReset && (isApprovedByApprovers || isPendingAndHasFeedbacks)) {
        const confirmed = window.confirm(
          "This change will reset the current approvals and require new approvals. please confirm or abort changes.",
        );

        if (confirmed === false) {
          formRef.current?.getMethods().reset();
          return;
        }
      }

      const savedTicket = await updateTicket({
        input: {
          id,
          updates: pick(updates, fields),
        },
      });

      setCurrentTicketState(savedTicket);
      onTicketUpdate(savedTicket);
      dispatch("update-tickets");
    } catch (err) {
      logError(err);

      showError(
        <Typography variant="body2">Something went wrong during saving your updates</Typography>,
      );
    }
  };

  const validationResolver = useMemo(() => {
    return partialTicketValidationResolverFor(fields);
  }, [fields]);

  return (
    <Form<UpdateTicketFormState>
      ref_={formRef}
      formMode="edit"
      onSubmit={handleSubmit}
      // TODO: fix `Delta` typing
      defaultValues={defaultValues as any}
      resolver={validationResolver}
      disabled={disabled}>
      {children(currentTicketState)}
    </Form>
  );
};
