import { auth } from "@@firebase";
import { getItem, setItem } from "@lib/localstorage";
import { logError } from "@lib/logger";
import { ConfirmationResult, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { noop } from "lodash";
import React, { createContext, useCallback, useContext, useMemo, useState } from "react";

export type RiderLoginStep = "phone-number" | "recaptcha" | "otp";

type RiderLoginContextValue = {
  step: RiderLoginStep;
  reset: () => void;
  phoneNumber: string | null;
  lastUsedRiderPhoneNumber: string | null;
  setPhoneNumber: (phone: string) => void;
  confirmOTP: (otp: string) => Promise<void>;
  renderRecaptcha: (container: HTMLDivElement) => void;
  recaptchaWidgetId: number | null;
  recaptchaResolved: boolean;
};

export const RiderLoginContext = createContext<RiderLoginContextValue>({
  step: "phone-number",
  reset: noop,
  phoneNumber: null,
  lastUsedRiderPhoneNumber: null,
  setPhoneNumber: noop,
  renderRecaptcha: noop,
  confirmOTP: () => Promise.resolve(),
  recaptchaWidgetId: null,
  recaptchaResolved: false,
});

export const useRiderLogin = () => useContext(RiderLoginContext);

export const RiderLoginProvider: React.FC = ({ children }) => {
  const [step, setStep] = useState<RiderLoginStep>("phone-number");
  const [phoneNumber, _setPhoneNumber] = useState<string | null>(null);
  const [recaptchaVerifier, setRecaptchaVerifier] = useState<RecaptchaVerifier | null>(null);
  const [confirmationResult, setConfirmationResult] = useState<ConfirmationResult | null>(null);
  const [recaptchaResolved, setRecaptchaResolved] = useState(false);
  const [recaptchaWidgetId, setRecaptchaWidgetId] = useState<number | null>(null);

  const reset = useCallback(() => {
    _setPhoneNumber(null);
    setStep("phone-number");
    setRecaptchaResolved(false);
  }, []);

  const setPhoneNumber = useCallback((value) => {
    setItem("lastUsedRiderPhoneNumber", value);
    _setPhoneNumber(value);
    setStep("recaptcha");
  }, []);

  const sendOTP = useCallback(
    (verifier: RecaptchaVerifier) => {
      if (!phoneNumber) return;

      // TODO: check on zephyr if phone number is an actual rider number
      // before sending the OTP
      // https://cornerapp.atlassian.net/browse/ZTT-379

      signInWithPhoneNumber(auth, phoneNumber, verifier)
        .then((confirmationResult) => {
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          setConfirmationResult(confirmationResult);
        })
        .catch((error) => {
          logError(error, "sendOTP.error");
          recaptchaVerifier?.clear();
          setStep("recaptcha");
        });
    },
    [phoneNumber, recaptchaVerifier],
  );

  const confirmOTP = useCallback(
    async (otp: string) => {
      if (!confirmationResult) return;
      await confirmationResult.confirm(otp);
      reset();
    },
    [confirmationResult, reset],
  );

  const renderRecaptcha = useCallback(
    (container: HTMLDivElement) => {
      setRecaptchaResolved(false);

      const verifier = new RecaptchaVerifier(
        container,
        {
          size: "normal",
          callback: () => {
            setRecaptchaResolved(true);
            sendOTP(verifier);
            setStep("otp");
          },
          "expired-callback": (error: any) => {
            logError(error, "renderRecaptcha.error");
            // Response expired. Ask user to solve reCAPTCHA again.
            verifier.clear();
            setStep("recaptcha");
          },
        },
        auth,
      );

      verifier.render().then((widgetId) => {
        setRecaptchaWidgetId(widgetId);
      });

      setRecaptchaVerifier(verifier);
    },
    [sendOTP],
  );

  const lastUsedRiderPhoneNumber = getItem("lastUsedRiderPhoneNumber");

  const value = useMemo(
    () => ({
      step,
      reset,
      renderRecaptcha,
      recaptchaWidgetId,
      phoneNumber,
      setPhoneNumber,
      lastUsedRiderPhoneNumber,
      recaptchaResolved,
      confirmOTP,
    }),
    [
      step,
      reset,
      renderRecaptcha,
      recaptchaWidgetId,
      phoneNumber,
      setPhoneNumber,
      lastUsedRiderPhoneNumber,
      recaptchaResolved,
      confirmOTP,
    ],
  );

  return <RiderLoginContext.Provider value={value}>{children}</RiderLoginContext.Provider>;
};
