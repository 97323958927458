import React, { useEffect, useMemo, useState } from "react";
import { Button, ButtonGroup, Grid, Hidden, Typography } from "@mui/material";
import { Blocker } from "@components/Blocker";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useListTickets } from "@cloud-functions";
import {
  FilterableProperties,
  useTicketFilter,
  FilterType,
  FilterCard,
  Filter,
  filtersToWhereClauses,
  getFilterKey,
} from "@containers/TicketFilter";
import { useAuth } from "@providers/Auth";
import { TicketDialog } from "@components/TicketDialog";
import { useOnEvent } from "@providers/EventsProvider";
import { GlobalTicketEvents } from "@lib/types";
import { makeStyles } from "@providers/Mui";
import { useQuery } from "@hooks/useQuery";
import { TextNotification } from "@components/TextNotification";
import { Column, TicketsTable } from "@containers/TicketsTable";
import { useSpaces } from "@providers/Spaces";
import { useLiveTicketsCollectionV1Feature, useTicketSLAFeature } from "@hooks/featureFlags";
import { collection, limit, orderBy, query, where } from "firebase/firestore";
import { firestore } from "@@firebase";
import { useOnSnapshot } from "@hooks/useOnSnapshot";
import { fieldsMap } from "@lib/constants";
import { useSnackbar } from "@providers/Snackbar";
import { logError } from "@lib/logger";
import { useStatLogger } from "@hooks/useStatLogger";
import { FiltersHistory, useFiltersHistory } from "@components/FiltersHistory";
import { useSyncRef } from "@hooks/useSyncRef";
import { Card } from "@components/Card";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  button: {
    textTransform: "none",
    whiteSpace: "nowrap",
  },
  table: {
    height: "auto",
    overflow: "scroll",
  },
  filters: {
    paddingBottom: theme.spacing(2),
  },
  newFilter: {
    paddingTop: theme.spacing(1),
  },
}));

const columns: Column[] = [
  "id",
  "title",
  "createdBy",
  "assignee",
  "status",
  "deadline",
  "createdAt",
  "category",
  "type",
  "item",
];

export const TicketsListPage: React.FC = () => {
  const params = useQuery();
  const classes = useStyles();
  const { selectedSpace } = useSpaces();
  const [viewTicketId, setViewTicketId] = useState<string | null>(null);
  const { user } = useAuth();
  const { history, add } = useFiltersHistory(5);
  const ticketSLAFeatureEnabled = useTicketSLAFeature();
  const liveTicketsCollectionV1Enabled = useLiveTicketsCollectionV1Feature();
  const { showError } = useSnackbar();

  const spaceId = selectedSpace?.id ?? undefined;

  const defaultFilter = {
    field: { id: "assignee", label: "Assignee" },
    label: user?.displayName,
    operation: "==",
    value: user?.id,
  } as FilterType;

  const { filters, remainingFields, addFilter, removeFilter, loading, selects, applyFilters } =
    useTicketFilter({
      fields: ["status", "severity", "country", "assignee", "createdBy"],
      operationsMap: {
        severity: ["==", "!="],
        country: ["==", "!="],
        assignee: ["==", "!=", "in"],
        status: ["in", "==", "!="],
        createdBy: ["==", "!=", "in"],
      },
      defaultFilter: user && params.get("filter") === "my" ? [defaultFilter] : undefined,
    });

  useEffect(() => {
    if (params.get("filter") === "my") {
      applyFilters([defaultFilter]);
    } else {
      applyFilters([]);
    }
  }, [params]);

  const [tickets, ticketsState] = useListTickets({
    query: {
      where: filtersToWhereClauses(filters, "open"),
      orderBy: ticketSLAFeatureEnabled ? [["deadline", "desc"]] : [["createdAt", "desc"]],
    },
    spaceId,
  });
  const [listOpen, setListOpen] = useState(false);

  const viewTicket = useMemo(
    () => tickets?.find((ticket) => ticket.id === viewTicketId),
    [viewTicketId, tickets],
  );

  useOnEvent<GlobalTicketEvents>("update-tickets", () =>
    ticketsState.refetch({
      query: {
        where: filtersToWhereClauses(filters, "open"),
        orderBy: ticketSLAFeatureEnabled ? [["deadline", "desc"]] : [["createdAt", "desc"]],
      },
      spaceId,
    }),
  );

  const filterKey = useMemo(() => getFilterKey(filters), [filters]);

  // using a ref and not adding `ticketsState` to deps, to keep stats counting only based on `filterKey`
  const ticketsStateRef = useSyncRef(ticketsState);

  const refetchWithStatisticsLogger = useStatLogger(
    (resetBeforeFetch: boolean) => {
      ticketsStateRef.current.refetch(
        {
          query: {
            where: filtersToWhereClauses(filters, "open"),
            orderBy: ticketSLAFeatureEnabled ? [["deadline", "desc"]] : [["createdAt", "desc"]],
          },
          spaceId,
        },
        {
          resetBeforeFetch,
        },
      );
    },
    "Tickets list, refetch tickets",
    [filterKey],
  );

  const ticketsQuery = useMemo(() => {
    if (liveTicketsCollectionV1Enabled) {
      return query(
        collection(firestore, "tickets_populated"),
        where("item.space.id", "==", spaceId),
        where("resolved", "==", false),
        orderBy("updatedAt", "desc"),
        limit(1),
      );
    }

    return query(
      collection(firestore, "tickets_populated"),
      where("item.space.id", "==", spaceId),
      where("resolved", "==", false),
      orderBy("createdAt", "desc"),
      limit(1),
    );
  }, [spaceId, liveTicketsCollectionV1Enabled]);

  useOnSnapshot(ticketsQuery, refetchWithStatisticsLogger, [filterKey]);

  const ticketColumns = useMemo(() => {
    if (ticketSLAFeatureEnabled) return columns;
    return columns.filter((column) => column !== fieldsMap.deadline);
  }, [ticketSLAFeatureEnabled]);

  const handleFetchNextPage = async () => {
    try {
      ticketsState.fetchNext({
        spaceId,
        query: {
          where: filtersToWhereClauses(filters, "open"),
          orderBy: ticketSLAFeatureEnabled ? [["deadline", "desc"]] : [["createdAt", "desc"]],
        },
      });
    } catch (err) {
      logError(err);

      showError(
        <Typography variant="body2">Something went wrong during tickets fetching</Typography>,
      );
    }
  };

  return (
    <Card className={classes.root}>
      <Grid container direction="column" className={classes.root} flexWrap="nowrap">
        <Hidden smDown>
          <Grid item className={classes.filters}>
            <Grid container direction="column" spacing={0}>
              {(!!filters.length || !!history.length) && (
                <Grid item container direction="row">
                  <Grid item container direction="row" spacing={1} md={9}>
                    {filters.map((props) => (
                      <FilterCard
                        {...props}
                        onRemove={() => removeFilter(props)}
                        key={props.field.id}
                      />
                    ))}
                  </Grid>
                  <Grid container item md={3} justifyContent="flex-end">
                    <ButtonGroup variant="outlined">
                      <Button
                        size="small"
                        startIcon={<StarBorderIcon />}
                        disabled={!filters.length}
                        onClick={() => add(filters)}
                        className={classes.button}>
                        Save filter
                      </Button>
                      <Button
                        size="small"
                        startIcon={<FilterListIcon />}
                        disabled={!history.length}
                        onClick={() => setListOpen(true)}
                        className={classes.button}>
                        My filters
                      </Button>
                    </ButtonGroup>
                  </Grid>
                </Grid>
              )}
              {!!remainingFields.length && (
                <Grid item className={classes.newFilter}>
                  <Filter<FilterableProperties>
                    options={remainingFields}
                    onAdd={addFilter}
                    selectsMap={selects}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Hidden>
        {!!tickets?.length && (
          <Grid item container direction="column" className={classes.table}>
            <TicketsTable
              columns={ticketColumns}
              tickets={tickets}
              fetchNext={handleFetchNextPage}
              interactionSettings={{
                changeAssignee: true,
                changeStatus: true,
              }}
              onOpen={(ticket) => setViewTicketId(ticket.id)}
              isFetching={ticketsState.loading}
            />
          </Grid>
        )}
        {!tickets?.length && !ticketsState.loading && !ticketsState.error && (
          <TextNotification message="No tickets found" />
        )}
        {!tickets?.length && !ticketsState.loading && !!ticketsState.error && (
          <TextNotification message="Something went wrong" />
        )}
      </Grid>
      <Blocker open={loading || ticketsState.loading} />
      <FiltersHistory
        open={listOpen}
        onClose={() => setListOpen(false)}
        filters={history}
        onFilterClick={applyFilters}
      />
      {viewTicket && (
        <TicketDialog
          open={!!viewTicket}
          onClose={() => setViewTicketId(null)}
          ticketId={viewTicket.id}
          spaceKey={viewTicket.item?.space?.key}
          isPrivate={viewTicket.isPrivate}
        />
      )}
    </Card>
  );
};
