import React from "react";
import { makeStyles } from "@providers/Mui";
import { Grid, Skeleton } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    width: "100%",
    maxWidth: 380,
  },
}));

export const ReviewPlaceholder: React.FC = () => {
  const classes = useStyles();

  return (
    <Grid className={classes.root} container direction="column" alignItems="center">
      <Skeleton variant="rectangular" width="70%" height={33} sx={{ mb: 2 }} />
      <Skeleton variant="rectangular" width="50%" height={24} sx={{ mb: 5 }} />
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        sx={{ mb: 5, width: "70%" }}
        flexWrap="nowrap"
      >
        {Array(5).fill(0).map((_, i) => (
          <Skeleton variant="circular" width={46} height={46} key={i} />
        ))}
      </Grid>
      <Skeleton variant="rectangular" width="100%" height={46} sx={{ mb: 7 }} />
      <Skeleton variant="rectangular" width="30%" height={24} />
    </Grid>
  );
};
