import React, { useCallback } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Typography } from "@mui/material";
import { AsyncAutocomplete, Form, TextField } from "@components/Form";
import { useAsync } from "@hooks/useAsync";
import { searchUsers as searchUsersCloudFunction } from "@cloud-functions";
import {
  createGroup as createGroupCloudFunction,
  updateGroup as updateGroupCloudFunction,
} from "@cloud-functions";
import { UserCard } from "@components/UserCard";
import { User, Group, UpdateGroupInput, CreateGroupInput } from "@functions-types";
import { makeStyles } from "@providers/Mui";
import { Blocker } from "@components/Blocker";
import { Button } from "@components/Button";
import { useSnackbar } from "@providers/Snackbar";
import { useDispatch } from "@providers/EventsProvider";
import { isNonNullish } from "@lib/utils";
import { logError } from "@lib/logger";
import { AdminSettingsEvents } from "../../types";
import { formatUserName } from "@lib/user";

type GroupFormProps = {
  group?: Group | null;
};

function fromGroupToUpdateGroupInput(group: Group): UpdateGroupInput {
  return {
    ...group,
    members: (group.members ?? []).map((user) => user?.id).filter(isNonNullish),
    managers: (group.managers ?? []).map((user) => user?.id).filter(isNonNullish),
  };
}

function fromGroupToCreateGroupInput(group: Group): CreateGroupInput {
  return {
    ...group,
    members: (group.members ?? []).map((user) => user?.id) as string[],
    managers: (group.managers ?? []).map((user) => user?.id) as string[],
  };
}

const resolver = yupResolver(
  yup.object().shape({
    name: yup.string().required("Name is a required field"),
    managers: yup.array().required("Please provide at least one manager"),
    members: yup.array().required("Please provide at least one member"),
  }),
);

export const GroupForm: React.FC<GroupFormProps> = ({ group }) => {
  const classes = useStyles();
  const { showError, showSuccess } = useSnackbar();
  const dispatch = useDispatch<AdminSettingsEvents>();
  const [createGroup, { isLoading: isCreateGroupLoading }] = useAsync(createGroupCloudFunction, {
    reThrow: true,
  });
  const [updateGroup, { isLoading: isUpdateGroupLoading }] = useAsync(updateGroupCloudFunction, {
    reThrow: true,
  });
  const isEditMode = Boolean(group);

  const handleSubmit = async (values: Group) => {
    try {
      if (isEditMode) {
        const input = fromGroupToUpdateGroupInput(values);
        await updateGroup({ input });
        dispatch("update-group");
      } else {
        const input = fromGroupToCreateGroupInput(values);
        await createGroup({ input });
        dispatch("create-group");
      }

      showSuccess(
        <Typography variant="body2">
          Group {isEditMode ? "updated" : "created"} successfully
        </Typography>,
      );
    } catch (err) {
      logError(err);

      showError(<Typography variant="body2">Something went wrong during saving group</Typography>);
    }
  };

  const searchUsers = useCallback(async (query: string) => {
    const users = await searchUsersCloudFunction({
      query,
    });

    return users.filter(isNonNullish);
  }, []);

  return (
    <Form<Group>
      formMode="create"
      onSubmit={handleSubmit}
      className={classes.root}
      defaultValues={group ?? undefined}
      resolver={resolver}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <TextField
            name="name"
            label="Name"
            variant="outlined"
            fullWidth
            className={classes.field}
          />
        </Grid>
        <Grid item>
          <AsyncAutocomplete<User>
            disablePortal
            label="Managers"
            name="managers"
            multiple
            getOptionText={formatUserName}
            search={searchUsers}
            disableClearable
            renderItem={(option) => {
              const { email, displayName, active } = option;

              if (!email || !displayName) {
                return <UserCard user={null} />;
              }

              return <UserCard user={{ displayName, email, active }} withBackground />;
            }}
          />
        </Grid>
        <Grid item>
          <AsyncAutocomplete<User>
            disablePortal
            label="Members"
            name="members"
            multiple
            getOptionText={formatUserName}
            search={searchUsers}
            disableClearable
            renderItem={(option) => {
              const { email, displayName, active } = option;

              if (!email || !displayName) {
                return <UserCard user={null} />;
              }

              return <UserCard user={{ displayName, email, active }} withBackground />;
            }}
          />
        </Grid>
        <Grid item>
          <Button type="submit" variant="contained" size="small">
            {isEditMode ? "Save changes" : "Create group"}
          </Button>
        </Grid>
      </Grid>
      <Blocker open={isCreateGroupLoading || isUpdateGroupLoading} />
    </Form>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[200],
    position: "relative",
  },
  header: {
    fontSize: 16,
    fontWeight: "bold",
    paddingBottom: theme.spacing(1),
  },
  field: {
    "& .MuiOutlinedInput-root": {
      backgroundColor: theme.palette.common.white,
    },
  },
  close: {
    marginLeft: theme.spacing(1),
  },
}));
