import React from "react";
import { Typography } from "@mui/material";
import { makeStyles } from "@providers/Mui";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
  },
}));

export const Severity: React.FC = ({ children }) => {
  const classes = useStyles();
  return (
    <Typography className={classes.root} variant="body2">
      {children}
    </Typography>
  );
};
