import React from "react";
import { Grid, IconButton, Typography } from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { makeStyles } from "@providers/Mui";

type CustomFieldItemProps = {
  name: string;
  fieldId: string;
  onDelete: (fieldId: string) => void;
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 0),
    borderBottom: "1px solid rgb(224, 224, 224)",
    "&:last-child": {
      borderBottom: "none",
      marginBottom: theme.spacing(1),
    },
  },
  remove: {
    flexShrink: 0,
    marginLeft: theme.spacing(1),
    padding: theme.spacing(0, 1),
  },
  header: {
    width: "100%",
    flexShrink: 2,
  },
}));

export const CustomFieldItem: React.FC<CustomFieldItemProps> = ({
  name,
  fieldId,
  onDelete,
}) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.root} flexWrap="nowrap" alignItems="center">
      <Typography className={classes.header}>
        {name}
      </Typography>
      <IconButton
        className={classes.remove}
        disableRipple
        color="warning"
        onClick={() => onDelete(fieldId)}
      >
        <RemoveCircleOutlineIcon />
      </IconButton>
    </Grid>
  );
};
