import React from "react";
import { Form, TextField } from "@components/Form";
import { useAsync } from "@hooks/useAsync";
import {
  createCategory as createCategoryCloudFunction,
  updateCategory as updateCategoryCloudFunction,
  Category,
  CustomField as CustomFieldType,
  Group,
} from "@cloud-functions";
import { Typography } from "@mui/material";
import { makeStyles } from "@providers/Mui";
import { Blocker } from "@components/Blocker";
import { Button } from "@components/Button";
import { categoryValidation } from "./validationSchema";
import { useSnackbar } from "@providers/Snackbar";
import { CustomField } from "@pages/Admin/CTI/CustomField";
import { isNonNullish } from "@lib/utils";
import { Caption } from "@components/Typography";
import { UserTypeRestrictionField } from "../UserTypeRestrictionField";
import { ApprovalField } from "../ApprovalField";
import { logError } from "@lib/logger";
import { fromApprovalsConfigToApprovalsInput } from "./utils";
import { useApprovalsFeature } from "@hooks/featureFlags";

type CategoryFormProps = {
  groups: Group[];
  defaultValues?: Category;
  customFields: CustomFieldType[];
  onSave: (category: Category) => void;
  onClose?: () => void;
  onSelect?: (category: Category) => void;
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[200],
    position: "relative",
    maxWidth: "100%",
    "& .MuiOutlinedInput-root": {
      backgroundColor: "white",
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
  },
  field: {
    "&:not(:first-child)": {
      marginTop: theme.spacing(2),
    },
  },
  header: {
    fontSize: 16,
    fontWeight: "bold",
    paddingBottom: theme.spacing(1),
  },
  close: {
    marginLeft: theme.spacing(1),
  },
}));

export const CategoryForm: React.FC<CategoryFormProps> = ({
  defaultValues,
  onSave,
  onClose,
  onSelect,
  customFields,
  groups,
}) => {
  const approvalsFeatureEnabled = useApprovalsFeature();
  const classes = useStyles();
  const { showError } = useSnackbar();
  const [createCategory, createCategoryState] = useAsync(createCategoryCloudFunction, {
    reThrow: true,
  });
  const [updateCategory, updateCategoryState] = useAsync(updateCategoryCloudFunction, {
    reThrow: true,
  });
  const isEdit = !!defaultValues?.id;

  const handleSubmit = async (values: Category) => {
    try {
      if (isEdit) {
        const category = await updateCategory({
          input: {
            id: defaultValues.id,
            updates: {
              ...values,
              customFields: (values.customFields || []).filter(isNonNullish).map(({ id }) => id),
              approvals: fromApprovalsConfigToApprovalsInput(values.approvals),
            },
          },
        });
        onSave(category);
      } else {
        const category = await createCategory({
          input: {
            ...values,
            customFields: (values.customFields || []).filter(isNonNullish).map(({ id }) => id),
            approvals: fromApprovalsConfigToApprovalsInput(values.approvals),
          },
        });
        onSelect?.(category);
        onSave(category);
      }
      onClose?.();
    } catch (err) {
      logError(err);

      showError(
        <Typography variant="body2">Something went wrong during saving category</Typography>,
      );
    }
  };

  return (
    <Form<Category>
      formMode="create"
      onSubmit={handleSubmit}
      defaultValues={defaultValues}
      className={classes.root}
      resolver={categoryValidation}>
      {defaultValues?.name && (
        <>
          <Caption>Category</Caption>
          <Typography className={classes.header}>{defaultValues.name}</Typography>
        </>
      )}
      <div className={classes.content}>
        <div className={classes.field}>
          <TextField name="name" label="Name" variant="outlined" fullWidth size="small" />
        </div>
        {approvalsFeatureEnabled && (
          <div className={classes.field}>
            <ApprovalField mode="category" groups={groups} />
          </div>
        )}
        <div className={classes.field}>
          <UserTypeRestrictionField />
        </div>
        <div className={classes.field}>
          <CustomField customFields={customFields} />
        </div>
        <div className={classes.field}>
          <TextField
            name="faq_link"
            label="FAQ"
            placeholder="FAQ url"
            variant="outlined"
            fullWidth
            size="small"
          />
        </div>
        <div className={classes.field}>
          <Button type="submit" variant="contained" size="small">
            {isEdit ? "Save category" : "Add category"}
          </Button>
          {onClose && (
            <Button
              type="button"
              onClick={onClose}
              variant="text"
              size="small"
              className={classes.close}>
              Cancel
            </Button>
          )}
        </div>
      </div>
      <Blocker open={updateCategoryState.isLoading || createCategoryState.isLoading} />
    </Form>
  );
};
