import React, { useMemo } from "react";
import { Dialog, DialogTitle, List, ListItem, ListItemText, Typography } from "@mui/material";
import { filterToString } from "./utils";
import { fieldsMap } from "@lib/constants";
import { FilterType } from "@containers/TicketFilter";

type FiltersHistoryProps = {
  open: boolean;
  onClose: () => void;
  filters: FilterType[][];
  onFilterClick: (filters: FilterType[]) => void;
};

const FilterItem = ({ field, operation, label }: FilterType) => {
  return (
    <>
      <Typography sx={{ textTransform: "capitalize", fontWeight: 500, }} component="span">
        {fieldsMap[field.id]}
      </Typography>
      <Typography sx={{ color: (theme) => theme.palette.primary.main, }} component="span">
        <> </>
        {operation}
        <> </>
      </Typography>
      <Typography sx={{ textTransform: "capitalize", fontWeight: 500, }} component="span">
        "{label}"
      </Typography>
    </>
  );
}
const filterToTitle = (filter: FilterType[]) => {
  return filter.reduce((acc, cur) => {
    if (!acc.length) {
      return [<FilterItem {...cur} key={cur.field.id} />];
    }

    return [
      ...acc,
      <Typography sx={{ color: (theme) => theme.palette.success.main }} component="span" key={`${cur.field.id}-and`}>&nbsp;&nbsp;AND&nbsp;&nbsp;</Typography>,
      <FilterItem {...cur} key={cur.field.id} />
    ]
  }, [] as React.ReactNode[]);
};

export const FiltersHistory: React.FC<FiltersHistoryProps> = ({ onClose, open, filters, onFilterClick }) => {
  const list = useMemo(() => {
    return filters.map((filter, i) => {
      const label = filterToString(filter);
      return (
        <ListItem
          button
          onClick={() => {
            onFilterClick(filter);
            onClose();
          }}
          key={label}
          divider={i !== filters.length - 1}
        >
          <ListItemText key={label} primary={<div>{filterToTitle(filter)}</div>} />
        </ListItem>
      );
    });
  }, [filters]);

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>My filters</DialogTitle>
      <List sx={{ pt: 0, pb: 4 }}>
        {list}
      </List>
    </Dialog>
  )
};
