import React from "react";
import { SLA } from "@functions-types";
import { Grid, LinearProgress, Typography } from "@mui/material";
import { makeStyles } from "@providers/Mui";
import { unitMap } from "@lib/constants";
import { SLAtoNumber } from "@lib/date";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { isBefore } from "date-fns";

type SLACardProps = {
  title: string;
  sla: SLA;
  deadline: number;
  resolvedAt?: number;
};

const useStyles = makeStyles<{ progress: number }>((theme) => ({
  root: {
    "&:not(:last-child)": {
      paddingBottom: theme.spacing(3),
    },
  },
  title: {
    fontSize: 12,
    fontWeight: 700,
    color: theme.palette.text.secondary,
    lineHeight: "24px",
  },
  time: {
    fontSize: 12,
    fontWeight: 700,
    color: theme.palette.text.primary,
    padding: theme.spacing(0.5, 0, 0),
  },
  delayedIcon: {
    color: theme.palette.error.dark,
    fontSize: 20,
    marginRight: theme.spacing(1),
  },
  successIcon: {
    color: theme.palette.success.main,
    fontSize: 20,
    marginRight: theme.spacing(1),
  },
  status: {
    fontSize: 16,
    fontWeight: 700,
  },
  progressBar: ({ progress }) => ({
    backgroundColor:
      progress < 80
        ? theme.palette.success.main
        : progress < 100
        ? theme.palette.warning.dark
        : theme.palette.error.dark,
  }),
  progressBarContainer: ({ progress }) => ({
    marginTop: theme.spacing(0.5),
    backgroundColor:
      progress < 80
        ? theme.palette.success.light
        : progress < 100
        ? theme.palette.warning.light
        : theme.palette.error.light,
  }),
}));

const getProgress = (
  current: number,
  deadline: number,
  frame: number,
): number => {
  if (current >= deadline && !frame) return 100;
  const diff = deadline - current;
  return Math.max(Math.min(100 - (diff / frame) * 100, 100), 0);
};

export const SLACard: React.FC<SLACardProps> = ({
  title,
  sla,
  deadline,
  resolvedAt,
}) => {
  const currentDate = resolvedAt ?? Date.now();
  const deadlineDate = deadline;
  const progress = getProgress(currentDate, deadlineDate, SLAtoNumber(sla));
  const classes = useStyles({ progress });

  return (
    <Grid className={classes.root}>
      <Grid container justifyContent="space-between" wrap="nowrap">
        <Typography className={classes.title}>{title}</Typography>
        {isBefore(currentDate, deadlineDate) ? (
          <Grid
            container
            item
            className={classes.status}
            alignItems="center"
            flexBasis={0}
            wrap="nowrap">
            <CheckIcon className={classes.successIcon} />
            {sla.value}
            {unitMap[sla.unit]}
          </Grid>
        ) : (
          <Grid
            container
            item
            className={classes.status}
            alignItems="center"
            flexBasis={0}
            wrap="nowrap">
            <CloseIcon className={classes.delayedIcon} />
            {sla.value}
            {unitMap[sla.unit]}
          </Grid>
        )}
      </Grid>
      {!resolvedAt && (
        <Typography className={classes.time}>
          within {sla.value}
          {unitMap[sla.unit]}
        </Typography>
      )}
      {!resolvedAt && (
        <LinearProgress
          variant="determinate"
          value={progress}
          classes={{
            root: classes.progressBarContainer,
            bar: classes.progressBar,
          }}
        />
      )}
    </Grid>
  );
};
