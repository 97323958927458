import React from "react";
import { FormHelperText, Rating, RatingProps } from "@mui/material";
import StarIcon from '@mui/icons-material/Star';
import { makeStyles } from "@providers/Mui";
import { FieldModeSwitch, FieldModeSwitchProps } from "@components/Form/FieldModeSwitch";
import { FieldLabel } from "@components/Form/FieldLabel";
import { RatingFieldViewer, RatingFieldViewerProps } from "./RatingFieldViewer";
import { Controller } from "react-hook-form";
import { Modify } from "@lib/types";
import { useForm } from "@components/Form/FormProvider";
import { get } from "lodash";

type RatingFieldProps = Modify<RatingProps, {
  name: string;
  onActivation?: () => void;
  label?: React.ReactNode;
  RatingFieldViewerProps?: Partial<RatingFieldViewerProps>;
  FieldModeSwitchProps?: Partial<FieldModeSwitchProps>;
  emptyIconClassName?: string;
}>;

const useStyles = makeStyles((theme) => ({
  sizeLarge: {
    fontSize: 46,
  },
  root: {
    color: theme.palette.yellow[300],
  }
}));

export const RatingField: React.FC<RatingFieldProps> = ({
  name,
  onActivation,
  label,
  RatingFieldViewerProps,
  FieldModeSwitchProps,
  emptyIconClassName,
  ...props
}) => {
  const classes = useStyles(props);
  const { methods } = useForm();

  const defaultValue = get(methods.control._defaultValues, name);
  return (
    <Controller
      name={name}
      render={({ field, fieldState, formState }) => {
        const hasUnsavedChanges = Boolean(field.value !== defaultValue);
        const error = fieldState.error?.message;

        return (
          <FieldModeSwitch
            field={name}
            edit={
              <>
                <FieldLabel label={label} name={name} />
                <Rating
                  {...props}
                  {...field}
                  disabled={formState.isSubmitting || props.disabled}
                  value={Number(field.value ?? defaultValue ?? props.defaultValue ?? 0)}
                  emptyIcon={
                    <StarIcon
                      sx={{ opacity: 0.5 }}
                      fontSize="inherit"
                      className={emptyIconClassName}
                    />
                  }
                  classes={classes}
                />
                {error && <FormHelperText error>{error}</FormHelperText>}
              </>
            }
            view={
              <RatingFieldViewer
                hasUnsavedChanges={hasUnsavedChanges}
                label={label}
                value={defaultValue}
                field={name}
                size={props.size}
                {...RatingFieldViewerProps}
              />
            }
            {...FieldModeSwitchProps}
          />
        );
      }}
    />
  );
};
