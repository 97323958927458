import { Category, Type, Item, CTIApprovalConfigRule } from "@functions-types";
import { capitalize } from "lodash";

export function getNodeParentApprovalsConfig(
  node: Category | Type | Item | null,
  nodeType: "category" | "type" | "item",
  type: Type | null,
  category: Category | null,
): Array<[CTIApprovalConfigRule[], Category | Type, string]> {
  if (node?.approvals?.mode === "none" || node?.approvals?.mode === "custom" || !node) {
    return [];
  }

  const parent = nodeType === "item" ? type : nodeType === "type" ? category : null;
  const parentType = nodeType === "item" ? "type" : nodeType === "type" ? "category" : "";

  // check parent
  if (parent?.approvals) {
    // check parent.parent
    const [grandParent] = getNodeParentApprovalsConfig(
      parent,
      parentType as "item",
      type,
      category,
    );

    return [grandParent, [parent.approvals.rules, parent, capitalize(parentType)]].filter(
      (record) => record?.length,
    ) as Array<[CTIApprovalConfigRule[], Category | Type, string]>;
  }

  return [];
}
