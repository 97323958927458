import React, { forwardRef, useMemo } from "react";
import {
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  FormControlLabel,
  FormControlLabelProps,
  Typography,
} from "@mui/material";
import { useForm } from "@components/Form/FormProvider";
import { Modify } from "@lib/types";
import { Controller } from "react-hook-form";
import { makeStyles } from "@providers/Mui";

type CheckboxFieldProps = Modify<
  MuiCheckboxProps,
  {
    name: string;
    submitOnChange?: boolean;
    label: FormControlLabelProps["label"];
    uncheckConfirmationText?: string;
  }
>;

const useStyles = makeStyles((theme) => ({
  error: {
    color: theme.palette.error.dark,
    fontSize: 12,
    paddingLeft: 14,
    paddingTop: theme.spacing(0.5),
  },
}));

export const CheckboxField: React.FC<CheckboxFieldProps> = forwardRef(
  ({ name, label, className, submitOnChange, uncheckConfirmationText, ...props }, ref) => {
    const { methods, triggerSubmit } = useForm();
    const defaultValue = methods.control._defaultValues[name];
    const classes = useStyles();

    const inputProps = useMemo(
      () =>
        ({
          ...(props.inputProps ?? {}),
          "data-cy": `cypress_checkboxField_forLabel_${label}_${name}`,
        } as React.InputHTMLAttributes<HTMLInputElement>),
      [props.inputProps, name],
    );

    return (
      <Controller
        name={name}
        render={({ field, fieldState }) => (
          <>
            <FormControlLabel
              control={
                <MuiCheckbox
                  {...props}
                  {...field}
                  inputProps={inputProps}
                  onChange={(event) => {
                    const nextValue = event.target.value === "true" ? false : true;

                    if (
                      !nextValue &&
                      uncheckConfirmationText &&
                      !window.confirm(uncheckConfirmationText)
                    ) {
                      return;
                    }

                    field.onChange(nextValue);

                    if (submitOnChange) {
                      triggerSubmit();
                    }
                  }}
                  value={field.value === undefined ? defaultValue : field.value}
                  checked={(field.value === undefined ? defaultValue : field.value) || false}
                  ref={ref}
                />
              }
              label={
                <>
                  {label}
                  {props.required ? " *" : ""}
                </>
              }
              className={className}
            />
            {fieldState.error?.message && (
              <Typography variant="body2" className={classes.error}>
                {fieldState.error.message}
              </Typography>
            )}
          </>
        )}
      />
    );
  },
);
