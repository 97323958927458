import React, { useState } from "react";
import {
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useListGroups } from "@cloud-functions";
import { Group } from "@functions-types";
import { useOnEvent } from "@providers/EventsProvider";
import { makeStyles } from "@providers/Mui";
import { AdminSettingsEvents } from "../types";
import { GroupForm } from "./GroupForm";

export const Groups: React.FC = () => {
  const classes = useStyles();
  const [groups, { refetch }] = useListGroups();

  const [selectedGroup, setSelectedGroup] = useState<Group | null>(null);

  useOnEvent<AdminSettingsEvents>("update-group", () => {
    refetch();
  });

  useOnEvent<AdminSettingsEvents>("create-group", async (newGroup) => {
    await refetch();
    setSelectedGroup(newGroup as Group);
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={2}>
        <List>
          {groups?.map((group) => (
            <ListItem disablePadding key={group.id}>
              <ListItemButton
                selected={group.id === selectedGroup?.id}
                onClick={() => {
                  setSelectedGroup(group);
                }}>
                <ListItemText>{group.name}</ListItemText>
              </ListItemButton>
            </ListItem>
          ))}

          <ListItem disablePadding>
            <ListItemButton
              className={classes.listItemButton}
              onClick={() => setSelectedGroup(null)}>
              <ListItemIcon>
                <AddIcon />
              </ListItemIcon>
              <ListItemText>Create</ListItemText>
            </ListItemButton>
          </ListItem>
        </List>
      </Grid>
      <Grid item xs={10}>
        <GroupForm group={selectedGroup} key={selectedGroup?.id} />
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  listItemButton: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
}));
