import { useEffect, useRef } from "react";

type UseKeyboardKeyOptions = {
  ctrlKey?: boolean;
  metaKey?: boolean;
  key?: string;
};

export function useKeyboardKey(
  options: UseKeyboardKeyOptions,
  cb: (ev: KeyboardEvent) => void,
) {
  const cbRef = useRef(cb);

  cbRef.current = cb;

  useEffect(() => {
    // attach the event listener
    document.addEventListener("keydown", (ev) => {
      if (options.ctrlKey && !ev.ctrlKey) {
        return;
      }

      if (options.metaKey && !ev.metaKey) {
        return;
      }

      if (options.key !== ev.key) {
        return;
      }

      cbRef.current(ev);
    });

    // remove the event listener
    return () => {
      document.removeEventListener("keydown", cbRef.current);
    };
  }, []);
}
