export const getDefaultValue = <T extends { id: unknown } | string>(
  data: null | T | T[],
): unknown => {
  return Array.isArray(data)
    ? data.map(getDefaultValue)
    : typeof data === "string"
    ? data
    : data?.id;
};

export const mapOptionToValue = <T extends { id: unknown }>(
  option: null | T,
  value: null | T | unknown,
): boolean => {
  return typeof value === "string"
    ? option?.id === value
    : option?.id === (value as T)?.id;
};

export const getLabelById =
  <T extends { id: unknown; label: string }>(options: ReadonlyArray<T>) =>
  (item: T | unknown): string => {
    return typeof item === "string"
      ? options?.find(({ id }) => id === item)?.label || ""
      : (item as T)?.label || "";
  };
