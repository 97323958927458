import React from "react";
import { DialogTitle, Grid, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@providers/Mui";
import clsx from "clsx";
import { HasClassName } from "@lib/types";

export type DialogHeaderProps = HasClassName & {
  header: React.ReactNode;
  onClose: () => void;
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 3),
  },
  header: {
    padding: theme.spacing(0, 1),
  },
}));

export const DialogHeader: React.FC<DialogHeaderProps> = ({
  header,
  onClose,
  className,
}) => {
  const classes = useStyles();

  return (
    <DialogTitle className={clsx(classes.root, className)}>
      <Grid
        direction="row"
        container
        flexWrap="nowrap"
        justifyContent="space-between"
        alignItems="center"
        className={classes.header}>
        <Grid item>{header}</Grid>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Grid>
    </DialogTitle>
  );
};
