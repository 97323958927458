import { RadioGroupField } from "@components/Form";
import { Category, CTIUserTypeRestriction, Type } from "@functions-types";
import { makeStyles } from "@providers/Mui";
import SettingsIcon from "@mui/icons-material/Settings";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import clsx from "clsx";
import { FC, useMemo, useState } from "react";
import { Collapse, darken, Tooltip, Typography } from "@mui/material";
import { FieldLabel } from "@components/Form/FieldLabel";
import { useWatch } from "react-hook-form";
import ArrowIcon from "@mui/icons-material/KeyboardArrowDown";

const ctiUserTypeRestrictionLabels: Record<CTIUserTypeRestriction, string> = {
  any: "Unrestricted",
  employee: "Employees only",
  rider: "Riders only",
  admin: "Admins only",
};

const userTypeRestrictionOptions: {
  value: CTIUserTypeRestriction;
  label: string;
}[] = [
  {
    value: "any",
    label: ctiUserTypeRestrictionLabels["any"],
  },
  {
    value: "employee",
    label: ctiUserTypeRestrictionLabels["employee"],
  },
  {
    value: "rider",
    label: ctiUserTypeRestrictionLabels["rider"],
  },
  {
    value: "admin",
    label: ctiUserTypeRestrictionLabels["admin"],
  },
];

type UserTypeRestrictionFieldProps = {
  className?: string;
  category?: Category | null;
  type?: Type | null;
};

export const UserTypeRestrictionField: FC<UserTypeRestrictionFieldProps> = ({
  className,
  category,
  type,
}) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles({ open });

  const [inherited, source, sourceName] = useMemo(() => {
    if (
      category?.userTypeRestriction &&
      category?.userTypeRestriction !== "any"
    ) {
      return [category.userTypeRestriction, category, "category"];
    }

    if (type?.userTypeRestriction && type?.userTypeRestriction !== "any") {
      return [type.userTypeRestriction, type, "type"];
    }

    return [null, null, null];
  }, [category, type]);

  const selfValue = useWatch({ name: "userTypeRestriction" });
  const restrictionKey = (inherited ??
    selfValue ??
    "any") as CTIUserTypeRestriction;
  const restrictionName = ctiUserTypeRestrictionLabels[restrictionKey];

  return (
    <div className={clsx(classes.root, className)}>
      <FieldLabel
        name="userTypeRestriction"
        className={classes.label}
        onClick={() => setOpen((o) => !o)}
        label={
          <>
            <SettingsIcon fontSize="inherit" className={classes.icon} />
            <span className={classes.labelText}>
              Configuration
              {!open && (
                <i>
                  {`: ${restrictionName}`}
                  {inherited && ` (inherited)`}
                </i>
              )}
            </span>
            <ArrowIcon
              fontSize="small"
              className={clsx(classes.arrow, open && classes.arrowOpen)}
            />
            <Tooltip
              title={`Limits the access to either internal employees, riders, or keep it unrestricted`}
              placement="top">
              <InfoOutlinedIcon fontSize="small" />
            </Tooltip>
          </>
        }
      />

      <Collapse in={open}>
        <div className={classes.options}>
          {inherited ? (
            <Typography>
              Inherited restriction{" "}
              <strong>({ctiUserTypeRestrictionLabels[inherited]})</strong> from
              parent{" "}
              <i>
                {sourceName} {source?.name}
              </i>
            </Typography>
          ) : (
            <RadioGroupField
              name="userTypeRestriction"
              label={null}
              options={userTypeRestrictionOptions}
            />
          )}
        </div>
      </Collapse>
    </div>
  );
};

const useStyles = makeStyles<{ open: boolean }>((theme) => ({
  root: {
    backgroundColor: "#fdf8ec",
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  label: {
    width: "100%",
    padding: theme.spacing(1),
    backgroundColor: darken("#fdf8ec", 0.02),
    marginBottom: ({ open }) => (open ? theme.spacing(1) : 0),
    "&:hover": {
      cursor: "pointer",
    },
  },
  labelText: {
    flexGrow: 1,
  },
  options: {
    padding: theme.spacing(1),
  },
  arrow: {
    transform: "rotate(0deg)",
    transition: "transform ease 500ms",
  },
  arrowOpen: {
    transform: "rotate(180deg)",
  },
}));
