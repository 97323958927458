import { createContext, FC, useContext } from "react";
import { Category, Item, Type } from "@functions-types";

type ActiveCTIContextValue = {
  item: Item | null;
  type: Type | null;
  category: Category | null;
};

type ActiveCTIProviderProps = ActiveCTIContextValue & {};

const ActiveCTIContext = createContext<ActiveCTIContextValue>({
  item: null,
  type: null,
  category: null,
});

export const ActiveCTIProvider: FC<ActiveCTIProviderProps> = ({
  children,
  ...value
}) => {
  return (
    <ActiveCTIContext.Provider value={value}>
      {children}
    </ActiveCTIContext.Provider>
  );
};

export const useActiveCTI = () => useContext(ActiveCTIContext);
