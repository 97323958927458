import React, { useCallback, useEffect } from "react";
import { searchTickets, Ticket } from "@cloud-functions";
import { useDebounce } from "@hooks/useDebounce";
import { useSyncRef } from "./useSyncRef";

type UseSearchTicketsOptions = {
  excludeIds?: string[];
};

export function useSearchTickets(
  options: UseSearchTicketsOptions = {
    excludeIds: [],
  },
) {
  const [results, setResults] = React.useState<readonly Ticket[] | null>(null);
  const [searchQuery, setSearchQuery] = React.useState("");

  const excludeIdsRef = useSyncRef<string[]>(options.excludeIds ?? []);

  const search = useCallback(
    async (query: string) => {
      const results = await searchTickets({ query });
      const filteredResults = results.filter((t) => !excludeIdsRef.current.includes(t.id));
      setResults(filteredResults);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const debouncedSearch = useDebounce(search, 500);
  const isSearching = Boolean(results === null && searchQuery.length);

  useEffect(() => {
    if (searchQuery) {
      setResults(null); // show loading instantly
      debouncedSearch(searchQuery);
    }
  }, [debouncedSearch, searchQuery]);

  const resetSearch = () => {
    setResults(null);
    setSearchQuery("");
  };

  return {
    results,
    searchQuery,
    setSearchQuery,
    resetSearch,
    isSearching,
  };
}
