import {
  CustomPage,
  UpdateCustomPageRequest,
  UpdateCustomPageResponse,
} from "@functions-types";
import { makeCloudFunction } from "../makeCloudFunction";

export type { CustomPage };
export const updateCustomPage = makeCloudFunction<
  UpdateCustomPageRequest,
  UpdateCustomPageResponse
>("customPage", "updateCustomPage");
