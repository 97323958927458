import React, { ReactNode, useMemo } from "react";
import { words as _words } from "lodash";
import { makeStyles } from "@providers/Mui";
import { useSearch } from "./SearchProvider";

type HighlightMatchesProps = {
  children?: string;
};

const useStyles = makeStyles((theme) => ({
  match: {
    backgroundColor: theme.palette.yellow[100],
    textDecoration: "underline",
    fontStyle: "italic",
  },
}));

export const HighlightMatches: React.FC<HighlightMatchesProps> = ({
  children: text = "",
}) => {
  const { tokens } = useSearch();
  const classes = useStyles();

  const words = useMemo(() => {
    const maskedText = text
      .split(" ")
      .map((word) => {
        const wordTokens = _words(word.toLowerCase());

        wordTokens.forEach((token) => {
          const matches = tokens[token];

          if (matches) {
            word = word.replace(new RegExp(token, "i"), (match) => {
              return `{{{${match}}}}`;
            });
          }
        });

        return word;
      })
      .join(" ");

    const parts = maskedText.split(/({{{|}}})/gi);
    const elements: ReactNode[] = [];

    for (let i = 0; i < parts.length; i++) {
      const str = parts[i];

      if (str === "{{{" && i + 2 < parts.length && parts[i + 2] === "}}}") {
        elements.push(<span className={classes.match}>{parts[i + 1]}</span>);
        i += 2;
      } else {
        elements.push(<>{str}</>);
      }
    }

    return elements;
  }, [text, tokens]);

  return <span>{words}</span>;
};
