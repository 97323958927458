import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as yup from "yup";
import { Grid, Typography } from "@mui/material";
import { AsyncAutocomplete, Autocomplete, Form, TextField } from "@components/Form";
import { useAsync } from "@hooks/useAsync";
import {
  createSpace as createSpaceCloudFunction,
  updateSpace as updateSpaceCloudFunction,
  Space,
  searchUsers as searchUsersCloudFunction,
  useListGroups,
} from "@cloud-functions";
import { UserCard } from "@components/UserCard";
import { UpdateSpaceInput, User } from "@functions-types";
import { makeStyles } from "@providers/Mui";
import { Blocker } from "@components/Blocker";
import { Button } from "@components/Button";
import { useSnackbar } from "@providers/Snackbar";
import { yupResolver } from "@hookform/resolvers/yup";
import { isNonNullish } from "@lib/utils";
import { useDispatch } from "@providers/EventsProvider";
import { AdminSettingsEvents } from "../types";
import { useSpaces } from "@providers/Spaces";
import { logError } from "@lib/logger";
import { formatUserName } from "@lib/user";
import { pick } from "lodash";

type SpaceFormProps = {
  space?: Space | null;
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[200],
    position: "relative",
  },
  header: {
    fontSize: 16,
    fontWeight: "bold",
    paddingBottom: theme.spacing(1),
  },
  field: {
    "& .MuiOutlinedInput-root": {
      backgroundColor: "white",
    },
  },
  close: {
    marginLeft: theme.spacing(1),
  },
}));

function fromSpaceToUpdateSpaceInput(space: Space): UpdateSpaceInput {
  return {
    ...pick(space, "id", "name", "key"),
    group: space.group?.id as string,
    managers: space.managers.map((user) => user?.id) as string[],
  };
}

export const SpaceForm: React.FC<SpaceFormProps> = ({ space: spaceFromProps }) => {
  const { spaces } = useSpaces();
  const [space, setSpace] = useState(spaceFromProps);
  const [groups, groupsState] = useListGroups();
  const dispatch = useDispatch<AdminSettingsEvents>();
  const classes = useStyles();
  const { showError, showSuccess } = useSnackbar();
  const [createSpace, createSpaceState] = useAsync(createSpaceCloudFunction, {
    reThrow: true,
  });
  const [updateSpace, updateSpaceState] = useAsync(updateSpaceCloudFunction, {
    reThrow: true,
  });
  const isEditMode = Boolean(space);

  const handleSubmit = async (values: Space) => {
    const input = fromSpaceToUpdateSpaceInput(values);

    try {
      if (isEditMode) {
        const updatedSpace = await updateSpace(input);
        setSpace(updatedSpace);
        showSuccess(<Typography variant="body2">Space updated successfully</Typography>);
        dispatch("update-space");
      } else {
        const createdSpace = await createSpace(input);
        showSuccess(<Typography variant="body2">Space created successfully</Typography>);
        dispatch("create-space", createdSpace);
      }
    } catch (err) {
      logError(err);

      showError(<Typography variant="body2">Something went wrong during saving space</Typography>);
    }
  };

  useEffect(() => {
    setSpace(spaceFromProps);
  }, [spaceFromProps]);

  const searchUsers = useCallback(async (query: string) => {
    const users = await searchUsersCloudFunction({
      query,
    });

    return users.filter(isNonNullish);
  }, []);

  const spaceResolver = useMemo(
    () =>
      yupResolver(
        yup.object().shape({
          name: yup.string().required("Name is a required field"),
          group: yup.object().required("Group is a required field"),
          managers: yup.array(yup.object().required()).required(),
          key: yup.string().test("test-unique-space-key", "Space key already exists", (value) => {
            if (isEditMode) {
              return true;
            }

            const space = spaces.find((s) => s.key.toLowerCase() === value?.toLowerCase());

            return !Boolean(space);
          }),
        }),
      ),
    [spaces, isEditMode],
  );

  const defaultValues = useMemo(() => {
    return space ?? {};
  }, [space]);

  const loading = updateSpaceState.isLoading
    || createSpaceState.isLoading
    || Boolean(createSpaceState.data)
    || groupsState.loading;

  return (
    <Form<Space>
      formMode="create"
      onSubmit={handleSubmit}
      defaultValues={defaultValues}
      className={classes.root}
      resolver={spaceResolver}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <TextField
            name="name"
            label="Name"
            variant="outlined"
            fullWidth
            className={classes.field}
          />
        </Grid>
        <Grid item>
          <TextField
            name="key"
            label="Key"
            variant="outlined"
            fullWidth
            disabled={isEditMode}
            helperText={isEditMode ? "Key is not editable" : undefined}
            className={classes.field}
          />
        </Grid>
        <Grid item>
          <AsyncAutocomplete<User>
            disablePortal
            label="Managers"
            name="managers"
            multiple
            getOptionText={formatUserName}
            search={searchUsers}
            disableClearable
            renderItem={(option) => {
              const { email, displayName, active } = option;

              if (!email || !displayName) {
                return <UserCard user={null} />;
              }

              return <UserCard user={{ displayName, email, active }} withBackground />;
            }}
          />
        </Grid>
        <Grid item>
          <Autocomplete
            options={groups ?? []}
            name="group"
            label="Group"
            saveOnChange={false}
            getOptionText={(option) => option.name}
            className={classes.field}
          />
        </Grid>
        <Grid item>
          <Button type="submit" variant="contained" size="small">
            {isEditMode ? "Save changes" : "Create space"}
          </Button>
        </Grid>
      </Grid>
      <Blocker open={loading} />
    </Form>
  );
};
