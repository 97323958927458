import { makeCloudFunction } from "../makeCloudFunction";
import { CTITree, ListCTITreeRequest, ListCTITreeResponse } from "@functions-types";
import { makeUseCloudFunction } from "../makeUseCloudFunction";

export type { CTITree };
export const listCTITree = makeCloudFunction<ListCTITreeRequest, ListCTITreeResponse>(
  "ctiTree",
  "listCTITree",
);
export const useListCTITree = makeUseCloudFunction<
  ListCTITreeRequest,
  ListCTITreeResponse
>(listCTITree);
