import {
  useListActivity,
  deleteComment,
  Ticket,
  updateComment as updateCommentCloudFunction,
} from "@cloud-functions";
import { Activity, CommentActivity, DecodedRichText } from "@functions-types";
import { Skeleton } from "@mui/material";
import { useOnEvent } from "@providers/EventsProvider";
import { makeStyles } from "@providers/Mui";
import { FC, useMemo, useState } from "react";
import { CommentList } from "./CommentList";
import { HistoryList } from "./HistoryList";
import { TicketActivityFilter } from "./TicketActivityFilter";
import { TicketActivityProvider } from "./TicketActivityProvider";
import { TicketActivityEvents } from "./types";
import { Label } from "@components/Label";

type TicketActivityProps = {
  ticket: Ticket;
  autoFocusOnComment?: boolean;
  defaultActivityTab?: ActivityFilterType;
};

export type ActivityFilterType = "all" | "comment" | "history";

export const TicketActivity: FC<TicketActivityProps> = ({
  ticket,
  autoFocusOnComment = false,
  defaultActivityTab,
}) => {
  const classes = useStyles();
  const [filter, setFilter] = useState<ActivityFilterType>(
    defaultActivityTab ?? "comment",
  );
  const [asc, setAsc] = useState(true);

  const [activityList, activityListState] = useListActivity({
    input: {
      ticket: ticket.id,
    },
  });

  const filteredActivityList = useMemo(() => {
    return (
      activityList?.filter((record) => {
        if (!record) return false;
        if (filter === "all") return true;
        if (filter === "history" && ["creation", "sla"].includes(record.type))
          return true;
        return record.type === filter;
      }) ?? []
    ).sort((a, b) => {
      const aNanoSeconds = a?.createdAt._seconds ?? 0;
      const bNanoSeconds = b?.createdAt._seconds ?? 0;

      return (aNanoSeconds - bNanoSeconds) * (asc ? 1 : -1);
    }) as Activity[];
  }, [activityList, filter, asc]);

  useOnEvent<TicketActivityEvents>("createComment", () => {
    activityListState.refetch({
      input: {
        ticket: ticket.id,
      },
    });
  });

  useOnEvent<TicketActivityEvents>("updateComment", (updatedComment) => {
    activityListState.updateItem(
      // TODO: fix `any` type
      (item: Activity) => item.id === (updatedComment as any).id,
      updatedComment,
    );
  });

  useOnEvent<TicketActivityEvents>("requestDeleteComment", async (comment) => {
    if (
      !window.confirm("Are you sure you want delete this comment permanently?")
    ) {
      return;
    }

    // assuming happy case scenario
    // what if following request fails?
    // should at least refetchList to show back the item
    // and show failure feedback ofc
    activityListState.removeItem(
      // TODO: fix `any` type
      (item: Activity) => item.id === (comment as any).id,
    );

    await deleteComment({
      ticket: ticket.id,
      // TODO: fix `any` type
      comment: (comment as any).id!,
    });
  });

  useOnEvent<TicketActivityEvents>("makeCommentPublic", async (commentData) => {
    if (
      !window.confirm(
        "Are you sure you want to make this comment public? This comment will be visible for everyone.",
      )
    ) {
      return;
    }

    try {
      // TODO: make rollback to old comment in case request is failed
      if (!commentData) return;
      activityListState.updateItem(
        (comment: CommentActivity) => comment.id === commentData.id,
        { ...commentData, private: false },
      );
      await updateCommentCloudFunction({
        input: {
          ...commentData,
          text: commentData.text as DecodedRichText,
          private: false,
        },
        ticket: ticket.id,
      });
    } catch (error) {
      console.error(error);
    }
  });

  useOnEvent<TicketActivityEvents>(
    "makeCommentPrivate",
    async (commentData) => {
      try {
        // TODO: make rollback to old comment in case request is failed
        if (!commentData) return;
        activityListState.updateItem(
          (comment: CommentActivity) => comment.id === commentData.id,
          { ...commentData, private: true },
        );
        await updateCommentCloudFunction({
          input: {
            ...commentData,
            text: commentData.text as DecodedRichText,
            private: true,
          },
          ticket: ticket.id,
        });
      } catch (error) {
        console.error(error);
      }
    },
  );

  const onFiltersChange = (value: ActivityFilterType) => {
    setFilter(value);

    activityListState.refetch(
      {
        input: {
          ticket: ticket.id,
        },
      },
      {
        resetBeforeFetch: true,
      },
    );
  };

  const isLoading = activityListState.loading;

  return (
    <TicketActivityProvider ticket={ticket}>
      <div className={classes.root}>
        <Label label="Activity" className={classes.label} />

        <TicketActivityFilter
          filter={filter}
          onChange={onFiltersChange}
          asc={asc}
          onChangeSort={setAsc}
        />

        {activityListState.loading && (
          <div className={classes.loadingPlaceholders}>
            <Skeleton variant="text" width="50%" />
            <Skeleton variant="text" width="80%" />
            <Skeleton variant="text" width="40%" />
            <Skeleton variant="text" width="60%" />
          </div>
        )}

        {(filter === "all" || filter === "history") && !isLoading && (
          <HistoryList records={filteredActivityList} />
        )}

        {filter === "comment" && !isLoading && (
          <CommentList
            records={filteredActivityList}
            autoFocusOnComment={autoFocusOnComment}
          />
        )}
      </div>
    </TicketActivityProvider>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },
  loadingPlaceholders: {
    marginTop: theme.spacing(2),
  },
  label: {
    marginBottom: theme.spacing(0.5),
  },
}));
