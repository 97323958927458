import React, { useEffect, useMemo, useState } from "react";
import { Collapse, FormHelperText, Grid, MenuItem, Select } from "@mui/material";
import { Automation, CustomField, CustomFieldsWithValue } from "@functions-types";
import clsx from "clsx";
import { FieldComponent } from "@components/TicketCard/CustomFields/FieldComponent";
import { Button } from "@components/Button";
import { makeStyles } from "@providers/Mui";
import { useForm } from "@components/Form/FormProvider";
import { useWatch } from "react-hook-form";
import { isNonNullish } from "@lib/utils";
import { usePrevious } from "@hooks/usePrevious";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    "&:not(:last-child)": {
      paddingBottom: theme.spacing(2),
      marginBottom: theme.spacing(2),
      borderBottom: `1px solid ${theme.palette.secondary.light}`,
    },
  },
  select: {
    backgroundColor: theme.palette.common.white,
  },
  valueType: {
    marginBottom: theme.spacing(1),
  },
  customField: {
    backgroundColor: theme.palette.common.white,
  },
}));

type ValueOption = {
  id: "any" | "defined";
  name: string;
};

const valueTypes: ValueOption[] = [
  { id: "any", name: "Any value" },
  { id: "defined", name: "Defined value" },
];

type CustomFieldFormProps = {
  customFields: CustomField[];
  currentField?: CustomField;
  customFieldsValues: Automation["customFields"] | null;
}

export const CustomFieldForm: React.FC<CustomFieldFormProps> = ({
  customFields,
  currentField,
  customFieldsValues,
}) => {
  const classes = useStyles();

  const { methods: { setValue } } = useForm();
  const [customField, setCustomField] = useState<CustomField | null>(currentField ?? null);
  const [valueType, setValueType] = useState<ValueOption | null>(null);
  const previousValueType = usePrevious(valueType);
  const [error, setError] = useState<string | null>(null);

  const customFieldValue: Record<string, CustomFieldsWithValue> = useWatch({
    name: "customFieldsValues"
  });

  const availableFields = useMemo(() => {
    if (!customFields) return [];
    const customFieldsMap = (customFieldsValues ?? []).reduce((acc, { customField }) => ({
      ...acc,
      [customField?.id ?? ""]: true,
    }), {} as Record<string, boolean>);
    return customFields.filter(isNonNullish).filter(({ id }) => !customFieldsMap[id]);
  }, [customFields, customFieldsValues]);

  useEffect(() => {
    setValueType(null);
  }, [customField]);

  const handleAddCustomField = () => {
    if (!customField?.id) return;

    if (
      valueType?.id === "defined" &&
      (!customFieldValue || customFieldValue[customField?.id] === undefined)
    ) {
      setError("Please set value");
      return;
    }
    (customFieldsValues ?? []).map((currentField) => {
      if (currentField.customField?.id === customField.id) {
        return { customField, value: customFieldValue?.[customField.id] }
      }
      return currentField;
    })
    if (currentField?.id) {
      setValue("customFields", (customFieldsValues ?? []).map((currentField) => {
        if (currentField.customField?.id === customField.id) {
          return { customField, value: customFieldValue?.[customField.id] }
        }
        return currentField;
      }));
    } else {
      setValue("customFields", [
        ...(customFieldsValues ?? []),
        { customField, value: customFieldValue?.[customField.id] }
      ]);
      setCustomField(null);
      setValueType(null);
      setError(null);
    }
  };

  useEffect(() => {
    if (currentField?.id && customFieldsValues && valueType === null) {
      const currentValue = customFieldsValues
        .find(({ customField }) => customField?.id === currentField.id);
      if (currentValue?.value !== null && currentValue?.value !== undefined) {
        setValueType(valueTypes[1]);
      } else {
        setValueType(valueTypes[0]);
      }
    }
  }, [currentField, customFieldsValues, valueType]);

  useEffect(() => {
    if (valueType?.id === "any" && customField?.id && previousValueType?.id !== valueType?.id) {
      setValue(
        "customFieldsValues",
        {
          ...(customFieldValue ?? {}),
          [customField.id]: undefined,
        },
      );
    }
  }, [valueType, customFieldValue, customField]);

  return (
    <div className={!!currentField?.id ? classes.root : undefined}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Select<CustomField | null>
            fullWidth
            displayEmpty
            onChange={(event) => {
              setCustomField(event.target.value as CustomField);
            }}
            size="small"
            value={customField}
            className={classes.select}
            renderValue={() => <>{customField?.name ?? "Select custom field"}</>}
            disabled={!!currentField?.id}
          >
            {availableFields.map((option) => (
              <MenuItem
                value={option as unknown as string}
                key={option.id}
              >
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={6}>
          <Select<ValueOption | null>
            fullWidth
            displayEmpty
            onChange={(event) => {
              setValueType(event.target.value as ValueOption);
            }}
            size="small"
            value={valueType}
            className={clsx(classes.select, classes.valueType)}
            renderValue={() => <>{valueType?.name ?? "Select value type"}</>}
            disabled={!customField}
          >
            {valueTypes.map((option) => (
              <MenuItem
                value={option as unknown as string}
                key={option.id}
              >
                {option.name}
              </MenuItem>
            ))}
          </Select>
          <Collapse in={valueType?.id === "defined"}>
            {customField && (
              <FieldComponent
                field={customField}
                className={classes.customField}
              />
            )}
          </Collapse>
        </Grid>
      </Grid>
      <FormHelperText error>{error}</FormHelperText>
      {!currentField?.id && (
        <Button
          variant="contained"
          onClick={handleAddCustomField}
          disabled={!valueType}
        >
          Add
        </Button>
      )}
    </div>
  );
};
