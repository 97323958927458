import React, { useMemo } from "react";
import clsx from "clsx";
import { Grid, MenuItem, Select } from "@mui/material";
import { OptionsTree } from "@functions-types";
import { makeStyles } from "@providers/Mui";
import { HasClassName } from "@lib/types";

type SelectLevelProps = HasClassName & {
  level: number;
  value: string[];
  options: OptionsTree[];
  onChange: (level: number, value: string) => void;
  disabled?: boolean;
  hasError?: boolean;
};

const useStyles = makeStyles((theme) => ({
  root: {
    "& > fieldset": {
      top: 0,
    },
    "& legend": {
      display: "none",
    },
  },
  hiddenIcon: {
    display: "none",
  },
  error: {
    color: theme.palette.error.dark,
    fontSize: 12,
    paddingLeft: 14,
    paddingTop: theme.spacing(0.5),
  },
}));

export const SelectLevel: React.FC<SelectLevelProps> = ({
  level,
  value,
  options,
  onChange,
  disabled,
  hasError,
  className,
}) => {
  const classes = useStyles();

  const fieldValue = useMemo(() => {
    return value[level] || "";
  }, [value, level]);

  const nextLevel = useMemo(() => {
    if (!fieldValue) {
      return null;
    }

    const selectedItem = options.find(
      (leaf) => leaf.value === fieldValue,
    );

    if (!selectedItem?.children) {
      return null;
    }

    return selectedItem.children;
  }, [fieldValue, options]);

  if (!options.length) {
    return null;
  }

  return (
    <>
      <Grid item sm={3} xs={12}>
        <Select
          fullWidth
          onChange={(event) => {
            onChange(level, event.target.value);
          }}
          disabled={disabled}
          size="small"
          error={hasError}
          value={fieldValue}
          classes={{
            outlined: className,
            icon: disabled ? classes.hiddenIcon : undefined,
          }}
          className={clsx(classes.root, className)}
        >
          {options.map((option, index) => (
            <MenuItem
              value={option.value}
              key={`${option.value}-${index}`}
            >
              {option.value}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      {nextLevel && (
        <SelectLevel
          value={value}
          options={nextLevel}
          onChange={onChange}
          disabled={disabled}
          hasError={hasError}
          level={level + 1}
        />
      )}
    </>
  );
};
