import { createContext, FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Ticket } from "@functions-types";
import { useAsync } from "@hooks/useAsync";
import { createApprovalFeedback } from "@cloud-functions";
import { approvalRulesToArray, TicketApprovalRuleWithId } from "./utils/approvalRulesToArray";
import { getActiveIndex } from "./utils";
import { useSnackbar } from "@providers/Snackbar";
import { Typography } from "@mui/material";
import { useDispatch } from "@providers/EventsProvider";
import { GlobalTicketEvents } from "@lib/types";

type TicketApprovalContextValue = {
  ticket: Ticket;
  approve: (ruleId: string) => Promise<void>;
  decline: (ruleId: string, comment: string) => Promise<void>;
  isLoading: boolean;
  hasNoApprovalRules: boolean;
  activeRuleIndex: number;
  rules: TicketApprovalRuleWithId[];
};

export type TicketApprovalProviderProps = {
  ticket: Ticket;
  onTicketUpdate: (ticket: Ticket) => void;
};

const TicketApprovalContext = createContext<TicketApprovalContextValue>(
  null as unknown as TicketApprovalContextValue,
);

export const TicketApprovalProvider: FC<TicketApprovalProviderProps> = ({
  children,
  ticket: ticketFromProps,
  onTicketUpdate,
}) => {
  const { showError } = useSnackbar();
  const [ticket, setTicket] = useState(ticketFromProps);
  const dispatch = useDispatch<GlobalTicketEvents>();

  const rules = useMemo(() => approvalRulesToArray(ticket.approvalRules), [ticket]);
  const hasNoApprovalRules = !rules || !rules.length;

  const activeRuleIndex = useMemo(() => {
    return getActiveIndex(ticket.approvalRules);
  }, [ticket]);

  // keep internal ticket in sync with outer ticket
  useEffect(() => {
    setTicket(ticketFromProps);
  }, [ticketFromProps]);

  const [approveFn, approveState] = useAsync(createApprovalFeedback);

  const approve = useCallback(
    async (ruleId: string) => {
      try {
        const updatedTicket = await approveFn({
          input: {
            id: ticket.id,
            ruleId,
            feedback: {
              comment: null,
              result: "approved",
            },
          },
        });
        setTicket(updatedTicket);
        onTicketUpdate(updatedTicket);
        dispatch("update-tickets");
      } catch {
        showError(<Typography variant="body2">Something went wrong</Typography>);
      }
    },
    [onTicketUpdate],
  );

  const decline = useCallback(
    async (ruleId: string, comment: string) => {
      try {
        const updatedTicket = await approveFn({
          input: {
            id: ticket.id,
            ruleId,
            feedback: {
              comment: comment.trim() || null,
              result: "rejected",
            },
          },
        });
        setTicket(updatedTicket);
        onTicketUpdate(updatedTicket);
        dispatch("update-tickets");
      } catch {
        showError(<Typography variant="body2">Something went wrong</Typography>);
      }
    },
    [onTicketUpdate],
  );

  const value: TicketApprovalContextValue = useMemo(
    () => ({
      ticket,
      approve,
      decline,
      isLoading: approveState.isLoading,
      rules,
      hasNoApprovalRules,
      activeRuleIndex,
    }),
    [ticket, approveState.isLoading, rules, hasNoApprovalRules, activeRuleIndex, approve, decline],
  );
  return <TicketApprovalContext.Provider value={value}>{children}</TicketApprovalContext.Provider>;
};

export const useTicketApproval = () => useContext(TicketApprovalContext);
