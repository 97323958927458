import React, { ReactNode } from "react";

export function isNonNullish<T>(x: T): x is NonNullable<T> {
  return x != null;
}

export const repeatNodes = (times: number, renderNode: (key: number) => ReactNode) =>
  Array(times)
    .fill(0)
    .map((_, key) => renderNode(key));

export function moveItem<T>(arr: T[], fromIndex: number, toIndex: number): T[] {
  const movedItem = arr[fromIndex];
  const result = [...arr];
  result.splice(fromIndex, 1);
  result.splice(toIndex, 0, movedItem);
  return result;
}

export function joinNodes(items: React.ReactNode[], separator: React.ReactNode) {
  return items.reduce((result: React.ReactNode[], item, index) => {
    if (index < items.length - 1) {
      return [
        ...result,
        <React.Fragment key={`${index}-item`}>{item}</React.Fragment>,
        <React.Fragment key={`${index}-separator`}>{separator}</React.Fragment>,
      ];
    }

    return [...result, <React.Fragment key={`${index}-item`}>{item}</React.Fragment>];
  }, []);
}
