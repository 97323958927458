import { User } from "@functions-types";
import { makeStyles } from "@providers/Mui";
import { FC } from "react";
import { UserCard } from "@components/UserCard";

type UserValueProps = {
  value: User;
};

export const UserValue: FC<UserValueProps> = ({ value }) => {
  const classes = useStyles();

  return (
    <UserCard user={value} withBackground className={classes.root} />
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    "&:last-child": {
      marginRight: 0,
      marginBottom: 0,
    },
  },
}));
