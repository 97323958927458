import { FieldView } from "@components/Form/FieldView";
import { makeStyles } from "@providers/Mui";
import { FieldLabel } from "@components/Form/FieldLabel";
import StarIcon from "@mui/icons-material/Star";
import { Rating, RatingProps } from "@mui/material";
import React from "react";

export type RatingFieldViewerProps = Pick<RatingProps, "size"> & {
  field: string;
  value?: number;
  label?: React.ReactNode;
  hasUnsavedChanges?: boolean;
  emptyValue?: React.ReactNode;
  className?: string;
  onClick?: () => void;
};

export const RatingFieldViewer = ({
  value = 0,
  label,
  hasUnsavedChanges,
  onClick,
  size,
}: RatingFieldViewerProps) => {
  const classes = useStyles();

  return (
    <>
      <FieldLabel label={label} hasUnsavedChanges={hasUnsavedChanges} />
      <Rating
        disabled
        value={Number(value)}
        emptyIcon={
          <StarIcon
            sx={{ opacity: 0.5 }}
            fontSize="inherit"
          />
        }
        classes={classes}
        onClick={onClick}
        size={size}
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  sizeLarge: {
    fontSize: 46,
  },
  root: {
    color: theme.palette.yellow[300],
  }
}));
