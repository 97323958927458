import { Button } from "@mui/material";
import { makeStyles } from "@providers/Mui";
import { FC } from "react";
import { useForm } from "../FormProvider";
import { ClassNameMapOf } from "@lib/types";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    margin: theme.spacing(1, 0),
  },
  button: {
    marginLeft: theme.spacing(2),
    textTransform: "none",
  },
}));

export type FieldSaveButtonsProps = {
  field: string;
  showSaveButton?: boolean;
  showCancelButton?: boolean;
  saveButtonText?: React.ReactNode;
  onCancel?: () => void;
  classes?: ClassNameMapOf<typeof useStyles>;
  children?: React.ReactNode;
};

export const FieldSaveButtons: FC<FieldSaveButtonsProps> = ({
  field,
  showSaveButton = true,
  showCancelButton = true,
  saveButtonText = "Save",
  onCancel,
  children,
  ...props
}) => {
  const classes = useStyles(props);
  const { resetActiveFields, formMode, methods } = useForm();

  if (formMode === "create") return null;
  if (!showCancelButton && !showSaveButton) return <>{children}</>;

  return (
    <div className={classes.root}>
      {children}
      {showCancelButton && (
        <Button
          className={classes.button}
          variant="text"
          type="button"
          disabled={methods.formState.isSubmitting}
          onClick={() => {
            methods.resetField(field);
            resetActiveFields();
            onCancel?.();
          }}>
          Cancel
        </Button>
      )}
      {showSaveButton && (
        <Button
          data-cy={`cypress_fieldSaveButton_${field}`}
          className={classes.button}
          variant="outlined"
          type="submit"
          disabled={methods.formState.isSubmitting}>
          {saveButtonText}
        </Button>
      )}
    </div>
  );
};
