import React, { useCallback, useEffect, useRef } from "react";
import { Controller } from "react-hook-form";
import {
  Autocomplete as MuiAutocomplete,
  AutocompleteProps as MuiAutocompleteProps,
  CircularProgress,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { Modify } from "@lib/types";
import { useDebounce } from "@hooks/useDebounce";
import { useForm, useOnFieldAcivated } from "@components/Form/FormProvider";
import { FieldModeSwitch } from "@components/Form/FieldModeSwitch";
import { FieldSaveButtons } from "@components/Form/FieldSaveButtons";
import { FieldLabel } from "@components/Form/FieldLabel";
import { AsyncAutocompleteViewer, AsyncAutocompleteViewerProps } from "./AsyncAutocompleteViewer";
import { makeStyles } from "@providers/Mui";

type AsyncAutocompleteProps<
  T extends { id: unknown },
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> = Modify<
  Omit<MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, "options">,
  {
    renderInput?:
      | MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>["renderInput"]
      | undefined;
    name: string;
    label: TextFieldProps["label"];
    action?: React.ReactNode;
    getValue?: (data: null | T) => unknown;
    getOptionIcon?: (option: T | null) => React.ReactNode;
    getOptionText?: (option: T) => string;
    search: (query: string) => Promise<T[]>;
    defaultOptions?: T[];
    onCancelChangesClick?: () => void;
    renderItem?: (item: T) => React.ReactNode;
    AsyncAutocompleteViewerProps?: Partial<AsyncAutocompleteViewerProps>;
    required?: boolean;
  }
>;

type Option = { id: string | null };

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    backgroundColor: "white",
  },
  tag: {
    width: "100%",
    marginBottom: 6,
    textAlign: "left",
    justifyContent: "space-between",
    "&:last-child": {
      marginBottom: 0,
    },
  },
  input: {
    "input&&&&": {
      width: "100%",
      fontSize: 15,
    },
  },
  loader: {
    position: "absolute",
    bottom: 14,
    right: theme.spacing(2),
  },
}));

export const AsyncAutocomplete = <
  T extends Option,
  Multiple extends boolean | undefined = boolean,
  DisableClearable extends boolean | undefined = boolean,
  FreeSolo extends boolean | undefined = boolean,
>({
  name,
  label,
  action,
  isOptionEqualToValue,
  getOptionIcon,
  search: userSearch,
  defaultOptions,
  getOptionText,
  multiple,
  onCancelChangesClick,
  renderItem,
  AsyncAutocompleteViewerProps,
  required,
  ...props
}: AsyncAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) => {
  const { methods } = useForm();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const classes = useStyles();

  useOnFieldAcivated(name, () => {
    inputRef.current?.focus();
    setOpen(true);
  });

  const defaultValue = methods.control._defaultValues[name];
  const [searchQuery, setSearchQuery] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<readonly T[] | null>(defaultOptions ?? null);

  const search = useCallback(
    async (query: string) => {
      const results = await userSearch(query);
      setOptions(results);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userSearch],
  );

  const debouncedSearch = useDebounce(search, 500);
  const loading = Boolean(open && options === null && searchQuery.length);

  useEffect(() => {
    if (searchQuery) {
      setOptions(null); // show loading instantly
      debouncedSearch(searchQuery);
    } else if (defaultOptions) {
      setOptions(defaultOptions);
    }
  }, [debouncedSearch, searchQuery, defaultOptions]);

  const getOptionLabel = (option: T) => {
    if (!option) return "-N/A-";

    return getOptionText?.(option) ?? option.id ?? "-";
  };

  useEffect(() => {
    if (defaultOptions) {
      setOptions(defaultOptions);
    }
  }, [defaultOptions]);

  return (
    <Controller
      name={name}
      render={({ field: { onChange, ...field }, fieldState }) => {
        const hasUnsavedChanges =
          field.value && typeof field.value === "object"
            ? field.value.id !== defaultValue?.id
            : field.value !== defaultValue;

        return (
          <FieldModeSwitch
            field={name}
            edit={
              <MuiAutocomplete<T, Multiple, DisableClearable, FreeSolo>
                {...field}
                {...props}
                multiple={multiple}
                onChange={(_, data) => {
                  onChange(data ?? null);

                  if (multiple) {
                    setSearchQuery("");
                  }
                }}
                classes={classes}
                disabled={methods.formState.isSubmitting}
                isOptionEqualToValue={isOptionEqualToValue}
                getOptionLabel={getOptionLabel}
                defaultValue={defaultValue}
                value={field.value ?? (multiple ? [] : null)}
                options={options ?? []}
                loading={loading}
                open={Boolean(open && (searchQuery.length || defaultOptions?.length))}
                renderOption={(props, option) => (
                  <li {...props} key={option.id} data-cy={`cypress_asyncAutocompleteItem_${name}`}>
                    {getOptionIcon?.(option)}
                    {getOptionText?.(option) ?? (option as any).label}
                  </li>
                )}
                onOpen={() => {
                  setOpen(true);
                }}
                onClose={() => {
                  setOpen(false);
                  setOptions([]);
                  setSearchQuery("");
                }}
                filterOptions={(o) => o}
                filterSelectedOptions
                ChipProps={{
                  className: classes.tag,
                }}
                renderInput={(props) => (
                  <>
                    <FieldLabel label={label} name={name} required={required} />

                    <TextField
                      {...props}
                      id={name}
                      disabled={methods.formState.isSubmitting}
                      error={Boolean(fieldState.error?.message)}
                      helperText={fieldState.error?.message}
                      data-cy={`cypress_asyncAutocomplete_${name}`}
                      size="small"
                      fullWidth
                      onChange={(e) => {
                        setSearchQuery(e.target.value);
                      }}
                      InputProps={{
                        ...props.InputProps,
                        style: {
                          paddingRight: "8px",
                        },
                        placeholder: "Start typing to search...",
                        endAdornment: loading ? (
                          <CircularProgress color="inherit" size={20} className={classes.loader} />
                        ) : null,
                      }}
                      classes={{ root: classes.input }}
                      inputProps={
                        multiple
                          ? {
                              ...props.inputProps,
                              value: searchQuery,
                            }
                          : props.inputProps
                      }
                    />

                    <FieldSaveButtons field={name} onCancel={onCancelChangesClick} />
                  </>
                )}
              />
            }
            view={
              <AsyncAutocompleteViewer<T>
                multiple={multiple}
                hasUnsavedChanges={hasUnsavedChanges}
                label={label}
                action={action}
                value={defaultValue ?? "N/A"}
                field={name}
                renderItem={renderItem}
                getOptionText={getOptionText}
                {...AsyncAutocompleteViewerProps}
              />
            }
          />
        );
      }}
    />
  );
};
