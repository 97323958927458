import { Typography } from "@mui/material";
import { makeStyles } from "@providers/Mui";
import { FC } from "react";

type EmptyMessageProps = {};

export const EmptyMessage: FC<EmptyMessageProps> = ({ children }) => {
  const classes = useStyles();

  return (
    <Typography className={classes.root} variant="body2">
      {children}
    </Typography>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    color: "#585858",
  },
}));
