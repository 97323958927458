import React from "react";
import { makeStyles } from "@providers/Mui";
import { Grid } from "@mui/material";
import { SelectField, TextField } from "@components/Form";
import { FieldLabel } from "@components/Form/FieldLabel";
import { units } from "../utils";

const useStyles = makeStyles((theme) => ({
  root: {},
  switcher: {
    width: "100%",
    marginBottom: theme.spacing(1.5),
  },
  field: {
    "& .MuiOutlinedInput-root": {
      backgroundColor: "white",
      borderRadius: "4px 0 0 4px",
    },
  },
  select: {
    backgroundColor: "white",
    minWidth: 105,
    "&&& .MuiOutlinedInput-notchedOutline": {
      borderLeft: 0,
      borderRadius: "0 4px 4px 0",
      borderColor: theme.palette.secondary.main,
      borderWidth: 1,
    },
  },
}));

type SlaFieldProps = {
  name: string;
  label: string;
};

export const SlaField: React.FC<SlaFieldProps> = ({ name, label }) => {
  const classes = useStyles();

  return (
    <>
      <FieldLabel label={label} />
      <Grid container flexWrap="nowrap">
        <TextField
          name={`${name}.value`}
          label={null}
          variant="outlined"
          fullWidth
          size="small"
          className={classes.field}
          FieldModeSwitchProps={{
            className: classes.switcher,
          }}
        />
        <SelectField
          label={null}
          name={`${name}.unit`}
          options={units}
          fullWidth
          getOptionText={(option) => option.label}
          className={classes.select}
        />
      </Grid>
    </>
  );
};
