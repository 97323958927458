import React, { useEffect, useMemo, useRef } from "react";
import { makeStyles } from "@providers/Mui";
import { H6 } from "@components/Typography";
import { Collapse, Grid, Typography } from "@mui/material";
import { Form, FormRef } from "@components/Form";
import { Filter, FilterableProperties, useTicketFilter } from "@containers/TicketFilter";
import { AutomationFormState as AutomationFormType } from "../types";
import { automationFormValidator } from "../automationFormValidator";
import { AutomationDetails } from "./AutomationDetails";
import { Button } from "@components/Button";
import { ConditionCard } from "./ConditionCard";
import { Blocker } from "@components/Blocker";
import { Label } from "@components/Label";
import { formStateToInput, fromModelState } from "@pages/Admin/Automation/utils";
import { logError } from "@lib/logger";
import { useSnackbar } from "@providers/Snackbar";
import { useAsync } from "@hooks/useAsync";
import {
  Automation,
  createAutomation as createAutomationCloudFunction, CTITree, Status,
  updateAutomation as updateAutomationCloudFunction, useListStatuses,
} from "@cloud-functions";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[200],
    height: "100%",
    overflowX: "auto",
  },
  container: {
    paddingBottom: theme.spacing(2),
    "&:last-children": {
      paddingBottom: 0,
    },
  },
  field: {
    "& .MuiOutlinedInput-root": {
      backgroundColor: theme.palette.common.white,
    },
  },
  select: {
    backgroundColor: theme.palette.common.white,
  },
  group: {
    margin: theme.spacing(1, 0, 2),
    borderLeft: `2px solid ${theme.palette.grey[400]}`,
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
  },
  content: {
    height: "100%",
  },
}));

type AutomationFormProps = {
  selectedAutomation: Automation | null;
  setSelectedAutomation: (automation: Automation) => void;
  refetch: () => void;
  loading: boolean;
  categories: CTITree[];
  statuses: Status[];
};

export const AutomationForm: React.FC<AutomationFormProps> = ({
  selectedAutomation,
  setSelectedAutomation,
  refetch,
  loading: externalLoading,
  categories,
  statuses,
}) => {
  const classes = useStyles();
  const automationFormRef = useRef<FormRef<AutomationFormType> | null>(null);
  const { showError } = useSnackbar();
  const { filters, remainingFields, addFilter, removeFilter, loading, selects, applyFilters } =
    useTicketFilter({
      fields: ["status", "severity", "country", "assignee", "createdBy"],
      operationsMap: {
        severity: ["==", "!="],
        country: ["==", "!="],
        assignee: ["in", "==", "!="],
        status: ["==", "!=", "in"],
        createdBy: ["in", "==", "!="],
      },
    });

  const [createAutomation, createAutomationState] = useAsync(createAutomationCloudFunction);
  const [updateAutomation, updateAutomationState] = useAsync(updateAutomationCloudFunction);

  const handleSubmit = async (values: AutomationFormType) => {
    try {
      let automation: Automation | null = null;
      if (selectedAutomation?.id) {
        automation = await updateAutomation({
          input: {
            id: selectedAutomation.id,
            updates: {
              id: selectedAutomation.id,
              ...formStateToInput(values, filters),
            },
          },
        });
      } else {
        automation = await createAutomation({
          input: formStateToInput(values, filters),
        });
      }

      if (automation) {
        setSelectedAutomation(automation);
        refetch();
        return;
      }

      throw "Something went wrong during saving automation";
    } catch (error) {
      logError(error);

      showError(
        <Typography variant="body2">Something went wrong during saving automation</Typography>,
      );
    }
  };

  const defaultValues = useMemo(
    () => (selectedAutomation ? fromModelState(selectedAutomation, statuses) : undefined),
    [selectedAutomation, statuses],
  );

  useEffect(() => {
    if (selectedAutomation?.filters) {
      applyFilters(selectedAutomation.filters);
    }
  }, [selectedAutomation]);

  const isLoading = loading
  || createAutomationState.isLoading
  || updateAutomationState.isLoading
  || externalLoading;

  return (
    <Grid className={classes.root}>
      <div className={classes.container}>
        <H6>Create automation</H6>
      </div>
      <Form<AutomationFormType>
        formMode="create"
        onSubmit={handleSubmit}
        resolver={automationFormValidator}
        key={selectedAutomation?.id}
        ref_={automationFormRef}
        defaultValues={defaultValues}
      >
        <AutomationDetails
          automation={selectedAutomation}
          categories={categories}
          statuses={statuses}
        />
      </Form>
      <div className={classes.container}>
        <Label label="Automation conditions" />
        {!!remainingFields.length && (
          <Filter<FilterableProperties>
            options={remainingFields}
            onAdd={addFilter}
            selectsMap={selects}
          />
        )}
      </div>
      <Collapse in={!!filters.length}>
        <div className={classes.group}>
          {filters.map((props) => (
            <ConditionCard {...props} onRemove={() => removeFilter(props)} key={props.field.id} />
          ))}
        </div>
      </Collapse>
      <div className={classes.container}>
        <Button
          type="submit"
          variant="contained"
          onClick={automationFormRef?.current?.triggerSubmit}>
          Save
        </Button>
      </div>
      <Blocker open={isLoading} />
    </Grid>
  );
};
