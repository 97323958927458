import React from "react";
import { Popup } from "@components/Popup";
import { makeStyles } from "@providers/Mui";
import { DialogContent, DialogTitle, Typography } from "@mui/material";
import { SearchResult } from "./SearchResult";
import { SearchProvider } from "./SearchProvider";
import { SearchTextField } from "@components/SearchTextField";
import { useSearchTickets } from "@hooks/useSearchTickets";

type SearchDialogProps = {
  open: boolean;
  onClose: () => void;
};

const usePopupStyles = makeStyles((theme) => ({
  icon: {
    marginLeft: theme.spacing(0.5),
    fontSize: 16,
  },
  paper: {
    padding: theme.spacing(1, 0),
    margin: theme.spacing(0.5),
    height: "calc(100% - 200px)",
    minWidth: "90%",

    [theme.breakpoints.up("sm")]: {
      minWidth: "60%",
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  header: {
    padding: theme.spacing(1, 0),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2),
    },
  },
  content: {
    padding: theme.spacing(1, 0),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2),
    },
  },
  results: {},
}));

export const SearchDialog: React.FC<SearchDialogProps> = ({ open, onClose }) => {
  const classes = useStyles();
  const popupClasses = usePopupStyles();

  const { isSearching, resetSearch, searchQuery, setSearchQuery, results } = useSearchTickets();

  const close = () => {
    resetSearch();
    onClose();
  };

  const loading = isSearching && open;

  return (
    <Popup open={open} onClose={close} maxWidth="xl" classes={popupClasses}>
      <DialogTitle className={classes.header}>
        <SearchTextField
          fullWidth
          showESCButton
          showSearchIcon
          onChange={setSearchQuery}
          loading={loading}
          onRequestClose={close}
        />
      </DialogTitle>

      <DialogContent className={classes.content}>
        {!loading && searchQuery && !results?.length && (
          <Typography>
            No results found for <strong>"{searchQuery}"</strong>
          </Typography>
        )}
        {searchQuery && (
          <SearchProvider query={searchQuery}>
            {!!results?.length && (
              <Typography>
                Results for <strong>"{searchQuery}"</strong>
              </Typography>
            )}

            <div className={classes.results}>
              {results?.map((ticket) => (
                <SearchResult key={ticket.id} ticket={ticket} onClick={close} />
              ))}
            </div>
          </SearchProvider>
        )}
      </DialogContent>
    </Popup>
  );
};
