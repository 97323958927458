import { CreateStoreRequest, Store } from "@functions-types";
import { StoreFormState } from "@pages/Admin/Stores/types";

type CreateStoreInput = CreateStoreRequest["input"];

export const formStateToInput = (state: StoreFormState): CreateStoreInput => ({
  ...state,
  managers: state.managers.map((m) => m.id),
  country: state.country.id,
});

export const storeToFormState = (store: Store | null): StoreFormState | undefined => {
  if (!store?.country?.id || !store?.managers) return undefined;
  return store as StoreFormState;
};
