import { CTIApprovalConfigRule } from "@functions-types";

export type CTIApprovalConfigRuleType = CTIApprovalConfigRule["type"];

export const ctiApprovalRuleTypeLabels: Record<
  CTIApprovalConfigRuleType,
  string
> = {
  manager_1: "First level manager",
  manager_2: "Second level manager",
  users: "Users",
  group: "Group",
};
