import React from "react";
import { Popup } from "@components/Popup";
import { TicketCard } from "@components/TicketCard";
import { makeStyles } from "@providers/Mui";
import { DialogContent } from "@mui/material";
import { DialogHeader } from "../DialogHeader";
import { TicketLink } from "@components/TicketLink";

type TicketDialogProps = {
  open: boolean;
  spaceKey?: string;
  ticketId: string;
  isPrivate: boolean;
  onClose: () => void;
};

const usePopupStyles = makeStyles((theme) => ({
  icon: {
    marginLeft: theme.spacing(0.5),
    fontSize: 16,
  },
  paper: {
    padding: theme.spacing(1, 0),
    margin: theme.spacing(0.5),
    maxHeight: "calc(100% - 8px)",

    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(4),
      maxHeight: "calc(100% - 64px)",
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  header: {
    padding: theme.spacing(1, 0),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2),
    },
  },
  content: {
    padding: theme.spacing(1, 0),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2),
    },
  },
}));

export const TicketDialog: React.FC<TicketDialogProps> = ({
  open,
  onClose,
  spaceKey,
  isPrivate,
  ticketId,
}) => {
  const classes = useStyles();
  const popupClasses = usePopupStyles();

  return (
    <Popup open={open} onClose={onClose} classes={popupClasses} maxWidth={false}>
      <DialogHeader
        onClose={onClose}
        header={<TicketLink spaceKey={spaceKey} ticketId={ticketId} isPrivate={isPrivate} />}
        className={classes.header}
      />

      <DialogContent className={classes.content}>
        <TicketCard ticketId={ticketId} />
      </DialogContent>
    </Popup>
  );
};
