import { Activity } from "@functions-types";
import { FC } from "react";
import { HistoryChangeRecord } from "./HistoryChangeRecord";
import { HistoryCommentRecord } from "./HistoryCommentRecord";
import { HistoryCreationRecord } from "./HistoryCreationRecord";
import { HistorySLARecord } from "@containers/TicketActivity/HistorySLARecord";

type HistoryRecordProps = {
  record: Activity;
};

export const HistoryRecord: FC<HistoryRecordProps> = ({ record }) => {
  if (record.type === "creation") {
    return <HistoryCreationRecord record={record} />;
  }

  if (record.type === "comment") {
    return <HistoryCommentRecord record={record} />;
  }

  if (record.type === "history") {
    return <HistoryChangeRecord record={record} />;
  }

  if (record.type === "sla") {
    return <HistorySLARecord record={record} />;
  }

  return null;
};
