import { makeCloudFunction } from "../makeCloudFunction";
import {
  UpdateWorkflowRequest,
  UpdateWorkflowResponse,
} from "@functions-types";

export const updateWorkflow = makeCloudFunction<
  UpdateWorkflowRequest,
  UpdateWorkflowResponse
>("workflow", "updateWorkflow");
