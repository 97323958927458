import { logError } from "@lib/logger";
import { useCallback, useRef, useState } from "react";

export type AsyncFunction<D> = (...args: any[]) => Promise<D>;

export type UseAsyncOptions = {
  reThrow?: boolean;
};

export function useAsync<
  D,
  F extends AsyncFunction<D>,
  R extends Awaited<ReturnType<F>> = Awaited<ReturnType<F>>,
>(fn: F, options: UseAsyncOptions = {}) {
  const { reThrow = false } = options;
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [data, setData] = useState<R | null>(null);
  const fnRef = useRef<F>(fn);

  // keep the fn ref fresh between rerenders
  fnRef.current = fn;

  const handler = useCallback(async (...args: Parameters<F>) => {
    try {
      setIsLoading(true);
      setError(null);

      const data = await fnRef.current(...args);

      setData(data as R);
      return data;
    } catch (err) {
      if (!reThrow) {
        logError(err);
      }

      setError(err as Error);

      if (reThrow) {
        setIsLoading(false);
        throw err;
      }
    } finally {
      setIsLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) as F;

  const reset = () => {
    setIsLoading(false);
    setError(null);
    setData(null);
  };

  return [handler, { data, isLoading, error, reset }] as const;
}
