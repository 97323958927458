import {
  Automation,
  UpdateAutomationRequest,
  UpdateAutomationResponse,
} from "@functions-types";
import { makeCloudFunction } from "../makeCloudFunction";

export type { Automation };
export const updateAutomation = makeCloudFunction<
  UpdateAutomationRequest,
  UpdateAutomationResponse
>("automation", "updateAutomation");
