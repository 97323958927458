import { default as Delta } from "quill-delta";
import { Controller } from "react-hook-form";
import { Modify } from "@lib/types";
import { FormHelperText } from "@mui/material";
import { FieldModeSwitch, FieldModeSwitchProps } from "@components/Form/FieldModeSwitch";
import { RichTextViewer, RichTextViewerProps } from "./RichTextViewer";
import { FieldLabel } from "@components/Form/FieldLabel";
import { FieldSaveButtons, FieldSaveButtonsProps } from "@components/Form/FieldSaveButtons";

import {
  QuillDelta,
  QuillEditor,
  QuillEditorProps,
  QuillEditorValue,
} from "@components/QuillEditor";
import { useEffect, useRef } from "react";
import { useForm, useOnFieldAcivated } from "@components/Form/FormProvider";
import { isEqual } from "lodash";
import { getTextFromHTML } from "./utils/getTextFromHTML";

type RichTextEditorProps = Modify<
  Omit<QuillEditorProps, "value" | "editorRef">,
  {
    label?: React.ReactNode;
    name: string;
    FieldSaveButtonsProps?: Partial<FieldSaveButtonsProps>;
    RichTextViewerProps?: Partial<RichTextViewerProps>;
    FieldModeSwitchProps?: Partial<FieldModeSwitchProps>;
    onActivation?: () => void;
    footer?: React.ReactNode;
    autoFocus?: boolean;
    autoActivate?: boolean;
    required?: boolean;
  }
>;

export const RichTextEditor = ({
  name,
  footer,
  FieldSaveButtonsProps,
  RichTextViewerProps,
  FieldModeSwitchProps,
  autoFocus = false,
  autoActivate = false,
  onActivation,
  required,
  ...props
}: RichTextEditorProps) => {
  const editorRef = useRef<any>();
  const { methods, activateField } = useForm();

  useOnFieldAcivated(name, () => {
    editorRef.current?.focus();
    onActivation?.();
  });

  useEffect(() => {
    if (autoActivate) {
      activateField(name, true);
    }
  }, [autoActivate]);

  useEffect(() => {
    if (autoFocus) {
      editorRef.current?.focus();
    }
  }, []);

  const defaultValue: QuillEditorValue | undefined = methods.control._defaultValues[name];

  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => {
        const error = fieldState.error?.message;
        const value = (field.value as QuillEditorValue) ??
          defaultValue ?? {
            json: new Delta(),
            text: "",
          };

        const hasUnsavedChanges = !isEqual(
          { ...(field.value?.json || {}) },
          { ...(defaultValue?.json || {}) },
        );

        return (
          <FieldModeSwitch
            field={name}
            edit={
              <div data-cy={`cypress_quillEditor_${name}`}>
                <FieldLabel label={props.label} name={name} required={required} />

                <QuillEditor
                  {...props}
                  disabled={props.disabled || methods.formState.isSubmitting}
                  editorRef={editorRef}
                  error={Boolean(error)}
                  value={value}
                  onChange={(html, delta, source, editor) => {
                    field.onChange({
                      json: editor.getContents(),
                      text: getTextFromHTML(html),
                    });
                  }}
                />

                {error && <FormHelperText error>{error}</FormHelperText>}

                {footer}

                <FieldSaveButtons field={name} {...FieldSaveButtonsProps} />
              </div>
            }
            view={
              <RichTextViewer
                hasUnsavedChanges={hasUnsavedChanges}
                label={props.label}
                delta={defaultValue?.json as QuillDelta}
                field={name}
                required={required}
                {...RichTextViewerProps}
              />
            }
            {...FieldModeSwitchProps}
          />
        );
      }}
    />
  );
};
