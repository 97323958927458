import React from "react";
import ViewListIcon from '@mui/icons-material/ViewList';
import { Grid, Typography, IconProps } from "@mui/material";
import { makeStyles } from "@providers/Mui";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    minHeight: 400,
  },
  icon: {
    color: theme.palette.secondary.main,
    fontSize: 60,
    marginBottom: theme.spacing(2),
  },
  message: {
    color: theme.palette.secondary.main,
    fontSize: 21,
    marginBottom: theme.spacing(2),
  },
}));


type TextNotificationProps = {
  message: React.ReactNode;
  IconComponent?: React.FC<IconProps>;
};

export const TextNotification: React.FC<TextNotificationProps> = ({
  message,
  IconComponent = ViewListIcon,
}) => {
  const classes = useStyles();

  return (
    <Grid container direction="column" alignItems="center" justifyContent="center" className={classes.root}>
      <IconComponent className={classes.icon} />
      <Typography variant="h4" className={classes.message}>
        {message}
      </Typography>
    </Grid>
  );
};
