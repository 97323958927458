import React from "react";
import ViewListIcon from '@mui/icons-material/ViewList';
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@providers/Mui";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    minHeight: 400,
  },
  icon: {
    color: theme.palette.secondary.main,
    fontSize: 48,
    marginBottom: theme.spacing(1.5),
  },
  message: {
    color: theme.palette.secondary.main,
    fontSize: 19,
    marginBottom: theme.spacing(2),
  },
}));

export const EmptyList: React.FC = () => {
  const classes = useStyles();

  return (
    <Grid container direction="column" alignItems="center" justifyContent="center" className={classes.root}>
      <ViewListIcon className={classes.icon} />
      <Typography variant="h4" className={classes.message}>
        No activity found
      </Typography>
    </Grid>
  );
};
