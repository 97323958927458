import { Ticket, CreateTicketRequest, CreateTicketResponse } from "@functions-types";
import { makeCloudFunction } from "../makeCloudFunction";

export type { Ticket };
export type TicketCreateLink = CreateTicketRequest["input"]["link"];

export const createTicket = makeCloudFunction<CreateTicketRequest, CreateTicketResponse>(
  "ticket",
  "createTicket",
);
