import { makeCloudFunction } from "../makeCloudFunction";
import { CustomPage } from "@functions-types";
import { makeUseCloudFunction } from "../makeUseCloudFunction";

export type { CustomPage };
export const listCustomPages = makeCloudFunction<void, CustomPage[]>(
  "customPage",
  "listCustomPages",
);
export const useListCustomPages = makeUseCloudFunction(listCustomPages);
