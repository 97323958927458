import React, { useMemo } from "react";
import { Ticket } from "@functions-types";
import { CustomPageCard } from "./CustomPageCard";

type CustomPageValuesProps = {
  customPages: Ticket["customPages"];
};

export const CustomPageValues: React.FC<CustomPageValuesProps> = ({
  customPages,
}) => {
  const customPagesList = useMemo(
    () => Object.values(customPages ?? {}),
    [customPages]
  );

  if (!customPagesList.length) {
    return null;
  }

  return (
    <>
      {customPagesList.map((customPage) => (
        <CustomPageCard customPage={customPage} key={customPage.id} />
      ))}
    </>
  );
};
