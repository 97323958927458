import { default as ReactQuill } from "react-quill";

import clsx from "clsx";
import { modules, formats } from "./quill.modules";
import { Modify } from "@lib/types";
import { OriginalQuillEditorProps, QuillEditorValue } from "./types";

import "react-quill/dist/quill.snow.css";
import "quill-mention";
import "quill-mention/dist/quill.mention.css";
import "./styles.scss";

export type QuillEditorProps = Modify<
  OriginalQuillEditorProps,
  {
    value: QuillEditorValue;
    error?: boolean;
    editorRef: React.MutableRefObject<ReactQuill | null>;
    minRows?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    disabled?: boolean;
  }
>;

export const QuillEditor = ({
  disabled = false,
  ...props
}: QuillEditorProps) => {
  const minRows = props.minRows ?? 1;

  return (
    <ReactQuill
      {...props}
      ref={props.editorRef}
      readOnly={props.readOnly || disabled}
      className={clsx(
        "editor",
        "edit-mode",
        props.error && "editor-with-error",
        `ql-editor-min-rows-${minRows}`,
        disabled && "editor-disabled",
      )}
      modules={modules}
      formats={formats}
      value={props.value.json}
    />
  );
};
