import React, { forwardRef } from "react";
import { makeStyles } from "@providers/Mui";
import { Button } from "@components/Button";
import { TreeItem } from "@mui/lab";

type AddNodeProps = {
  id: string;
  _ref: React.RefObject<HTMLButtonElement>;
  onClick: () => void;
};

const useStyles = makeStyles((theme) => ({
  newNode: {
    color: theme.palette.info.main,
    textTransform: "none",
    padding: 0,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  selected: {
    "&&": {
      backgroundColor: "transparent !important",
    },
  },
  content: {
    "&&:hover": {
      backgroundColor: "transparent",
    },
  },
  label: {
    "&&": {
      paddingLeft: 0,
    },
  },
}));

export const AddNode: React.FC<AddNodeProps> = ({ id, _ref, onClick }) => {
  const { newNode, ...classes } = useStyles();
  return (
    <TreeItem
      nodeId={`${id}-add`}
      label={
        <Button
          className={newNode}
          fullWidth={false}
          _ref={_ref}
          onClick={onClick}
        >
          Add field
        </Button>
      }
      classes={classes}
    />
  );
};
