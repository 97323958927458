import React from "react";
import { Hidden, Link, Tooltip } from "@mui/material";
import { HistoryModel } from "@functions-types";
import { makeStyles } from "@providers/Mui";
import { useNavigate } from "react-router-dom";
import { format, formatDistance } from "@lib/date";

type HistoryItemProps = HistoryModel & {};

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: 14,
    padding: theme.spacing(2, 0),
    "&:not(:last-child)": {
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
  },
  name: {
    paddingRight: theme.spacing(2),
    display: "inline-block",
  },
  type: {
    paddingRight: theme.spacing(1),
  },
  link: {
    cursor: "pointer",
    whiteSpace: "nowrap",
  },
  date: {
    paddingLeft: theme.spacing(1),
    fontSize: 12,
  },
}));

const mapHistoryType: Record<HistoryModel["type"], string> = {
  commentCreated: "commented on the ticket",
  ticketUpdated: "updated the ticket",
};

export const HistoryItem: React.FC<HistoryItemProps> = ({
  createdBy,
  createdAt,
  ticketId,
  type,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <div className={classes.root}>
      <strong className={classes.name}>{createdBy?.displayName}</strong>
      <span className={classes.type}>{mapHistoryType[type]}</span>
      <Link
        underline="none"
        className={classes.link}
        onClick={() => navigate(`/ticket/${ticketId}`)}>
        {ticketId}
      </Link>

      <Hidden smUp>
        <i className={classes.date}>
          {format(createdAt, "dd.MMM.yyyy hh:mm aaa")}
        </i>
      </Hidden>
      <Hidden smDown>
        <Tooltip
          title={format(createdAt, "dd.MMM.yyyy hh:mm aaa")}
          placement="top">
          <i className={classes.date}>{formatDistance(createdAt)}</i>
        </Tooltip>
      </Hidden>
    </div>
  );
};
