import React, { useCallback, useEffect, useRef, useState } from "react";
import { difference } from "lodash";
import { Grid, Tooltip, Button, CircularProgress, Typography } from "@mui/material";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Attachment } from "@functions-types";
import { FieldLabel } from "@components/Form/FieldLabel";
import { fieldsMap } from "@lib/constants";
import { makeStyles } from "@providers/Mui";
import { useUploading } from "@hooks/useUploading";
import { isNonNullish } from "@lib/utils";
import { AttachmentCard } from "./AttachmentCard";
import { useForm } from "@components/Form/FormProvider";
import { useSnackbar } from "@providers/Snackbar";

type AttachmentSectionProps = {
  attachments?: Attachment[];
};

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2, 0)
  },
  input: {
    display: "none",
  },
  button: {
    padding: theme.spacing(1),
    minWidth: 0,
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.info.main,
  },
  loader: {
    color: "white",
  }
}));

export const MAX_FILE_SIZE = 8 * 1024 * 1024; // 8Mb

export const AttachmentSection: React.FC<AttachmentSectionProps> = ({ attachments: defaultAttachments }) => {
  const classes = useStyles();
  const { methods, formMode, triggerSubmit } = useForm();
  const { setValue } = methods;
  const inputRef = useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<FileList | null>(null);
  const { upload, loading } = useUploading("ticket");
  const [attachments, setAttachments] = useState(defaultAttachments ?? []);
  const { showError } = useSnackbar();

  useEffect(() => {
    if (files) {
      (async () => {
        const uploadedRefs = await Promise.all(Array.from(files).map(async (file) => {
          if (file.size > MAX_FILE_SIZE) {
            showError(
              <Typography variant="body2">
                File {file.name} is too large. Max size is 8Mb.
              </Typography>,
            );
            return null;
          }
          const fileRef = await upload(file);
          if (fileRef) {
            return {
              fileRef,
              fileName: file.name,
            };
          }

          return null;
        }));
        setFiles(null);
        setAttachments((attachments) => [
          ...attachments,
          ...uploadedRefs.filter(isNonNullish),
        ]);
        if (inputRef.current?.value) {
          inputRef.current.value = "";
        }
      })();
    }
  }, [files]);

  const handleDelete = useCallback((fileRef: string) => {
    setAttachments((attachments) => attachments.filter((attachment) => attachment.fileRef !== fileRef));
  }, [attachments, formMode]);

  useEffect(() => {
    setValue("attachments", attachments);
    if (formMode === "edit" && difference(attachments, defaultAttachments ?? []).length) {
      triggerSubmit();
    }
  }, [formMode, attachments]);

  return (
    <Grid className={classes.root}>
      <FieldLabel label={fieldsMap.attachments} />
      {(attachments || []).map((attachment) => (
        <AttachmentCard
          {...attachment}
          onDelete={handleDelete}
          key={attachment.fileRef}
        />
      ))}
      <label htmlFor="attachment">
        <input
          name="attachment"
          id="attachment"
          type="file"
          className={classes.input}
          onChange={(event) => setFiles(event.target.files)}
          ref={inputRef}
        />
        <Tooltip title="Add ticket attachment">
          <Button
            color="primary"
            variant="contained"
            className={classes.button}
            disabled={loading}
            component="span"
          >
            {loading ? (
              <CircularProgress size={24} color="secondary" className={classes.loader} />
            ) : (
              <AttachFileIcon />
            )}
          </Button>
        </Tooltip>
      </label>
    </Grid>
  );
};
