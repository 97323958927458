import {
  Automation,
  CreateAutomationRequest,
  CreateAutomationResponse,
} from "@functions-types";
import { makeCloudFunction } from "../makeCloudFunction";

export type { Automation };
export const createAutomation = makeCloudFunction<
  CreateAutomationRequest,
  CreateAutomationResponse
>("automation", "createAutomation");
