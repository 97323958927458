import { FC, useCallback } from "react";
import { useWatch } from "react-hook-form";
import { Grid } from "@mui/material";
import { Attachment } from "@functions-types";
import { AttachmentCard } from "@components/TicketCard/AttachmentSection/AttachmentCard";
import { useForm } from "@components/Form/FormProvider";

export const CommentAttachmentsList: FC = () => {
  const { methods, formMode } = useForm();
  const { setValue } = methods;
  const attachments: Attachment[] = useWatch({ name: "attachments" }) || [];

  const handleDelete = useCallback(
    (fileRef: string, fileName: string) => {
      if (
        !window.confirm(
          `Are you sure you want delete attachment ${fileName} permanently?`,
        )
      ) {
        return;
      }

      setValue(
        "attachments",
        attachments.filter(
          (attachment: Attachment) => attachment.fileRef !== fileRef,
        ),
      );
    },
    [attachments, formMode],
  );

  return (
    <Grid>
      {attachments.map((attachment: Attachment) => (
        <AttachmentCard
          {...attachment}
          onDelete={handleDelete}
          key={attachment?.fileRef}
        />
      ))}
    </Grid>
  );
};
