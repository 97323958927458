import { ItemFormState } from "./types";
import { Item } from "@cloud-functions";
import { CreateItemRequest, SLAUnit } from "@functions-types";
import { isNonNullish } from "@lib/utils";
import { fromApprovalsConfigToApprovalsInput } from "../CategoryForm/utils";

export const units: { id: SLAUnit; label: string }[] = [
  { id: "minute", label: "Minutes" },
  { id: "hour", label: "Hours" },
  { id: "day", label: "Days" },
];

type CreateItemInput = CreateItemRequest["input"];

export const itemFormStateToValue = (state: ItemFormState): CreateItemInput => {
  const { firstResponseSLA, nextResponseSLA, resolveSLA } = state;
  return {
    name: state.name,
    type: state.type?.id ?? "",
    space: state.space?.id ?? "",
    default_severity: state.default_severity?.id ?? "",
    faq_link: state.faq_link,
    faq_label: state.faq_label,
    userTypeRestriction: state.userTypeRestriction,
    customFields: (state.customFields || []).filter(isNonNullish).map(({ id }) => id),
    firstResponseSLA: {
      value: Number(firstResponseSLA.value),
      unit: firstResponseSLA.unit.id,
    },
    nextResponseSLA: {
      value: Number(nextResponseSLA.value),
      unit: nextResponseSLA.unit.id,
    },
    resolveSLA: {
      value: Number(resolveSLA.value),
      unit: resolveSLA.unit.id,
    },
    approvals: fromApprovalsConfigToApprovalsInput(state.approvals),
  };
};

const getUnit = (unitId?: SLAUnit): { id: SLAUnit; label: string } => {
  if (!unitId) return units[0];
  return units.find(({ id }) => id === unitId) ?? units[0];
};

export const itemToFormState = (itemValues?: Item): ItemFormState | undefined => {
  if (!itemValues) return undefined;
  const { firstResponseSLA, nextResponseSLA, resolveSLA, ...values } = itemValues;
  return {
    ...values,
    firstResponseSLA: {
      value: firstResponseSLA?.value ?? 0,
      unit: getUnit(firstResponseSLA?.unit),
    },
    nextResponseSLA: {
      value: nextResponseSLA?.value ?? 0,
      unit: getUnit(nextResponseSLA?.unit),
    },
    resolveSLA: {
      value: resolveSLA?.value ?? 0,
      unit: getUnit(resolveSLA?.unit),
    },
  };
};
