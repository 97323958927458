import React, { useState } from "react";
import { makeStyles } from "@providers/Mui";
import { Grid, Typography } from "@mui/material";
import { Button } from "@components/Button";
import { Form } from "@components/Form";
import { CustomPage } from "@functions-types";
import { useSnackbar } from "@providers/Snackbar";
import { useAsync } from "@hooks/useAsync";
import {
  createCustomPage as createCustomPageCloudFunction,
  updateCustomPage as updateCustomPageCloudFunction,
  useListCustomPages,
} from "@cloud-functions";
import { customPageValidator } from "./customPageValidator";
import { CustomPagesForm } from "./CustomPagesForm";
import { customPageToInput } from "./utils";
import { logError } from "@lib/logger";
import { Blocker } from "@components/Blocker";
import { PagesList } from "@pages/Admin/CustomPages/PagesList";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    height: "100%",
  },
  content: {
    height: "100%",
  },
  newPage: {
    color: theme.palette.info.main,
    textTransform: "none",
    width: "100%",
    marginTop: theme.spacing(1),
  },
}));

export const CustomPages: React.FC = () => {
  const classes = useStyles();
  const { showError } = useSnackbar();
  const [customPages, customPagesStatus] = useListCustomPages();
  const [createCustomPage, createCustomPageState] = useAsync(
    createCustomPageCloudFunction,
  );
  const [updateCustomPage, updateCustomPageState] = useAsync(
    updateCustomPageCloudFunction,
  );
  const [selectedPage, setSelectedPage] = useState<CustomPage | null>(null);

  const handleSubmit = async (values: CustomPage) => {
    try {
      if (selectedPage?.id) {
        const updatedPage = await updateCustomPage({
          input: {
            id: selectedPage.id,
            updates: {
              id: selectedPage.id,
              ...customPageToInput(values),
            },
          },
        });
        customPagesStatus.refetch();
        setSelectedPage(updatedPage);
      } else {
        const customPage = await createCustomPage({
          input: customPageToInput(values),
        });
        customPagesStatus.refetch();
        setSelectedPage(customPage);
      }
    } catch (err) {
      logError(err);

      showError(
        <Typography variant="body2">
          Something went wrong during saving custom page
        </Typography>,
      );
    }
  };

  const handleSelectPage = (customPage: CustomPage): void => {
    setSelectedPage(customPage);
  };

  const loading = createCustomPageState.isLoading
    || updateCustomPageState.isLoading
    || customPagesStatus.loading;

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={3}>
        <PagesList
          customPages={customPages ?? []}
          activeField={selectedPage?.id}
          onSelect={handleSelectPage}
        />
        <Button
          className={classes.newPage}
          onClick={() => setSelectedPage(null)}
        >
          Create custom page
        </Button>
      </Grid>
      <Grid item xs={9} className={classes.content}>
        <Form<CustomPage>
          formMode="create"
          onSubmit={handleSubmit}
          className={classes.content}
          resolver={customPageValidator}
          defaultValues={selectedPage ?? undefined}
          key={selectedPage?.id}
        >
          <CustomPagesForm />
        </Form>
      </Grid>
      <Blocker open={loading} />
    </Grid>
  );
};
