import { Controller } from "react-hook-form";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  RadioGroupProps,
} from "@mui/material";
import { get } from "lodash";
import { Modify } from "@lib/types";
import { makeStyles } from "@providers/Mui";
import { FieldModeSwitch, FieldModeSwitchProps } from "../../FieldModeSwitch";
import { FieldLabel } from "../../FieldLabel";
import { FieldSaveButtons, FieldSaveButtonsProps } from "../../FieldSaveButtons";
import { RadioGroupFieldViewer, RadioGroupFieldViewerProps } from "./RadioGroupFieldViewer";
import React from "react";
import { useForm, useOnFieldAcivated } from "@components/Form/FormProvider";
import clsx from "clsx";

type RadioGroupFieldProps = Modify<
  RadioGroupProps,
  {
    label: React.ReactNode;
    name: string;
    disabled?: boolean;
    options: { label: string; value: string }[];
    FieldSaveButtonsProps?: Partial<FieldSaveButtonsProps>;
    RadioGroupFieldViewerProps?: Partial<RadioGroupFieldViewerProps>;
    FieldModeSwitchProps?: Partial<FieldModeSwitchProps>;
    onActivation?: () => void;
  }
>;

export const RadioGroupField = ({
  name,
  label,
  FieldSaveButtonsProps,
  RadioGroupFieldViewerProps,
  FieldModeSwitchProps,
  className,
  options,
  onActivation,
  ...props
}: RadioGroupFieldProps) => {
  const classes = useStyles(props);
  const { methods } = useForm();

  useOnFieldAcivated(name, () => {
    onActivation?.();
  });

  const defaultValue = get(methods.control._defaultValues, name);

  return (
    <Controller
      name={name}
      render={({ field, fieldState, formState }) => {
        const hasUnsavedChanges = Boolean(field.value !== defaultValue);
        const error = fieldState.error?.message;

        return (
          <FieldModeSwitch
            field={name}
            edit={
              <>
                <FieldLabel label={label} name={name} />

                <FormControl error={Boolean(fieldState.error?.message)}>
                  <RadioGroup
                    {...field}
                    {...props}
                    onChange={(...args) => {
                      field.onChange(...args);
                      props.onChange?.(...args);
                    }}
                    classes={classes}
                    id={name}
                    className={clsx(classes.root, className)}
                    defaultValue={undefined}
                    value={field.value ?? defaultValue ?? ""}
                    row
                    aria-labelledby={name}
                    name={name}>
                    {options.map((option) => (
                      <FormControlLabel
                        style={{ width: "100%" }}
                        value={option.value}
                        label={option.label}
                        control={<Radio size="small" />}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>

                {error && <FormHelperText error>{error}</FormHelperText>}

                <FieldSaveButtons field={name} {...FieldSaveButtonsProps} />
              </>
            }
            view={
              <RadioGroupFieldViewer
                hasUnsavedChanges={hasUnsavedChanges}
                label={label}
                value={defaultValue}
                field={name}
                {...RadioGroupFieldViewerProps}
              />
            }
            {...FieldModeSwitchProps}
          />
        );
      }}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  root: {},
}));
