import React, { useRef, useState } from "react";
import { Button, ButtonProps, lighten, Menu, MenuItem } from "@mui/material";
import { makeStyles } from "@providers/Mui";
import { useNavigate } from "react-router-dom";
import { useSpaces } from "@providers/Spaces";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 32,
    textTransform: "none",
    margin: theme.spacing(3, 1, 1.5, 0),

    "&, &:hover, &.Mui-selected": {
      color: theme.palette.primary.main,
      border: "none",
      fontWeight: 700,
    },
    "&.Mui-selected": {
      color: theme.palette.common.white,
      backgroundColor: lighten(theme.palette.primary.main, 0.1),
    },
  },
}));

type SpacesMenuProps = {
  className?: string;
  ButtonProps?: ButtonProps;
};

export const SpacesMenu: React.FC<SpacesMenuProps> = (props) => {
  const { spaces, selectedSpace, setSelectedSpace, loading } = useSpaces();
  const [open, setOpen] = useState(false);
  const anchorEl = useRef<HTMLButtonElement | null>(null);
  const navigate = useNavigate();
  const classes = useStyles();

  if (loading || spaces.length === 0) return null;

  return (
    <>
      <Button
        className={clsx(classes.root, props.className)}
        ref={anchorEl}
        onClick={() => setOpen(true)}
        variant="outlined"
        endIcon={<KeyboardArrowDownIcon />}>
        {selectedSpace?.name ?? "Space"}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl.current}
        open={open}
        onClose={() => setOpen(false)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}>
        {spaces.map((space) => (
          <MenuItem
            key={space.key}
            onClick={() => {
              setOpen(false);
              setSelectedSpace(space);
              navigate(`/space/${space.key}`);
            }}
            selected={space.key === selectedSpace?.key}>
            {space.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
