import React, { useEffect, useMemo, useState } from "react";
import { Grid, TextField } from "@mui/material";
import { H6 } from "@components/Typography";
import { makeStyles } from "@providers/Mui";
import { CustomField } from "@functions-types";
import { FieldCard } from "./FieldCard";

const useStyles = makeStyles((theme) => ({
  root: {},
  header: {
    padding: theme.spacing(1, 2),
  },
  filterTextField: {
    padding: theme.spacing(1, 2),
    marginBottom: theme.spacing(1),
  },
}));

type FieldsListProps = {
  customFields: CustomField[];
  onSelect: (customField: CustomField) => void;
  activeField?: string;
};

export const FieldsList: React.FC<FieldsListProps> = ({
  customFields,
  onSelect,
  activeField,
}) => {
  const classes = useStyles();
  const sortedCustomFields = useMemo(() => {
    return Array.from(customFields).sort(function (a, b) {
      return a.label.localeCompare(b.label);
    });
  }, [customFields]);

  const [filter, setFilter] = useState("");
  const [sortedFilteredField, setFilteredField] = useState(sortedCustomFields);

  const onFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
  };

  useEffect(() => {
    // render whole list on empty query
    if (filter.trim().length === 0) {
      setFilteredField(sortedCustomFields);
      return;
    }

    const filteredFields = sortedCustomFields.filter((customField) => {
      return customField.label.toLowerCase().includes(filter.toLowerCase());
    });

    setFilteredField(filteredFields);
  }, [filter, sortedCustomFields]);

  return (
    <Grid container direction="column" className={classes.root}>
      <H6 className={classes.header}>Custom fields</H6>
      <TextField
        placeholder="Start typing to filter..."
        value={filter}
        onChange={onFilterChange}
        size="small"
        className={classes.filterTextField}
      />
      {sortedFilteredField.map((field) => (
        <FieldCard
          customField={field}
          onSelect={onSelect}
          activeField={activeField}
          key={field.id}
        />
      ))}
    </Grid>
  );
};
