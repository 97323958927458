import { FC, useMemo } from "react";
import { Grid } from "@mui/material";
import { useWatch } from "react-hook-form";
import { Autocomplete } from "@components/Form";
import { FieldGroup } from "@components/Form/FieldGroup";
import { useForm } from "@components/Form/FormProvider";
import { Faq } from "@components/Faq";
import { fieldsMap } from "@lib/constants";
import { UseTicketLookupsResult } from "@lib/ticket";
import { CTITree } from "@cloud-functions";
import { useOnChange } from "@hooks/useOnChange";
import { SLADetails } from "@components/SLADetails";
import { useTicketSLAFeature } from "@hooks/featureFlags";

type CategoryTypeItemPickerProps = Pick<UseTicketLookupsResult, "categories">;

export const CategoryTypeItemPicker: FC<CategoryTypeItemPickerProps> = ({
  categories,
}) => {
  const ticketSLAFeatureEnabled = useTicketSLAFeature();
  const { methods } = useForm();
  const { setValue } = methods;

  const category: CTITree | null = useWatch({ name: "category" });
  const type: CTITree["types"][0] | null = useWatch({ name: "type" });
  const item: CTITree["types"][0]["items"][0] | null = useWatch({
    name: "item",
  });

  useOnChange(category, () => {
    setValue("type", null);
    setValue("item", null);
  });

  useOnChange(type, () => {
    setValue("item", null);
  });

  const types = useMemo(() => category?.types ?? [], [category]);
  const items = useMemo(() => type?.items ?? [], [type]);
  const slaExists = item?.resolveSLA || item?.firstResponseSLA || item?.nextResponseSLA;

  return (
    <>
      <FieldGroup
        title="Assignment"
        collapsable={false}
        summary={`${fieldsMap.category}, ${fieldsMap.type}, ${fieldsMap.item} and ${fieldsMap.assignee}`}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              required
              name="category"
              label={fieldsMap.category}
              options={categories}
              saveOnChange={false}
              getOptionText={(option) => option.name}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              actions={
                category?.faq_link && <Faq url={category.faq_link} label={category.faq_label} />
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              required
              name="type"
              label={fieldsMap.type}
              saveOnChange={false}
              getOptionText={(option) => option.name}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              placeholder={!types.length ? `-- Please select ${fieldsMap.category} --` : undefined}
              options={types}
              disabled={!types.length}
              actions={type?.faq_link && <Faq url={type.faq_link} label={type.faq_label} />}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              required
              name="item"
              label={fieldsMap.item}
              placeholder={!items.length ? `-- Please select ${fieldsMap.type} --` : undefined}
              saveOnChange={false}
              getOptionText={(option) => option.name}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              options={items}
              disabled={!items.length}
              actions={item?.faq_link && <Faq url={item.faq_link} label={item.faq_label} />}
            />
          </Grid>
        </Grid>
      </FieldGroup>
      {slaExists && ticketSLAFeatureEnabled && (
        <FieldGroup title="SLA" collapsable={false}>
          {item.firstResponseSLA && (
            <SLADetails sla={item.firstResponseSLA} type="firstReactionSLA" />
          )}
          {item.nextResponseSLA && <SLADetails sla={item.nextResponseSLA} type="nextReactionSLA" />}
          {item.resolveSLA && <SLADetails sla={item.resolveSLA} type="resolveSLA" />}
        </FieldGroup>
      )}
    </>
  );
};
