import React from "react";
import { CustomField, Space } from "@functions-types";
import {
  AsyncAutocomplete,
  CheckboxField,
  DatePickerField,
  SelectField,
  TextField,
  NestedSelectField,
} from "@components/Form";
import { useForm } from "@components/Form/FormProvider";
import { makeStyles } from "@providers/Mui";
import { searchSpaces } from "@cloud-functions";
import { StoreField } from "@components/Form/fields/StoreField";
import { HasClassName } from "@lib/types";

type FieldComponentProps = HasClassName & {
  field: CustomField;
};

const useStyles = makeStyles((theme) => ({
  select: {
    padding: theme.spacing(1, 0.5),
  },
}));

export const FieldComponent: React.FC<FieldComponentProps> = ({ field, className }) => {
  const { activateAllFields } = useForm();
  const classes = useStyles();
  const name = `customFieldsValues.${field.id}`;

  switch (field.type) {
    case "checkbox": {
      return (
        <CheckboxField
          name={name}
          label={field.checkboxLabel ?? field.label}
          defaultValue={field.defaultValue as string}
          onClick={activateAllFields}
          required={field.required}
        />
      );
    }

    case "spaceField": {
      return (
        <AsyncAutocomplete<Space>
          required={field.required}
          label={field.label}
          name={name}
          fullWidth
          search={(query) => searchSpaces({ query })}
          multiple={field.multiple}
          AsyncAutocompleteViewerProps={{
            onClick: activateAllFields,
          }}
          getOptionText={(o) => `${o.name}`}
          className={className}
        />
      );
    }

    case "textField": {
      return (
        <TextField
          required={field.required}
          label={field.label}
          name={name}
          fullWidth
          FieldSaveButtonsProps={{
            showCancelButton: false,
            showSaveButton: false,
          }}
          TextFieldViewerProps={{
            onClick: activateAllFields,
          }}
          className={className}
        />
      );
    }

    case "multilineText": {
      return (
        <TextField
          required={field.required}
          label={field.label}
          name={name}
          multiline
          rows={3}
          fullWidth
          TextFieldViewerProps={{
            onClick: activateAllFields,
          }}
          FieldSaveButtonsProps={{
            showCancelButton: false,
            showSaveButton: false,
          }}
          className={className}
        />
      );
    }

    case "selectField": {
      const options = field.options || [];
      return (
        <SelectField
          required={field.required}
          label={field.label}
          name={name}
          options={options}
          fullWidth
          viewModeClassName={classes.select}
          multiple={field.multiple}
          SelectFieldViewerProps={{
            onClick: activateAllFields,
          }}
          className={className}
        />
      );
    }

    case "nestedSelectField": {
      const options = field.optionsTree || [];
      return (
        <NestedSelectField
          required={field.required}
          label={field.label}
          name={name}
          options={options}
          fullWidth
          viewModeClassName={classes.select}
          multiple={field.multiple}
          NestedSelectFieldViewerProps={{
            onClick: activateAllFields,
          }}
          className={className}
        />
      );
    }

    case "numberField": {
      return (
        <TextField
          required={field.required}
          name={name}
          type="number"
          label={field.label}
          fullWidth
          onClick={activateAllFields}
          FieldSaveButtonsProps={{
            showCancelButton: false,
            showSaveButton: false,
          }}
          TextFieldViewerProps={{
            onClick: activateAllFields,
          }}
          className={className}
        />
      );
    }

    case "datePicker": {
      return (
        <DatePickerField
          required={field.required}
          label={field.label}
          name={name}
          FieldSaveButtonsProps={{
            showCancelButton: false,
            showSaveButton: false,
          }}
          DatePickerViewerProps={{
            onClick: activateAllFields,
          }}
          className={className}
        />
      );
    }

    case "storeSelectField": {
      return (
        <StoreField
          label={field.label}
          name={`customFieldsValues.${field.id}`}
          fullWidth
          viewModeClassName={classes.select}
          multiple={field.multiple}
          StoreFieldViewerProps={{
            onClick: activateAllFields,
          }}
          className={className}
          required={field.required}
        />
      );
    }

    default: {
      return null;
    }
  }
};
