import {
  CustomFieldsWithValue,
  Ticket,
  UpdateTicketRequest,
} from "@functions-types";
import { MapKeys } from "@lib/types";
import { UpdateTicketFormState } from "./types";
import { get, isEqual } from "lodash";
import { default as Delta } from "quill-delta";
import { QuillDelta, QuillEditorValue } from "@components/QuillEditor";

export type PartialUpdateTicketInput = Partial<
  UpdateTicketRequest["input"]["updates"]
>;

export type UpdateTicketFormField =
  | keyof Pick<
      PartialUpdateTicketInput,
      | "assignee"
      | "title"
      | "country"
      | "description"
      | "item"
      | "severity"
      | "status"
      | "watchers"
    >
  | "category"
  | "type";

export type UpdateTicketForm = MapKeys<
  UpdateTicketFormField,
  {
    assignee: string;
    title: string;
    country: string;
    description: QuillEditorValue;
    item: string;
    severity: string;
    status: string;
    watchers: string[];
    category: string;
    type: string;
  }
>;

type LegacyDraftContentState = {
  blocks: unknown[];
  entityMap: { [key: string]: unknown };
};

function isLegacyEditorState(value: any): value is LegacyDraftContentState {
  return value && "blocks" in value && "entityMap" in value;
}

function isDelta(value?: any): value is QuillDelta {
  return value && "ops" in value;
}

function deltaFromText(text: string): QuillDelta {
  return new Delta().insert(text) as unknown as QuillDelta; // because types missmatch : S
}

export const getUpdateTicketInitialState = (
  ticket: Ticket,
): UpdateTicketFormState => {
  let description: QuillEditorValue = {
    json: new Delta() as unknown as QuillDelta, // because types missmatch : S
    text: "",
  };

  if (isLegacyEditorState(ticket.description?.json)) {
    // removes legacy formattting
    // and loads ccontent from string
    description = {
      json: deltaFromText(ticket.description?.text ?? ""),
      text: ticket.description?.text ?? "",
    };
  }

  if (ticket.description && isDelta(ticket.description?.json)) {
    description = ticket.description as QuillEditorValue;
  }

  return {
    ...ticket,
    watchers: ticket.watchers ?? [],
    description,
  };
};

const excludedFields: Partial<Record<keyof Ticket, boolean>> = {
  type: true,
  category: true,
  updatedAt: true,
  description: true, // we care about this field separately
};

type FormValue = string | number | { id: string } | { id: string }[] | null;
const getFieldValue = <T extends FormValue>(
  value: T,
): string | number | string[] | null => {
  if (!value) return value;
  if (
    typeof value === "string" ||
    typeof value === "number" ||
    typeof value === "boolean"
  )
    return value;
  if ("id" in value) return value.id;
  return value.map(({ id }) => id);
};

export const mergeFieldsWithValue = (
  customFields: CustomFieldsWithValue[],
  customFieldsValues: UpdateTicketFormState["customFieldsValues"],
): CustomFieldsWithValue[] => {
  return customFields.map((field) => {
    const value = get(customFieldsValues, field.id) ?? field?.value;
    return {
      ...field,
      value,
    };
  });
};

export const getCustomFieldValuesFromState = (
  state: Pick<UpdateTicketFormState, "customFieldsValues">,
  customFields: CustomFieldsWithValue[],
): PartialUpdateTicketInput => ({
  customFields: mergeFieldsWithValue(customFields, state.customFieldsValues),
});

export const getUpdatesFromState = (
  fields: [keyof Ticket, ...Array<keyof Ticket>],
  initialState: Partial<UpdateTicketFormState>,
  newState: Partial<UpdateTicketFormState>,
): PartialUpdateTicketInput => {
  const initialValuesMap = Object.entries(initialState).reduce(
    (acc, [key, value]) => {
      if (!fields.includes(key as keyof Ticket)) return acc;
      if (key in excludedFields) return acc;
      if (key === "attachments") {
        return {
          ...acc,
          attachments: value,
        };
      }
      return {
        ...acc,
        [key]: getFieldValue(value as FormValue),
      };
    },
    {},
  ) as PartialUpdateTicketInput;

  return Object.entries(newState).reduce((acc, [key, value]) => {
    if (!fields.includes(key as keyof Ticket)) return acc;
    if (key in excludedFields) return acc;
    if (key === "attachments") {
      if (!isEqual(initialValuesMap.attachments, value)) {
        return {
          ...acc,
          attachments: value,
        };
      }
      return acc;
    }

    const initialValue = initialValuesMap[key as keyof typeof initialValuesMap];
    const newValue = getFieldValue(value as FormValue);
    if (!isEqual(initialValue, newValue)) {
      return {
        ...acc,
        [key]: newValue,
      };
    }

    return {
      ...acc,
    };
  }, {});
};
