import { Controller } from "react-hook-form";
import { DatePicker, DatePickerProps } from "@mui/lab";
import { get } from "lodash";
import { Modify } from "@lib/types";
import { makeStyles } from "@providers/Mui";
import { FieldModeSwitch, FieldModeSwitchProps } from "../../FieldModeSwitch";
import { FieldLabel } from "../../FieldLabel";
import {
  FieldSaveButtons,
  FieldSaveButtonsProps,
} from "../../FieldSaveButtons";
import { DatePickerViewer, DatePickerViewerProps } from "./DatePickerViewer";
import { useRef } from "react";
import { useForm, useOnFieldAcivated } from "@components/Form/FormProvider";
import clsx from "clsx";
import { TextField } from "@mui/material";

type DatePickerFieldProps = Modify<
  DatePickerProps,
  {
    name: string;
    FieldSaveButtonsProps?: Partial<FieldSaveButtonsProps>;
    DatePickerViewerProps?: Partial<DatePickerViewerProps>;
    FieldModeSwitchProps?: Partial<FieldModeSwitchProps>;
    renderInput?: DatePickerProps["renderInput"];
    onChange?: DatePickerProps["onChange"];
    value?: DatePickerProps["value"];
    required?: boolean;
  }
>;

export const DatePickerField = ({
  name,
  label,
  FieldSaveButtonsProps,
  DatePickerViewerProps,
  FieldModeSwitchProps,
  className,
  required,
  ...props
}: DatePickerFieldProps) => {
  const classes = useStyles(props);
  const { methods } = useForm();
  const inputRef = useRef<HTMLInputElement | null>(null);

  useOnFieldAcivated(name, () => {
    setTimeout(() => {
      inputRef.current?.focus();
    }, 200);
  });

  const defaultValue = get(methods.control._defaultValues, name);

  return (
    <Controller
      name={name}
      render={({ field, fieldState, formState }) => {
        const hasUnsavedChanges = Boolean(field.value !== defaultValue);
        return (
          <FieldModeSwitch
            field={name}
            edit={
              <>
                <FieldLabel label={label} name={name} required={required} />
                <DatePicker
                  inputFormat="dd/MM/yyyy"
                  {...field}
                  {...props}
                  onChange={(...args) => {
                    field.onChange(...args);
                    props.onChange?.(...args);
                  }}
                  disabled={formState.isSubmitting || props.disabled}
                  inputRef={inputRef}
                  className={clsx(classes.root, className)}
                  value={field.value ?? defaultValue ?? null}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      classes={classes}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      inputProps={{
                        ...params.inputProps,
                        "data-cy": `cypress_datePickerField_forLabel_${label}_${name}`,
                      }}
                      className={clsx(classes.root, className)}
                    />
                  )}
                />

                <FieldSaveButtons field={name} {...FieldSaveButtonsProps} />
              </>
            }
            view={
              <DatePickerViewer
                hasUnsavedChanges={hasUnsavedChanges}
                label={label}
                value={defaultValue}
                field={name}
                {...DatePickerViewerProps}
              />
            }
            {...FieldModeSwitchProps}
          />
        );
      }}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    ".MuiOutlinedInput": {
      border: "none",
    },

    "& input": {
      "&[type=number]": {
        "-moz-appearance": "textfield",
      },
      "&::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "&::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
    },
  },
}));
