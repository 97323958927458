import {
  CustomField,
  OptionsTree,
  UpdateCustomFieldRequest,
  UpdateCustomFieldResponse,
} from "@functions-types";
import { makeCloudFunction } from "../makeCloudFunction";

export type { CustomField, OptionsTree };
export const updateCustomField = makeCloudFunction<
  UpdateCustomFieldRequest,
  UpdateCustomFieldResponse
>("customField", "updateCustomField");
