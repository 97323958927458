import * as yup from "yup";

export const richTextValidation = (message: string) =>
  yup.object().test("is-rich-text", message, (value: any) => {
    return (
      typeof value === "object" &&
      "text" in value &&
      "json" in value &&
      typeof value.text === "string" &&
      value.text.trim().length > 0
    );
  });
