import { useCallback, useMemo, useState } from "react";
import { CustomField, CustomFieldsWithValue } from "@functions-types";

type CustomFieldsMap = Record<string, CustomField[]>;

type UseCustomFieldsHookResult = {
  addCustomFields: (key: string, fields: CustomField[]) => void;
  deleteCustomFields: (key: string) => void;
  fieldsList: CustomFieldsWithValue[];
};

export const useCustomFields = (): UseCustomFieldsHookResult => {
  const [customFields, setCustomFields] = useState<CustomFieldsMap>({});
  const addCustomFields = useCallback((key: string, customFields: CustomField[]) => {
    setCustomFields((currentFieldsMap) => ({
      ...currentFieldsMap,
      [key]: customFields,
    }));
  }, []);

  const deleteCustomFields = useCallback((sourceId: string) => {
    setCustomFields((currentFieldsMap) => {
      const newMap = {...currentFieldsMap};
      delete newMap[sourceId];
      return newMap;
    });
  }, []);

  const fieldsList = useMemo(() => {
    const fields = Object.entries(customFields).reduce((acc, [sourceId, fields]) => {
      fields.forEach((field) => {
        acc[field.id] = (acc[field.id] || { ...field, value: field.defaultValue, source: [] });
        acc[field.id].source.push(sourceId);
      });
      return acc;
    }, {} as Record<string, CustomFieldsWithValue>);

    return Object.values(fields);
  }, [customFields]);

  return useMemo(() => ({
    addCustomFields,
    deleteCustomFields,
    fieldsList,
  }), [fieldsList]);
};
