import { Controller } from "react-hook-form";
import OtpInput from "react-otp-input";
import { TextFieldProps as MuiTextFieldProps } from "@mui/material";
import { Modify } from "@lib/types";
import { makeStyles } from "@providers/Mui";
import { useForm } from "@components/Form/FormProvider";

import { FieldModeSwitch, FieldModeSwitchProps } from "../../FieldModeSwitch";
import { FieldLabel } from "../../FieldLabel";
import {
  FieldSaveButtons,
  FieldSaveButtonsProps,
} from "../../FieldSaveButtons";
import { OTPFieldViewer, OTPFieldViewerProps } from "./OTPFieldViewer";

type OTPFieldProps = Modify<
  MuiTextFieldProps,
  {
    name: string;
    FieldSaveButtonsProps?: Partial<FieldSaveButtonsProps>;
    OTPFieldViewerProps?: Partial<OTPFieldViewerProps>;
    FieldModeSwitchProps?: Partial<FieldModeSwitchProps>;
    onChange?: (val: string) => void;
  }
>;

export const OTPField = ({
  name,
  label,
  FieldSaveButtonsProps,
  OTPFieldViewerProps,
  FieldModeSwitchProps,
  size = "small",
  className,
  ...props
}: OTPFieldProps) => {
  const classes = useStyles(props);
  const { methods } = useForm();

  const defaultValue = methods.control._defaultValues[name];

  return (
    <Controller
      name={name}
      render={({ field, fieldState, formState }) => {
        const hasUnsavedChanges = Boolean(field.value !== defaultValue);

        return (
          <FieldModeSwitch
            field={name}
            edit={
              <>
                <FieldLabel label={label} name={name} />

                <OtpInput
                  shouldAutoFocus
                  value={field.value}
                  onChange={(...args: any[]) => {
                    field.onChange(...args);
                    props.onChange?.(args[0]);
                  }}
                  numInputs={6}
                  separator={<span className={classes.separator} />}
                  inputStyle={classes.input}
                  isDisabled={props.disabled}
                />

                <FieldSaveButtons field={name} {...FieldSaveButtonsProps} />
              </>
            }
            view={
              <OTPFieldViewer
                hasUnsavedChanges={hasUnsavedChanges}
                label={label}
                value={defaultValue}
                field={name}
                {...OTPFieldViewerProps}
              />
            }
            {...FieldModeSwitchProps}
          />
        );
      }}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    ".MuiOutlinedInput": {
      border: "none",
    },
  },
  input: {
    backgroundColor: "#EDF4F7",
    border: 0,
    width: "32px !important",
    height: "40px !important",
    borderRadius: 6,
    fontSize: 14,
    [theme.breakpoints.up("md")]: {
      width: "50px !important",
      height: "60px !important",
      fontSize: 32,
    },
  },
  separator: {
    width: 18,
  },
}));
