import React from "react";
import { Grid, Chip, Typography } from "@mui/material";
import { fieldsMap } from "@lib/constants";
import { FieldValue, Operation } from "./types";

type FilterCardProps = {
  field: FieldValue;
  operation: Operation;
  value: unknown;
  label: string;
  onRemove: () => void;
};

export const FilterCard: React.FC<FilterCardProps> = ({ field, operation, label, onRemove }) => {
  return (
    <Grid item>
      <Chip
        label={(
          <Typography>
            {fieldsMap[field.id]}
            <Typography sx={{ color: (theme) => theme.palette.primary.main }} component="span"> {operation} </Typography>
            {label}
          </Typography>
        )}
        variant="outlined"
        onDelete={onRemove}
        size="medium"
      />
    </Grid>
  )
};
