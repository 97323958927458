import React, { useCallback } from "react";
import { Country, Store, User } from "@functions-types";
import { makeStyles } from "@providers/Mui";
import { H6 } from "@components/Typography";
import { Grid } from "@mui/material";
import { AsyncAutocomplete, SelectField, TextField } from "@components/Form";
import { Button } from "@components/Button";
import { UserCard } from "@components/UserCard";
import { searchUsers as searchUsersCloudFunction } from "@cloud-functions";
import { isNonNullish } from "@lib/utils";
import { formatUserName } from "@lib/user";

type StoreFormProps = {
  selectedStore: Store | null;
  countries: Country[];
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[200],
    height: "100%",
    overflowX: "auto",
  },
  container: {
    paddingBottom: theme.spacing(2),
    "&:last-children": {
      paddingBottom: 0,
    },
  },
  field: {
    "& .MuiOutlinedInput-root": {
      backgroundColor: theme.palette.common.white,
    },
  },
  select: {
    backgroundColor: theme.palette.common.white,
  },
}));

export const StoreForm: React.FC<StoreFormProps> = ({ selectedStore, countries }) => {
  const classes = useStyles();

  const searchUsers = useCallback(async (query: string) => {
    const users = await searchUsersCloudFunction({
      query,
    });

    return users.filter(isNonNullish);
  }, []);

  return (
    <Grid className={classes.root}>
      <div className={classes.container}>
        {selectedStore?.id ? <H6>Edit store: {selectedStore?.name}</H6> : <H6>Create store</H6>}
      </div>
      <div className={classes.container}>
        <TextField
          name="name"
          label="Name"
          variant="outlined"
          fullWidth
          className={classes.field}
        />
      </div>
      <div className={classes.container}>
        <SelectField
          getOptionText={(option) => option.name}
          fullWidth
          label="Country"
          name="country"
          options={countries}
          className={classes.select}
        />
      </div>
      <div className={classes.container}>
        <AsyncAutocomplete<User>
          disablePortal
          multiple
          label="Managers"
          name="managers"
          getOptionText={formatUserName}
          search={searchUsers}
          disableClearable
          renderItem={(option) => {
            const { email, displayName, active } = option;

            if (!email || !displayName) {
              return <UserCard user={null} />;
            }

            return <UserCard user={{ displayName, email, active }} withBackground />;
          }}
        />
      </div>
      <div className={classes.container}>
        <Button type="submit" variant="contained">
          Save
        </Button>
      </div>
    </Grid>
  );
};
