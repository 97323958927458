import { FilteredStatus, WorkflowStatusFormState } from "./types";
import { WorkflowTransition } from "@functions-types";
import { availableTypes } from "@pages/Workflows/WorkflowStatusForm/values";

export const emptyAction = { id: "-", name: "No action selected" };

export const workflowStatusToFormState = (
  status?: FilteredStatus
): WorkflowStatusFormState | undefined => {
  if (!status) {
    return undefined;
  }

  return {
    ...status,
    type: availableTypes.find(({ id }) => id === status.type) ?? availableTypes[0],
    transitions: status.transitions.map(({ action, ...transition }) => ({
      ...transition,
      action: action?.customPage
        ? { id: action.customPage.id, name: action.customPage.name }
        : emptyAction
    }))
  }
};

export const formStateToWorkflowStatus = (
  state: WorkflowStatusFormState,
): FilteredStatus => ({
  ...state,
  type: state.type.id,
  transitions: state.transitions.map((transition) => {
    if (transition.action && transition.action?.id !== emptyAction.id) {
      return {
        destinationId: transition.destinationId,
        action: {
          type: "customPage",
          customPage: transition.action,
        }
      } as WorkflowTransition;
    }

    return {
      destinationId: transition.destinationId,
      action: null,
    };
  }),
});
