import { FC, memo, useRef } from "react";
import {
  createComment as createCommentCloudFunction,
  updateComment as updateCommentCloudFunction,
} from "@cloud-functions";
import { Tooltip, Typography } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import { CheckboxField, Form, FormRef, RichTextEditor } from "@components/Form";
import { QuillEditorValue } from "@components/QuillEditor";
import { makeStyles } from "@providers/Mui";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch } from "@providers/EventsProvider";
import { CommentActivity } from "@functions-types";
import { useCommentAttachmentsFeature } from "@hooks/featureFlags";
import { Attachment } from "@functions-types";
import { CommentAttachmentsList } from "./CommentAttachmentsList";
import { useTicketActivity } from "./TicketActivityProvider";
import { CommentAttachmentUploader } from "./CommentAttachmentUploader";
import { TicketActivityEvents } from "./types";
import { richTextValidation } from "@lib/validations/rich-text";

type CommentBoxProps = {
  comment?: CommentActivity;
  onCancel?: () => void;
  onUpdate?: () => void;
  autoFocusOnComment?: boolean;
};

const commentFormValidation = yup.object().shape({
  comment: richTextValidation("Comment is required, don't you think? 🤷‍♂️"),
});

const commentFormValidationResolver = yupResolver(commentFormValidation);

type CommentFormFields = {
  comment: QuillEditorValue;
  private?: boolean;
  attachments: Array<Attachment>;
};

export const CommentBox: FC<CommentBoxProps> = memo(
  ({ comment, onCancel, onUpdate, autoFocusOnComment = false }) => {
    const isEditMode = Boolean(comment);
    const classes = useStyles();
    const buttonsClasses = useButtonsClasses();
    const { ticketId, teamMember } = useTicketActivity();
    const dispatch = useDispatch<TicketActivityEvents>();
    const formRef = useRef<FormRef<CommentFormFields> | null>(null);

    const createComment = async (values: CommentFormFields) => {
      await createCommentCloudFunction({
        input: {
          text: values.comment,
          private: values.private ?? false,
          attachments: values.attachments,
        },
        ticket: ticketId,
      });

      dispatch("createComment");
    };

    const updateComment = async (values: CommentFormFields) => {
      const id = comment?.id!;

      const updatedComment = await updateCommentCloudFunction({
        input: {
          id,
          text: values.comment,
          private: values.private ?? false,
          attachments: values.attachments,
        },
        ticket: ticketId,
      });

      dispatch("updateComment", updatedComment);
      onUpdate?.();
    };

    const handleSubmit = async (values: CommentFormFields) => {
      if (isEditMode) {
        await updateComment(values);
      } else {
        await createComment(values);
      }
      formRef?.current?.setDefaultValues({});
    };

    const commentAttachmentsFeatureEnabled = useCommentAttachmentsFeature();

    return (
      <div className={classes.root} data-cy="cypress_commentBox">
        <Form<CommentFormFields>
          onSubmit={handleSubmit}
          formMode="edit"
          resolver={commentFormValidationResolver}
          ref_={formRef}
          defaultValues={{
            comment: comment?.text as QuillEditorValue,
            private: comment?.private,
            attachments: comment?.attachments,
          }}>
          <RichTextEditor
            autoFocus
            minRows={2}
            autoActivate={autoFocusOnComment}
            name="comment"
            footer={
              commentAttachmentsFeatureEnabled && <CommentAttachmentsList />
            }
            FieldSaveButtonsProps={{
              saveButtonText: isEditMode ? "Save" : "Comment",
              onCancel,
              classes: buttonsClasses,
              children: (
                <div className={classes.actions}>
                  {commentAttachmentsFeatureEnabled && (
                    <CommentAttachmentUploader commentId={comment?.id} />
                  )}
                  {teamMember && (
                    <Tooltip
                      title="This comment will be available for you and your team only"
                      placement="top">
                      <CheckboxField
                        name="private"
                        size="small"
                        disableRipple
                        className={classes.private}
                        classes={{
                          root: classes.checkBox,
                        }}
                        label={
                          <Typography className={classes.privateLabel}>
                            <LockIcon className={classes.privateIcon} />
                            Private
                          </Typography>
                        }
                      />
                    </Tooltip>
                  )}
                </div>
              ),
            }}
            RichTextViewerProps={{
              emptyValue: "Add a comment...",
              className: classes.commentViewer,
            }}
            FieldModeSwitchProps={{
              mode: isEditMode ? "create" : "edit",
            }}
          />
        </Form>
      </div>
    );
  },
);

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  commentViewer: {
    border: "1px solid #bcbcbc",
  },
  private: {
    marginLeft: "auto",
    marginRight: 0,
  },
  privateLabel: {
    fontSize: 14,
    color: theme.palette.grey[700],
    paddingTop: 2,
  },
  privateIcon: {
    fontSize: 15,
    marginBottom: -2,
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(1),
  },
  checkBox: {
    padding: 0,
  },
  actions: {
    display: "flex",
    flexDirection: "row",
    marginLeft: "auto",
    alignItems: "center",
  },
  input: {
    display: "none",
  },
  button: {},
  loader: {},
}));

const useButtonsClasses = makeStyles((theme) => ({
  root: {
    flexDirection: "row-reverse",
  },
  button: {
    marginLeft: 0,
    marginRight: theme.spacing(2),
  },
}));
