import {
  AppBar as MuiAppBar,
  Button,
  Chip,
  Divider,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/material/Box";
import { useAuth, useUser } from "@providers/Auth";
import { makeStyles } from "@providers/Mui";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import logo from "./logo.png";
import desktopLogo from "./desktopLogo.png";
import { TicketForm } from "@components/TicketForm";
import SearchIcon from "@mui/icons-material/Search";
import clsx from "clsx";
import { useSpaces } from "@providers/Spaces";
import { MobileDrawer } from "./MobileDrawer";
import { SearchDialog } from "./SearchDialog";
import { useKeyboardKey } from "@hooks/useKeyboardKey";
import { useTicketSearchFeature, useUserProfileFeature } from "@hooks/featureFlags";

export const AppBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const auth = useAuth();
  const { user } = useUser();
  const classes = useStyles();
  const [createTicketOpen, setCreateTicketOpen] = useState(false);
  const ticketSearchFeatureEnabled = useTicketSearchFeature();
  const userProfileFeatureEnabled = useUserProfileFeature();

  const userMenuItems = [
    {
      label: "Profile",
      onClick: () => navigate("/profile"),
      disabled: !userProfileFeatureEnabled,
    },
    {
      label: "Logout",
      onClick: auth.logout,
      disabled: false,
    },
  ];

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const appBarDisplayName = user.email ?? user.phone;

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { selectedSpace, loading: loadingSpaces } = useSpaces();
  const selectedSpaceRoute = `/space/${selectedSpace?.key}/tickets`;

  const homeSelected = location.pathname === "/";
  const ticketsSelected = location.pathname.startsWith("/space");
  const settingsSelected = location.pathname.startsWith("/admin");

  const [isSearchOpen, setIsSearchOpen] = useState(false);

  useKeyboardKey({ metaKey: true, key: "k" }, () => {
    setIsSearchOpen(true);
  });

  useKeyboardKey({ metaKey: true, key: "/" }, (ev) => {
    ev.preventDefault();
    setIsSearchOpen(true);
  });

  return (
    <MuiAppBar className={classes.root} position="fixed" elevation={0} color="primary">
      <Toolbar className={classes.toolbar}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}>
          <Hidden smUp>
            <IconButton className={classes.burgerMenuButton} onClick={() => setIsDrawerOpen(true)}>
              <MenuIcon />
            </IconButton>
          </Hidden>
          <Hidden smDown>
            <Link to="/" className={classes.homeLink}>
              <img className={classes.logo} src={logo} alt="Zapp logo" />
            </Link>
          </Hidden>
          <Button
            onClick={() => {
              setCreateTicketOpen(true);
            }}
            size="small"
            variant="text"
            className={clsx(classes.button, classes.createButton)}
            data-cy="cypress_createNewTicket">
            Create
          </Button>
          <Hidden smUp>
            <Link to="/" className={classes.homeLink}>
              <img className={classes.logo} src={desktopLogo} alt="Zapp logo" />
            </Link>
          </Hidden>
          <Hidden smDown>
            <Link to="/" className={classes.button}>
              <Button
                size="small"
                className={clsx(classes.button, homeSelected && classes.selectedTab)}>
                Home
              </Button>
            </Link>
            {!loadingSpaces && selectedSpace && (
              <Link to={selectedSpaceRoute} className={classes.button}>
                <Button
                  size="small"
                  className={clsx(classes.button, ticketsSelected && classes.selectedTab)}>
                  Tickets
                </Button>
              </Link>
            )}
            {(user?.isAdmin || user?.isManager) && (
              <Link to="/admin" className={classes.button}>
                <Button
                  size="small"
                  className={clsx(classes.button, settingsSelected && classes.selectedTab)}>
                  Settings
                </Button>
              </Link>
            )}
            {ticketSearchFeatureEnabled && (
              <Button
                size="small"
                className={clsx(classes.button, classes.searchButton)}
                classes={{
                  endIcon: classes.searchShortcut,
                }}
                startIcon={<SearchIcon />}
                endIcon={<Chip label="⌘k" size="small" className={classes.searchShortcut} />}
                variant="outlined"
                disableRipple
                onClick={() => setIsSearchOpen(true)}>
                Search...
              </Button>
            )}
          </Hidden>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}>
          <Hidden smDown>
            <Tooltip title="User menu">
              <Button
                onClick={handleOpenUserMenu}
                endIcon={<KeyboardArrowDownIcon color="primary" />}
                variant="text"
                className={classes.userMenuButton}>
                {appBarDisplayName}
              </Button>
            </Tooltip>
          </Hidden>
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}>
            {(user.email || user.displayName) && (
              <MenuItem className={classes.usernameMenuItem} disableRipple disableTouchRipple>
                <Typography textAlign="left">
                  <strong>{user.displayName}</strong>
                  {user.email && (
                    <>
                      <br />
                      {user.email}
                    </>
                  )}
                  {user.isRider && user.phone && (
                    <>
                      <br />
                      {user.phone}
                    </>
                  )}
                </Typography>
              </MenuItem>
            )}

            <Divider />

            {userMenuItems.map((setting) => (
              <MenuItem
                key={setting.label}
                onClick={() => {
                  setting.onClick();
                }}
                disabled={setting.disabled}>
                <Typography textAlign="center">{setting.label}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Toolbar>
      {createTicketOpen && (
        <TicketForm
          open={createTicketOpen}
          onClose={() => setCreateTicketOpen(false)}
          link={null}
        />
      )}
      <MobileDrawer open={isDrawerOpen} setOpen={setIsDrawerOpen} />
      <SearchDialog open={isSearchOpen} onClose={() => setIsSearchOpen(false)} />
    </MuiAppBar>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[350],
    zIndex: 20,
    borderBottom: `1px solid ${theme.palette.grey[350]}`,
    [theme.breakpoints.up("sm")]: {
      backgroundColor: theme.palette.common.white,
    },
  },
  toolbar: {
    padding: theme.spacing(0, 1),
    minHeight: 60,
    justifyContent: "space-between",
  },
  homeLink: {
    height: 24,
  },
  logo: {
    height: "100%",
    marginRight: 0,
    marginLeft: theme.spacing(0),
    [theme.breakpoints.up("sm")]: {
      marginRight: theme.spacing(2),
      marginLeft: 0,
    },
  },
  mobileLogo: {
    width: 40,
    height: 40,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  burgerMenuButton: {
    marginRight: "auto",
    color: "black",
  },
  button: {
    margin: theme.spacing(0.5),
    textTransform: "none",
    textDecoration: "none",
    fontWeight: 500,

    "&, &:hover": {
      backgroundColor: "none",
      color: theme.palette.common.black,
    },
  },
  createButton: {
    boxShadow: "none",
    margin: theme.spacing(0, 1),
    "&, &:hover": {
      fontWeight: "bold",
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.main,
    },
  },

  userMenuButton: {
    textTransform: "none",
    color: "black",
    fontWeight: "bold",
  },
  usernameMenuItem: {
    "&:hover": {
      backgroundColor: theme.palette.common.white,
      cursor: "default",
    },
  },
  mobileSpacesMenuButton: {
    marginLeft: 0,
    marginRight: 0,
    paddingLeft: 0,
    paddingRight: 0,
    width: "100%",
    justifyContent: "space-between",
  },
  selectedTab: {
    "&, &:hover": {
      // TODO: add color to theme
      backgroundColor: "rgba(25, 118, 210, 0.1)",
    },
  },

  searchButton: {
    minWidth: 350,
    justifyContent: "flex-start",
    borderColor: theme.palette.grey[400],
    padding: theme.spacing(0.5, 0.75),
    color: theme.palette.grey[500],
    "&:hover": {
      borderColor: theme.palette.grey[500],
      cursor: "text",
    },
  },
  searchShortcut: {
    borderRadius: 4,
    fontSize: `12px !important`,
    marginLeft: "auto",
  },
}));
