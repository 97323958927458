import {
  createContext,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { FieldMode } from "./FieldModeSwitch";

export type FormProviderRef = {
  resetActiveFields: () => void;
  triggerSubmit: () => void;
};

type FormContextValue<
  D extends FieldValues = FieldValues,
  C extends Record<never, never> = Record<never, never>,
> = {
  formMode?: FieldMode;
  activeFields: Record<string, boolean>;
  activeAll: boolean;
  lastActivedField?: string;
  activateField: (value: string, keepOthers?: boolean) => void;
  activateAllFields: () => void;
  resetActiveFields: () => void;
  triggerSubmit: () => void;
  methods: UseFormReturn<D, C>;
  disabled: boolean;
};

export type FormProviderProps<
  D extends FieldValues = FieldValues,
  C extends Record<never, never> = Record<never, never>,
> = {
  methods: UseFormReturn<D, C>;
  children: React.ReactNode;
  formMode?: FormContextValue["formMode"];
  ref_?: React.MutableRefObject<FormProviderRef | null>;
  disabled?: boolean;
};

const FormContext = createContext(
  null as unknown as FormContextValue<any, any>,
);

export const FormProvider = <
  D extends FieldValues = FieldValues,
  C extends Record<never, never> = Record<never, never>,
>({
  children,
  formMode,
  methods,
  ref_,
  disabled = false,
}: FormProviderProps<D, C>) => {
  const submitButtonRef = useRef<HTMLButtonElement | null>(null);
  const [activeFields, setActiveFields] = useState<Record<string, boolean>>({});
  const [lastActivedField, setLastActivedField] = useState<string>();
  const [activeAll, setActiveAll] = useState(false);

  const resetActiveFields = () => {
    setActiveFields({});
    setActiveAll(false);
  };

  const triggerSubmit = () => {
    submitButtonRef.current?.click();
  };

  const activateField = (field: string, keepOthers = false) => {
    if (!keepOthers) {
      setActiveFields({});
    }

    setActiveFields((current) => ({
      ...current,
      [field]: true,
    }));
    setLastActivedField(field);
  };

  const activateAllFields = () => {
    setActiveAll(true);
  };

  useImperativeHandle(
    ref_,
    () => ({
      resetActiveFields,
      triggerSubmit,
    }),
    [],
  );

  const values = useMemo(
    () => ({
      activeFields,
      activeAll,
      formMode,
      methods,
      lastActivedField,
      activateField,
      activateAllFields,
      resetActiveFields,
      triggerSubmit,
      disabled,
    }),
    [activeFields, lastActivedField, disabled, activeAll],
  );

  return (
    <FormContext.Provider value={values}>
      {children}
      <button ref={submitButtonRef} type="submit" style={{ display: "none" }} />
    </FormContext.Provider>
  );
};

export const useForm = () => useContext(FormContext);
export const useOnFieldAcivated = (name: string, cb: () => void) => {
  const { lastActivedField } = useForm();
  const cbRef = useRef(cb);

  cbRef.current = cb;

  useEffect(() => {
    if (lastActivedField === name) {
      cbRef.current();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastActivedField]);
};
