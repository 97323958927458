import { makeCloudFunction } from "../makeCloudFunction";
import { Item, ListItemsRequest, ListItemsResponse } from "@functions-types";
import { makeUseCloudFunction } from "../makeUseCloudFunction";

export type { Item };
export const listItems = makeCloudFunction<ListItemsRequest, ListItemsResponse>(
  "item",
  "listItems",
);
export const useListItems = makeUseCloudFunction<
  ListItemsRequest,
  ListItemsResponse
>(listItems);
