import clsx from "clsx";
import { FieldView } from "@components/Form/FieldView";
import { makeStyles } from "@providers/Mui";
import { FieldLabel } from "@components/Form/FieldLabel";
import { HasClassName } from "@lib/types";
import { OptionsTree } from "@functions-types";
import { Grid } from "@mui/material";

export type NestedSelectFieldViewerProps = HasClassName & {
  field: string;
  value?: string[];
  label?: React.ReactNode;
  hasUnsavedChanges?: boolean;
  onClick?: () => void;
};

export const NestedSelectFieldViewer = ({
  field,
  value,
  label,
  hasUnsavedChanges,
  className,
  onClick,
}: NestedSelectFieldViewerProps) => {
  const classes = useStyles();

  return (
    <>
      <FieldLabel label={label} hasUnsavedChanges={hasUnsavedChanges} />
      <Grid container wrap="nowrap" spacing={2}>
        {(value || []).map((value, index) => (
          <Grid item sm={3} xs={12}>
            <FieldView field={field} onClick={onClick}>
              <div className={clsx(classes.root, className)}>{value ?? "N/A"}</div>
            </FieldView>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
  },
}));
