import { StylesProvider, createGenerateClassName } from "@mui/styles";
import { ThemeProvider } from "@mui/styles";
import { FC } from "react";
import { createTheme, CssBaseline, GlobalStyles, Color, Palette, PaletteMode } from "@mui/material";
import { StyledEngineProvider } from "@mui/material/styles";
import {
  ColorPartial,
  CommonColors,
  PaletteColorOptions,
  PaletteTonalOffset,
  TypeAction,
  TypeBackground,
  TypeText,
} from "@mui/material/styles/createPalette";

declare module "@mui/material" {
  interface Color {
    50: string;
    100: string;
    200: string;
    300: string;
    350: string; // custom
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
    A100: string;
    A200: string;
    A400: string;
    A700: string;
  }
}
declare module "@mui/material/styles" {
  interface Theme {
    palette: Palette & {
      yellow: Color;
      orange: Color;
      green: Color;
      red: Color;
      grey: Color & {
        350: string;
      };
      blue: Color;
    };
  }

  interface PaletteOptions {
    yellow?: ColorPartial; // custom
    orange?: ColorPartial; // custom
    red?: ColorPartial; // custom
    green?: ColorPartial; // custom
    blue?: ColorPartial; // custom
    primary?: PaletteColorOptions;
    secondary?: PaletteColorOptions;
    error?: PaletteColorOptions;
    warning?: PaletteColorOptions;
    info?: PaletteColorOptions;
    success?: PaletteColorOptions;
    mode?: PaletteMode;
    tonalOffset?: PaletteTonalOffset;
    contrastThreshold?: number;
    common?: Partial<CommonColors>;
    grey?: ColorPartial;
    text?: Partial<TypeText>;
    divider?: string;
    action?: Partial<TypeAction>;
    background?: Partial<TypeBackground>;
    getContrastText?: (background: string) => string;
  }

  interface ThemeOptions {
    palette?: PaletteOptions;
  }
}

const generateClassName = createGenerateClassName({
  productionPrefix: "c",
});

const theme = createTheme({
  palette: {
    primary: {
      "100": "rgb(222, 235, 255)",
      light: "#37c7ff",
      main: "#06baff",
      dark: "#0482b2",
      contrastText: "#fff",
    },
    error: {
      light: "rgba(217, 67, 20, 0.22)",
      main: "#ef6363",
      dark: "#d32f2f",
    },
    secondary: {
      light: "#D4D4D4",
      main: "rgba(0, 0, 0, 0.23)",
    },
    warning: {
      light: "#fff9e9",
      main: "#ffc35f",
      dark: "#FFAB00",
    },
    grey: {
      50: "#FAFAFA",
      100: "#F4F5F7",
      200: "rgba(25, 118, 210, 0.08)",
      300: "#F2F5F9",
      350: "#F2F2F2",
      400: "#E3E3E3",
      600: "#AEAEAE",
      700: "rgb(107, 119, 140)",
      800: "#393939",
    },
    success: {
      light: "#CDDEC8",
      main: "#548B2F",
    },
    text: {
      secondary: "#565656",
      primary: "#2B2B2B",
    },
    yellow: {
      100: "#ffef9f",
      300: "#FFD602",
    },
    green: {
      100: "#c0e2cd",
      500: "#66D693",
    },
    red: {
      100: "#ffe4e4",
      400: "#ffa7a7",
    },
    orange: {
      100: "#FFF0D9",
      200: "#F59401",
    },
    blue: {
      100: "#edf4f7",
      500: "#1B1464",
    },
  },
  components: {
    MuiBackdrop: {
      defaultProps: {
        style: {
          backgroundColor: "rgb(222 222 222 / 50%)",
        },
      },
    },
    MuiDialog: {
      defaultProps: {
        style: {
          backgroundColor: "rgba(255, 255, 255, .15)",
          backdropFilter: "blur(2px)",
        },
      },
    },
  },
});

export const colors = {
  yellow: {
    main: "#FFF152",
  },
  red: {},
};

export const MuiProvider: FC = ({ children }) => {
  return (
    // https://mui.com/guides/migration-v4/#style-library
    <StyledEngineProvider injectFirst>
      <StylesProvider generateClassName={generateClassName}>
        <ThemeProvider theme={theme}>
          <CssBaseline enableColorScheme />
          <GlobalStyles styles={{ body: { backgroundColor: theme.palette.blue[100] } }} />
          {children}
        </ThemeProvider>
      </StylesProvider>
    </StyledEngineProvider>
  );
};
