import React from "react";
import { Grid, Skeleton } from "@mui/material";

const HistoryItem: React.FC = () => {
  return (
    <Grid
      container
      sx={{ borderBottom: `1px solid #F2F5F9`, pt: 2, pb: 2 }}
      alignItems="center"
    >
      <Skeleton
        variant="rectangular"
        width={26}
        height={26}
        sx={{ mr: 2.5 }}
      />
      <Skeleton
        variant="text"
        width="20%"
        height={15}
        sx={{ borderRadius: 0 }}
      />
      <Skeleton
        variant="text"
        width="20%"
        height={15}
        sx={{ ml: "auto", borderRadius: 0 }}
      />
    </Grid>
  );
};

export const HistoryPlaceholder: React.FC = () => {
  return (
    <Grid sx={{ pr: 2.5, pl: 2.5 }}>
      <Skeleton variant="text" width="30%" />
      {Array(10).fill(0).map((_, index) => (
        <HistoryItem key={index} />
      ))}
    </Grid>
  );
};
