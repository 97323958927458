import { ref, getDownloadURL } from "firebase/storage";
import { storage } from "@@firebase";
import { useCallback, useState } from "react";
import { logError } from "@lib/logger";

export const useDownloading = () => {
  const [loading, setLoading] = useState(false);
  const download = useCallback(async (fileRefId: string) => {
    setLoading(true);
    try {
      const fileRef = ref(storage, fileRefId);
      return await getDownloadURL(fileRef);
    } catch (err) {
      logError(err);
    } finally {
      setLoading(false);
    }
  }, []);

  return { loading, download };
};
