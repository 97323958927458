import { FieldView } from "@components/Form/FieldView";
import { makeStyles } from "@providers/Mui";
import { FieldLabel } from "@components/Form/FieldLabel";

export type TextFieldViewerProps = {
  field: string;
  value?: string;
  label?: React.ReactNode;
  hasUnsavedChanges?: boolean;
  emptyValue?: React.ReactNode;
  className?: string;
  onClick?: () => void;
  required?: boolean;
};

export const TextFieldViewer = ({
  field,
  value,
  label,
  hasUnsavedChanges,
  emptyValue = "N/A",
  className,
  onClick,
  required,
}: TextFieldViewerProps) => {
  const classes = useStyles();

  return (
    <>
      <FieldLabel
        label={label}
        hasUnsavedChanges={hasUnsavedChanges}
        required={required}
      />
      <FieldView field={field} className={className} onClick={onClick}>
        <div className={classes.root}>{value ?? emptyValue}</div>
      </FieldView>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 0.5),
  },
}));
