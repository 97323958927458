import { SLA, Ticket, User } from "@functions-types";

export const fieldsMap: Record<keyof Ticket | "space" | "linkedTickets", string> = {
  id: "Id",
  title: "Subject",
  isPrivate: "Is private",
  description: "Details",
  severity: "Severity",
  space: "Space",
  status: "Status",
  country: "Country",
  item: "Item",
  watchers: "Watchers",
  watchersIds: "Watchers",
  assignee: "Assignee",
  createdBy: "Reporter",
  createdAt: "Created",
  updatedAt: "Updated",
  category: "Category",
  type: "Type",
  trulyCreatedBy: "On behalf of",
  attachments: "Ticket attachments",
  customFields: "Custom fields",
  firstReactionSLA: "First response",
  nextReactionSLA: "Next response",
  resolveSLA: "Resolving time",
  deadline: "Deadline",
  resolvedAt: "Resolved at",
  resolved: "Resolved",
  reviewRequested: "Review requested",
  reactionNotificationAt: "Reaction notification at",
  isReactionNotificationSent: "Is reaction notification sent",
  resolveNotificationAt: "Resolve notification at",
  isResolveNotificationSent: "Is resolve notification sent",
  isMissedResolveDeadlineNotificationSent: "Is missed resolution deadline notification sent",
  customPages: "Custom pages",
  approvalRules: "Approvals",
  approvalState: "Approval",
  pendingApproversIds: "Pending approvers",
  store: "Store",
  linkedTickets: "Linked tickets",
};

export const userFieldsMap: Omit<Record<keyof User | "score", string>, "__syncTime"> = {
  id: "Id",
  title: "Position",
  firstName: "First name",
  lastName: "Last name",
  displayName: "Name",
  department: "Department",
  active: "Active",
  isRider: "Is rider",
  isDeveloper: "Is developer",
  phone: "Phone number",
  email: "Email",
  manager: "Is manager",
  managerId: "Manager ID",
  thumbnailUrl: "Picture URL",
  createdAt: "Created at",
  updatedAt: "Updated at",
  isAdmin: "Is admin",
  isManager: "Is manager",
  currentWeekRating: "Rating",
  previousWeekRating: "Previous week rating",
  score: "Score",
};

export const unitMap: Record<SLA["unit"], string> = {
  minute: "m",
  hour: "h",
  day: "d",
};
