import React from "react";
import { SvgIconProps } from "@mui/material";
import { makeStyles } from "@providers/Mui";
import { HasClassName } from "@lib/types";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    fontSize: 12,
    [theme.breakpoints.up("sm")]: {
      fontSize: 14,
    },
    fontWeight: "bold",
    color: theme.palette.text.secondary,
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}));

type LabelProps = HasClassName & {
  label: string;
  Icon?: React.ComponentType<SvgIconProps>;
  required?: boolean;
};

export const Label: React.FC<LabelProps> = ({
  label,
  Icon,
  className,
  required,
}) => {
  const classes = useStyles();

  return (
    <label className={clsx(classes.root, className)}>
      {Icon && <Icon fontSize="inherit" className={classes.icon} />}
      {label}
      {required && " *"}
    </label>
  );
};
