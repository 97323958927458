import { makeStyles } from "@providers/Mui";
import { FC } from "react";
import { Activity } from "@functions-types";
import { formatDistance, format, dateFromFirebaseDateTimeOrNumber } from "@lib/date";
import { Grid, Tooltip } from "@mui/material";
import { UserAvatar } from "@components/UserAvatar";
import LockIcon from "@mui/icons-material/Lock";
import clsx from "clsx";

type RecordLayoutProps = {
  record: Activity;
  verb?: React.ReactNode;
};

export const RecordLayout: FC<RecordLayoutProps> = ({ record, verb, children }) => {
  const privateActivity = record.type === "comment" && record.private;
  const classes = useStyles({
    hasBody: !!children,
    isPrivate: privateActivity,
  });

  const isNewThreshold = 5 * 1000; // 5 seconds
  const isNew =
    dateFromFirebaseDateTimeOrNumber(record.createdAt).getTime() + isNewThreshold > Date.now();

  return (
    <div className={clsx(classes.root, isNew && classes.new)}>
      <UserAvatar user={record.createdBy} />
      <div className={classes.content}>
        <Grid container alignItems="center">
          <span className={classes.name}>{record.createdBy?.displayName}</span>

          {verb && <span className={classes.verb}>{verb}</span>}

          <Tooltip title={format(record.createdAt, "dd.MMM.yyyy hh:mm aaa")} placement="top">
            <span className={classes.date}>{formatDistance(record.createdAt)}</span>
          </Tooltip>
          {privateActivity && (
            <Tooltip title="This activity available for you and your team" placement="top">
              <span className={classes.private}>
                <LockIcon className={classes.privateIcon} />
                Private
              </span>
            </Tooltip>
          )}
        </Grid>

        {children && <div className={classes.body}>{children}</div>}
      </div>
    </div>
  );
};

const useStyles = makeStyles<{ hasBody: boolean; isPrivate: boolean }>((theme) => ({
  root: ({ isPrivate }) => ({
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "row",
    alignItems: ({ hasBody }) => (hasBody ? "flex-start" : "center"),
    position: "relative",
    backgroundColor: isPrivate ? theme.palette.warning.light : "transparent",
    padding: theme.spacing(isPrivate ? 1 : 0, 1),
  }),
  new: {
    animation: `$newRecord 1s ease-in-out`,
  },
  "@keyframes newRecord": {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
  content: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  name: {
    fontWeight: "bold",
    color: "#353535",
  },
  verb: {
    marginLeft: theme.spacing(2),
    fontSize: 14,
    color: "#484848",
  },
  date: {
    marginLeft: theme.spacing(2),
    fontSize: 12,
    color: "#505050",
  },
  body: {
    marginTop: theme.spacing(0.5),
  },
  private: {
    fontSize: 12,
    color: "#505050",
    marginLeft: "auto",
    marginRight: theme.spacing(1),
  },
  privateIcon: {
    fontSize: 13,
    marginBottom: -2,
    marginRight: theme.spacing(0.5),
    color: "#505050",
  },
}));
