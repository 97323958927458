import React, { useMemo } from "react";
import { Grid } from "@mui/material";
import { Form, SelectField } from "@components/Form";
import { Status } from "@functions-types";
import { makeStyles } from "@providers/Mui";
import { Button } from "@components/Button";
import { availableTypes } from "./values";
import { workflowStatusValidator } from "./validationSchema";
import { FilteredStatus, WorkflowStatusFormState } from "../types";
import { StatusChip } from "@components/StatusChip";
import { FieldLabel } from "@components/Form/FieldLabel";
import { TransitionsSelector } from "./TransitionsSelector";
import { formStateToWorkflowStatus, workflowStatusToFormState } from "../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[200],
    position: "relative",
  },
  field: {
    "& .MuiOutlinedInput-root": {
      backgroundColor: theme.palette.common.white,
    },
  },
  close: {
    marginLeft: theme.spacing(1),
  },
  select: {
    backgroundColor: theme.palette.common.white,
  },
}));

type WorkflowItemFormProps = {
  onClose: () => void;
  onSave: (status: FilteredStatus) => Promise<void>;
  workflowStatuses: FilteredStatus[];
  statuses: Status[];
  currentStatus?: FilteredStatus;
};

export const WorkflowStatusForm: React.FC<WorkflowItemFormProps> = ({
  onClose,
  onSave,
  workflowStatuses,
  statuses,
  currentStatus,
}) => {
  const classes = useStyles();
  const types = useMemo(() => {
    if (workflowStatuses.some(({ type }) => type === "start")) {
      return availableTypes.filter(({ id }) => id !== "start");
    }

    return availableTypes;
  }, [workflowStatuses]);
  const isEdit = !!currentStatus?.status?.id;

  const handleSubmit = async (values: WorkflowStatusFormState) => {
    await onSave(formStateToWorkflowStatus(values));
  };

  const usedStatusesMap: Record<string, boolean> = useMemo(
    () => workflowStatuses.reduce((acc, cur) => ({
      ...acc,
      [cur.status.id]: true,
    }), {}),
    [workflowStatuses],
  );

  const availableStatuses = useMemo(() => {
    return statuses.filter(({ id }) => !usedStatusesMap[id]);
  }, [usedStatusesMap, statuses]);

  const defaultWorkflowValues = useMemo(
    () => workflowStatusToFormState(currentStatus),
    [currentStatus],
  );

  return (
    <Form<WorkflowStatusFormState>
      formMode="create"
      onSubmit={handleSubmit}
      className={classes.root}
      resolver={workflowStatusValidator}
      defaultValues={defaultWorkflowValues}
    >
      <Grid container direction="column" spacing={2}>
        <Grid item>
          {currentStatus ? (
            <>
              <FieldLabel label="Status" />
              <StatusChip status={currentStatus.status} />
            </>
          ) : (
            <SelectField
              label="Status"
              name="status"
              options={availableStatuses}
              fullWidth
              getOptionText={(option) => option.label}
              className={classes.select}
            />
          )}
        </Grid>
        <Grid item>
          <SelectField
            label="Type"
            name="type"
            options={types}
            fullWidth
            getOptionText={(option) => option.label}
            className={classes.select}
          />
        </Grid>
        <Grid item>
          <TransitionsSelector statuses={statuses} />
        </Grid>
        <Grid item>
          <Button type="submit" variant="contained" size="small">
            {isEdit ? "Save status" : "Add status"}
          </Button>
          {onClose && (
            <Button
              type="button"
              onClick={onClose}
              variant="text"
              size="small"
              className={classes.close}
            >
              Cancel
            </Button>
          )}
        </Grid>
      </Grid>
    </Form>
  );
};
