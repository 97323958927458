import React from "react";
import { Form, TextField } from "@components/Form";
import { useAsync } from "@hooks/useAsync";
import {
  Category,
  createType as createTypeCloudFunction,
  CustomField as CustomFieldType, Group,
  Type,
  updateType as updateTypeCloudFunction,
} from "@cloud-functions";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@providers/Mui";
import { Blocker } from "@components/Blocker";
import { Button } from "@components/Button";
import { typeValidation } from "./validationSchema";
import { useSnackbar } from "@providers/Snackbar";
import { CustomField } from "@pages/Admin/CTI/CustomField";
import { isNonNullish } from "@lib/utils";
import { Caption } from "@components/Typography";
import { UserTypeRestrictionField } from "../UserTypeRestrictionField";
import { logError } from "@lib/logger";
import { ApprovalField } from "../ApprovalField";
import { fromApprovalsConfigToApprovalsInput } from "../CategoryForm/utils";
import { useApprovalsFeature } from "@hooks/featureFlags";

type TypeFormProps = {
  groups: Group[];
  defaultValues?: Type;
  category: Category;
  customFields: CustomFieldType[];
  onSave: (type: Type) => void;
  onClose?: () => void;
  onSelect?: (type: Type) => void;
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[200],
    position: "relative",
  },
  header: {
    fontSize: 16,
    fontWeight: "bold",
    paddingBottom: theme.spacing(1),
  },
  field: {
    "& .MuiOutlinedInput-root": {
      backgroundColor: "white",
    },
  },
  close: {
    marginLeft: theme.spacing(1),
  },
}));

export const TypeForm: React.FC<TypeFormProps> = ({
  defaultValues,
  customFields,
  onSave,
  onClose,
  category,
  onSelect,
  groups,
}) => {
  const classes = useStyles();
  const approvalsFeatureEnabled = useApprovalsFeature();
  const { showError } = useSnackbar();
  const [createType, createTypeState] = useAsync(createTypeCloudFunction);
  const [updateType, updateTypeState] = useAsync(updateTypeCloudFunction, {
    reThrow: true,
  });
  const isEdit = !!defaultValues?.id;

  const handleSubmit = async (values: Type) => {
    try {
      if (defaultValues?.id) {
        const type = await updateType({
          input: {
            id: defaultValues.id,
            updates: {
              ...values,
              category: category.id,
              customFields: (values.customFields || []).filter(isNonNullish).map(({ id }) => id),
              approvals: fromApprovalsConfigToApprovalsInput(values.approvals),
            },
          },
        });

        onSave(type);
      } else {
        const type = await createType({
          input: {
            ...values,
            category: category.id,
            customFields: (values.customFields || []).filter(isNonNullish).map(({ id }) => id),
            approvals: fromApprovalsConfigToApprovalsInput(values.approvals),
          },
        });
        onSelect?.(type);
        onSave(type);
      }
      onClose?.();
    } catch (err) {
      logError(err);

      showError(<Typography variant="body2">Something went wrong during saving type</Typography>);
    }
  };

  return (
    <Form<Type>
      formMode="create"
      onSubmit={handleSubmit}
      defaultValues={defaultValues}
      className={classes.root}
      resolver={typeValidation}>
      {defaultValues?.name && (
        <>
          <Caption>Type</Caption>
          <Typography className={classes.header}>{defaultValues.name}</Typography>
        </>
      )}
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <TextField
            name="name"
            label="Name"
            variant="outlined"
            fullWidth
            size="small"
            className={classes.field}
          />
        </Grid>
        {approvalsFeatureEnabled && (
          <Grid item>
            <ApprovalField className={classes.field} mode="type" groups={groups} />
          </Grid>
        )}
        <Grid item>
          <UserTypeRestrictionField className={classes.field} category={category} />
        </Grid>
        <Grid item>
          <CustomField customFields={customFields} />
        </Grid>
        <Grid item>
          <TextField
            name="faq_link"
            label="FAQ"
            placeholder="FAQ url"
            variant="outlined"
            fullWidth
            size="small"
            className={classes.field}
          />
        </Grid>
        <Grid item>
          <Button type="submit" variant="contained" size="small">
            {isEdit ? "Save type" : "Add type"}
          </Button>
          {onClose && (
            <Button
              type="button"
              onClick={onClose}
              variant="text"
              size="small"
              className={classes.close}>
              Cancel
            </Button>
          )}
        </Grid>
      </Grid>
      <Blocker open={updateTypeState.isLoading || createTypeState.isLoading} />
    </Form>
  );
};
