import {
  Category,
  CreateCategoryRequest,
  CreateCategoryResponse,
} from "@functions-types";
import { makeCloudFunction } from "../makeCloudFunction";

export type { Category };
export const createCategory = makeCloudFunction<
  CreateCategoryRequest,
  CreateCategoryResponse
>("category", "createCategory");
