import React from "react";
import { Chip, CircularProgress, TextField, TextFieldProps, Tooltip } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { makeStyles } from "@providers/Mui";
import { HasClassName } from "@lib/types";

type SearchTextFieldProps = HasClassName & {
  inputRef?: TextFieldProps["inputRef"];
  loading: boolean;
  onChange: (value: string) => void;
  onRequestClose: () => void;
  fullWidth?: boolean;
  showESCButton?: boolean;
  showSearchIcon?: boolean;
  disabled?: boolean;
};

const useStyles = makeStyles((theme) => ({
  loader: {
    position: "absolute",
    bottom: 14,
    right: theme.spacing(2),
  },
  searchIcon: {
    marginRight: theme.spacing(1),
  },
  closeButton: {
    borderRadius: 4,
    marginRight: theme.spacing(1),
  },
}));

export const SearchTextField: React.FC<SearchTextFieldProps> = ({
  inputRef,
  className,
  loading,
  onChange,
  onRequestClose,
  fullWidth = false,
  showESCButton = false,
  showSearchIcon = false,
  disabled = false,
}) => {
  const classes = useStyles();

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Escape") {
      onRequestClose();
    }
  }

  return (
    <TextField
      disabled={disabled}
      inputRef={inputRef}
      className={className}
      fullWidth={fullWidth}
      variant="outlined"
      autoFocus
      onChange={(e) => {
        onChange(e.target.value);
      }}
      onKeyDown={handleListKeyDown}
      InputProps={{
        size: "small",
        startAdornment: showSearchIcon ? <SearchIcon className={classes.searchIcon} /> : null,
        style: {
          paddingRight: "8px",
        },
        placeholder: "Start typing to search...",
        endAdornment: loading ? (
          <CircularProgress color="inherit" size={20} className={classes.loader} />
        ) : showESCButton ? (
          <Tooltip title="Click or Press ESC key to close">
            <Chip
              label="esc"
              size="small"
              className={classes.closeButton}
              onClick={onRequestClose}
            />
          </Tooltip>
        ) : null,
      }}
    />
  );
};
