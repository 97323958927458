import { Controller } from "react-hook-form";
import { ReactNode, useEffect, useMemo, useRef } from "react";
import clsx from "clsx";
import { TextField as MuiTextField, TextFieldProps as MuiTextFieldProps } from "@mui/material";
import { get } from "lodash";
import { Modify } from "@lib/types";
import { makeStyles } from "@providers/Mui";
import { FieldModeSwitch, FieldModeSwitchProps } from "../../FieldModeSwitch";
import { FieldLabel } from "../../FieldLabel";
import { FieldSaveButtons, FieldSaveButtonsProps } from "../../FieldSaveButtons";
import { TextFieldViewer, TextFieldViewerProps } from "./TextFieldViewer";
import { useForm, useOnFieldAcivated } from "@components/Form/FormProvider";

type TextFieldProps = Modify<
  MuiTextFieldProps,
  {
    name: string;
    FieldSaveButtonsProps?: Partial<FieldSaveButtonsProps>;
    TextFieldViewerProps?: Partial<TextFieldViewerProps>;
    FieldModeSwitchProps?: Partial<FieldModeSwitchProps>;
    onActivation?: () => void;
    footer?: ReactNode;
    autoFocusEditMode?: boolean;
  }
>;

export const TextField = ({
  name,
  label,
  FieldSaveButtonsProps,
  TextFieldViewerProps,
  FieldModeSwitchProps,
  size = "small",
  className,
  onActivation,
  footer,
  autoFocusEditMode,
  required,
  ...props
}: TextFieldProps) => {
  const classes = useStyles(props);
  const { methods, activateField } = useForm();
  const inputRef = useRef<HTMLInputElement | null>(null);

  useOnFieldAcivated(name, () => {
    setTimeout(() => {
      inputRef.current?.focus();
    }, 200);
    onActivation?.();
  });

  useEffect(() => {
    if (autoFocusEditMode) {
      activateField(name, true);
    }
  }, [autoFocusEditMode]);

  const defaultValue = get(methods.control._defaultValues, name);

  const inputProps = useMemo(
    () => ({
      ...(props.inputProps ?? {}),
      "data-cy": `cypress_textField_forLabel_${label}_${name}`,
    }),
    [props.inputProps, name],
  );

  return (
    <Controller
      name={name}
      render={({ field, fieldState, formState }) => {
        const hasUnsavedChanges = Boolean(field.value !== defaultValue);

        return (
          <FieldModeSwitch
            field={name}
            edit={
              <>
                <FieldLabel label={label} name={name} required={required} />

                <MuiTextField
                  {...field}
                  {...props}
                  inputProps={inputProps}
                  onChange={(...args) => {
                    field.onChange(...args);
                    props.onChange?.(...args);
                  }}
                  classes={classes}
                  id={name}
                  size={size}
                  disabled={formState.isSubmitting || props.disabled}
                  inputRef={inputRef}
                  className={clsx(classes.root, className)}
                  defaultValue={undefined}
                  value={field.value ?? defaultValue ?? ""}
                  error={Boolean(fieldState.error?.message)}
                  helperText={fieldState.error?.message ?? props.helperText}
                />

                {footer}

                <FieldSaveButtons field={name} {...FieldSaveButtonsProps} />
              </>
            }
            view={
              <TextFieldViewer
                hasUnsavedChanges={hasUnsavedChanges}
                label={label}
                value={defaultValue}
                field={name}
                required={required}
                {...TextFieldViewerProps}
              />
            }
            {...FieldModeSwitchProps}
          />
        );
      }}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    ".MuiOutlinedInput": {
      border: "none",
    },

    "& input": {
      "&[type=number]": {
        "-moz-appearance": "textfield",
      },
      "&::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "&::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
    },
  },
}));
