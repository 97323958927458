import { TicketApprovalRule, TicketApprovalRules } from "../types";

export type TicketApprovalRuleWithId = TicketApprovalRule & { id: string };

export function approvalRulesToArray(rules: TicketApprovalRules): TicketApprovalRuleWithId[] {
  return Object.entries(rules ?? {})
    .map(([id, value]) => ({
      id,
      ...value,
    }))
    .sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
}
