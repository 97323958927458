import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

export const itemValidation = yupResolver(
  yup.object().shape({
    name: yup.string().required("Name is a required field"),
    space: yup.object().required("Space is a required field"),
    faq_link: yup.string().url("FAQ link is invalid").nullable(),
    firstResponseSLA: yup.object().shape({
      value: yup.number().transform(value => (isNaN(value) ? 0 : value)).nullable().min(1, "Please set first response SLA"),
    }),
    nextResponseSLA: yup.object().shape({
      value: yup.number().transform(value => (isNaN(value) ? 0 : value)).nullable().min(1, "Please set next response SLA"),
    }),
    resolveSLA: yup.object().shape({
      value: yup.number().transform(value => (isNaN(value) ? 0 : value)).nullable().min(1, "Please set resolve SLA"),
    }),
  }),
);
