import React from "react";
import { Button as MuiButton, ButtonProps as MuiButtonProps } from "@mui/material";
import { makeStyles } from "@providers/Mui";
import { Modify } from "@lib/types";

const useStyles = makeStyles((theme) => ({
  root: {
    textTransform: "none",
  },
  containedPrimary: {
    backgroundColor: theme.palette.info.main,
  },
}));

type ButtonProps = Modify<MuiButtonProps, {
  _ref?: React.MutableRefObject<HTMLButtonElement | null>;
}>;

export const Button: React.FC<ButtonProps> = ({ _ref, ...props }) => {
  const classes = useStyles(props);
  return <MuiButton {...props} classes={classes} ref={_ref} />;
};
