import { get } from "lodash";
import { CreateTicketFormState } from "./types";
import { CreateTicketRequest, CustomFieldsWithValue } from "@functions-types";
import { isNonNullish } from "@lib/utils";
import { TicketCreateLink } from "@cloud-functions";

export const mergeFieldsWithValue = (
  customFields: CustomFieldsWithValue[],
  customFieldsValues: CreateTicketFormState["customFieldsValues"],
): CustomFieldsWithValue[] => {
  return customFields.map((field) => {
    const value = get(customFieldsValues, field.id) ?? field?.value;

    return {
      ...field,
      value,
    };
  });
};

type CreateTicketInput = CreateTicketRequest["input"];

export const convertFormStateToValues = (
  state: CreateTicketFormState,
  customFields: CustomFieldsWithValue[],
  link: TicketCreateLink,
): CreateTicketInput => {
  return {
    title: state.title || "",
    isPrivate: state.isPrivate || false,
    severity: state.severity?.id || "",
    item: state.item?.id || "",
    country: state.country?.id || "",
    watchers: (state.watchers || []).map((user: any) => user?.id).filter(isNonNullish),
    assignee: state.assignee?.id || "",
    createdFor: state?.createdFor?.id || undefined,
    description: state.description,
    customFields: mergeFieldsWithValue(customFields, state.customFieldsValues),
    attachments: state.attachments,
    link,
  };
};
