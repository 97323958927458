import { useMemo } from "react";
import { FormControl, MenuItem, Select, SelectProps, FormHelperText } from "@mui/material";
import { Controller } from "react-hook-form";
import { get } from "lodash";
import { Modify } from "@lib/types";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";
import { FieldMode, FieldModeSwitch } from "@components/Form/FieldModeSwitch";
import { SelectFieldViewer, SelectFieldViewerProps } from "./SelectFieldViewer";
import React, { useRef, useState } from "react";
import { FieldLabel } from "@components/Form/FieldLabel";
import { useForm, useOnFieldAcivated } from "@components/Form/FormProvider";
import { makeStyles } from "@providers/Mui";
import clsx from "clsx";

export type SelectOption = string | { id: string };

type SelectFieldProps<T extends SelectOption> = Modify<
  SelectProps,
  {
    name: string;
    controlSx?: SxProps<Theme>;
    options: T[];
    mode?: FieldMode;
    renderOption?: (option: T) => React.ReactNode;
    valueClassName?: string;
    viewModeClassName?: string;
    getOptionText?: (option: T) => string;
    SelectFieldViewerProps?: Partial<SelectFieldViewerProps<T>>;
    submitOnChange?: boolean;
    helperText?: React.ReactNode;
  }
>;

const useStyles = makeStyles((theme) => ({
  root: {
    "& > fieldset": {
      top: 0,
    },
    "& legend": {
      display: "none",
    },
  },
  hiddenIcon: {
    display: "none",
  },
  error: {
    color: theme.palette.error.dark,
    fontSize: 12,
    paddingLeft: 14,
    paddingTop: theme.spacing(0.5),
  },
}));

export const SelectField = <T extends SelectOption>({
  name,
  label,
  id,
  controlSx,
  options,
  mode,
  renderOption,
  valueClassName,
  className,
  getOptionText,
  viewModeClassName,
  SelectFieldViewerProps = {},
  submitOnChange = false,
  helperText,
  required,
  ...props
}: SelectFieldProps<T>) => {
  const { methods, triggerSubmit, resetActiveFields, formMode, disabled: formDisabled } = useForm();
  const defaultValue = get(methods.control._defaultValues, name);
  const classes = useStyles();

  const getOptionLabel = (option: T) => {
    if (!option) return "-N/A-";

    return getOptionText?.(option) ?? option ?? "-";
  };

  const defaultValueLabel = getOptionLabel(defaultValue);
  const selectRef = useRef<HTMLUListElement | null>(null);

  const [open, setOpen] = useState(false);
  useOnFieldAcivated(name, () => {
    selectRef.current?.focus();
    setOpen(true);
  });

  const inputProps = useMemo(
    () => ({
      ...(props.inputProps ?? {}),
      "data-cy": `cypress_select_forLabel_${label}_${name}`,
    }),
    [props.inputProps, name],
  );

  return (
    <Controller
      name={name}
      render={({ field, fieldState }) => {
        const value = field.value ?? defaultValue ?? "";

        return (
          <FieldModeSwitch
            mode={mode}
            field={name}
            edit={
              <>
                <FormControl sx={controlSx} fullWidth={props.fullWidth}>
                  <FieldLabel label={label} name={name} required={required} />
                  <Select
                    {...props}
                    {...field}
                    inputProps={inputProps}
                    id={name}
                    data-cy={`cypress_select_${name}`}
                    onChange={(event, ...rest) => {
                      field.onChange(event.target.value);
                      props.onChange?.(event, ...rest);
                      selectRef.current?.blur();

                      if (formMode !== "create" && submitOnChange) {
                        triggerSubmit();
                      }
                    }}
                    disabled={methods.formState.isSubmitting || formDisabled || props.disabled}
                    fullWidth
                    ref={selectRef}
                    labelId={id}
                    size="small"
                    error={Boolean(fieldState.error?.message)}
                    value={value}
                    classes={{
                      outlined: valueClassName,
                      icon: formDisabled ? classes.hiddenIcon : undefined,
                    }}
                    onClose={() => {
                      if (submitOnChange) {
                        resetActiveFields();
                      }
                      setOpen(false);
                    }}
                    onOpen={() => {
                      setOpen(true);
                    }}
                    open={open}
                    renderValue={(option) => <>{getOptionLabel(option)}</>}
                    className={clsx(classes.root, className)}>
                    {options.map((option) => {
                      if (renderOption) {
                        return renderOption(option);
                      }

                      return (
                        <MenuItem
                          data-cy={`cypress_selectItem_${name}`}
                          value={option as unknown as string}
                          key={typeof option === "string" ? option : option.id}>
                          {typeof option === "string" ? option : getOptionLabel(option)}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {fieldState.error?.message && (
                    <FormHelperText error>{fieldState.error.message}</FormHelperText>
                  )}
                </FormControl>
                {helperText && <FormHelperText>{helperText}</FormHelperText>}
              </>
            }
            view={
              <SelectFieldViewer
                {...SelectFieldViewerProps}
                label={label}
                value={
                  // TODO: maybe apply same logic to other fields
                  // here we show new value while submitting the form
                  methods.formState.isSubmitting ? getOptionLabel(field.value) : defaultValueLabel
                }
                field={name}
                className={viewModeClassName}
              />
            }
          />
        );
      }}
    />
  );
};
