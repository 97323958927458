import { CTIApprovalConfigRule } from "@functions-types";
import { makeStyles } from "@providers/Mui";
import clsx from "clsx";
import { FC, useMemo } from "react";
import { ctiApprovalRuleTypeLabels } from "./utils/ctiApprovalRuleTypeLabels";

type ApprovalRulePreviewProps = {
  value: CTIApprovalConfigRule;
  className?: string;
};

export const ApprovalRulePreview: FC<ApprovalRulePreviewProps> = ({ value, className }) => {
  const classes = useStyles();
  const summary = useMemo(() => {
    switch (value.type) {
      case "manager_1":
        return ctiApprovalRuleTypeLabels["manager_1"];
      case "manager_2":
        return ctiApprovalRuleTypeLabels["manager_2"];
      case "users":
        if (value.users?.length === 1) {
          const user = value.users[0];
          return `${user.firstName} ${user.lastName} (${user.email})`;
        }

        return `At least ${value.count ?? 1} approvals from ${value.users
          ?.map((u) => `${u.firstName} ${u.lastName} (${u.email})`)
          .join(", ")}`;

      case "group":
        return `At least ${value.count ?? 1} approvals from the group (${value.group?.name})`;

      default:
        return "N/A";
    }
  }, [value]);

  return <div className={clsx(classes.root, className)}>{summary}</div>;
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(1),
    borderRadius: 5,
    "&:not(:last-child)": {
      marginBottom: theme.spacing(1),
    },
  },
}));
