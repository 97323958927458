import React, { useEffect, useState } from "react";
import { Button, Grid, MenuItem, MenuList, Typography } from "@mui/material";
import { makeStyles } from "@providers/Mui";

type SectionPops<T extends { id: string; name: string }> = {
  header?: string;
  items?: T[];
  editForm?: React.ReactNode;
  creationButtonLabel?: string;
  creationForm?: (onCancel: () => void) => React.ReactNode;
  activeItem?: T | null;
  onItemClick?: (item: T) => void;
};

const useStyles = makeStyles((theme) => ({
  root: {
    borderRight: "1px solid rgb(224, 224, 224)",

    "&:last-child": {
      borderRight: "none",
    },
  },
  header: {
    padding: theme.spacing(1, 2),
    fontSize: 16,
    fontWeight: "bold",
  },
  button: {
    color: theme.palette.info.main,
    textTransform: "none",
  },
  empty: {
    padding: theme.spacing(3),
    fontSize: 18,
    color: theme.palette.secondary.main,
    textAlign: "center",
  },
  menuItem: {
    whiteSpace: "normal",
  },
}));

export const Section = <T extends { id: string; name: string }>({
  header,
  items,
  editForm,
  creationForm,
  creationButtonLabel,
  activeItem,
  onItemClick,
}: SectionPops<T>) => {
  const classes = useStyles();
  const [formOpen, setFormOpen] = useState(false);

  useEffect(() => {
    setFormOpen(false);
  }, [activeItem]);

  return (
    <Grid item sm={3} container direction="column" className={classes.root}>
      {editForm}
      {header && (
        <Typography variant="h6" className={classes.header}>
          {header}
        </Typography>
      )}
      {items?.length === 0 && <Typography className={classes.empty}>List is empty</Typography>}
      {items && (
        <MenuList>
          {items.map((item) => (
            <MenuItem
              key={item.id}
              className={classes.menuItem}
              selected={activeItem?.id === item.id}
              onClick={() => onItemClick?.(item)}>
              {item.name}
            </MenuItem>
          ))}
        </MenuList>
      )}
      {creationButtonLabel && !formOpen && (
        <Button className={classes.button} onClick={() => setFormOpen(true)}>
          {creationButtonLabel}
        </Button>
      )}
      {formOpen && creationForm && creationForm(() => setFormOpen(false))}
    </Grid>
  );
};
