import { Ticket, WorkflowTransition } from "@functions-types";

export const getTicketTransitions = (
  ticket: Ticket,
  currentStatusId?: string
): WorkflowTransition[] | undefined => {
  const ticketWorkflowStatuses = ticket.item?.space?.workflow?.statuses ?? [];
  const statusId = currentStatusId ?? ticket.status?.id;
  const activeWorkflowStatus = ticketWorkflowStatuses
    .find(({ status }) => status?.id === statusId);

  return activeWorkflowStatus?.transitions;
};
