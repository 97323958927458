import React from "react";
import ErrorIcon from "@mui/icons-material/Error";
import { makeStyles } from "@providers/Mui";
import { Grid } from "@mui/material";
import { format } from "@lib/date";
import clsx from "clsx";

type DeadlineProps = {
  date: number | null;
  resolved: boolean;
};

const useStyles = makeStyles((theme) => ({
  root: {
    whiteSpace: "nowrap",
  },
  delayed: {
    fontWeight: 700,
  },
  icon: {
    color: theme.palette.error.dark,
    fontSize: 20,
    marginLeft: theme.spacing(1),
  },
}));

export const Deadline: React.FC<DeadlineProps> = ({ date = 0, resolved }) => {
  const classes = useStyles();
  const delayed = date && Date.now() > date;

  return (
    <Grid container wrap="nowrap">
      <span
        className={clsx(
          classes.root,
          delayed && !resolved ? classes.delayed : undefined,
        )}>
        {date ? format(date, "dd MMMM yyyy") : "N/A"}
      </span>
      {delayed && !resolved && <ErrorIcon className={classes.icon} />}
    </Grid>
  );
};
