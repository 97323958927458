import { default as ReactQuill } from "react-quill";

import { searchUsers } from "@cloud-functions";

type ReactQuillProps = React.ComponentProps<typeof ReactQuill>;

export const modules: ReactQuillProps["modules"] = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    [{ color: [] }, { background: [] }],
    ["code-block"],
    ["link"],
  ],
  mention: {
    allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
    mentionDenotationChars: ["@"],
    source: async function (
      searchTerm: string,
      renderList: (matches: any[], query: string) => void,
    ) {
      const users = await searchUsers({ query: searchTerm });
      const mappedUsers = users.map((user) => ({
        id: user.id,
        value: user.displayName,
      }));

      renderList(mappedUsers, searchTerm);
    },
  },
};

export const formats: ReactQuillProps["formats"] = [
  "header",
  "font",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "mention",
  "color",
  "background",
  "code-block",
];
