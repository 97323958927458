import React, { useCallback, useEffect, useMemo, useState } from "react";
import { fieldsMap } from "@lib/constants";
import PersonIcon from "@mui/icons-material/Person";
import { Button, CircularProgress, Typography } from "@mui/material";
import { UserCard } from "@components/UserCard";
import { AsyncAutocomplete } from "@components/Form";
import { Label } from "@components/Label";
import { useAssignToMyselfFeature } from "@hooks/featureFlags";
import { useForm } from "@components/Form/FormProvider";
import { useUser } from "@providers/Auth";
import { Ticket, User } from "@functions-types";
import { useWatch } from "react-hook-form";
import { makeStyles } from "@providers/Mui";
import { isNonNullish } from "@lib/utils";
import { isSpaceManagerOrMember } from "@lib/can";

type AssigneeFieldProps = {
  canAct: boolean;
  searchUser: (query: string) => Promise<User[]>;
  ticket?: Ticket;
};

const useStyles = makeStyles((theme) => ({
  assignee: {
    width: "100%",
    backgroundColor: "rgb(231 231 231)",
  },
  assigneeWarning: {
    backgroundColor: theme.palette.red[100],
    padding: theme.spacing(2),
  },
  actionLoader: {
    marginLeft: "auto",
    marginRight: theme.spacing(1),
  },
  actionButton: {
    marginLeft: "auto",
    textTransform: "none",
    paddingTop: 0,
    paddingBottom: 0,
  },
  actionButtonText: {
    color: theme.palette.text.secondary,
    fontWeight: "bold",
    fontSize: 14,
  },
}));

export const AssigneeField: React.FC<AssigneeFieldProps> = ({
  canAct,
  searchUser,
  ticket,
}) => {
  const classes = useStyles();
  const assignToMyselfFeatureEnabled = useAssignToMyselfFeature();
  const { triggerSubmit, methods } = useForm();
  const assignee: User | null = useWatch({ name: "assignee" });
  const { user } = useUser();
  const [isTakingTicket, setIsTakingTicket] = useState(false);
  const isAssignedToMyself = useMemo(
    () => assignee?.id === user.id,
    [assignee, user],
  );

  const defaultAssigneeOptions = useMemo(() => {
    return [ticket?.assignee].filter(isNonNullish);
  }, [ticket?.assignee]);

  const assignToMyself = useCallback(() => {
    methods.setValue("assignee", user);
    triggerSubmit();
    setIsTakingTicket(true);
  }, [user]);

  const shouldShowAssigneedWarning = useMemo(() => {
    if (!ticket?.item?.space || !ticket?.assignee) return false;
    if (ticket?.assignee?.isAdmin || ticket?.assignee?.isManager) return false;
    if (user.id === ticket?.assignee?.id) return false;

    return !isSpaceManagerOrMember(ticket.assignee, ticket?.item?.space);
  }, [ticket?.assignee, ticket?.item?.space, user]);

  useEffect(() => {
    if (!methods.formState.isSubmitting && isTakingTicket) {
      setIsTakingTicket(false);
    }
  }, [methods.formState.isSubmitting, isTakingTicket]);

  return (
    <>
      <AsyncAutocomplete
        disablePortal
        label={<Label label={fieldsMap.assignee} Icon={PersonIcon} />}
        action={
          assignToMyselfFeatureEnabled &&
          (!isAssignedToMyself || isTakingTicket) &&
          canAct && (
            <>
              {methods.formState.isSubmitting ? (
                <CircularProgress
                  size={12}
                  className={classes.actionLoader}
                />
              ) : (
                <Button
                  variant="text"
                  type="button"
                  className={classes.actionButton}
                  onClick={assignToMyself}
                  disabled={methods.formState.isSubmitting}
                  disableRipple>
                  <Typography className={classes.actionButtonText}>
                    <>Take it</>
                  </Typography>
                </Button>
              )}
            </>
          )
        }
        name="assignee"
        getOptionText={(o) => `${o.displayName} (${o.email})`}
        isOptionEqualToValue={(option, value) =>
          option.id === value.id
        }
        search={searchUser}
        defaultOptions={defaultAssigneeOptions.length ? defaultAssigneeOptions : undefined}
        key={ticket?.assignee?.id ?? "assignee"}
        onCancelChangesClick={() => {
          methods.resetField("category");
          methods.resetField("type");
          methods.resetField("item");
          methods.resetField("assignee");
        }}
        renderItem={(option) => {
          const { email, displayName, active } = option;
          if (!displayName) {
            return <UserCard user={null} />;
          }
          return <UserCard user={{ displayName, email, active }} />;
        }}
      />
      {shouldShowAssigneedWarning && (
        <Typography className={classes.assigneeWarning}>
          Ticket is assigned to a user outside the space, this gives
          the assignee ({ticket?.assignee?.displayName}) full access
          on this ticket.
        </Typography>
      )}
    </>
  );
};
