import { Divider, Drawer, IconButton, List, ListItem, ListItemText } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useAuth, useUser } from "@providers/Auth";
import { makeStyles } from "@providers/Mui";
import { FC } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import { useSpaces } from "@providers/Spaces";
import { useIsMobile } from "@hooks/useIsMobile";
import { useUserProfileFeature } from "@hooks/featureFlags";

type MobileDrawerProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
};

export const MobileDrawer: FC<MobileDrawerProps> = ({ open, setOpen }) => {
  const location = useLocation();
  const auth = useAuth();
  const { user } = useUser();
  const classes = useStyles();

  const appBarDisplayName = user.email ?? user.phone;
  const isMobile = useIsMobile();
  const userProfileFeatureEnabled = useUserProfileFeature();

  const { selectedSpace, loading: loadingSpaces } = useSpaces();
  const selectedSpaceRoute = `/space/${selectedSpace?.key}/tickets`;

  const homeSelected = location.pathname === "/";
  const ticketsSelected = location.pathname.startsWith("/space");
  const settingsSelected = location.pathname.startsWith("/admin");
  const userProfileSelected = location.pathname.startsWith("/profile");

  return (
    <Drawer keepMounted anchor="left" open={open && isMobile} onClose={() => setOpen(false)}>
      <List sx={{ width: 300 }}>
        <ListItem>
          <ListItemText
            sx={{
              textAlign: "right",
            }}
            primary={
              <IconButton onClick={() => setOpen(false)}>
                <CloseIcon />
              </IconButton>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText primary={<strong>{appBarDisplayName}</strong>} />
        </ListItem>

        <Divider />
        <ListItem button selected={homeSelected}>
          <ListItemText
            primary={
              <Link to="/" className={classes.drawerLink}>
                Home
              </Link>
            }
          />
        </ListItem>
        {!loadingSpaces && selectedSpace && (
          <ListItem button selected={ticketsSelected}>
            <ListItemText
              primary={
                <Link to={selectedSpaceRoute} className={classes.drawerLink}>
                  Tickets
                </Link>
              }
            />
          </ListItem>
        )}
        {(user?.isAdmin || user?.isManager) && (
          <ListItem button selected={settingsSelected}>
            <ListItemText
              primary={
                <Link to="/admin" className={classes.drawerLink}>
                  Settings
                </Link>
              }
            />
          </ListItem>
        )}
        <Divider />
        <ListItem button selected={userProfileSelected} disabled={!userProfileFeatureEnabled}>
          <ListItemText
            primary={
              <Link to="/profile" className={classes.drawerLink}>
                Profile
              </Link>
            }
          />
        </ListItem>
        <ListItem button onClick={auth.logout}>
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
    </Drawer>
  );
};

const useStyles = makeStyles((theme) => ({
  drawerLink: {
    display: "block",
    width: "100%",
    "&, &:visited": {
      textDecoration: "none",
      color: theme.palette.common.black,
    },
  },
}));
