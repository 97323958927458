import { FilterRequestMode, FilterType } from "./types";

import { EncodedTicketPopulatedSnapshot, WhereClause } from "@functions-types";

export const filterToString = (filter: FilterType[]) =>
  filter.map(({ label, operation, field }) => `${field} ${operation} ${label}`).join(" | ");

export const unwrapValue = (value: unknown): unknown => {
  return Array.isArray(value)
    ? value.map((item) => unwrapValue(item?.id ?? ""))
    : value && typeof value === "object"
    ? (value as { id: unknown }).id
    : value;
};

function getFieldQuery(fieldId: FilterType["field"]["id"], value: unknown) {
  // if we filter by field == null then we don't need .id for non-primitive fields
  if (!value) {
    return fieldId;
  }

  // in other case we need .id
  if (
    [
      "assignee",
      "createdBy",
      "trulyCreatedBy",
      "item",
      "category",
      "type",
      "country",
      "severity",
      "status",
      "group",
    ].includes(fieldId)
  ) {
    return `${fieldId}.id`;
  }

  return fieldId; // primitives
}

export const filtersToWhereClauses = (
  filters: FilterType[],
  mode: FilterRequestMode,
): WhereClause<keyof EncodedTicketPopulatedSnapshot>[] => {
  const modeClause: WhereClause<keyof EncodedTicketPopulatedSnapshot> = [
    "resolved",
    "==",
    mode === "resolved",
  ];

  const mappedFilters: WhereClause<keyof EncodedTicketPopulatedSnapshot>[] = filters.map(
    (filter) => {
      const field = getFieldQuery(
        filter.field.id,
        filter.value,
      ) as keyof EncodedTicketPopulatedSnapshot;

      const value = !["in", "not-in"].includes(filter.operation)
        ? Array.isArray(filter.value)
          ? unwrapValue(filter.value[0])
          : unwrapValue(filter.value)
        : unwrapValue(filter.value);

      return [field, filter.operation, value];
    },
  );

  return [modeClause, ...mappedFilters];
};

export const getFilterKey = (filter: FilterType[]) => {
  return filter.map(({ value }) => JSON.stringify(value)).join();
};
