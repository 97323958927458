import { TicketApprovalRules } from "../types";
import { approvalRulesToArray } from "./approvalRulesToArray";
import { ruleIsFullfilled, ruleIsRejected } from "./getApprovalOverallState";

export function getActiveIndex(rules: TicketApprovalRules) {
  return approvalRulesToArray(rules).findIndex((rule) => {
    const isRejected = ruleIsRejected(rule);

    if (isRejected) return true;

    return !isRejected && !ruleIsFullfilled(rule);
  });
}
