import { useMemo } from "react";
import { Grid } from "@mui/material";
import { makeStyles } from "@providers/Mui";
import PersonIcon from "@mui/icons-material/Person";
import { Card } from "@components/Card";
import { useUser } from "@providers/Auth";
import { H5, H6, Subtitle } from "@components/Typography";

type UserInformationProps = {};

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  title: {
    marginBottom: theme.spacing(4),
  },
  item: {
    margin: theme.spacing(4, 0),
    "&:first-of-type": {
      marginTop: 0,
    },
  },
  label: {
    color: theme.palette.grey[800],
  },
  avatar: {
    width: 100,
    height: 100,
    borderRadius: 100,
    backgroundColor: theme.palette.blue[500],
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: theme.spacing(0, "auto", 4, "auto"),
    [theme.breakpoints.up("sm")]: {
      margin: "0 auto",
    },
  },
  avatarIcon: {
    color: theme.palette.common.white,
    fontSize: 32,
  },
}));

export const UserInformation: React.FC<UserInformationProps> = () => {
  const classes = useStyles();
  const { user } = useUser();

  const items = useMemo(
    () => [
      { title: "Display name", value: user.displayName },
      { title: "Email", value: user.email },
      { title: "Department", value: user.department },
    ],
    [user],
  );

  return (
    <Card>
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <H5 className={classes.title}>My profile</H5>
        </Grid>
        <Grid xs={12} md={3}>
          <div className={classes.avatar}>
            <PersonIcon className={classes.avatarIcon} />
          </div>
        </Grid>
        <Grid xs={12} md={9}>
          {items.map((item) => (
            <div key={item.title} className={classes.item}>
              <Subtitle>{item.title}</Subtitle>
              <H6>{item.value}</H6>
            </div>
          ))}
        </Grid>
      </Grid>
    </Card>
  );
};
