import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

export const customPageValidator = yupResolver(
  yup.object().shape({
    name: yup.string().required("Name is a required field"),
    customFields: yup
      .array()
      .test(
        {
          test: (value) => !!value?.length,
          message: "Please add at least one field",
        },
      ),
  }),
);