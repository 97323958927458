import { auth } from "@@firebase";
import {
  signInWithRedirect,
  GoogleAuthProvider,
  browserLocalPersistence,
} from "firebase/auth";

export async function login() {
  const provider = new GoogleAuthProvider();

  provider.setCustomParameters({
    hd: "quickcommerce.org",
  });

  await auth.setPersistence(browserLocalPersistence);
  await signInWithRedirect(auth, provider);
}
