import React from "react";
import { Card } from "@components/Card";
import { H6 } from "@components/Typography";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@providers/Mui";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    minHeight: theme.spacing(20),
  },
  header: {},
}));

export const Performance: React.FC = () => {
  const classes = useStyles();
  return (
    <Grid item xs={12}>
      <Card className={classes.root}>
        <H6 className={classes.header}>My perfomance metrics vs. my team’s average</H6>
        <Typography>Coming soon...</Typography>
      </Card>
    </Grid>
  );
};
