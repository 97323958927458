import React, { Suspense } from "react";
import { Grid } from "@mui/material";
import { RichTextEditor, TextField } from "@components/Form";
import { fieldsMap } from "@lib/constants";
import { Ticket } from "@functions-types";
import { useLinkedTicketsFeature, useTicketAttachmentsFeature } from "@hooks/featureFlags";
import { isNonNullish } from "@lib/utils";

import { AttachmentSection } from "../AttachmentSection";

const LinkedTicketsSection = React.lazy(() => import("../LinkedTicketsSection/lazy"));

type TicketContentProps = {
  ticket?: Ticket;
};

export const TicketContent: React.FC<TicketContentProps> = ({ ticket }) => {
  const ticketAttachmentsEnabled = useTicketAttachmentsFeature();
  const linkedTicketsFeatureEnabled = useLinkedTicketsFeature();

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <TextField
          required
          name="title"
          data-cy="cypress_ticketTitle"
          label={fieldsMap.title}
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item>
        <RichTextEditor
          label={fieldsMap.description}
          name="description"
          placeholder="Add text description..."
          minRows={4}
          required
        />
      </Grid>
      {ticketAttachmentsEnabled && (
        <Grid item>
          <AttachmentSection attachments={ticket?.attachments?.filter?.(isNonNullish)} />
        </Grid>
      )}
      {linkedTicketsFeatureEnabled && ticket && (
        <Grid item sx={{ width: "100%" }}>
          <Suspense fallback="Loading...">
            <LinkedTicketsSection ticket={ticket} />
          </Suspense>
        </Grid>
      )}
    </Grid>
  );
};
