import { makeStyles } from "@providers/Mui";
import { FC, useState } from "react";
import { CommentActivity } from "@functions-types";
import { Button, Typography } from "@mui/material";
import { CommentBox } from "./CommentBox";
import { useUser } from "@providers/Auth";
import { useDispatch } from "@providers/EventsProvider";
import { AttachmentCard } from "@components/TicketCard/AttachmentSection/AttachmentCard";
import { useCommentAttachmentsFeature } from "@hooks/featureFlags";
import { QuillDelta, QuillViewer } from "@components/QuillEditor";
import { TicketActivityEvents } from "./types";
import { RecordLayout } from "./RecordLayout";
import { useTicketActivity } from "./TicketActivityProvider";

type CommentViewProps = {
  comment: CommentActivity;
};

type CommentViewMode = "view" | "edit";

export const CommentView: FC<CommentViewProps> = ({ comment }) => {
  const { user } = useUser();
  const { teamMember } = useTicketActivity();
  const [mode, setMode] = useState<CommentViewMode>("view");
  const dispatch = useDispatch<TicketActivityEvents>();
  const classes = useStyles();

  const makeCommentPublic = async () => {
    dispatch("makeCommentPublic", comment);
  };

  const makeCommentPrivate = async () => {
    dispatch("makeCommentPrivate", comment);
  };

  const commentAttachmentsFeatureEnabled = useCommentAttachmentsFeature();

  return (
    <RecordLayout record={comment}>
      {mode === "view" && (
        <Typography variant="body1" component="div" className={classes.text}>
          <QuillViewer
            value={
              typeof comment.text === "string"
                ? comment.text // legacy
                : (comment.text.json as QuillDelta)
            }
          />
        </Typography>
      )}

      {mode === "view" && commentAttachmentsFeatureEnabled && (
        <>
          {comment.attachments?.map((attachment) => (
            <AttachmentCard {...attachment} key={attachment?.fileRef} />
          ))}
        </>
      )}

      {mode === "edit" && (
        <CommentBox
          comment={comment}
          onCancel={() => setMode("view")}
          onUpdate={() => setMode("view")}
        />
      )}

      <div className={classes.actions}>
        {/* TODO: should match here by firestore/google id */}
        {mode === "view" &&
          user?.id === comment.createdBy?.id &&
          teamMember &&
          comment.private && (
            <Button
              variant="text"
              className={classes.action}
              onClick={makeCommentPublic}>
              Make public
            </Button>
          )}

        {/* TODO: should match here by firestore/google id */}
        {mode === "view" &&
          user?.id === comment.createdBy?.id &&
          teamMember &&
          !comment.private && (
            <Button
              variant="text"
              className={classes.action}
              onClick={makeCommentPrivate}>
              Make private
            </Button>
          )}

        {/* TODO: should match here by firestore/google id */}
        {mode === "view" && user?.id === comment.createdBy?.id && (
          <Button
            variant="text"
            className={classes.action}
            onClick={() => setMode("edit")}>
            Edit
          </Button>
        )}

        {/* TODO: should match here by firestore/google id */}
        {mode === "view" && user?.id === comment.createdBy?.id && (
          <Button
            variant="text"
            className={classes.action}
            onClick={() => {
              dispatch("requestDeleteComment", comment);
            }}>
            Delete
          </Button>
        )}
      </div>
    </RecordLayout>
  );
};

const useStyles = makeStyles((theme) => ({
  text: {
    marginTop: theme.spacing(0.5),
    "& .ql-editor": {
      padding: 0,
    },
  },
  actions: {
    marginTop: theme.spacing(1),
  },
  action: {
    marginRight: theme.spacing(2),
    textTransform: "none",
    padding: 0,
    minWidth: 0,
    lineHeight: "12px",
    color: "grey",
  },
  line: {
    minHeight: 24,
    margin: 0,
  },
}));
